import { CustomHeaderProps } from '@ag-grid-community/react';
import * as React from 'react';
import { useState } from 'react';
import { HStack, Icon, IconButton, Text } from 'components/design/next';
import { Input, Spacer } from '@chakra-ui/react';
import { useColumnHeaderStore } from 'app/pages/reports/data-sets/components/data-set-page/data-set-table/column-header-store';

export interface ColumnHeaderProps extends CustomHeaderProps {
  onColumnRename?: (columnId: string, newName: string) => void;
}

export const ColumnHeader = (props: ColumnHeaderProps) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [name, setName] = React.useState(props.displayName);
  // Blur runs right after pressing escape, so we recognize the reverting case with this
  const lastKeyPressEventRef = React.useRef<React.KeyboardEvent | null>(null);
  const editingColumnId = useColumnHeaderStore(store => store.editingColumnId);
  const setEditingColumnId = useColumnHeaderStore(store => store.setEditingColumnId);

  const handleEditStart = React.useCallback(() => {
    setIsEditing(true);
    if (!isEditing) {
      setTimeout(() => inputRef.current?.select(), 100);
    }
  }, [isEditing]);

  React.useEffect(
    function editNewColumnName() {
      if (!isEditing && editingColumnId && editingColumnId === props.column.getColId()) {
        handleEditStart();
        setEditingColumnId(null);
      }
    },
    [editingColumnId, handleEditStart, isEditing, props.column, setEditingColumnId],
  );

  const handleSave = () => {
    if (lastKeyPressEventRef.current?.key === 'Escape') return;
    setIsEditing(false);
    if (name == props.displayName) return;
    props.onColumnRename?.(props.column.getColId(), name);
  };

  const handleRevert = () => {
    setName(props.displayName);
    setIsEditing(false);
  };

  return (
    <HStack
      _hover={{
        '& .chakra-button': {
          visibility: isEditing ? 'hidden' : 'visible',
        },
      }}
      justifyContent="space-between"
      w="full"
    >
      <HStack w="full" onClick={handleEditStart} overflowX="hidden">
        <Input
          display={isEditing ? 'auto' : 'none'}
          disabled={!isEditing}
          aria-label="Edit column name"
          autoFocus
          fontWeight="bold"
          ref={inputRef}
          value={name}
          onChange={e => setName(e.target.value)}
          onBlur={handleSave}
          onKeyDown={e => {
            lastKeyPressEventRef.current = e;
            if (e.key === 'Escape') handleRevert();
            else if (e.key === 'Enter') handleSave();
          }}
          p="0"
        />
        <Text
          display={isEditing ? 'none' : 'auto'}
          fontWeight="bold"
          color="gray.700"
          overflowX="hidden"
          textOverflow="ellipsis"
        >
          {name}
        </Text>
        <IconButton
          visibility="hidden"
          minW="unset"
          variant="ghost"
          w={5}
          h={5}
          aria-label="Edit column name"
          _hover={{ bgColor: 'transparent' }}
          onClick={handleEditStart}
          color="gray.400"
          icon={<Icon icon="edit" size="3.5" />}
        />
      </HStack>
      <Spacer />
      <IconButton
        ref={buttonRef}
        variant="ghost"
        size="sm"
        icon={<Icon size="4" variant="far" icon="ellipsis" color="gray.500" />}
        aria-label="show column menu"
        onClick={e => {
          e.stopPropagation();
          buttonRef.current && props.showColumnMenu(buttonRef.current);
        }}
      />
    </HStack>
  );
};

import { EmailFormFieldWidget } from '@process-street/subgrade/process';
import { UpdateFormFieldValueMutationResponse } from 'features/widgets/query-builder';
import { Option } from 'space-monad';
import { ActionObject, actions, ActorRefFrom, assign, createMachine, spawn, StateFrom } from 'xstate';
import { FormFieldMachineBuilderProps, WithFormFieldMachineEvent } from '../../../types';
import {
  makeValidationMachine,
  ValidationActorRef,
  ValidationParentEvent,
  ValidationSelectors,
} from '../validation-machine';
import { makeEmailValidationSchema } from 'pages/forms/_id/edit/components/form-fields/email-form-field/email-form-field-schema';
import {
  makeRulesEngineTargetMachine,
  sendRulesActorFormFieldValueUpdate,
} from '../../form-response-body/rules-engine-machine';
import { makeUpdateFormFieldValueMutation } from '../make-update-form-field-value-mutation';
import { FormResponseErrorToasts } from 'pages/responses/_id/utils/form-response-error-toasts';
import { AxiosError } from 'axios';
import { makeErrorLoggerAction } from 'app/utils/machines';

const { send, sendParent, cancel, forwardTo } = actions;

export type Context = {
  value: string;
  widget: EmailFormFieldWidget;
  validationActor: ValidationActorRef<string | undefined>;
  rulesEngineTargetActor: ActorRefFrom<typeof makeRulesEngineTargetMachine>;
  inputNode: HTMLElement | null;
};

export type Event = WithFormFieldMachineEvent<ValidationParentEvent>;

export const makeEmailFormFieldMachine = ({
  formFieldWidget,
  formFieldValue,
  autoFocus,
  checklistRevisionId,
  sharedContext,
  isEditable,
  inputNode,
}: FormFieldMachineBuilderProps<EmailFormFieldWidget>) => {
  const stringValue = Option(formFieldValue)
    .map(ffv => ffv.fieldValue.value)
    .map(String)
    .getOrElse('');

  const validationSchema = makeEmailValidationSchema({
    required: formFieldWidget.required,
    constraints: formFieldWidget.constraints,
  });

  const initialState = isEditable ? 'enabled' : 'disabled';
  const id = `email-form-field-machine:${formFieldWidget.id}`;

  return createMachine(
    {
      id,
      context: () => ({
        inputNode,
        value: stringValue,
        widget: formFieldWidget,
        validationActor: spawn(makeValidationMachine({ validationSchema, initialValue: stringValue }), {
          name: 'validation-actor',
        }),
        rulesEngineTargetActor: spawn(
          makeRulesEngineTargetMachine({ type: 'widget', widgetHeaderGroupId: formFieldWidget.header.group.id }),
          { name: 'hidden-by-rule-actor', sync: true },
        ),
      }),
      schema: {
        context: {} as Context,
        events: {} as Event,
        services: {} as {
          updateFormFieldValue: {
            data: UpdateFormFieldValueMutationResponse;
          };
        },
      },
      predictableActionArguments: true,
      preserveActionOrder: true,
      tsTypes: {} as import('./email-form-field-machine.typegen').Typegen0,
      type: 'parallel',
      on: {
        SET_NODE: { actions: ['assignNode'] },
        SCROLL_INTO_VIEW: { actions: ['scrollIntoView'] },
      },
      states: {
        input: {
          initial: initialState,
          states: {
            disabled: {
              on: {
                ENABLE: {
                  target: 'enabled',
                },
              },
            },
            enabled: {
              initial: 'idle',
              on: { DISABLE: 'disabled' },
              states: {
                idle: { on: { FOCUS: 'focused' } },
                focused: {
                  on: {
                    BLUR: 'idle',
                    CHANGE: { actions: ['updateValue', 'sendRulesActorFormFieldValueUpdate'] },
                  },
                },
              },
            },
          },
        },

        autoFocus: {
          initial: autoFocus ? 'enabled' : 'disabled',
          states: { disabled: {}, enabled: {} },
        },

        mutation: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                UPDATE_VALUE: { target: 'debouncing', actions: ['sendDebouncedUpdateValue'] },
              },
            },
            debouncing: {
              on: {
                UPDATE_VALUE: {
                  actions: ['cancelDebouncedUpdateValue', 'sendDebouncedUpdateValue'],
                },
                DEBOUNCED_UPDATE_VALUE: 'updating',
              },
            },
            updating: {
              invoke: {
                src: 'updateFormFieldValue',
                onDone: {
                  target: 'idle',
                  actions: ['sendParentFormFieldValueUpdate'],
                },
                onError: {
                  target: ['idle'],
                  actions: ['logError', 'showErrorToast'],
                },
              },
            },
          },
        },

        // This state is a kind of controller to forward events up and down
        // Since it is a parallel state, in can listen for events without blocking or getting blocked by nested states
        validation: {
          initial: 'enabled',
          states: {
            enabled: {
              on: {
                CHANGE: { actions: 'forwardToValidation' },
                REVEAL_INVALID: { actions: 'forwardToValidation' },
                BLUR: { actions: 'forwardToValidation' },
                VALID: { actions: 'sendParentValid' },
                INVALID: { actions: 'sendParentInvalid' },
                HIDE: { actions: 'sendParentValid', target: 'disabled' },
              },
            },
            disabled: {
              on: {
                REVEAL: { target: 'enabled', actions: 'restoreValidationWithParent' },
              },
            },
          },
        },
      },
    },
    {
      services: {
        updateFormFieldValue: async (_, event) =>
          makeUpdateFormFieldValueMutation({
            queryClient: sharedContext.queryClient,
            body: { checklistRevisionId, widgetId: formFieldWidget.id, value: event.value },
          }).execute(),
      },
      actions: {
        assignNode: assign({ inputNode: (_, event) => event.node }),

        updateValue: assign({
          value: (_, event) => event.value,
        }),
        showErrorToast: (_, event) => FormResponseErrorToasts.showFormFieldUpdateErrorToast(event.data as AxiosError),
        sendDebouncedUpdateValue: send(
          (_, event) => ({
            type: 'DEBOUNCED_UPDATE_VALUE',
            value: event.value,
          }),
          {
            delay: 500,
            id: 'debounced-update-value',
          },
        ),
        cancelDebouncedUpdateValue: cancel('debounced-update-value'),
        sendParentInvalid: sendParent({ type: 'INVALID_WIDGET', widgetId: formFieldWidget.id }),
        sendParentValid: sendParent({ type: 'VALID_WIDGET', widgetId: formFieldWidget.id }),
        restoreValidationWithParent: sendParent((ctx, _evt) => {
          if (ctx.validationActor.getSnapshot()?.matches('valid')) {
            return { type: 'VALID_WIDGET', widgetId: formFieldWidget.id };
          }
          return { type: 'INVALID_WIDGET', widgetId: formFieldWidget.id };
        }),
        forwardToValidation: forwardTo(ctx => ctx.validationActor) as ActionObject<Context, Event>,

        sendRulesActorFormFieldValueUpdate: (_ctx, event) =>
          sendRulesActorFormFieldValueUpdate({
            formFieldValue: { ...formFieldValue!, fieldValue: { value: event.value } },
            formFieldWidget,
          }),
        scrollIntoView: ctx => {
          ctx.inputNode?.scrollIntoView();
        },
        sendParentFormFieldValueUpdate: sendParent((_ctx, evt) => ({
          type: 'FORM_FIELD_VAUE_UPDATE',
          formFieldValue: evt.data.formFieldValue,
        })),
        logError: makeErrorLoggerAction(id),
      },
    },
  );
};

export type EmailFormFieldMachine = ReturnType<typeof makeEmailFormFieldMachine>;
export type EmailFormFieldActor = ActorRefFrom<EmailFormFieldMachine>;

type State = StateFrom<EmailFormFieldMachine>;
export const Selectors = {
  isValid: (state: State) => ValidationSelectors.isActorValid(state.context.validationActor),
  shouldShowError: (state: State) => ValidationSelectors.isActorInvalidVisible(state.context.validationActor),
};

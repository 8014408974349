import { NumberFormFieldWidget } from '../widget-model';
import { Option } from 'space-monad';
import { TypeOfEnumValue } from '@process-street/subgrade/core';

function numberFormFieldCIF<
  Key extends string,
  Code extends string,
  T extends Record<
    Key,
    {
      code: Code;
      isInvalid: (constraints: NonNullable<NumberFormFieldWidget['constraints']>, value: string) => boolean;
    }
  >,
>(obj: T): T {
  return obj;
}

export const NumberFormFieldValidation = numberFormFieldCIF({
  minDigits: {
    code: 'min-digits',
    isInvalid: (constraints, value) => {
      const digitsBeforeDecimal = value
        .split('.')[0]
        .replace(/^-/, '')
        .replace(/^(0*)\d/, (match, group) => (group === '' ? match : match.slice(group.length)));
      return !!constraints.minDigits && constraints.minDigits > digitsBeforeDecimal.length;
    },
  },
  maxDigits: {
    code: 'max-digits',
    isInvalid: (constraints, value) => {
      const digitsBeforeDecimal = value.split('.')[0].replace(/^-/, '').replace(/^0+/, '');
      return !!constraints.maxDigits && constraints.maxDigits < digitsBeforeDecimal.length;
    },
  },
  minValue: {
    code: 'min-value',
    isInvalid: (constraints, value) => !!constraints.minValue && constraints.minValue > parseFloat(value),
  },
  maxValue: {
    code: 'max-value',
    isInvalid: (constraints, value) => !!constraints.maxValue && constraints.maxValue < parseFloat(value),
  },
  decimalPlaces: {
    code: 'decimal-places',
    isInvalid: (constraints, value) => !isWithinDecimalPlaces(value, constraints.decimalPlaces ?? 0),
  },
  noNegative: {
    code: 'no-negative',
    isInvalid: (constraints, value) => constraints.allowNegative !== true && parseFloat(value) < 0,
  },
  isNumber: {
    code: 'is-number',
    isInvalid: (_constraints, value) => Number.isNaN(Number(value)),
  },
});

export type NumberFormFieldValidationCode = TypeOfEnumValue<typeof NumberFormFieldValidation>['code'] | undefined;

export const getNumberFormFieldWidgetErrorTypes = (
  constraints?: NumberFormFieldWidget['constraints'],
  value?: string,
): Set<NumberFormFieldValidationCode> => {
  if (value == null || !constraints || value === '') return new Set<NumberFormFieldValidationCode>();
  return new Set(
    Object.values(NumberFormFieldValidation)
      .filter(config => config.isInvalid(constraints, value))
      .map(test => test.code),
  );
};

export function isWithinDecimalPlaces(value: string, decimalPlaces: number): boolean {
  return Option(value.split('.')[1])
    .map(decimals => decimals.length <= decimalPlaces)
    .getOrElse(true);
}

export function addMissingZeros(value: string, decimalPlaces: number): string {
  const numberValue = parseFloat(value);
  return isNaN(numberValue)
    ? value
    : numberValue.toLocaleString('en-US', { minimumFractionDigits: decimalPlaces }).replace(/,/g, '');
}

import { useCallback, useMemo } from 'react';
import { TextareaFormFieldActor, TextareaFormFieldMachineState } from './textarea-form-field';
import { useSelector } from '@xstate/react';
import { ValidationActorRef, ValidationSelectors } from './validation-machine';

export namespace FormFieldMachineHooks {
  export type ActorRef = TextareaFormFieldActor;
  export type State = TextareaFormFieldMachineState;

  export namespace Selectors {
    export function getValidationActor<S extends State>(state: S) {
      return state.context.validationActor;
    }

    export function getWidget<S extends State>(state: S) {
      return state.context.widget;
    }

    export function getInputNode<S extends State>(state: S) {
      return state.context.inputNode;
    }

    export function getIsInputDisabled<S extends State>(state: S) {
      return state.matches('input.disabled');
    }

    export function getIsAutofocused<S extends State>(state: S) {
      return state.matches('autoFocus.enabled');
    }
  }

  export function useValidationActor<T extends ActorRef>(actorRef: T) {
    return useSelector(actorRef, Selectors.getValidationActor);
  }

  export function useIsInvalid<T extends ActorRef>(actorRef: T) {
    const validationActor = useValidationActor(actorRef);
    return ValidationSelectors.isActorInvalidVisible(validationActor);
  }

  export function useWidget<T extends ActorRef>(actorRef: T) {
    return useSelector(actorRef, Selectors.getWidget);
  }

  export function useInputNode<T extends ActorRef>(actorRef: T) {
    return useSelector(actorRef, Selectors.getWidget);
  }

  export function useIsInputDisabled<T extends ActorRef>(actorRef: T) {
    return useSelector(actorRef, Selectors.getIsInputDisabled);
  }

  export function useIsAutofocused<T extends ActorRef>(actorRef: T) {
    return useSelector(actorRef, Selectors.getIsAutofocused);
  }

  export function useValidationSnapshot(parentRef: ActorRef) {
    // 1. Subscribe to the parent to get the child-actor ref
    const validationActor = useSelector(parentRef, state => state.context.validationActor) as ValidationActorRef<any>;

    // 2. Subscribe to the child to get its snapshot
    const validationSnapshot = useSelector(validationActor, snapshot => snapshot);

    return validationSnapshot;
  }

  export function useValidationErrorMessage(parentRef: ActorRef) {
    const validationSnapshot = useValidationSnapshot(parentRef);
    return validationSnapshot.context.errorMessage;
  }

  export function useIsInvalidVisible(parentRef: ActorRef) {
    const validationSnapshot = useValidationSnapshot(parentRef);
    return validationSnapshot.matches('invalid.visible');
  }

  export function useApi<T extends ActorRef>(actorRef: T) {
    const onBlur = useCallback(() => {
      actorRef.send({ type: 'BLUR' });
    }, [actorRef]);

    const onChange = useCallback(
      (value: string) => {
        actorRef.send({ type: 'CHANGE', value });
      },
      [actorRef],
    );

    const onFocus = useCallback(() => {
      actorRef.send({ type: 'FOCUS' });
    }, [actorRef]);

    const onSetNode = useCallback(
      (node: HTMLDivElement | null) => {
        actorRef.send({ type: 'SET_NODE', node });
      },
      [actorRef],
    );

    return useMemo(
      () => ({
        onBlur,
        onChange,
        onFocus,
        onSetNode,
      }),
      [onBlur, onChange, onFocus, onSetNode],
    );
  }
}

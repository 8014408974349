import * as React from 'react';
import { HStack, Skeleton, Text, useBreakpointValue } from 'components/design/next';
import { ChecklistGridCountQuery } from 'features/checklists/query-builder/checklist-grid-count';
import { useChecklistSearchCriteria } from 'pages/reports/hooks/use-checklist-search-criteria';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { match, P } from 'ts-pattern';
import { SavedView } from 'components/dashboard/models/saved-views';
import { ChecklistGridSearchRequest } from '@process-street/subgrade/dashboard';
import { conditionalFilterTreeToList } from 'app/components/dashboard/services/grid-data.service.impl';
import { Muid } from '@process-street/subgrade/core';

export type ChecklistDashboardResultsProps = {
  savedView?: SavedView;
  organizationMembershipId: Muid;
};

const formatNumber = (n: number) => String(n).replace(/(.)(?=(\d{3})+$)/g, '$1,');

const enrichSearchRequestFromSavedView = (
  request: ChecklistGridSearchRequest,
  organizationMembershipId: Muid,
  savedView?: SavedView,
): ChecklistGridSearchRequest => {
  if (!savedView) return request;

  const { columnsConfig, filters } = savedView;

  const searchRequest: ChecklistGridSearchRequest = { ...request };

  if (columnsConfig?.sorting) {
    const { sortColumn, sortAsc, sortByWidgetGroupId } = columnsConfig.sorting;
    searchRequest.sortBy = sortColumn;
    searchRequest.sortAsc = sortAsc;
    searchRequest.sortByWidgetGroupId = sortByWidgetGroupId;
  }

  if (filters) {
    const { searchPattern, selectedTemplates, conditionalFilter } = filters;
    searchRequest.searchPattern = searchPattern;
    searchRequest.templateIds = selectedTemplates;

    if (conditionalFilter) {
      searchRequest.filter = conditionalFilterTreeToList(conditionalFilter, organizationMembershipId);
    }
  }

  return searchRequest;
};

export const ChecklistDashboardResults = ({ savedView, organizationMembershipId }: ChecklistDashboardResultsProps) => {
  const searchCriteria = useChecklistSearchCriteria();
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const criteria = enrichSearchRequestFromSavedView(searchCriteria, organizationMembershipId, savedView);
  const countQuery = ChecklistGridCountQuery.useQuery(
    { organizationId, ...criteria },
    { enabled: Boolean(organizationId) },
  );
  const count = countQuery.data?.total;

  const label = match(count)
    .with(0, () => 'No results')
    .with(1, () => '1 result')
    .with(P.number, c => `${formatNumber(c)} results`)
    .otherwise(() => '');

  return (
    <HStack spacing={{ base: '0', xl: '4' }} flexWrap="wrap" mb="4">
      <Text as="h2" color="gray.700" display={{ base: 'none', xl: 'inline-block' }} variant="2" fontWeight="medium">
        Workflow Runs
      </Text>
      <Skeleton isLoaded={!countQuery.isLoading}>
        <Text as="span" color="gray.500" variant={useBreakpointValue({ base: '-2', sm: '-1' })}>
          {label}
        </Text>
      </Skeleton>
    </HStack>
  );
};

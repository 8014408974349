import angular from 'angular';
import './template.scss';
import { createSelector } from 'reselect';
import { SessionSelector } from 'reducers/session/session.selectors';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.controllers')
  .controller('PageViewCtrl', function ($state, $scope, $ngRedux, AnonymousAuthService) {
    const logger = trace({ name: 'PageViewCtrl' });
    logger.info('loading ctrl');

    const mapStateToScope = createSelector([SessionSelector.getCurrentUser], currentUser => {
      $scope.user = currentUser;
      return {
        currentUser,
      };
    });

    const ctrl = this;
    const unsubscribe = $ngRedux.connect(mapStateToScope)($scope);
    $scope.initialized = undefined;
    ctrl.$onInit = () => {
      logger.info('initiate page view');

      AnonymousAuthService.templateViewAnonymousAuth($state.params.id, { shouldRedirectToRequestPermissions: true })
        .then(user => {
          $scope.$apply(() => {
            $scope.user = user;
            $scope.initialized = true;
          });
        })
        .catch(error => logger.error(error.message));
    };

    $scope.$on('$destroy', () => {
      logger.info('destroying ctrl');

      AnonymousAuthService.logoutIfAuthedAnonymously();

      unsubscribe();
    });
  });

import * as React from 'react';
import { ActorRefFrom } from 'xstate';
import { TaskActor, TaskMachine } from './task-machine';
import { useSelector } from '@xstate/react';
import { TaskMachineSelectors } from './task-machine-selectors';
import { useSafeActor } from 'app/hooks/use-safe-actor';
import { TaskWithTaskTemplate } from '@process-street/subgrade/process';

export namespace TaskMachineHooks {
  export const useIsTaskComplete = (actor: ActorRefFrom<TaskMachine> | undefined) => {
    const safeActor = useSafeActor<TaskMachine>(actor);
    return useSelector(safeActor, TaskMachineSelectors.getIsCompleted);
  };

  export const useTask = (actor: ActorRefFrom<TaskMachine> | undefined) => {
    const safeActor = useSafeActor<TaskMachine>(actor);
    return useSelector(safeActor, TaskMachineSelectors.getTask);
  };

  export const useRulesEngineTargetActor = (actor: ActorRefFrom<TaskMachine> | undefined) => {
    const safeActor = useSafeActor<TaskMachine>(actor);
    return useSelector(safeActor, TaskMachineSelectors.getRulesEngineTargetActor);
  };

  export const useIsTaskApproval = (actor: ActorRefFrom<TaskMachine> | undefined) => {
    const safeActor = useSafeActor<TaskMachine>(actor);
    return useSelector(safeActor, TaskMachineSelectors.getIsApproval);
  };

  export const useShouldShowErrors = (actor: ActorRefFrom<TaskMachine> | undefined) => {
    const safeActor = useSafeActor<TaskMachine>(actor);
    return useSelector(safeActor, TaskMachineSelectors.shouldShowErrors);
  };

  export const useInvalidWidgetCount = (actor: ActorRefFrom<TaskMachine> | undefined) => {
    const safeActor = useSafeActor<TaskMachine>(actor);
    return useSelector(safeActor, TaskMachineSelectors.getInvalidWidgetCount);
  };

  export const useApi = (actorRef: TaskActor) => {
    const onUpdateTaskDueDate = React.useCallback(
      ({ task, date, dateOverridden }: { task: TaskWithTaskTemplate; date: number; dateOverridden: boolean }) =>
        actorRef.send({
          type: 'UPDATE_TASK_DUE_DATE',
          data: { task: { ...task, dueDate: date }, dateOverridden },
        }),
      [actorRef],
    );
    return React.useMemo(
      () => ({
        onUpdateTaskDueDate,
      }),
      [onUpdateTaskDueDate],
    );
  };
}

import { TaskTemplate, Template, TextWidget as TW, WithTaskTemplate } from '@process-street/subgrade/process';
import { TinyMCEEditor } from 'features/rich-text';
import { UpdateWidgetMutation, WidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { ActorRefFrom, assign, createMachine, sendParent } from 'xstate';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { WidgetEvent } from '../../../types';
import { SharedContext } from 'pages/forms/_id/shared';
import { GetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template/query-builder';
import { match } from 'ts-pattern';
import produce from 'immer';
import { makeDeleteWidgetMutation } from '../../form-fields/common/mutations/make-delete-widget-mutation';
import { makeErrorLoggerAction } from 'app/utils/machines';
import { TextContentMachineHelpers } from './text-content-machine-helpers';
import { WidgetMachineHelpers } from '../../../helpers/machine';

type TextWidget = WithTaskTemplate<TW>;

type Context = {
  /** The current widget state */
  widget: TextWidget;
  template: Template;
  /**
   * The widget version upon creation or a forced external update.
   * Used for syncing external updates into TinyMCE.
   * */
  initialWidget: TextWidget;
  /** The version we revert to if a save fails. */
  widgetBeforeSave: TextWidget;
  editor?: TinyMCEEditor;
  /** There is a race condition between the auto focus event from parent and the editor being ready. */
  shouldAutoFocus: boolean;
  recentlyMovedFrom?: TaskTemplate;
  isReadOnly?: boolean;
  inputNode: HTMLElement | null;
};

type Event =
  | WidgetEvent<TextWidget>
  | { type: 'CHANGE'; content: string }
  | { type: 'BLUR' }
  | { type: 'FOCUS' }
  | { type: 'SET_EDITOR'; editor: TinyMCEEditor }
  | { type: 'FOCUS_PREVIOUS_WIDGET'; caretOffset: number }
  | { type: 'FOCUS_NEXT_WIDGET'; caretOffset: number }
  // Internal type
  | { type: 'done.invoke.updateWidget'; data: TextWidget };

export type TextContentMachine = ReturnType<typeof makeTextContentMachine>;
export type TextContentActor = ActorRefFrom<TextContentMachine>;

export const makeTextContentMachine = ({
  widget,
  template,
  sharedContext,
  isReadOnly = false,
}: {
  widget: TextWidget;
  template: Template;
  sharedContext: SharedContext;
  isReadOnly?: boolean;
}) => {
  const { queryClient, templateId } = sharedContext;

  const cacheSetter = WidgetsByTemplateRevisionIdQuery.makeCacheSetter({
    queryClient,
    templateRevisionId: widget.header.taskTemplate.templateRevision.id,
  });

  const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
    queryClient,
    templateId,
  });

  const id = WidgetMachineHelpers.getId(widget);

  return createMachine(
    {
      id,
      initial: 'blurred',
      predictableActionArguments: true,
      schema: {
        events: {} as Event,
        context: {} as Context,
      },
      tsTypes: {} as import('./text-content-machine.typegen').Typegen0,
      context: {
        widget,
        template,
        initialWidget: widget,
        widgetBeforeSave: widget,
        shouldAutoFocus: false,
        recentlyMovedFrom: undefined,
        isReadOnly,
        inputNode: null,
      },
      states: {
        blurred: {
          on: {
            FOCUS: { target: 'focused' },
            // AUTO_FOCUS and SET_EDITOR have a race condition, combined they handle both cases.
            AUTO_FOCUS: [
              { cond: 'editorIsReady', target: 'focused', actions: ['focusEditor'] },
              { actions: ['setShouldAutoFocus'] },
            ],
            SET_EDITOR: [
              {
                cond: 'shouldAutoFocus',
                target: 'focused',
                actions: ['assignInitialWidget', 'setEditor', 'focusEditor', 'disableAutoFocus'],
              },
              { actions: ['assignInitialWidget', 'setEditor'] },
            ],
            DELETE_WIDGET: { target: 'deleting' },
            MOVE_DOWN: { actions: ['sendMoveDown'] },
            MOVE_UP: { actions: ['sendMoveUp'] },
            DUPLICATE: { actions: ['sendDuplicate'] },
            MOVE_TO_STEP: { actions: ['sendMoveToStep'] },
            MOVED_FROM_STEP: { actions: ['assignRecentlyMovedFrom'] },
            UPDATE_WIDGET_HEADER: { actions: ['assignHeader', 'assignInitialWidget'] },
            SET_NODE: { actions: ['assignNode'] },
            SCROLL_INTO_VIEW: { actions: ['scrollIntoView'] },
          },
        },
        focused: {
          on: {
            BLUR: [
              {
                cond: 'contentDidChange',
                target: 'saving',
              },
              { target: 'blurred' },
            ],
            CHANGE: {
              target: '.typing',
              actions: ['setContent'],
            },
            DELETE_WIDGET: { target: 'deleting' },
            FOCUS_NEXT_WIDGET: {
              actions: ['sendFocusNextWidget'],
            },
            FOCUS_PREVIOUS_WIDGET: {
              actions: ['sendFocusPreviousWidget'],
            },
          },
          initial: 'idle',
          states: {
            idle: {},
            typing: {
              on: {
                CHANGE: {
                  target: 'typing',
                  internal: false,
                  actions: ['setContent'],
                },
              },
              after: {
                DEBOUNCE_DELAY: [
                  {
                    cond: 'contentDidChange',
                    target: 'saving',
                  },
                  {
                    target: 'idle',
                  },
                ],
              },
            },
            saving: {
              invoke: [
                {
                  id: 'updateWidget',
                  src: 'updateWidget',
                  onDone: {
                    target: 'idle',
                    actions: ['setWidget'],
                  },
                  onError: {
                    target: 'idle',
                    actions: ['logError', 'resetWidget'],
                  },
                },
              ],
            },
          },
        },
        saving: {
          invoke: [
            {
              id: 'updateWidget',
              src: 'updateWidget',
              onDone: {
                target: 'blurred',
                actions: ['assignWidget', 'setWidget'],
              },
              onError: {
                target: 'blurred',
                actions: ['logError', 'resetWidget'],
              },
            },
          ],
        },
        deleting: {
          invoke: [
            {
              id: 'deleteWidget',
              src: 'deleteWidget',
              onDone: {
                target: 'deleted',
              },
              onError: {
                target: 'blurred',
                actions: 'logError',
              },
            },
          ],
        },
        deleted: {
          type: 'final',
        },
      },
      on: {
        // forced external update
        UPDATE_WIDGET: {
          target: 'saving',
          actions: ['assignInitialWidget', 'assignWidget'],
        },
      },
    },
    {
      actions: {
        logError: makeErrorLoggerAction(id),
        assignWidget: assign({
          widget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET' }, ({ widget }) =>
                produce(widget, draftWidget => {
                  draftWidget.header.taskTemplate = ctx.widget.header.taskTemplate;
                }),
              )
              .with({ type: 'done.invoke.updateWidget' }, ({ data }) =>
                produce(data, draftWidget => {
                  draftWidget.header.taskTemplate = ctx.widget.header.taskTemplate;
                  // ignore data sent back from server for local updates
                  // this prevents rerenders & cursor jumpiness
                  draftWidget.content = ctx.widget.content;
                }),
              )
              .otherwise(() => ctx.widget),
        }),
        assignInitialWidget: assign({
          initialWidget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET' }, ({ widget }) => widget)
              .otherwise(() => ctx.widget),
        }),
        setContent: assign({
          widget: (context, e) => ({
            ...context.widget,
            content: e.content,
          }),
        }),
        setWidget: assign({
          widget: (ctx, e) =>
            produce(e.data, draftWidget => {
              draftWidget.header.taskTemplate = ctx.widget.header.taskTemplate;
            }),
          widgetBeforeSave: (ctx, e) =>
            produce(e.data, draftWidget => {
              draftWidget.header.taskTemplate = ctx.widget.header.taskTemplate;
            }),
        }),
        resetWidget: assign({
          widget: context => context.widgetBeforeSave,
          initialWidget: context => context.widgetBeforeSave,
        }),
        sendMoveUp: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'up',
        })),
        sendMoveDown: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'down',
        })),
        sendDuplicate: sendParent(ctx => ({
          type: 'DUPLICATE_WIDGET',
          widget: ctx.widget,
        })),
        setEditor: assign({
          editor: (_ctx, e) => {
            return e.editor;
          },
        }),
        focusEditor: (context, evt) => {
          const { editor } = context;
          if (!editor) return;

          if (evt.type === 'AUTO_FOCUS') {
            if (evt.caretPlacement === 'end') {
              TextContentMachineHelpers.placeCaretAtEnd(editor, evt.caretOffset);
            } else if (evt.caretPlacement === 'start') {
              TextContentMachineHelpers.placeCaretAtStart(editor, evt.caretOffset);
            } else {
              context.editor?.focus();
            }
          } else {
            context.editor?.focus();
          }
        },
        setShouldAutoFocus: assign({ shouldAutoFocus: (_ctx, _e) => true }),
        disableAutoFocus: assign({ shouldAutoFocus: (_ctx, _e) => false }),
        sendMoveToStep: sendParent((ctx, e) => ({
          type: 'MOVE_WIDGET_TO_STEP',
          widget: ctx.widget,
          from: e.from,
          to: e.to,
        })),
        assignHeader: assign({
          widget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET_HEADER' }, ({ header }) => ({
                ...ctx.widget,
                header: {
                  ...ctx.widget?.header,
                  ...{ ...(header as TextWidget['header']) },
                },
              }))
              .otherwise(() => ctx.widget),
        }),
        assignRecentlyMovedFrom: assign({
          recentlyMovedFrom: (_ctx, e) =>
            match(e)
              .with({ type: 'MOVED_FROM_STEP' }, ({ from }) => ({
                ...from,
              }))
              .otherwise(() => undefined),
        }),
        assignNode: assign({ inputNode: (_, evt) => evt.node }),
        scrollIntoView: ctx => {
          WidgetMachineHelpers.scrollToWidget(ctx.widget);
        },
        sendFocusNextWidget: sendParent((ctx, evt) => ({
          type: 'FOCUS_NEXT_WIDGET',
          widget: ctx.widget,
          caretOffset: evt.caretOffset,
        })),
        sendFocusPreviousWidget: sendParent((ctx, evt) => ({
          type: 'FOCUS_PREVIOUS_WIDGET',
          widget: ctx.widget,
          caretOffset: evt.caretOffset,
        })),
      },
      services: {
        updateWidget: async context => {
          return makeMutation(queryClient, {
            mutationKey: UpdateWidgetMutation.getKey(),
            mutationFn: () => UpdateWidgetMutation.mutationFn<TextWidget>(context.widget!),
            onSuccess: ({ content }) => {
              cacheSetter.update({ ...context.widget, content });
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
          }).execute();
        },
        deleteWidget: ({ widget }) =>
          makeDeleteWidgetMutation({
            widget,
            queryClient,
            templateId,
          }),
      },
      delays: {
        DEBOUNCE_DELAY: 500,
      },
      guards: {
        contentDidChange: (ctx, _e) => ctx.widgetBeforeSave.content !== ctx.widget.content,
        editorIsReady: (ctx, _e) => Boolean(ctx.editor),
        shouldAutoFocus: (ctx, _e) => ctx.shouldAutoFocus,
      },
    },
  );
};

import { ActorRefFrom, assign, createMachine, send, spawn } from 'xstate';
import { Muid, User } from '@process-street/subgrade/core';
import {
  Checklist,
  ChecklistRevision,
  ChecklistStatus,
  FormFieldValue,
  FormFieldValueWithWidget,
  FormFieldWidgetWithValue,
  isFormFieldWidget,
  TaskStatus,
  TaskWithTaskTemplate,
  Template,
  TemplateRevision,
  TemplateType,
  Widget,
  WidgetUtils,
} from '@process-street/subgrade/process';
import { makeTaskMachine, TaskMachine } from 'pages/responses/_id/components/task/task-machine';
import { FormMachineUtils } from './form-response-machine-utils';
import { WithSharedContext } from '../../types';
import { makeRulesEngineMachine, RulesEngineMachineActorRef } from './rules-engine-machine/rules-engine-machine';
import {
  FormResponseMachineReceptionist,
  makeRulesEngineTargetTaskKey,
} from '../../form-response-machine-receptionist';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';
import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { Approval, ApprovalRuleSubject, ApprovalStatus } from '@process-street/subgrade/approval-rule';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { queryClient } from 'components/react-root';
import { CreateAllApprovalsMutation, GetApprovalsByChecklistRevisionIdQuery } from 'features/approvals/query-builder';
import { GetAllTasksByChecklistRevisionIdQuery } from 'features/task/query-builder';
import { makeErrorLoggerAction } from 'utils/machines';
import { ToastServiceImpl } from 'app/services/toast-service.impl';
import { GetChecklistQuery, UpdateChecklistStatusMutation } from 'app/features/checklists/query-builder';
import { ObjectUtils } from 'app/utils/object-utils';

export type Context = {
  approvalRules: ApprovalRuleSubject[];
  approvals: Approval[];
  checklist: Checklist;
  currentTaskActor?: ActorRefFrom<TaskMachine>;
  invalidTaskMap: Record<Muid, boolean>;
  oneOffTasks: OneOffTask[];
  rulesActor: RulesEngineMachineActorRef;
  shouldHideCompletedTasks: boolean;
  shouldHideStoppedTasks: boolean;
  shouldSkipRedirectAfterUpdatingTaskStatus?: boolean;
  taskActorsMap: Record<Muid, ActorRefFrom<TaskMachine>>;
  templateRevision: TemplateRevision;
  formFieldWidgetsWithValues: FormFieldWidgetWithValue[];
  template: Template;
};

export type Event =
  | { type: 'COMPLETE_TASK'; taskId: Muid }
  | { type: 'CREATE_APPROVALS'; approvals: CreateAllApprovalsMutation.ApprovalDto[] }
  | { type: 'NEXT_TASK' | 'PREVIOUS_TASK' | 'SKIP_TASK' }
  | { type: 'UNCOMPLETE_TASK'; taskId: Muid }
  | { type: 'UNCOMPLETE_CURRENT_TASK' }
  | { type: 'UPDATE_APPROVALS'; approvals?: Approval[] }
  | { type: 'UPDATE_CHECKLIST'; checklist?: Checklist }
  | { type: 'UPDATE_ONE_OFF_TASKS'; oneOffTasks?: OneOffTask[] }
  | { type: 'SELECT_TASK'; taskId: Muid }
  | { type: 'TASK_STATUS_UPDATE_SUCCESS' | 'TASK_STATUS_UPDATE_FAILED'; skipRedirect?: boolean }
  | { type: `${'INVALID' | 'VALID'}_TASK`; taskId: Muid }
  | { type: 'TOGGLE_COMPLETED_TASKS' }
  | { type: 'TOGGLE_STOPPED_TASKS' }
  | { type: 'COMPLETE_RUN' }
  | { type: 'UNCOMPLETE_RUN' }
  | { type: 'FORM_FIELD_VAUE_UPDATE'; formFieldValue: FormFieldValue };

export type FormResponseMachineBuilderProps = {
  approvalRules: ApprovalRuleSubject[];
  approvals: Approval[];
  checklist: Checklist;
  checklistRevision: ChecklistRevision;
  currentUser: User;
  formFieldValues: FormFieldValueWithWidget[];
  oneOffTasks: OneOffTask[];
  tasks: TaskWithTaskTemplate[];
  template: Template;
  widgets: Widget[];
};

export type FormResponseMachineBuilderInternalProps = WithSharedContext<FormResponseMachineBuilderProps>;

export const makeFormResponseMachine = (props: FormResponseMachineBuilderInternalProps) => {
  const {
    approvalRules,
    approvals,
    checklist,
    checklistRevision,
    currentUser,
    formFieldValues,
    oneOffTasks,
    sharedContext,
    tasks,
    template,
    widgets,
  } = props;
  const { $state } = sharedContext;

  const isChecklistComplete = checklist.status === ChecklistStatus.Completed;
  const isAnonymous = isAnonymousUser(currentUser);
  const isEditable = !isChecklistComplete;
  const isChecklist = template.templateType === TemplateType.Playbook;

  // completed forms are not accessible anonymously when opening them
  const isInaccessible = isChecklistComplete && isAnonymous;
  const initialResponseState = isInaccessible ? 'inaccessible' : isChecklistComplete ? 'complete' : 'active';

  return createMachine(
    {
      context: () => {
        const rulesActor = spawn(
          makeRulesEngineMachine({ checklist, checklistRevision, tasks, formFieldValues, widgets, sharedContext }),
          { name: 'rules-machine', sync: true },
        );
        const taskActorsMap = Object.fromEntries(
          tasks.map(task => {
            const taskWidgets = widgets.filter(w => w.header.taskTemplate.id === task.taskTemplate.id);
            const taskWidgetIdsSet = new Set(taskWidgets.map(widget => widget.id));
            const taskFormFieldValues = formFieldValues.filter(ffv => taskWidgetIdsSet.has(ffv.formFieldWidget.id));

            const taskActor = spawn(
              makeTaskMachine({
                sharedContext,
                checklist,
                checklistRevision,
                task,
                widgets: taskWidgets,
                formFieldValues: taskFormFieldValues,
                isEditable,
                approvals,
                approvalRules,
              }),
              { name: `task-machine:${task.id}` },
            );
            FormResponseMachineReceptionist.register({
              name: makeRulesEngineTargetTaskKey(task.taskTemplate.group.id),
              actorRef: taskActor.getSnapshot()!.context.rulesEngineTargetActor,
            });

            return [task.id, taskActor];
          }),
        ) as Record<Muid, ActorRefFrom<TaskMachine>>;

        const visibleTasksActorMap = Object.values(taskActorsMap).filter(
          taskActor => !taskActor.getSnapshot()?.context.task.hidden,
        );
        const currentTaskActor = visibleTasksActorMap[0] ?? undefined;
        FormResponseMachineReceptionist.register({
          name: 'rules-engine-actor',
          actorRef: rulesActor,
        });

        const formFieldWidgetsWithValues: FormFieldWidgetWithValue[] = widgets
          .filter(isFormFieldWidget)
          .map(formFieldWidget => {
            const value = formFieldValues.find(
              formFieldValue => formFieldValue.formFieldWidget.id === formFieldWidget.id,
            );

            const formFieldValue = WidgetUtils.formFieldWidgetWithValueToFormFieldValue(value);

            return { ...formFieldWidget, formFieldValue };
          });

        return {
          checklist,
          checklistRevision,
          templateRevision: checklistRevision.templateRevision as TemplateRevision,
          template,
          taskActorsMap,
          currentTaskActor,
          rulesActor,
          invalidTaskMap: {},
          shouldHideCompletedTasks: false,
          shouldHideStoppedTasks: true,
          oneOffTasks,
          approvals,
          approvalRules,
          formFieldWidgetsWithValues,
        } as Context;
      },
      tsTypes: {} as import('./form-response-machine.typegen').Typegen0,
      schema: {
        context: {} as Context,
        events: {} as Event,
        services: {} as {
          createApprovalsMutation: {
            data: CreateAllApprovalsMutation.Response;
          };
          completeChecklistStatusMutation: {
            data: UpdateChecklistStatusMutation.Response;
          };
          uncompleteChecklistStatusMutation: {
            data: UpdateChecklistStatusMutation.Response;
          };
        },
      },
      predictableActionArguments: true,
      preserveActionOrder: true,
      id: `form-response-machine:${checklist.id}`,
      type: 'parallel',
      states: {
        response: {
          initial: initialResponseState,
          states: {
            active: {
              initial: 'idle',
              id: 'active',
              states: {
                idle: {
                  on: {
                    CREATE_APPROVALS: [{ target: 'creatingApprovals' }],
                    COMPLETE_TASK: [
                      {
                        cond: 'taskHasInvalidFields',
                        actions: ['sendRevealInvalid'],
                        target: '#validation.invalid.visible',
                      },
                      { target: 'completingTask' },
                    ],
                    UNCOMPLETE_TASK: [
                      {
                        cond: 'taskHasInvalidFields',
                        actions: ['sendRevealInvalid'],
                        target: '#validation.invalid.visible',
                      },
                      { target: 'uncompletingTask' },
                    ],
                    UNCOMPLETE_CURRENT_TASK: [
                      {
                        cond: 'taskHasInvalidFields',
                        actions: ['sendRevealInvalid'],
                        target: '#validation.invalid.visible',
                      },
                      { target: 'uncompletingCurrentTask' },
                    ],
                    NEXT_TASK: [
                      {
                        cond: 'taskHasInvalidFields',
                        actions: ['sendRevealInvalid'],
                        target: '#validation.invalid.visible',
                      },
                      { target: 'completingCurrentTask' },
                    ],
                    PREVIOUS_TASK: {
                      target: 'uncompletingPreviousTask',
                    },
                    SKIP_TASK: {
                      target: 'idle',
                      cond: 'hasMoreTasks',
                      actions: ['assignNextTaskAsCurrentTask', 'goToCurrentTask'],
                    },
                    SELECT_TASK: {
                      target: 'idle',
                      actions: ['assignSelectedTaskAsCurrentTask', 'goToCurrentTask'],
                    },
                    UPDATE_CHECKLIST: [{ target: 'idle', actions: ['assignChecklist'] }],
                    UPDATE_APPROVALS: [{ target: 'idle', actions: ['assignApprovals'] }],
                    UPDATE_ONE_OFF_TASKS: [{ target: 'idle', actions: ['assignOneOffTasks'] }],
                    TOGGLE_COMPLETED_TASKS: [
                      {
                        target: 'idle',
                        actions: [
                          'assignToggleCompletedTasks',
                          'assignNextIncompleteTaskAsCurrentTask',
                          'goToCurrentTask',
                        ],
                      },
                    ],
                    TOGGLE_STOPPED_TASKS: [{ target: 'idle', actions: ['assignToggleStoppedTasks'] }],
                    COMPLETE_RUN: [
                      {
                        actions: ['makeTaskErrorsVisible', 'notifyPendingFormFields'],
                        cond: 'isSomeInvalidTaskMissingRequiredFields',
                      },
                      { actions: ['makeTaskErrorsVisible', 'notifyPendingStopTask'], cond: 'isSomePendingStopTask' },
                      { actions: ['notifyPendingOneOffTasks'], cond: 'isSomePendingOneOffTask' },
                      { target: 'completingRun', cond: 'areAllTasksValid' },
                    ],
                    FORM_FIELD_VAUE_UPDATE: {
                      actions: 'assignFormFieldValues',
                    },
                  },
                },
                completingRun: {
                  invoke: {
                    src: 'completeChecklistStatusMutation',
                    onDone: {
                      target: '#complete',
                      actions: ['sendFormCompleteEventToTasks'],
                    },
                    onError: {
                      target: 'idle',
                    },
                  },
                },
                creatingApprovals: {
                  invoke: {
                    src: 'createApprovalsMutation',
                    onDone: {
                      target: 'idle',
                      actions: ['sendTaskRejectedToRejectedTasks', 'sendTaskApprovedToApprovedTasks'],
                    },
                    onError: {
                      target: 'idle',
                      actions: 'logError',
                    },
                  },
                },
                completingTask: {
                  entry: ['sendCompleteTask'],
                  on: {
                    TASK_STATUS_UPDATE_SUCCESS: [
                      {
                        target: 'idle',
                        cond: 'hasMoreTasks',
                      },
                      {
                        target: '#complete',
                        actions: ['sendFormCompleteEventToTasks'],
                        cond: 'isChecklist',
                      },
                      {
                        target: '#complete',
                        actions: ['sendFormCompleteEventToTasks'],
                      },
                    ],
                    TASK_STATUS_UPDATE_FAILED: [
                      {
                        target: 'idle',
                      },
                    ],
                    SELECT_TASK: {
                      target: 'idle',
                      actions: ['assignSelectedTaskAsCurrentTask', 'goToCurrentTask'],
                    },
                  },
                },

                completingCurrentTask: {
                  entry: ['sendCompleteCurrentTask'],
                  on: {
                    TASK_STATUS_UPDATE_SUCCESS: [
                      {
                        target: 'idle',
                        actions: ['assignNextTaskAsCurrentTask', 'goToCurrentTask'],
                        cond: 'hasMoreTasks',
                      },
                      {
                        target: '#complete',
                        actions: ['sendFormCompleteEventToTasks'],
                        cond: 'isChecklist',
                      },
                      {
                        target: '#complete',
                        actions: ['assignCurrentTaskToUndefined', 'sendFormCompleteEventToTasks'],
                      },
                    ],
                    TASK_STATUS_UPDATE_FAILED: [
                      {
                        target: 'idle',
                      },
                    ],
                    SELECT_TASK: {
                      target: 'idle',
                      actions: ['assignSelectedTaskAsCurrentTask', 'goToCurrentTask'],
                    },
                  },
                },
                uncompletingCurrentTask: {
                  entry: ['sendUncompleteCurrentTask'],
                  on: {
                    TASK_STATUS_UPDATE_SUCCESS: [
                      {
                        target: 'idle',
                      },
                    ],
                    TASK_STATUS_UPDATE_FAILED: [
                      {
                        target: 'idle',
                      },
                    ],
                    SELECT_TASK: {
                      target: 'idle',
                      actions: ['assignSelectedTaskAsCurrentTask', 'goToCurrentTask'],
                    },
                  },
                },
                uncompletingPreviousTask: {
                  entry: ['sendUncompletePreviousTask'],
                  on: {
                    TASK_STATUS_UPDATE_SUCCESS: [
                      {
                        target: 'idle',
                        actions: ['assignPreviousTaskAsCurrentTask', 'goToCurrentTask'],
                      },
                    ],
                    TASK_STATUS_UPDATE_FAILED: [
                      {
                        target: 'idle',
                      },
                    ],
                    SELECT_TASK: {
                      target: 'idle',
                      actions: ['assignSelectedTaskAsCurrentTask', 'goToCurrentTask'],
                    },
                  },
                },
                uncompletingTask: {
                  entry: ['sendUncompleteTask'],
                  on: {
                    TASK_STATUS_UPDATE_SUCCESS: [
                      {
                        target: 'idle',
                      },
                    ],
                    TASK_STATUS_UPDATE_FAILED: [
                      {
                        target: 'idle',
                      },
                    ],
                    SELECT_TASK: {
                      target: 'idle',
                    },
                  },
                },
              },
            },
            complete: {
              id: 'complete',
              initial: 'idle',
              states: {
                idle: {
                  entry: ['goToFinishedPageIfNoMoreTasks'],
                  on: {
                    UPDATE_CHECKLIST: [{ target: 'idle', actions: ['assignChecklist'] }],
                    NEXT_TASK: [{ target: 'idle', actions: ['assignNextTaskAsCurrentTask', 'goToCurrentTask'] }],
                    PREVIOUS_TASK: [
                      { target: 'idle', actions: ['assignPreviousTaskAsCurrentTask', 'goToCurrentTask'] },
                    ],
                    SELECT_TASK: {
                      target: 'idle',
                      actions: ['assignSelectedTaskAsCurrentTask', 'goToCurrentTask'],
                    },
                    TOGGLE_COMPLETED_TASKS: [
                      {
                        target: 'idle',
                        actions: [
                          'assignToggleCompletedTasks',
                          'assignNextIncompleteTaskAsCurrentTask',
                          'goToCurrentTask',
                        ],
                      },
                    ],
                    UNCOMPLETE_RUN: [
                      {
                        target: 'uncompletingRun',
                      },
                    ],
                  },
                },
                uncompletingRun: {
                  invoke: {
                    src: 'uncompleteChecklistStatusMutation',
                    onDone: {
                      target: '#active',
                      actions: ['sendFormUncompleteEventToTasks'],
                    },
                    onError: {
                      target: 'idle',
                    },
                  },
                },
              },
            },
            inaccessible: {
              entry: () => $state.go('logout'),
            },
          },
        },

        validation: {
          id: 'validation',
          initial: 'valid',
          states: {
            valid: {
              on: {
                INVALID_TASK: { target: 'invalid', actions: 'addInvalidTask' },
              },
            },
            invalid: {
              on: {
                INVALID_TASK: { actions: 'addInvalidTask' },
                VALID_TASK: [
                  { cond: 'areAllTasksValid', target: 'valid', actions: 'removeInvalidTask' },
                  { actions: 'removeInvalidTask' },
                ],
              },
              initial: 'hidden',
              states: { hidden: {}, visible: {} },
            },
          },
        },
      },
    },
    {
      guards: {
        hasMoreTasks: (context, _) => {
          return FormMachineUtils.hasMoreTasks(context.taskActorsMap, context.currentTaskActor);
        },
        isChecklist: () => isChecklist,
        taskHasInvalidFields: (context, _) => {
          const snapshot = context.currentTaskActor?.getSnapshot();
          // Fallback to true because if any of these actors are undefined, we've got bigger problems 🤠
          const isInvalid = snapshot?.matches('validation.invalid') ?? true;
          const hasInvalidFields = ObjectUtils.hasKeys(snapshot?.context.invalidWidgetMap ?? {});

          return isInvalid && hasInvalidFields;
        },
        areAllTasksValid: (context, evt) => {
          if (evt.type === 'COMPLETE_RUN') {
            return Object.keys(context.invalidTaskMap).length === 0;
          }
          const { [evt.taskId]: _, ...rest } = context.invalidTaskMap;
          return Object.keys(rest).length === 0;
        },
        isSomeInvalidTaskMissingRequiredFields: context => {
          const invalidTaskIds = Object.entries(context.invalidTaskMap)
            .map(([taskId, isInvalid]) => {
              return isInvalid ? taskId : undefined;
            })
            .filter(Boolean) as Muid[];

          const isSomeInvalidTaskMissingRequiredFields = invalidTaskIds.some(taskId => {
            const taskSnapshot = context.taskActorsMap[taskId]?.getSnapshot();

            if (!taskSnapshot) return false;
            const { invalidWidgetMap } = taskSnapshot.context;

            return Object.keys(invalidWidgetMap).length > 0;
          });

          return isSomeInvalidTaskMissingRequiredFields;
        },
        isSomePendingStopTask: context => {
          const incompleteStopTasks = Object.values(context.taskActorsMap).filter(actor => {
            const snapshot = actor.getSnapshot();

            if (!snapshot) return false;

            const isStopTask = snapshot?.context.task.taskTemplate.stop;
            const isComplete = snapshot?.matches('task.complete');

            return isStopTask && !isComplete;
          });

          return incompleteStopTasks.length > 0;
        },
        isSomePendingOneOffTask: context => {
          return context.oneOffTasks.some(task => task.status !== TaskStatus.Completed && task.required);
        },
      },
      actions: {
        notifyPendingFormFields: ctx => {
          const invalidFieldsCount = Object.keys(ctx.invalidTaskMap).reduce((sum, taskId) => {
            const snapshot = ctx.taskActorsMap[taskId]?.getSnapshot();

            if (!snapshot) return sum;

            return sum + Object.keys(snapshot.context.invalidWidgetMap).length;
          }, 0);

          ToastServiceImpl.openToast({
            status: 'warning',
            title: "We couldn't complete the workflow run",
            description: `Oops! ${invalidFieldsCount} form field${
              invalidFieldsCount > 1 ? 's' : ''
            } still need to be completed.`,
          });
        },
        notifyPendingStopTask: () => {
          ToastServiceImpl.openToast({
            status: 'warning',
            title: "We couldn't complete the workflow run",
            description: 'Oops! All the stop tasks must be completed.',
          });
        },
        notifyPendingOneOffTasks: () => {
          ToastServiceImpl.openToast({
            status: 'warning',
            title: "We couldn't complete the workflow run",
            description: 'Oops! All the one-off tasks must be completed.',
          });
        },
        sendCompleteCurrentTask: send(
          { type: 'COMPLETE_TASK' },
          { to: (ctx, __) => ctx.currentTaskActor as ActorRefFrom<TaskMachine> },
        ),
        sendUncompletePreviousTask: send(
          { type: 'UNCOMPLETE_TASK' },
          {
            to: (ctx, __) => FormMachineUtils.getPreviousTaskActor(ctx.taskActorsMap, ctx.currentTaskActor),
          },
        ),
        sendUncompleteCurrentTask: send(
          { type: 'UNCOMPLETE_TASK' },
          {
            to: ctx => ctx.currentTaskActor!,
          },
        ),
        sendFormCompleteEventToTasks: ctx => {
          return Object.values(ctx.taskActorsMap).map(taskActor => taskActor.send({ type: 'FORM_COMPLETE' }));
        },
        sendFormUncompleteEventToTasks: ctx => {
          return Object.values(ctx.taskActorsMap).map(taskActor => taskActor.send({ type: 'FORM_REACTIVATE' }));
        },
        sendTaskRejectedToRejectedTasks: (ctx, event) => {
          event.data.approvals
            .filter(approval => approval.status === ApprovalStatus.Rejected)
            .forEach(approval => {
              const taskActor = ctx.taskActorsMap[approval.subjectTaskId];
              taskActor.send({ type: 'TASK_REJECTED' });
            });
        },
        sendTaskApprovedToApprovedTasks: (ctx, event) => {
          event.data.approvals
            .filter(approval => approval.status === ApprovalStatus.Approved)
            .forEach(approval => {
              const taskActor = ctx.taskActorsMap[approval.subjectTaskId];
              taskActor.send({ type: 'TASK_APPROVED' });
            });
        },
        assignPreviousTaskAsCurrentTask: assign({
          currentTaskActor: (ctx, __) => FormMachineUtils.getPreviousTaskActor(ctx.taskActorsMap, ctx.currentTaskActor),
        }),
        assignNextTaskAsCurrentTask: assign({
          currentTaskActor: ctx => {
            if (ctx.shouldSkipRedirectAfterUpdatingTaskStatus) return ctx.currentTaskActor;

            return FormMachineUtils.getNextTaskActor(ctx.taskActorsMap, ctx.currentTaskActor);
          },
        }),
        assignNextIncompleteTaskAsCurrentTask: assign({
          currentTaskActor: ctx => {
            const currentTaskIndex = FormMachineUtils.getCurrentTaskIndex(ctx.taskActorsMap, ctx.currentTaskActor);
            const taskActors = FormMachineUtils.getVisibleTasks(ctx.taskActorsMap);
            const orderedTaskActors = [
              // first, try to find next incomplete task (or remain on current one)
              ...taskActors.slice(currentTaskIndex),
              // try to find nearest incomplete task before the current one if there are none after
              ...taskActors.slice(0, currentTaskIndex).reverse(),
            ];
            const nextIncompleteTaskActor = orderedTaskActors.find(taskActor =>
              taskActor.getSnapshot()?.matches('task.incomplete'),
            );
            return nextIncompleteTaskActor ?? ctx.currentTaskActor;
          },
        }),
        assignSelectedTaskAsCurrentTask: assign({
          currentTaskActor: (ctx, evt) => ctx.taskActorsMap[evt.taskId],
        }),
        assignCurrentTaskToUndefined: assign({
          currentTaskActor: (_, __) => {
            return undefined;
          },
        }),
        goToFinishedPageIfNoMoreTasks: (ctx, __) => {
          if (ctx.currentTaskActor === undefined) {
            void $state.go('formResponseFinish', {
              id: checklist.id,
            });
          }
        },
        goToCurrentTask: ctx => {
          // Forms don't support multiple pages
          if (!$state.includes('checklistV2')) return;
          if (ctx.shouldSkipRedirectAfterUpdatingTaskStatus) return;

          void $state.go('checklistV2.task', {
            id: ctx.checklist.id,
            title: ctx.checklist.name,
            groupId: ctx.currentTaskActor?.getSnapshot()?.context.task.id,
          });
        },
        addInvalidTask: assign({
          invalidTaskMap: (ctx, event) => {
            ctx.invalidTaskMap[event.taskId] = true;
            return ctx.invalidTaskMap;
          },
        }),
        removeInvalidTask: assign({
          invalidTaskMap: (ctx, event) => {
            delete ctx.invalidTaskMap[event.taskId];
            return ctx.invalidTaskMap;
          },
        }),
        sendRevealInvalid: ctx => {
          ctx.currentTaskActor?.send({ type: 'REVEAL_INVALID' });
        },
        assignChecklist: assign((ctx, evt) => ({ checklist: evt.checklist ?? ctx.checklist })),
        assignApprovals: assign((ctx, evt) => ({ approvals: evt.approvals ?? ctx.approvals })),
        sendCompleteTask: send(
          { type: 'COMPLETE_TASK' },
          { to: (ctx, evt) => ctx.taskActorsMap[evt.taskId] as ActorRefFrom<TaskMachine> },
        ),
        sendUncompleteTask: send(
          { type: 'UNCOMPLETE_TASK' },
          { to: (ctx, evt) => ctx.taskActorsMap[evt.taskId] as ActorRefFrom<TaskMachine> },
        ),
        assignOneOffTasks: assign((ctx, evt) => ({ oneOffTasks: evt.oneOffTasks ?? ctx.oneOffTasks })),
        assignToggleCompletedTasks: assign(ctx => ({
          shouldHideCompletedTasks: !ctx.shouldHideCompletedTasks,
        })),
        assignToggleStoppedTasks: assign(ctx => ({
          shouldHideStoppedTasks: !ctx.shouldHideStoppedTasks,
        })),
        assignFormFieldValues: assign((ctx, evt) => {
          const ret = {
            formFieldWidgetsWithValues: ctx.formFieldWidgetsWithValues.map(ffwwv =>
              ffwwv.id === evt.formFieldValue.formFieldWidget.id
                ? { ...ffwwv, formFieldValue: evt.formFieldValue }
                : { ...ffwwv },
            ),
          };

          return ret;
        }),
        logError: makeErrorLoggerAction(`form-response-machine:${checklist.id}`),

        makeTaskErrorsVisible: ctx => {
          Object.values(ctx.taskActorsMap).forEach(taskActor => {
            taskActor.send({ type: 'REVEAL_INVALID' });
          });
        },
      },
      services: {
        createApprovalsMutation: async (_ctx, evt) => {
          const params = {
            checklistRevisionId: checklistRevision.id,
            approvals: evt.approvals,
          };

          return makeMutation(queryClient, {
            mutationKey: CreateAllApprovalsMutation.key,
            mutationFn: () => CreateAllApprovalsMutation.mutationFn(params),
            onSuccess: response => {
              void queryClient.invalidateQueries(
                GetApprovalsByChecklistRevisionIdQuery.getKey({ checklistRevisionId: checklistRevision.id }),
              );

              const wasRejection = response.approvals.some(approval => approval.status === ApprovalStatus.Rejected);
              if (wasRejection) {
                void queryClient.invalidateQueries(
                  GetAllTasksByChecklistRevisionIdQuery.getKey({
                    checklistRevisionId: checklistRevision.id,
                  }),
                );
              }
            },
          }).execute();
        },

        completeChecklistStatusMutation: async _ctx => {
          return makeMutation(queryClient, {
            mutationKey: UpdateChecklistStatusMutation.key,
            mutationFn: () =>
              UpdateChecklistStatusMutation.mutationFn({
                checklistId: checklist.id,
                status: ChecklistStatus.Completed,
              }),
            onSuccess: () => {
              void queryClient.invalidateQueries({
                queryKey: GetChecklistQuery.getKey({ checklistId: checklist.id }),
              });
            },
            onError: () => {
              ToastServiceImpl.openToast({
                status: 'warning',
                title: "We couldn't complete the workflow run",
                description: 'Oops! Something went wrong. Please try again.',
              });
            },
          }).execute();
        },

        uncompleteChecklistStatusMutation: async _ctx => {
          return makeMutation(queryClient, {
            mutationKey: UpdateChecklistStatusMutation.key,
            mutationFn: () =>
              UpdateChecklistStatusMutation.mutationFn({
                checklistId: checklist.id,
                status: ChecklistStatus.Active,
              }),
            onSuccess: () => {
              void queryClient.invalidateQueries({
                queryKey: GetChecklistQuery.getKey({ checklistId: checklist.id }),
              });
            },
            onError: () => {
              ToastServiceImpl.openToast({
                status: 'warning',
                title: "We couldn't reactivate the workflow run",
                description: 'Oops! Something went wrong. Please try again.',
              });
            },
          }).execute();
        },
      },
    },
  );
};

export type FormResponseMachine = ReturnType<typeof makeFormResponseMachine>;
export type FormResponseActor = ActorRefFrom<FormResponseMachine>;

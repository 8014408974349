import * as React from 'react';
import { Icon, Center, Tooltip } from 'app/components/design/next';

export const TaskListItemCodeTaskIndicator = () => {
  return (
    <Tooltip label="Code task">
      <Center w={8} h={8}>
        <Icon icon="code" aria-label="Code task" size="3.5" color="inherit" />
      </Center>
    </Tooltip>
  );
};

import * as React from 'react';
import { createConnector } from 'react-instantsearch-dom';
import { Spinner, Text } from 'components/design/next';
import { useSearchStatus } from './search-context';
import { StatsProvided } from 'react-instantsearch-core';

export type StatsProps = Partial<Pick<StatsProvided, 'nbHits'>>;

export const StatsUI = ({ nbHits }: StatsProps) => {
  const { query } = useSearchStatus();

  return nbHits === undefined ? (
    <Spinner />
  ) : (
    <Text color="gray.500" fontSize="sm">
      {nbHits > 0 ? (
        <>
          {nbHits} result{nbHits === 1 ? '' : 's'} found
        </>
      ) : query ? (
        <>
          No results found for{' '}
          <Text as="strong" color="gray.600" fontSize="inherit">
            "{query}"
          </Text>
          .
        </>
      ) : (
        'No results found'
      )}
    </Text>
  );
};

const connectMultiIndexStats = createConnector({
  displayName: 'Stats',
  getProvidedProps: (_props, _searchState, searchResults) => {
    if (!searchResults.results) return { nbHits: 0 };
    if (searchResults.results?.hits) {
      // single-index
      return { nbHits: searchResults.results.hits.length };
    }
    // multi-index
    return {
      nbHits: Object.values(searchResults.results)
        .map(results => results.hits)
        .flat().length,
    };
  },
});

export const Stats = connectMultiIndexStats(StatsUI);

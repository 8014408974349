import {
  Button,
  ButtonProps,
  Icon,
  IconButton,
  useDisclosure,
  UseDisclosureReturn,
  useToast,
} from 'components/design/next';
import {
  DataSet,
  DataSetColumnFilterForm,
  DataSetColumnState,
  SavedView,
  SavedViewFilterOperator,
} from '@process-street/subgrade/process';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { GetAllDataSetsQuery, useCreateSavedViewMutation } from '../../query-builder';
import { OnSubmitCreateSavedViewParams, SavedViewFormModal } from '../saved-view-form-modal';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { NoAccessTooltip } from 'pages/reports/data-sets/components/data-set-page/no-access-tooltip';

export type CreateSavedViewButtonProps = {
  variant?: 'icon' | 'text';
  buttonProps?: ButtonProps;
  modalProps?: Partial<UseDisclosureReturn>;
  dataSet: DataSet;
  columns?: DataSetColumnState[];
  filters?: DataSetColumnFilterForm;
  onModalOpen?: () => void;
  onModalClose?: () => void;
  isInitialOpen?: boolean;
  isDisabled: boolean;
  onSavedViewCreated?: (savedView: SavedView) => void;
  operator?: SavedViewFilterOperator;
};

export const CreateSavedViewButton: React.FC<React.PropsWithChildren<CreateSavedViewButtonProps>> = ({
  variant = 'text',
  buttonProps,
  dataSet,
  columns,
  modalProps,
  isDisabled,
  children,
  onSavedViewCreated,
  operator,
}) => {
  const modalDisclosure = useDisclosure({
    onOpen: modalProps?.onOpen,
    onClose: modalProps?.onClose,
    defaultIsOpen: modalProps?.isOpen,
  });
  const toast = useToast();
  const queryClient = useQueryClient();

  const initialValues: Partial<SavedView> = {
    columns:
      columns ??
      dataSet.columnDefs.map(colDef => ({
        id: colDef.id,
        filters: [],
        hide: false, // specify explicitly to show all data in a newly created saved view
      })),
  };

  const createSavedViewMutation = useCreateSavedViewMutation({
    onSuccess: (savedView, __) => {
      toast({
        status: 'success',
        title: `Saved view created`,
      });
      queryClient.invalidateQueries(GetAllDataSetsQuery.key);

      if (onSavedViewCreated) {
        onSavedViewCreated(savedView);
      }
      modalDisclosure.onClose();
    },
    onError: (_, __) => {
      toast({
        status: 'error',
        title: "We're having problems creating the saved view",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const handleSubmit = (savedView: OnSubmitCreateSavedViewParams) => {
    createSavedViewMutation.mutate({
      columns: savedView.columns,
      name: savedView.name,
      dataSetId: dataSet.id,
      config: {
        filterOperator: operator ?? SavedViewFilterOperator.And,
      },
    });
  };

  const tooltip = isDisabled ? `You don't have access to create a saved view.` : `Create Saved View`;

  return (
    <>
      {variant === 'icon' && (
        <NoAccessTooltip label={tooltip} dataSet={dataSet} contactAdmin={isDisabled}>
          <IconButton
            isDisabled={isDisabled}
            onFocus={e => e.preventDefault()}
            onClick={modalDisclosure.onOpen}
            borderRadius="full"
            {...buttonProps}
            size="sm"
            variant="ghost"
            aria-label="Create Saved View"
          >
            <Icon icon="plus" size="4" color="gray.500" />
          </IconButton>
        </NoAccessTooltip>
      )}

      {variant === 'text' && (
        <Button
          isDisabled={isDisabled}
          onClick={modalDisclosure.onOpen}
          variant="solid"
          colorScheme="gray"
          size="sm"
          margin="0 auto"
          top="calc(50% - 20px)"
          leftIcon={<Icon icon="plus" size="4" />}
          mt="1"
          fontSize="sm"
          {...buttonProps}
        >
          {children ?? 'Create Saved View'}
        </Button>
      )}

      <SavedViewFormModal
        status={createSavedViewMutation.status}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        {...modalDisclosure}
      />
    </>
  );
};

import * as React from 'react';
import { FileWidget, ImageWidget, VideoWidget, WidgetConstants } from '@process-street/subgrade/process';
import { Text, Box, useToast, Tooltip, BoxProps } from 'components/design/next';
import { SIZE_TOO_LARGE, useUploadQuery, useWistiaUploadQuery } from './query';
import last from 'lodash/last';
import { UploadArea, UploadProgress } from 'features/upload/components';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { useFeatureFlag } from 'features/feature-flags';
import { Accept } from 'react-dropzone';

export type UploadableWidgets = FileWidget | ImageWidget | VideoWidget;

interface UploadElementProps<W extends UploadableWidgets> {
  widget: W;
  uploadingMessage: string;
  onFinish: (widget: W) => void;
  acceptMimeTypes?: Accept;
}

interface UploadVideoElementProps {
  widget: VideoWidget;
  uploadingMessage: string;
  onFinish: (widget: VideoWidget) => void;
  onUploadStarted: () => void;
  acceptMimeTypes?: Accept;
  dropZoneProps?: BoxProps;
  onDrop?: () => void;
  isReadOnly?: boolean;
}

export const UploadElement = <W extends UploadableWidgets>({
  widget,
  uploadingMessage,
  onFinish,
  acceptMimeTypes,
  children,
}: React.PropsWithChildren<UploadElementProps<W>>) => {
  const toast = useToast();

  const { dropzoneState, progress, uploadError, clearUploadError } = useUploadQuery<typeof widget>(
    widget,
    onFinish,
    acceptMimeTypes,
  );

  const getHighlightColor = () => {
    if (dropzoneState.isDragAccept) {
      return 'brand.100';
    }
    if (dropzoneState.isDragReject || dropzoneState.fileRejections.length > 0) {
      return 'red.100';
    }
    if (dropzoneState.isDragActive) {
      return 'brand.100';
    }
  };

  if (uploadError) {
    toast({
      status: 'error',
      title: "We're having problems uploading the video",
      description: DefaultErrorMessages.unexpectedErrorDescription,
    });
    clearUploadError();
  }

  const fileTooLarge =
    dropzoneState.fileRejections.length > 0 && last(dropzoneState.fileRejections[0].errors)?.code === SIZE_TOO_LARGE;

  return (
    <div {...dropzoneState.getRootProps({ className: 'dropzone' })}>
      <Box bg="gray.100" p={4} borderRadius="md" cursor="pointer" bgColor={getHighlightColor()}>
        {progress === undefined ? (
          <UploadArea dropzoneInputProps={dropzoneState.getInputProps()} children={children} />
        ) : (
          <UploadProgress progress={progress} message={uploadingMessage} />
        )}
      </Box>
      {fileTooLarge ? <Text color="red.500">{last(dropzoneState.fileRejections[0].errors)?.message}</Text> : undefined}
    </div>
  );
};

export const UploadVideoElement: React.FC<React.PropsWithChildren<UploadVideoElementProps>> = ({
  widget,
  uploadingMessage,
  onFinish,
  onUploadStarted,
  acceptMimeTypes,
  children,
  dropZoneProps,
  onDrop,
  isReadOnly,
  ...props
}) => {
  const toast = useToast();

  const isS3VideoEnabled = useFeatureFlag('s3Video');
  const wistiaUploadQuery = useWistiaUploadQuery({
    widget,
    onFinish,
    onUploadStarted,
    accept: acceptMimeTypes,
    onDropFile: onDrop,
    isReadOnly,
  });
  const s3UploadQuery = useUploadQuery<VideoWidget>(widget, onFinish, acceptMimeTypes);

  const { dropzoneState, progress, uploadError, clearUploadError } = isS3VideoEnabled
    ? s3UploadQuery
    : wistiaUploadQuery;

  const getHighlightColor = () => {
    if (dropzoneState.isDragAccept) {
      return 'brand.100';
    }
    if (dropzoneState.isDragReject || dropzoneState.fileRejections.length > 0) {
      return 'red.100';
    }
    if (dropzoneState.isDragActive) {
      return 'brand.100';
    }
    return 'brand.100';
  };

  if (uploadError) {
    toast({
      status: 'error',
      title: "We're having problems uploading the video",
      description: DefaultErrorMessages.unexpectedErrorDescription,
    });
    clearUploadError();
  }

  const isFileTooLarge =
    dropzoneState.fileRejections.length > 0 && last(dropzoneState.fileRejections[0].errors)?.code === SIZE_TOO_LARGE;

  return (
    <Box {...dropzoneState.getRootProps({ className: 'dropzone' })} {...dropZoneProps}>
      <Tooltip
        aria-label="max upload size"
        label={`Up to ${WidgetConstants.VIDEO_MAX_FILE_SIZE / 1024 / 1024 / 1024} GB`}
        placement="bottom"
        hasArrow
        isDisabled={isReadOnly}
      >
        <Box
          bg="gray.100"
          p={4}
          borderRadius="md"
          cursor={isReadOnly ? undefined : 'pointer'}
          bgColor={getHighlightColor()}
        >
          {progress === undefined ? (
            <UploadArea dropzoneInputProps={dropzoneState.getInputProps()} boxProps={props}>
              {children}
            </UploadArea>
          ) : (
            <UploadProgress progress={progress} message={uploadingMessage} />
          )}
        </Box>
      </Tooltip>
      {isFileTooLarge ? (
        <Text color="red.500">{last(dropzoneState.fileRejections[0].errors)?.message}</Text>
      ) : undefined}
    </Box>
  );
};

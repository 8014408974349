import produce from 'immer';
import { MuidUtils } from '../../core';
import {
  ChecklistWidget,
  CrossLinkWidget,
  EmailWidget,
  EmbedWidget,
  FieldType,
  FileWidget,
  FormFieldWidget,
  FormFieldWidgetWithValue,
  ImageWidget,
  TableWidget,
  TaskTemplate,
  TextWidget,
  VideoWidget,
  VideoWidgetService,
  Widget,
  WidgetHeaderOfType,
  WidgetType,
  WithTaskTemplate,
} from '../../process';
import { generateAudit } from './audit-generator';
import { generateOrganizationRef } from './organization-generator';
import { generateS3File } from './s3-file-generator';
import { generateTaskTemplate } from './task-template-generator';
import { generateFormFieldValue } from './form-field-value-generator';

export const generateWidgetHeader = <Type extends WidgetType>(
  type: Type,
  header?: Partial<WidgetHeaderOfType<Type>>,
): WidgetHeaderOfType<Type> =>
  ({
    id: MuidUtils.randomMuid(),
    audit: generateAudit(),
    hiddenByDefault: false,
    organization: generateOrganizationRef(),
    group: { id: MuidUtils.randomMuid() },
    taskTemplate: { id: MuidUtils.randomMuid() },
    type,
    ...header,
  } as WidgetHeaderOfType<Type>);

export const generateFormFieldWidget = <FFWType extends FormFieldWidget>(formFieldWidget?: Partial<FFWType>): FFWType =>
  ({
    id: MuidUtils.randomMuid(),
    header: generateWidgetHeader(WidgetType.FormField),
    templateRevision: { id: MuidUtils.randomMuid() },
    fieldType: FieldType.Text,
    config: {},
    key: MuidUtils.randomMuid(),
    constraints: {},
    deleted: false,
    required: false,
    ...formFieldWidget,
  } as FFWType);

export const generateFormFieldWidgetWithValue = (
  formFieldWidgetWithValue: Partial<FormFieldWidgetWithValue>,
): FormFieldWidgetWithValue =>
  ({
    id: MuidUtils.randomMuid(),
    header: generateWidgetHeader(WidgetType.FormField),
    templateRevision: { id: MuidUtils.randomMuid() },
    fieldType: FieldType.Text,
    config: {},
    key: MuidUtils.randomMuid(),
    constraints: {},
    deleted: false,
    required: false,
    formFieldValue: generateFormFieldValue(),
    ...formFieldWidgetWithValue,
  } as FormFieldWidgetWithValue);

export const generateChecklistWidget = (checklistWidget?: Partial<ChecklistWidget>): ChecklistWidget => ({
  audit: generateAudit(),
  checklistRevisionId: MuidUtils.randomMuid(),
  groupId: MuidUtils.randomMuid(),
  hidden: false,
  id: MuidUtils.randomMuid(),
  organizationId: MuidUtils.randomMuid(),
  ...checklistWidget,
});

export const generateImageWidget = (imageWidget?: Partial<ImageWidget>): ImageWidget => ({
  id: MuidUtils.randomMuid(),
  header: generateWidgetHeader(WidgetType.Image),
  file: generateS3File(),
  caption: 'Caption',
  ...imageWidget,
});

export const generateTextWidget = ({
  header,
  ...textWidget
}: Partial<
  Omit<TextWidget, 'header'> & { header: Partial<WidgetHeaderOfType<WidgetType.Text>> }
> = {}): TextWidget => ({
  id: MuidUtils.randomMuid(),
  header: generateWidgetHeader(WidgetType.Text, header),
  content: '<p>Some html string</p>',
  ...textWidget,
});

export const generateTableWidget = ({
  header,
  ...tableWidget
}: Partial<
  Omit<TableWidget, 'header'> & { header: Partial<WidgetHeaderOfType<WidgetType.Table>> }
> = {}): TableWidget => ({
  id: MuidUtils.randomMuid(),
  header: generateWidgetHeader(WidgetType.Table, header),
  content:
    '<table><thead><tr><th><p>Name</p></th><th><p><strong>Position</strong></p></th></tr></thead><tbody><tr><td><p>Jane Doe</p></td><td><p>Head of Marketing</p></td></tr><tr><td><p>John Doe</p></td><td><p>Assistant to the regional manager</p></td></tr><tr><td><p></p></td><td><p></p></td></tr></tbody></table>',
  ...tableWidget,
});

export const generateEmptyTableWidget = ({
  header,
  ...tableWidget
}: Partial<
  Omit<TableWidget, 'header'> & { header: Partial<WidgetHeaderOfType<WidgetType.Table>> }
> = {}): TableWidget => ({
  id: MuidUtils.randomMuid(),
  header: generateWidgetHeader(WidgetType.Table, header),
  content:
    '<table><thead><tr><th><p></p></th><th><p></p></th></tr></thead><tbody><tr><td><p></p></td><td><p></p></td></tr><tr><td><p></p></td><td><p></p></td></tr></tbody></table>',
  ...tableWidget,
});

export function expandTaskTemplate<W extends Widget>(widget: W): WithTaskTemplate<W>;
export function expandTaskTemplate<W extends Widget>({
  widget,
  taskTemplate,
}: {
  widget: W;
  taskTemplate?: Partial<TaskTemplate>;
}): WithTaskTemplate<W>;
export function expandTaskTemplate<W extends Widget>(
  data:
    | W
    | {
        widget: W;
        taskTemplate?: Partial<TaskTemplate>;
      },
): WithTaskTemplate<W> {
  const widget = 'widget' in data ? data.widget : data;
  const taskTemplate = 'taskTemplate' in data ? data.taskTemplate : undefined;
  return produce(widget, draft => {
    draft.header.taskTemplate = generateTaskTemplate({ ...taskTemplate, id: draft.header.taskTemplate.id });
  }) as WithTaskTemplate<W>;
}

export const generateVideoWidget = (videoWidget?: Partial<VideoWidget>): VideoWidget => ({
  id: MuidUtils.randomMuid(),
  header: generateWidgetHeader(WidgetType.Video),
  file: generateS3File(),
  description: 'Cats & you know...',
  service: VideoWidgetService.YouTube,
  serviceCode: MuidUtils.randomMuid(),
  ...videoWidget,
});

export const generateFileWidget = (fileWidget?: Partial<FileWidget>): FileWidget => ({
  id: MuidUtils.randomMuid(),
  header: generateWidgetHeader(WidgetType.File),
  file: generateS3File(),
  description: 'Some file',
  ...fileWidget,
});

export const generateEmailWidget = (emailWidget?: Partial<EmailWidget>): EmailWidget => ({
  id: MuidUtils.randomMuid(),
  header: generateWidgetHeader(WidgetType.Email),
  ...emailWidget,
});

export const generateEmbedWidget = (embedWidget?: Partial<EmbedWidget>): EmbedWidget => ({
  id: MuidUtils.randomMuid(),
  header: generateWidgetHeader(WidgetType.Embed),
  ...embedWidget,
});

export const generateCrossLinkWidget = (crossLinkWidget?: Partial<CrossLinkWidget>): CrossLinkWidget => ({
  id: MuidUtils.randomMuid(),
  header: generateWidgetHeader(WidgetType.CrossLink),
  ...crossLinkWidget,
});

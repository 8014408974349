import * as React from 'react';
import { useKey } from 'react-use';
import { Box, Divider, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from 'components/design/next';
import { useNgStateModalControls } from 'components/ng-state-modal-controls';
import { SearchBox } from './search-box';
import { Hits } from './hits';
import { AlgoliaIndices, SearchContext } from './search-context';
import { Stats } from './stats';
import { useGlobalSearchIsEnabled } from '../use-global-search-is-enabled';
import { createUsableContext } from '@process-street/subgrade/util';
import { TemplateHit } from './template-hit';
import { TemplateType } from '@process-street/subgrade/process';
import { AppModalName, AppModalQueryParam, GlobalSearchVariant } from 'app/app.constants';
import { useStateParam } from 'hooks/use-state-param';
import { Configure } from 'react-instantsearch-dom';
import { Index } from 'react-instantsearch-core';
import { SavedViewHit } from 'features/global-search/components/saved-view-hit';
import { GlobalSearchHits } from 'features/global-search/components/model';

export const MODAL_KEY = AppModalName.GlobalSearch;

const cmdKPressed = (e: KeyboardEvent): boolean => {
  return e.key === 'k' && (e.metaKey || e.ctrlKey);
};

type Context = { isOpen: boolean };
export const [useGlobalSearchModalContext, GlobalSearchModalContext] = createUsableContext<Context>({
  hookName: 'useGlobalSearchModalContext',
  providerName: 'GlobalSearchModalContext',
});

export const GlobalSearchModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { onOpen, isOpen, onClose } = useNgStateModalControls(MODAL_KEY);
  const context = React.useMemo(() => ({ isOpen }), [isOpen]);
  const enabled = useGlobalSearchIsEnabled();

  const modalVariant = useStateParam<GlobalSearchVariant>({
    key: AppModalQueryParam.ModalVariant,
    defaultValue: GlobalSearchVariant.Search,
  });
  const templateType = modalVariant === GlobalSearchVariant.Run ? TemplateType.Playbook : undefined;

  useKey(
    cmdKPressed,
    e => {
      e.preventDefault();
      onOpen();
    },
    {},
    [onOpen],
  );

  return enabled ? (
    <GlobalSearchModalContext.Provider value={context}>
      <SearchContext isOpen={isOpen} templateType={templateType}>
        <Configure getRankingInfo />
        {modalVariant !== GlobalSearchVariant.Run && <Index indexName={AlgoliaIndices.SavedViews} />}
        <Modal aria-label={MODAL_KEY} isOpen={isOpen} onClose={onClose} onEsc={onClose}>
          <ModalOverlay role="button" aria-label="close-global-search-modal" />
          <ModalContent
            borderColor="gray.300"
            borderStyle="solid"
            borderWidth="px"
            maxW="xl"
            borderRadius="md"
            px="8"
            py="6"
          >
            <Box mb="4">
              <SearchBox />
            </Box>
            <ModalBody display="flex" flexDirection="column" w="full" overflowY="hidden" py="0" px="0">
              <Hits
                listProps={{
                  overflowY: 'auto',
                  maxH: '60vh',
                  position: 'relative',
                }}
              >
                {/* since the hit doesn't know the list option is checking for mouse enter, we'll skip default hover styles */}
                {(hit: GlobalSearchHits.AnyHit) => {
                  return GlobalSearchHits.isSavedViewHit(hit) ? (
                    <SavedViewHit hit={hit} _hover={{ textDecoration: 'none', color: 'inherit' }} />
                  ) : (
                    <TemplateHit hit={hit} _hover={{ textDecoration: 'none', color: 'inherit' }} />
                  );
                }}
              </Hits>
            </ModalBody>

            <Divider my="0" />
            <ModalFooter px="4" justifyContent="flex-start" pt="3" pb="0">
              <Stats />
            </ModalFooter>
          </ModalContent>
        </Modal>
      </SearchContext>
    </GlobalSearchModalContext.Provider>
  ) : null;
};

import * as React from 'react';
import { Button, ButtonProps, Icon } from 'app/components/design/next';
import { CustomIconName } from 'app/components/design/next/icon/icon-name';

export type RightSidebarButtonProps = React.PropsWithChildren<{
  iconName: CustomIconName;
  onClick?: () => void;
}> &
  ButtonProps;

export const RightSidebarButton = React.forwardRef<HTMLButtonElement, RightSidebarButtonProps>(
  ({ children, iconName, onClick, ...buttonProps }, ref) => {
    return (
      <Button
        ref={ref}
        leftIcon={<Icon color="gray.400" icon={iconName} size="4" />}
        iconSpacing={2}
        w="full"
        colorScheme="brand"
        variant="ghost"
        rounded="md"
        justifyContent="flex-start"
        fontWeight="normal"
        color="gray.600"
        onClick={onClick}
        {...buttonProps}
      >
        {children}
      </Button>
    );
  },
);

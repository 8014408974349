import { TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import {
  PlacementWithLogical,
  Popover,
  PopoverContent,
  Text,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
} from 'components/design/next';

import * as React from 'react';
import { SingleTriggerButton } from './single-trigger-button';
import { useGetPermissionPickerLabel } from '../hooks/use-get-permission-picker-label';
import { useSelectedOrganization } from 'app/hooks/use-selected-organization';

import { BulkTriggerButton } from './bulk-trigger-button';
import { TaskPermissionsPopoverContent } from './task-permissions-popover-content';

export type TaskPermissionsPickerProps = {
  taskTemplates: TaskTemplate[];
  templateRevisionId: TemplateRevision['id'];
  isDisabled: boolean;
};

export const TaskPermissionsPicker = ({
  taskTemplates,
  templateRevisionId,
  isDisabled,
}: TaskPermissionsPickerProps) => {
  const organizationId = useSelectedOrganization()?.id ?? '';
  const popoverDisclosure = useDisclosure();

  const { label, hasDefaultPermits } = useGetPermissionPickerLabel({
    taskTemplates,
    templateRevisionId,
    organizationId,
  });

  const popoverPlacement = useBreakpointValue<PlacementWithLogical>({ base: 'bottom', md: 'bottom-start' }) ?? 'bottom';

  const isBulk = taskTemplates.length > 1;

  return (
    <Popover placement={popoverPlacement} closeOnBlur={true} isLazy {...popoverDisclosure}>
      <Tooltip
        w="100%"
        isDisabled={!label}
        label={
          <Text variant="-2" textAlign="center">
            {label}
          </Text>
        }
        hasArrow
        shouldWrapChildren
      >
        {isBulk ? (
          <BulkTriggerButton isDisabled={isDisabled} hasDefaultPermits={hasDefaultPermits} />
        ) : (
          <SingleTriggerButton isDisabled={isDisabled} hasDefaultPermits={hasDefaultPermits} />
        )}
      </Tooltip>

      {popoverDisclosure.isOpen && (
        <PopoverContent
          py="3"
          w={{ base: 'auto', md: 'sm' }}
          zIndex="popover"
          textAlign="left"
          minW={{ base: 'unset', md: '430px' }}
        >
          <TaskPermissionsPopoverContent
            organizationId={organizationId}
            taskTemplates={taskTemplates}
            templateRevisionId={templateRevisionId}
          />
        </PopoverContent>
      )}
    </Popover>
  );
};

import * as React from 'react';
import { ApprovalTaskGroupsMap, ApprovalUtils } from '@process-street/subgrade/approval-rule';
import { ActorRefFrom } from 'xstate';
import { TaskMachine } from '../../task/task-machine';
import { FormResponsePageMachineHooks } from '../../../form-response-page-hooks';
import { TaskMachineHooks } from '../../task/task-machine-hooks';
import { FormResponseMachineHooks } from '../../form-response-body/form-response-machine-hooks';

export function useTaskApprovalTaskGroupsMap({
  taskMachine,
}: {
  taskMachine: ActorRefFrom<TaskMachine>;
}): ApprovalTaskGroupsMap {
  const approvals = FormResponseMachineHooks.useApprovals();
  const approvalTask = TaskMachineHooks.useTask(taskMachine);
  const tasks = FormResponseMachineHooks.useTasks();
  const subjectGroupIdToApprovalGroupIdMap = FormResponsePageMachineHooks.useSubjectGroupIdToApprovalGroupIdMap();

  const subjectTasks = React.useMemo(
    () =>
      approvalTask
        ? tasks.filter(
            task =>
              subjectGroupIdToApprovalGroupIdMap.get(task.taskTemplate.group.id) === approvalTask.taskTemplate.group.id,
          )
        : [],
    [approvalTask, tasks, subjectGroupIdToApprovalGroupIdMap],
  );

  const subjectTaskIdToApprovalMap = React.useMemo(
    () =>
      Object.fromEntries(
        approvals
          .filter(approval => approval.approvalTaskId === approvalTask?.id)
          .map(approval => [approval.subjectTaskId, approval]),
      ),
    [approvals, approvalTask],
  );

  const taskGroupsMap = React.useMemo(
    () => ApprovalUtils.groupTaskByApprovalStatus(subjectTasks, subjectTaskIdToApprovalMap),
    [subjectTasks, subjectTaskIdToApprovalMap],
  );

  return taskGroupsMap;
}

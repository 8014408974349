import { DateFormFieldWidget, DueDateRuleDefinition } from '@process-street/subgrade/process';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { UpdateFormFieldValueMutationResponse } from 'features/widgets/query-builder';
import { FormFieldMachineBuilderProps, WithFormFieldMachineEvent } from 'pages/responses/_id/types';
import { Option } from 'space-monad';
import { ActionObject, actions, ActorRefFrom, assign, createMachine, spawn } from 'xstate';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { makeValidationMachine, ValidationActorRef, ValidationParentEvent } from '../validation-machine';
import { makeDateValidationSchema } from 'pages/forms/_id/edit/components/form-fields/date-form-field/date-form-field-schema';
import {
  makeRulesEngineTargetMachine,
  sendRulesActorFormFieldValueUpdate,
} from '../../form-response-body/rules-engine-machine';
import { makeUpdateFormFieldValueMutation } from '../make-update-form-field-value-mutation';
import { makeErrorLoggerAction } from 'app/utils/machines';
import { GetDueDateRulesByChecklistRevisionIdQuery } from 'app/features/dynamic-due-dates/query-builder';
import { SourceType } from 'app/components/dynamic-due-dates/services/task-due-date-constants';
import { GetAllTasksByChecklistRevisionIdQuery } from 'app/features/task/query-builder';

const { sendParent, forwardTo } = actions;

export type Context = {
  widget: DateFormFieldWidget;
  value: number | undefined;
  isTimeHidden: boolean;
  validationActor: ValidationActorRef<number | undefined>;
  rulesEngineTargetActor: ActorRefFrom<typeof makeRulesEngineTargetMachine>;
  inputNode: HTMLElement | null;
};

export type Event =
  | Exclude<WithFormFieldMachineEvent<ValidationParentEvent, number | undefined>, { type: 'CHANGE' | 'SYNC' }>
  | {
      type: 'CHANGE' | 'SYNC';
      value: number | undefined;
      isTimeHidden: boolean;
    };

export const makeDateFormFieldMachine = ({
  formFieldWidget,
  formFieldValue,
  autoFocus,
  checklistRevisionId,
  sharedContext,
  isEditable,
  inputNode,
}: FormFieldMachineBuilderProps<DateFormFieldWidget>) => {
  const numberValue = Option(formFieldValue)
    .map(ffv => ffv.fieldValue.value)
    .map(Number)
    .get();

  const isTimeHidden = formFieldValue?.fieldValue.timeHidden ?? true;

  const validationSchema = makeDateValidationSchema({
    required: formFieldWidget.required,
    constraints: formFieldWidget.constraints,
  });

  const initialState = isEditable ? 'enabled' : 'disabled';

  const id = `date-form-field-machine:${formFieldWidget.id}`;

  return createMachine(
    {
      id,
      type: 'parallel',
      context: () => ({
        inputNode,
        widget: formFieldWidget,
        value: numberValue,
        isTimeHidden,
        validationActor: spawn(makeValidationMachine({ validationSchema, initialValue: numberValue }), {
          name: 'validation-actor',
        }),
        rulesEngineTargetActor: spawn(
          makeRulesEngineTargetMachine({ type: 'widget', widgetHeaderGroupId: formFieldWidget.header.group.id }),
          { name: 'hidden-by-rule-actor', sync: true },
        ),
      }),
      tsTypes: {} as import('./date-form-field-machine.typegen').Typegen0,
      schema: {
        context: {} as Context,
        events: {} as Event,
        services: {} as {
          updateFormFieldValueMutation: {
            data: UpdateFormFieldValueMutationResponse;
          };
        },
      },
      predictableActionArguments: true,
      preserveActionOrder: true,
      on: {
        SET_NODE: { actions: ['assignNode'] },
        SCROLL_INTO_VIEW: { actions: ['scrollIntoView'] },
      },
      states: {
        input: {
          initial: initialState,
          states: {
            disabled: {
              on: {
                ENABLE: {
                  target: 'enabled',
                },
              },
            },
            enabled: {
              on: {
                DISABLE: 'disabled',
                CHANGE: { actions: ['assignValue', 'assignIsTimeHidden', 'sendRulesActorFormFieldValueUpdate'] },
              },
            },
          },
        },

        autoFocus: {
          initial: autoFocus ? 'enabled' : 'disabled',
          states: { disabled: {}, enabled: {} },
        },

        mutation: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                UPDATE_VALUE: 'updating',
              },
            },
            updating: {
              invoke: {
                src: 'updateFormFieldValueMutation',
                onDone: {
                  target: 'idle',
                  actions: ['sendParentFormFieldValueUpdate', 'updateTasksIfDueDateRuleExists'],
                },
                onError: {
                  target: 'idle',
                  actions: ['logError', 'openErrorToast'],
                },
              },
            },
          },
        },

        // This state is a kind of controller to forward events up and down
        // Since it is a parallel state, in can listen for events without blocking or getting blocked by nested states
        validation: {
          initial: 'enabled',
          states: {
            enabled: {
              on: {
                CHANGE: { actions: 'forwardToValidation' },
                REVEAL_INVALID: { actions: 'forwardToValidation' },
                BLUR: { actions: 'forwardToValidation' },
                VALID: { actions: 'sendParentValid' },
                INVALID: { actions: 'sendParentInvalid' },
                HIDE: { actions: 'sendParentValid', target: 'disabled' },
              },
            },
            disabled: {
              on: {
                REVEAL: { target: 'enabled', actions: 'restoreValidationWithParent' },
              },
            },
          },
        },
      },
    },
    {
      services: {
        updateFormFieldValueMutation: async (ctx, event) =>
          makeUpdateFormFieldValueMutation({
            queryClient: sharedContext.queryClient,
            body: {
              checklistRevisionId,
              widgetId: formFieldWidget.id,
              value: event.value,
              timeHidden: ctx.isTimeHidden,
            },
          }).execute(),
      },
      actions: {
        assignNode: assign({ inputNode: (_, event) => event.node }),
        assignValue: assign({
          value: (_, event) => event.value,
        }),
        assignIsTimeHidden: assign({
          isTimeHidden: (_, event) => event.isTimeHidden,
        }),
        openErrorToast: (_, __) => {
          ToastServiceImpl.openToast({
            status: 'error',
            title: "We're having problems updating the form field",
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        },
        sendParentInvalid: sendParent({ type: 'INVALID_WIDGET', widgetId: formFieldWidget.id }),
        sendParentValid: sendParent({ type: 'VALID_WIDGET', widgetId: formFieldWidget.id }),
        forwardToValidation: forwardTo(ctx => ctx.validationActor) as ActionObject<Context, Event>,
        restoreValidationWithParent: sendParent((ctx, _evt) => {
          if (ctx.validationActor.getSnapshot()?.matches('valid')) {
            return { type: 'VALID_WIDGET', widgetId: formFieldWidget.id };
          }
          return { type: 'INVALID_WIDGET', widgetId: formFieldWidget.id };
        }),

        sendRulesActorFormFieldValueUpdate: (_ctx, event) =>
          sendRulesActorFormFieldValueUpdate({
            formFieldValue: { ...formFieldValue!, fieldValue: { value: event.value } },
            formFieldWidget,
          }),
        scrollIntoView: ctx => {
          ctx.inputNode?.scrollIntoView();
        },
        sendParentFormFieldValueUpdate: sendParent((_ctx, evt) => ({
          type: 'FORM_FIELD_VAUE_UPDATE',
          formFieldValue: evt.data.formFieldValue,
        })),
        logError: makeErrorLoggerAction(id),
        updateTasksIfDueDateRuleExists: (ctx, _) => {
          const rules = sharedContext.queryClient.getQueryData<DueDateRuleDefinition[]>(
            GetDueDateRulesByChecklistRevisionIdQuery.getKey({ checklistRevisionId }),
          );
          if (!rules) return;
          const hasRule = rules.some(
            rule =>
              rule.sourceType === SourceType.FORM_FIELD_VALUE &&
              rule.formFieldWidgetGroup?.id === ctx.widget.header.group.id,
          );

          if (hasRule) {
            sharedContext.queryClient.invalidateQueries(
              GetAllTasksByChecklistRevisionIdQuery.getKey({ checklistRevisionId }),
            );
          }
        },
      },
    },
  );
};

export type DateFormFieldMachine = ReturnType<typeof makeDateFormFieldMachine>;
export type DateFormFieldActor = ActorRefFrom<DateFormFieldMachine>;

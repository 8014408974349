enum WidgetBarType {
  CROSS_LINK = 'CrossLink',
  EMAIL = 'Email',
  EMBED = 'Embed',
  FILE = 'File',
  IMAGE = 'Image',
  TABLE = 'Table',
  TEXT = 'Text',
  VIDEO = 'Video',
  FORM_DATE = 'FormDate',
  FORM_EMAIL = 'FormEmail',
  FORM_FILE = 'FormFile',
  FORM_HIDDEN = 'FormHidden',
  FORM_MEMBERS = 'FormMembers',
  FORM_MULTI_CHOICE = 'FormMultiChoice',
  FORM_MULTI_SELECT = 'FormMultiSelect',
  FORM_NUMBER = 'FormNumber',
  FORM_SELECT = 'FormSelect',
  FORM_SEND_RICH_EMAIL = 'FormSendRichEmail',
  FORM_SNIPPET = 'FormSnippet',
  FORM_TABLE = 'FormTable',
  FORM_TEXT = 'FormText',
  FORM_TEXTAREA = 'FormTextarea',
  FORM_URL = 'FormUrl',
}

export enum FormFieldKeysContext {
  RUN_LINK = 'run-link',
  MERGE_TAG = 'merge-tag',
}

const IMAGE_MIME_TYPES = /([./])(gif|jpe?g|png)$/i;

/**
 * The MIME types supported by the file widget.
 */
const DOCUMENT_MIME_TYPES = /([./])(pdf|docx?|xlsx?|pptx?)$/i;

/**
 * These are the video types supported by Wistia:
 * http://wistia.com/doc/export-settings#formats_wistia_supports
 */
const VIDEO_MIME_TYPES =
  /([./])(quicktime|mpeg|avi|x-ms-video|flv|x-flv|f4v|x-f4v|mp4|x-m4v|x-ms-asf|x-ms-wmv|dvd|3gpp|x-matroska|divx|x-divx|xvid|x-xvid)$/i;

const VIDEO_MAX_FILE_SIZE = 8 * 1024 * 1024 * 1024; // 8 GB Wistia limit

const MAX_FILE_SIZE = 250 * 1024 * 1024; // 250 MB

enum BulkUpdateResponse {
  OK = 'Ok',
  CONFLICT = 'Conflict',
  NOT_FOUND = 'NotFound',
  FORBIDDEN = 'Forbidden',
  NOTHING_TO_UPDATE = 'NothingToUpdate',
  NOT_UPDATED = 'NotUpdated',
}

export const WidgetConstants = {
  WidgetBarType,
  FormFieldKeysContext,
  IMAGE_MIME_TYPES,
  DOCUMENT_MIME_TYPES,
  VIDEO_MIME_TYPES,
  VIDEO_MAX_FILE_SIZE,
  MAX_FILE_SIZE,
  BulkUpdateResponse,
};

import { MuidUtils, orderTreeService, User } from '@process-street/subgrade/core';
import {
  FieldType,
  FormFieldWidget,
  isFormFieldWidget,
  MembersFormFieldConfig,
  SelectFormFieldConfig,
  SendRichEmailFormFieldConfig,
  TaskConstants,
  TaskTemplate,
  TaskTemplateTaskType,
  Template,
  TemplateRevision,
  TemplateRevisionStatus,
  TemplateType,
  TextareaFormFieldConfig,
  UrlFormFieldConfig,
  Widget,
  WidgetHeader,
  WidgetHeaderLike,
  WidgetType,
} from '@process-street/subgrade/process';
import {
  CreateTaskTemplateMutation,
  makeGetTaskTemplatesByTemplateRevisionIdQueryObserver,
  makeOptimisticTaskTemplate,
  makeOptimisticTaskTemplates,
  OptimisticTaskTemplate,
  TaskTemplatesByTemplateRevisionIdQuery,
  TaskTemplatesByTemplateRevisionIdQueryResponse,
  UpdateTaskTemplateMutation,
  UpdateTaskTemplateOrderTreesMutation,
} from 'features/task-templates/query-builder';
import {
  GetNewestTemplateRevisionsByTemplateIdQuery,
  GetNewestTemplateRevisionsByTemplateIdQueryResponse,
  GetTemplateQuery,
  makeGetNewestTemplateRevisionsByTemplateIdQueryObserver,
  makeGetTemplateQueryObserver,
} from 'features/template/query-builder';
import {
  CopyWidgetByHeaderIdMutation,
  CreateWidgetMutation,
  makeGetWidgetsByTemplateRevisionIdObserver,
  UpdateWidgetOrderTreesMutation,
  WidgetsByTemplateRevisionIdQuery,
  WidgetsByTemplateRevisionIdQueryResponse,
} from 'features/widgets/query-builder';
import { CopyWidgetOptions } from 'pages/pages/_id/edit/page/utils/widget.api';
import { createOrderTreeBulkUpdater } from 'services/order-tree-bulk-updater.impl';
import { ActorRef, assign, createMachine, send, sendTo, spawn } from 'xstate';
import { FormFieldHelpers, toOrderTrees } from '../helpers/form-field';
import { CreateTemplateRevisionMutation } from 'features/template-revisions/query-builder/create-template-revision-mutation';
import { match, P } from 'ts-pattern';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { makeQueryMachine, QueryActorSelectors } from 'utils/query-builder/query-machine';
import { RefetchOnMountOnly } from 'utils/query-builder';
import { makeUIMachine, SharedContext } from '../../shared';
import { makeTopBarMachine } from '../../shared/form-editor-top-bar/top-bar-machine';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { getOrderableComparer } from 'services/util-pure';
import { makeDeleteTaskTemplateMachine } from './delete-task-template-machine';
import React from 'react';
import { NewFormNameModalContent } from '../new-form-name-modal-content';
import { UpdateTemplateMutation } from 'features/template/mutation-builder';
import { TemplateConstants } from 'services/template-constants';
import { DuplicateTaskTemplateMutation } from 'features/task-templates/query-builder/duplicate-task-template-mutation';
import { createDuplicateTaskTemplatePlaceholder } from 'directives/task-template-list/create-duplicate-task-template-placeholder';
import { MoveWidgetMutation } from 'features/widgets/query-builder/move-widget-mutation';
import { LocationService } from '@process-street/subgrade/util';
import { TableFormFieldConfig } from '@process-street/subgrade/process/configs/table-form-field-config';
import { makeGetAllTaskTemplatesAssignmentsByTemplateRevisionIdQuery } from 'app/features/task-template-assignment/query-builder';
import { makeGetAllOrganizationMembershipsQueryObserver } from 'app/features/organization-memberships/query-builder';
import { GetTaskAssignmentRulesByTemplateRevisionIdQuery } from 'app/features/task-assignment-rules/query-builder';
import { makeGetAllGroupsQueryObserver } from 'app/features/group/query-builder/get-all-groups';
import { ALL_MEMBERS_GROUP_USERNAME } from '@process-street/subgrade/util/membership-utils';
import { GetDueDateRulesByTemplateRevisionIdQuery } from 'app/features/dynamic-due-dates/query-builder';
import { MergeTagReferenceUpdateService } from 'app/features/widgets/merge-tag-reference-update-service';
import { GetAllRulesByTemplateRevisionIdQuery } from 'app/features/conditional-logic/query-builder';
import { GetApprovalRulesByTemplateRevisionIdQuery } from 'app/features/approval-rules/query-builder';
import { AiWorkflowGenerationActorSelector, makeAiWorkflowGenerationMachine } from '../ai-workflow-generation-machine';
import { makeTaskTemplateListMachine } from 'app/pages/workflows/_id/edit-v2/components/task-list/task-template-list-machine';
import { GetAllNativeAutomationsQuery } from 'app/features/native-automations/query-builder';
import { toSuccess } from 'app/reducers/util';
import { WIDGET_GET_ALL_BY_TEMPLATE_REVISION_ID } from 'app/components/widgets/store/widget.actions';
import { TEMPLATE_GET_BY_ID } from 'app/reducers/template/template.actions';
import { TASK_TEMPLATE_GET_ALL_BY_TEMPLATE_REVISION_ID } from 'app/reducers/task-template/task-template.actions';
import { DYNAMIC_DUE_DATE_GET_ALL_BY_TEMPLATE_REVISION } from 'app/components/dynamic-due-dates/store/dynamic-due-dates.actions';
import pLimit from 'p-limit';
import { fromPromise } from 'xstate/lib/behaviors';
import produce from 'immer';
import {
  generateCrossLinkWidget,
  generateEmailWidget,
  generateEmbedWidget,
  generateEmptyTableWidget,
  generateFileWidget,
  generateFormFieldWidget,
  generateImageWidget,
  generateTextWidget,
  generateVideoWidget,
  generateWidgetHeader,
} from '@process-street/subgrade/test';
import { AnalyticsService } from 'app/components/analytics/analytics.service';
import { AnalyticsConstants } from '@process-street/subgrade/analytics';
import {
  FormEditorPageMachineCreateWidgetEvent,
  FormPageEditorMachineContext,
  FormPageEditorMachineEvent,
  WidgetActorMap,
} from './form-editor-page-machine-types';
import { FormEditorPageMachineHelpers } from './form-editor-page-machine-helpers';
import { FormEditorPageMachineActions } from './actions';
import { spawnWidgetMachine } from './helpers/spawn-widget-machine';
import { makeErrorLoggerAction } from 'app/utils/machines';
import { WidgetEvent } from '../types';
import groupBy from 'lodash/groupBy';

const { Key, Event } = AnalyticsConstants;

const AUTO_FOCUS_CONTENT_WIDGET_TYPES_ON_CREATION = [WidgetType.Embed];
const AUTO_FOCUS_FORM_FIELD_WIDGET_TYPES_ON_CREATION = [] as Array<FieldType>;

const widgetOrderTreeUpdater = createOrderTreeBulkUpdater<Widget>({
  getOrderTree: widget => widget.header.orderTree!,
  // @ts-expect-error -- TODO
  setOrderTree: (widget, orderTree) => {
    return {
      ...widget,
      header: {
        ...widget.header,
        orderTree,
      },
    };
  },
});

export const makeFormEditorPageMachine = ({
  sharedContext,
  isReadOnly = false,
}: {
  sharedContext: SharedContext;
  isReadOnly?: boolean;
}) => {
  const { queryClient, templateId, dispatch: reduxDispatch, $state, taskTemplateService } = sharedContext;

  const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
    queryClient,
    templateId,
  });

  const id = 'formEditorPage';
  return createMachine(
    {
      id,
      initial: 'loading',
      predictableActionArguments: true,
      schema: {
        events: {} as FormPageEditorMachineEvent,
        context: {} as FormPageEditorMachineContext,
      },
      tsTypes: {} as import('./form-editor-page-machine.typegen').Typegen0,
      context: () =>
        ({
          uiActorRef: spawn(makeUIMachine({ sharedContext }), { name: 'ui-actor' }),
          topBarActorRef: spawn(makeTopBarMachine({ sharedContext }), { name: 'top-bar-actor' }),
          aiWorkflowGenerationActorRef: spawn(makeAiWorkflowGenerationMachine({ sharedContext })),
          taskTemplateListActorRef: spawn(
            makeTaskTemplateListMachine({ sharedContext, taskTemplates: [], isReadOnly }),
          ),
          publishedTaskTemplates: undefined,
          publishedWidgets: undefined,
          widgetsAtDragStart: undefined,
          widgetActorMap: {},
          taskTemplatesAtDragStart: undefined,
          widgetsLookupMap: {
            byId: {},
            byGroupId: {},
            byTaskTemplateId: {},
            byHeaderId: {},
          },
          taskTemplatesMap: {},
          isReadOnly,
          createTaskTemplateMutationActorRefMap: {},
          updateTaskTemplateMutationActorRefMap: {},
          deleteTaskTemplateActorRefMap: {},
          createWidgetMutationActorRefMap: {},
          sharedContext,
        } as FormPageEditorMachineContext),

      states: {
        loading: {
          initial: 'fetching',
          entry: ['assignTemplateQuery', 'assignTemplateRevisionsQuery', 'assignGroupsQuery'],
          on: {
            'xstate.update': [
              { cond: 'draftDoesNotExist', target: '.creatingDraft' },
              {
                cond: 'draftTemplateRevisionIdReady',
                actions: [
                  'assignTasksQuery',
                  'assignGeneratedTasksQuery',
                  'assignWidgetsQuery',
                  'assignTemplateTaskAssignmentsQuery',
                  'assignOrganizationMembershipsQuery',
                  'assignTaskAssignmentRulesQuery',
                  'assignDueDateRulesQuery',
                  'assignRulesQuery',
                  'assignApprovalRulesQuery',
                ],
              },
              {
                cond: 'allDataReadyAndNotGenerating',
                actions: ['assignWidgetActors'],
                target: isReadOnly ? '#viewing' : '#editing',
              },
            ],
          },
          states: {
            fetching: {},
            creatingDraft: {
              invoke: [
                {
                  id: 'createDraftRevision',
                  src: 'createDraftRevisionMutation',
                  onDone: { target: 'fetching' },
                },
              ],
            },
          },
        },
        viewing: {
          id: 'viewing',
          on: {
            'xstate.update': [
              {
                cond: 'isTaskTemplatesUpdate',
                actions: ['sendTaskTemplatesToTaskTemplateListActor', 'syncReduxState'],
              },
              {
                cond: 'isTemplateUpdate',
                actions: ['sendTemplateToTaskTemplateListActor', 'syncReduxState'],
              },
              { actions: ['syncReduxState'] },
            ],
          },
          entry: ['sendTaskTemplatesToTaskTemplateListActor', 'sendTemplateToTaskTemplateListActor', 'syncReduxState'],
        },
        editing: {
          id: 'editing',
          initial: 'determiningIfNew',
          on: {
            'xstate.update': [
              { cond: 'isWidgetSuccessAndNotGenerating', actions: ['assignWidgetActors', 'syncReduxState'] },
              {
                cond: 'isTaskTemplatesUpdate',
                actions: [
                  'sendTaskTemplatesToTaskTemplateListActor',
                  'sendTemplateToTaskTemplateListActor',
                  'syncReduxState',
                ],
              },
              { actions: ['syncReduxState'] },
            ],
            'PUBLISH': 'publishing',
          },
          entry: ['sendTaskTemplatesToTaskTemplateListActor', 'sendTemplateToTaskTemplateListActor', 'syncReduxState'],
          states: {
            determiningIfNew: {
              always: [{ cond: 'isBrandNewForm', target: 'new' }, { target: 'idle' }],
            },
            new: {
              initial: 'idle',
              states: {
                idle: {
                  entry: ['sendOpenModalToUiActor'],
                  on: { CONTINUE: 'updatingName' },
                  invoke: {
                    id: 'createFirstWidgetMutation',
                    src: 'createFirstWidgetMutation',
                  },
                },
                updatingName: {
                  invoke: {
                    id: 'updateTemplateMutation',
                    src: 'updateTemplateMutation',
                    onDone: { target: '#idle', actions: ['sendCloseModalToUiActor', 'sendAutoFocusToFirstWidget'] },
                  },
                },
              },
            },
            idle: {
              id: 'idle',
              on: {
                'BULK_CREATE_TASK_FROM_PASTE': 'bulkCreateTaskFromPaste',

                // Task template creation events
                'CREATE_TASK_TEMPLATE': {
                  actions: ['createTaskTemplateMutation'],
                },
                'done.invoke.createTaskTemplateMutation': {
                  actions: ['stopCreateTaskTemplateMutationActor', 'unassignCreateTaskTemplateMutationActor'],
                },

                'DELETE_TASK_TEMPLATE': [{ actions: ['deleteTaskTemplateMachine'] }],
                'DELETING_TASK_TEMPLATE': { actions: 'optimisticallyNavigateToNextTaskTemplate' },
                'done.invoke.deleteTaskTemplateMachine': { actions: 'unassignDeleteTaskTemplateMachine' },
                'CREATE_WIDGET': { actions: ['createWidgetMutation'] },
                'PASTE_FILE': { actions: ['createWidgetFromClipboard'] },
                'DUPLICATE_TASK_TEMPLATE': 'duplicatingTaskTemplate',
                'DUPLICATE_WIDGET': 'duplicatingWidget',
                'MOVE_TASK_TEMPLATE': 'movingTaskTemplate',
                'MOVE_WIDGET': 'movingWidget',
                'MOVE_WIDGET_TO_STEP': 'movingWidgetToStep',
                'REORDER_TASK_TEMPLATES_DRAG_START': {
                  target: 'reorderingTaskTemplates',
                  actions: ['assignTaskTemplatesAtDragStart'],
                },
                'REORDER_WIDGETS_DRAG_START': {
                  target: 'reorderingWidgets',
                  actions: ['assignWidgetsAtDragStart'],
                },
                'UPDATE_GENERATED_TASK_TEMPLATE': {
                  actions: 'updateGeneratedTaskTemplates',
                },
                'UPDATE_GENERATED_WIDGET': {
                  actions: [
                    'assignGeneratedWidgetActor',
                    'updateGeneratedWidgetLabel',
                    'updateGeneratedWidgetConfig',
                    'sendScrollIntoView',
                  ],
                },
                'UPDATE_MERGE_TAGS_REFERENCES': 'updatingMergeTags',

                'UPDATE_TASK_TEMPLATE': { actions: ['updateTaskTemplateMutation'] },
                'done.invoke.updateTaskTemplateMutation': {
                  actions: ['stopUpdateTaskTemplateMutationActor', 'unassignUpdateTaskTemplateMutationActor'],
                },

                'SELECT_TASK_TEMPLATE': { actions: ['selectTaskTemplate'] },
                'FOCUS_NEXT_WIDGET': { actions: ['focusNextWidget'] },
                'FOCUS_PREVIOUS_WIDGET': { actions: ['focusPreviousWidget'] },
                'FOCUS_FIRST_TEXT_WIDGET': { actions: 'focusFirstTextWidget' },
              },
            },
            bulkCreateTaskFromPaste: {
              invoke: [
                {
                  id: 'bulkCreateTask',
                  src: 'bulkCreateTaskFromPasteMutation',
                  onDone: { target: 'idle' },
                  onError: { target: 'idle', actions: 'logError' },
                },
              ],
            },
            movingWidget: {
              invoke: [
                {
                  id: 'moveWidget',
                  src: 'moveWidgetMutation',
                  onDone: {
                    target: 'idle',
                  },
                  onError: {
                    target: 'idle',
                    actions: 'logError',
                  },
                },
              ],
            },
            duplicatingWidget: {
              invoke: [
                {
                  id: 'duplicateWidget',
                  src: 'duplicateWidgetMutation',
                  onDone: {
                    target: 'idle',
                  },
                  onError: {
                    target: 'idle',
                    actions: 'logError',
                  },
                },
              ],
            },
            reorderingWidgets: {
              initial: 'init',
              states: {
                init: {
                  on: {
                    REORDER_WIDGETS: { target: 'reordered', actions: ['optimisticallyReorderWidgets'] },
                    MOUSE_UP: 'done',
                  },
                },
                reordered: {
                  on: {
                    REORDER_WIDGETS: { actions: ['optimisticallyReorderWidgets'] },
                    MOUSE_UP: 'persisting',
                  },
                },
                persisting: {
                  invoke: {
                    id: 'reorderWidgets',
                    src: 'reorderWidgetsMutation',
                    onDone: 'done',
                    onError: { target: 'done', actions: ['logError', 'openReorderErrorToast'] },
                  },
                },
                done: { type: 'final', entry: ['unassignWidgetsAtDragStart'] },
              },
              onDone: 'idle',
            },
            reorderingTaskTemplates: {
              initial: 'init',
              states: {
                init: {
                  on: {
                    REORDER_TASK_TEMPLATES: { target: 'reordered', actions: ['optimisticallyReorderTaskTemplates'] },
                    MOUSE_UP: 'done',
                  },
                },
                reordered: {
                  on: {
                    REORDER_TASK_TEMPLATES: { actions: ['optimisticallyReorderTaskTemplates'] },
                    MOUSE_UP: 'persisting',
                  },
                },
                persisting: {
                  invoke: {
                    id: 'reorderTaskTemplates',
                    src: 'reorderTaskTemplatesMutation',
                    onDone: 'done',
                    onError: { target: 'done', actions: ['logError', 'openReorderErrorToast'] },
                  },
                },
                done: { type: 'final', entry: ['unassignTaskTemplatesAtDragStart'] },
              },
              onDone: 'idle',
            },
            movingTaskTemplate: {
              invoke: {
                id: 'moveTaskTemplate',
                src: 'moveTaskTemplateMutation',
                onDone: 'idle',
                onError: { target: 'idle', actions: 'logError' },
              },
            },
            duplicatingTaskTemplate: {
              invoke: [
                {
                  id: 'duplicateTaskTemplate',
                  src: 'duplicateTaskTemplateMutation',
                  onDone: { target: 'idle' },
                  onError: { target: 'idle', actions: 'logError' },
                },
              ],
            },
            movingWidgetToStep: {
              invoke: [
                {
                  id: 'moveWidgetToStep',
                  src: 'moveWidgetToStepMutation',
                  onDone: {
                    target: 'idle',
                  },
                  onError: {
                    target: 'idle',
                    actions: 'logError',
                  },
                },
              ],
            },
            updatingMergeTags: {
              invoke: [
                {
                  id: 'updateMergeTags',
                  src: 'updateMergeTagsMutation',
                  onDone: {
                    target: 'idle',
                  },
                  onError: {
                    target: 'idle',
                    actions: 'logError',
                  },
                },
              ],
            },
            updatingGeneratedWidget: {
              on: {
                UPDATE_GENERATED_WIDGET: [
                  {
                    target: 'idle',
                    actions: [
                      'assignGeneratedWidgetActor',
                      'updateGeneratedWidgetLabel',
                      'updateGeneratedWidgetConfig',
                    ],
                  },
                ],
              },
            },
          },
        },
        publishing: {
          initial: 'init',
          states: {
            init: {
              on: {
                PUBLISH_SUCCESS: { actions: ['assignPublishedWidgetsAndTaskTemplates', 'assignBlankQueries'] },
                NEW_DRAFT_CREATED: {
                  target: 'refreshing',
                  actions: [
                    'assignWidgetsQuery',
                    'assignTasksQuery',
                    'assignGeneratedTasksQuery',
                    'assignTemplateTaskAssignmentsQuery',
                    'assignOrganizationMembershipsQuery',
                    'assignTaskAssignmentRulesQuery',
                    'assignDueDateRulesQuery',
                    'assignRulesQuery',
                    'assignApprovalRulesQuery',
                  ],
                },
              },
            },
            refreshing: {
              on: {
                'xstate.update': {
                  cond: 'allDataReadyAndNotGenerating',
                  actions: ['assignWidgetActors'],
                  target: 'done',
                },
              },
            },
            done: { type: 'final' },
          },
          onDone: { target: 'editing', actions: ['sendOpenShareMenuToUiActor'] },
        },
      },
    },
    {
      actions: {
        logError: makeErrorLoggerAction(id),
        selectTaskTemplate: sendTo(
          ctx => ctx.taskTemplateListActorRef,
          (_ctx, evt) => ({
            type: 'SELECT_TASK_TEMPLATE',
            taskTemplate: evt.taskTemplate,
            metaKey: false,
            ctrlKey: false,
            shiftKey: false,
          }),
        ),
        assignGeneratedWidgetActor: assign({
          widgetActorMap: (ctx, evt) => {
            const { widget } = evt;
            const existingActor = ctx.widgetActorMap[widget.header.id];
            if (existingActor) {
              return ctx.widgetActorMap;
            }
            const template = QueryActorSelectors.getQueryData(ctx.templateQuery);
            if (!template) {
              throw new Error('missing template');
            }
            const actor = spawnWidgetMachine(widget, template, sharedContext, ctx.isReadOnly);

            if (actor) {
              queryClient.setQueryData<Widget[]>(
                WidgetsByTemplateRevisionIdQuery.getKey(FormEditorPageMachineHelpers.getTemplateRevision(ctx)?.id),
                current => {
                  if (!current) return [{ ...widget, label: '' }];
                  return [...current, { ...widget, label: '' }];
                },
              );
              return {
                ...ctx.widgetActorMap,
                [widget.header.id]: actor,
              };
            }

            return ctx.widgetActorMap;
          },
        }),

        updateGeneratedWidgetLabel: send(
          (_ctx, evt) => ({ type: 'SET_WIDGET_LABEL', label: isFormFieldWidget(evt.widget) ? evt.widget.label : '' }),
          {
            to: (ctx, evt) => ctx.widgetActorMap[evt.widget.header.id],
          },
        ),
        updateGeneratedWidgetConfig: send(
          (ctx, evt) => {
            if (isFormFieldWidget(evt.widget) && evt.widget.fieldType === FieldType.MultiSelect) {
              queryClient.setQueryData<Widget[]>(
                WidgetsByTemplateRevisionIdQuery.getKey(FormEditorPageMachineHelpers.getTemplateRevision(ctx)?.id),
                current => {
                  if (!current) {
                    return [{ ...evt.widget }];
                  }
                  return current.map(w => (w.header.id === evt.widget.header.id ? evt.widget : w));
                },
              );
            }
            return {
              type: 'SET_WIDGET_CONFIG',
              config: isFormFieldWidget(evt.widget) ? evt.widget.config : {},
            };
          },
          {
            to: (ctx, evt) => ctx.widgetActorMap[evt.widget.header.id],
          },
        ),
        sendScrollIntoView: sendTo(
          (ctx, evt) => {
            const { widget } = match(evt)
              .with({ type: 'UPDATE_GENERATED_WIDGET' }, e => ({ widget: e.widget }))
              .otherwise(() => ({ widget: undefined }));

            return widget ? ctx.widgetActorMap[widget.header.id] : ctx.widgetActorMap[0];
          },
          { type: 'SCROLL_INTO_VIEW' },
          { delay: 200 },
        ),
        updateGeneratedTaskTemplates: (ctx, evt) => {
          const templateRevisionId = FormEditorPageMachineHelpers.getTemplateRevision(ctx)?.id;
          if (!templateRevisionId) throw new Error('draft template revision not found');

          queryClient.setQueryData<TaskTemplate[]>(
            TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
            current => {
              if (!current || current.length === 0) return [{ ...evt.taskTemplate }];
              const exists = current.some(t => t.id === evt.taskTemplate.id);
              return exists
                ? current.map(t => (t.id === evt.taskTemplate.id ? evt.taskTemplate : t))
                : current.concat(evt.taskTemplate);
            },
          );
        },
        assignTemplateQuery: assign({
          templateQuery: (_ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: makeGetTemplateQueryObserver({ templateId, queryClient, options: RefetchOnMountOnly }),
              }),
              { sync: true, name: 'template-query' },
            );
          },
        }),
        assignTemplateRevisionsQuery: assign({
          templateRevisionsQuery: (_ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: makeGetNewestTemplateRevisionsByTemplateIdQueryObserver({
                  templateId,
                  queryClient,
                  options: RefetchOnMountOnly,
                }),
              }),
              { sync: true, name: 'template-revisions-query' },
            );
          },
        }),
        assignWidgetsQuery: assign({
          widgetsByTemplateRevisionIdQuery: (ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: makeGetWidgetsByTemplateRevisionIdObserver({
                  queryClient,
                  templateRevisionId: FormEditorPageMachineHelpers.getTemplateRevision(ctx)?.id,
                  options: {
                    ...RefetchOnMountOnly,
                    keepPreviousData: true,
                    select: FormEditorPageMachineHelpers.createWidgetsLookup,
                  },
                }),
              }),
              { sync: true, name: 'widgets-query' },
            );
          },
        }),
        assignGroupsQuery: assign({
          groupsQuery: (_ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: makeGetAllGroupsQueryObserver({
                  queryClient,
                  options: { ...RefetchOnMountOnly, keepPreviousData: true },
                }),
              }),
              { sync: true, name: 'groups-query' },
            );
          },
        }),
        assignTasksQuery: assign({
          taskTemplatesByTemplateRevisionIdQuery: (ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: makeGetTaskTemplatesByTemplateRevisionIdQueryObserver({
                  queryClient,
                  templateRevisionId: FormEditorPageMachineHelpers.getTemplateRevision(ctx)?.id,
                  options: { ...RefetchOnMountOnly, select: FormEditorPageMachineHelpers.createTaskTemplatesLookup },
                }),
              }),
              { sync: true, name: 'task-templates-query' },
            );
          },
        }),
        assignGeneratedTasksQuery: assign({
          generatedTaskTemplatesByTemplateRevisionIdQuery: (ctx, _evt) => {
            const templateRevisionId = FormEditorPageMachineHelpers.getTemplateRevision(ctx)?.id;
            if (!templateRevisionId) throw new Error('template revision not found');
            return spawn(
              makeQueryMachine({
                observer: makeGetTaskTemplatesByTemplateRevisionIdQueryObserver({
                  queryClient,
                  templateRevisionId,
                  options: {
                    ...RefetchOnMountOnly,
                    queryKey: ['generated', TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId })],
                  },
                }),
              }),
            );
          },
        }),
        assignTemplateTaskAssignmentsQuery: assign({
          templateTaskAssignmentsQuery: (ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: makeGetAllTaskTemplatesAssignmentsByTemplateRevisionIdQuery({
                  queryClient,
                  templateRevisionId: FormEditorPageMachineHelpers.getTemplateRevision(ctx)?.id,
                  options: RefetchOnMountOnly,
                }),
              }),
              { sync: true, name: 'template-revision-task-templates-assignments-query' },
            );
          },
        }),
        assignOrganizationMembershipsQuery: assign({
          organizationMembershipsQuery: (ctx, _evt) => {
            const organizationId = QueryActorSelectors.getQueryData(ctx.templateRevisionsQuery)?.[0]?.organization.id;
            return spawn(
              makeQueryMachine({
                observer: makeGetAllOrganizationMembershipsQueryObserver({
                  queryClient,
                  organizationId,
                  options: RefetchOnMountOnly,
                }),
              }),
              { sync: true, name: 'organization-memberships-query' },
            );
          },
        }),
        assignTaskAssignmentRulesQuery: assign({
          taskAssignmentRulesQuery: (ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: GetTaskAssignmentRulesByTemplateRevisionIdQuery.makeQueryObserver({
                  queryClient,
                  templateRevisionId: FormEditorPageMachineHelpers.getTemplateRevision(ctx)?.id,
                  options: RefetchOnMountOnly,
                }),
              }),
              { sync: true, name: 'task-assignment-rules-by-template-revision-id-query' },
            );
          },
        }),
        assignDueDateRulesQuery: assign({
          dueDateRulesQuery: (ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: GetDueDateRulesByTemplateRevisionIdQuery.makeQueryObserver({
                  queryClient,
                  options: RefetchOnMountOnly,
                  templateRevisionId: FormEditorPageMachineHelpers.getTemplateRevision(ctx)?.id,
                }),
              }),
              { sync: true, name: 'due-date-rules-query' },
            );
          },
        }),
        assignRulesQuery: assign({
          rulesQuery: (ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: GetAllRulesByTemplateRevisionIdQuery.makeQueryObserver({
                  queryClient,
                  options: RefetchOnMountOnly,
                  templateRevisionId: FormEditorPageMachineHelpers.getTemplateRevision(ctx)?.id,
                }),
              }),
              { sync: true, name: 'rules-query' },
            );
          },
        }),
        assignApprovalRulesQuery: assign({
          approvalRulesQuery: (ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: GetApprovalRulesByTemplateRevisionIdQuery.makeQueryObserver({
                  queryClient,
                  options: RefetchOnMountOnly,
                  templateRevisionId: FormEditorPageMachineHelpers.getTemplateRevision(ctx)?.id,
                }),
              }),
              { sync: true, name: 'approval-rules-query' },
            );
          },
        }),
        assignWidgetActors: assign({
          widgetActorMap: (context, _evt) => {
            const data = QueryActorSelectors.getQueryData(context.widgetsByTemplateRevisionIdQuery)?.all ?? [];
            const template = QueryActorSelectors.getQueryData(context.templateQuery);
            if (!template) {
              throw new Error('missing template');
            }
            return data.reduce<WidgetActorMap>((acc, widget) => {
              const existingActor = context.widgetActorMap?.[widget.header.id];
              if (existingActor) {
                acc[widget.header.id] = existingActor;
                return acc;
              }

              const actor = spawnWidgetMachine(widget, template, sharedContext, context.isReadOnly);

              if (actor) {
                acc[widget.header.id] = actor;
              }

              return acc;
            }, {});
          },
        }),

        sendAutoFocusToFirstWidget: sendTo(
          (context, _event) => {
            return Object.values(context.widgetActorMap!)[0]!;
          },
          { type: 'AUTO_FOCUS' },
          // there is a race condition with the modal closing causing a call stack overflow
          { delay: 100 },
        ),

        assignBlankQueries: assign({
          taskTemplatesByTemplateRevisionIdQuery: (_ctx, _evt) => undefined,
          widgetsByTemplateRevisionIdQuery: (_ctx, _evt) => undefined,
        }),

        assignPublishedWidgetsAndTaskTemplates: assign({
          publishedWidgets: (context, _evt) => {
            return QueryActorSelectors.getQueryData(context.widgetsByTemplateRevisionIdQuery)?.all;
          },
          publishedTaskTemplates: (context, _evt) => {
            return QueryActorSelectors.getQueryData(context.taskTemplatesByTemplateRevisionIdQuery)?.all;
          },
        }),

        sendOpenModalToUiActor: sendTo(ctx => ctx.uiActorRef, {
          type: 'OPEN_MODAL',
          modal: React.createElement(NewFormNameModalContent),
          modalProps: { isCentered: true, closeOnEsc: false, closeOnOverlayClick: false },
          overlayProps: { backdropFilter: 'blur(8px)' },
        }),

        sendCloseModalToUiActor: sendTo(ctx => ctx.uiActorRef, { type: 'CLOSE_MODAL' }),

        optimisticallyReorderWidgets: (ctx, { widgets }) => {
          queryClient.setQueryData(
            WidgetsByTemplateRevisionIdQuery.getKey(FormEditorPageMachineHelpers.getTemplateRevision(ctx)?.id),
            () => widgets,
          );
        },

        optimisticallyReorderTaskTemplates: (ctx, { taskTemplates }) => {
          queryClient.setQueryData(
            TaskTemplatesByTemplateRevisionIdQuery.getKey({
              templateRevisionId: FormEditorPageMachineHelpers.getTemplateRevision(ctx)?.id,
            }),
            () => taskTemplates,
          );
        },

        assignWidgetsAtDragStart: assign({
          widgetsAtDragStart: (ctx, _evt) => {
            const template = QueryActorSelectors.getQueryData(ctx.templateQuery);

            return match(template)
              .with(
                { templateType: TemplateType.Page },
                () => QueryActorSelectors.getQueryData(ctx.widgetsByTemplateRevisionIdQuery)?.all ?? [],
              )
              .otherwise(() => {
                const taskTemplateGroupId = sharedContext.$state.params.groupId;
                const taskTemplate = QueryActorSelectors.getQueryData(
                  ctx.taskTemplatesByTemplateRevisionIdQuery,
                )?.all?.find(taskTemplate => taskTemplate.group.id === taskTemplateGroupId);
                const widgets =
                  QueryActorSelectors.getQueryData(ctx.widgetsByTemplateRevisionIdQuery)?.all?.filter(
                    w => w.header.taskTemplate.id === taskTemplate?.id,
                  ) ?? [];
                return widgets;
              });
          },
        }),

        unassignWidgetsAtDragStart: assign({
          widgetsAtDragStart: (_ctx, _evt) => undefined,
        }),

        assignTaskTemplatesAtDragStart: assign({
          taskTemplatesAtDragStart: (ctx, _evt) =>
            QueryActorSelectors.getQueryData(ctx.taskTemplatesByTemplateRevisionIdQuery)?.all ?? [],
        }),

        unassignTaskTemplatesAtDragStart: assign({
          taskTemplatesAtDragStart: () => undefined,
        }),

        openReorderErrorToast: () => {
          ToastServiceImpl.openToast({
            status: 'error',
            title: "We're having problems reordering",
          });
        },

        sendOpenShareMenuToUiActor: sendTo(ctx => ctx.uiActorRef, { type: 'OPEN_DISCLOSURE', name: 'shareMenu' }),

        sendTemplateToTaskTemplateListActor: sendTo(
          ctx => ctx.taskTemplateListActorRef,
          ctx => ({
            type: 'SET_TEMPLATE',
            template: QueryActorSelectors.getQueryData(ctx.templateQuery),
          }),
        ),

        sendTaskTemplatesToTaskTemplateListActor: sendTo(
          ctx => ctx.taskTemplateListActorRef,
          ctx => {
            return {
              type: 'SET_TASK_TEMPLATES',
              taskTemplates: QueryActorSelectors.getQueryData(ctx.taskTemplatesByTemplateRevisionIdQuery)?.all ?? [],
            };
          },
        ),

        syncReduxState: (ctx, evt) => {
          const templateRevisionId = FormEditorPageMachineHelpers.getTemplateRevision(ctx)?.id;
          if (evt.type !== 'xstate.update' || !templateRevisionId) return;

          match(evt)
            .with({ id: 'widgets-query' }, ({ state }) => {
              const widgets = state.context.data?.all ?? [];

              reduxDispatch({
                type: toSuccess(WIDGET_GET_ALL_BY_TEMPLATE_REVISION_ID),
                payload: widgets,
                meta: { templateRevisionId, flushCache: true },
              });
              reduxDispatch({
                type: WIDGET_GET_ALL_BY_TEMPLATE_REVISION_ID,
                payload: widgets,
                meta: { templateRevisionId, flushCache: true },
              });
            })
            .with({ id: 'template-query' }, ({ state }) => {
              const template = state.context.data;

              if (template) {
                reduxDispatch({
                  type: toSuccess(TEMPLATE_GET_BY_ID),
                  payload: template,
                  meta: { templateId: template.id },
                });
              }
            })
            .with({ id: 'task-templates-query' }, ({ state }) => {
              const taskTemplates = state.context.data?.all ?? [];

              reduxDispatch({
                type: TASK_TEMPLATE_GET_ALL_BY_TEMPLATE_REVISION_ID,
                payload: taskTemplates,
                meta: { templateRevisionId, flushCache: false },
              });
            })
            .with({ id: 'due-date-rules-query' }, ({ state }) => {
              const dueDateRuleDefinitions = state.context.data ?? [];

              reduxDispatch({
                type: DYNAMIC_DUE_DATE_GET_ALL_BY_TEMPLATE_REVISION,
                payload: dueDateRuleDefinitions,
                meta: { templateRevisionId },
              });
            })
            .otherwise(() => {});
        },

        createTaskTemplateMutation: assign({
          createTaskTemplateMutationActorRefMap: (context, evt) => {
            const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
              queryClient,
              templateId,
            });

            const taskTemplatesLookup =
              QueryActorSelectors.getQueryData(context.taskTemplatesByTemplateRevisionIdQuery) ??
              FormEditorPageMachineHelpers.emptyTaskTemplatesLookupMap;

            const template = QueryActorSelectors.getQueryData(context.templateQuery);
            const templateRevisionId = FormEditorPageMachineHelpers.getTemplateRevision(context)?.id;

            if (!templateRevisionId) throw new Error(`templateRevisionId is missing`);

            const newTaskTemplate = makeOptimisticTaskTemplate({
              taskType: TaskTemplateTaskType.Standard,
              atIndex: evt.at ?? taskTemplatesLookup.all.length - 1,
              templateRevisionId,
              taskTemplates: taskTemplatesLookup.all,
              ...evt.taskTemplate,
            });

            queryClient.setQueryData<Array<TaskTemplate | OptimisticTaskTemplate>>(
              TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
              current => FormEditorPageMachineHelpers.appendTaskTemplate(current ?? [], newTaskTemplate, evt.at),
            );

            const actor = spawn(
              fromPromise(() => {
                templateRevisionCacheSetter.updateDraftLastUpdatedDate();

                const { $state } = sharedContext;
                if (template?.templateType === TemplateType.Form) {
                  void $state.go('form.step', { groupId: newTaskTemplate.group?.id }, { inherit: true });
                }

                return makeMutation(queryClient, {
                  mutationFn: () => CreateTaskTemplateMutation.mutationFn(newTaskTemplate),
                  mutationKey: CreateTaskTemplateMutation.key,
                  onSuccess: newTaskTemplate => {
                    AnalyticsService.trackEvent(AnalyticsConstants.Event.TASK_TEMPLATE_CREATED);
                    queryClient.setQueryData<TaskTemplate[]>(
                      TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
                      current => {
                        return (current || []).map(taskTemplate => {
                          // Replace optimistic task template with the one returned by the server
                          if (taskTemplate.id === newTaskTemplate.id) return newTaskTemplate;

                          return taskTemplate;
                        });
                      },
                    );
                    if (newTaskTemplate.taskType === TaskTemplateTaskType.AI) {
                      void queryClient.invalidateQueries(GetAllNativeAutomationsQuery.getKey({ templateRevisionId }));
                    }
                  },
                  onError: (_err, _vars) => {
                    ToastServiceImpl.openToast({
                      status: 'error',
                      title: "We're having problems creating that step",
                    });
                  },
                }).execute();
              }),
              { sync: true, name: 'createTaskTemplateMutation' },
            );

            return {
              ...context.createTaskTemplateMutationActorRefMap,
              [newTaskTemplate.id]: actor,
            };
          },
        }),

        stopCreateTaskTemplateMutationActor: (ctx, evt) => {
          ctx.createTaskTemplateMutationActorRefMap[evt.data.id]?.stop?.();
        },
        unassignCreateTaskTemplateMutationActor: assign({
          createTaskTemplateMutationActorRefMap: (ctx, evt) =>
            produce(ctx.createTaskTemplateMutationActorRefMap, draft => {
              delete draft[evt.data.id];
            }),
        }),

        deleteTaskTemplateMachine: assign({
          deleteTaskTemplateActorRefMap: (ctx, evt) => {
            const templateRevisionId = FormEditorPageMachineHelpers.getTemplateRevision(ctx)?.id;

            if (!templateRevisionId) {
              return ctx.deleteTaskTemplateActorRefMap;
            }

            const widgetsLookup =
              QueryActorSelectors.getQueryData(ctx.widgetsByTemplateRevisionIdQuery) ??
              FormEditorPageMachineHelpers.emptyWidgetsLookup;

            const widgetIdsForTaskTemplate = widgetsLookup.byTaskTemplateId[evt.taskTemplateId] ?? [];
            const widgetsForTaskTemplate = widgetIdsForTaskTemplate.map(widgetId => widgetsLookup.byId[widgetId]);

            const actor = spawn(
              makeDeleteTaskTemplateMachine({
                sharedContext,
                taskTemplateId: evt.taskTemplateId,
                templateRevisionId,
                widgets: widgetsForTaskTemplate ?? [],
              }),
              { name: 'deleteTaskTemplateMachine' },
            );

            return {
              ...ctx.deleteTaskTemplateActorRefMap,
              [evt.taskTemplateId]: actor,
            };
          },
        }),
        optimisticallyNavigateToNextTaskTemplate: (ctx, evt) => {
          const templateRevisionId = FormEditorPageMachineHelpers.getTemplateRevision(ctx)?.id;

          if (!templateRevisionId) {
            throw new Error('`templateRevisionId` is missing');
          }

          const taskTemplates = FormEditorPageMachineHelpers.getTaskTemplates(ctx);

          const sortedTasks = taskTemplateService.getTaskTemplatesSorted([...(taskTemplates ?? [])]);

          const taskTemplateToNavigate = match(sortedTasks)
            .with(P.not(P.nullish), taskTemplates => {
              const index = taskTemplates.findIndex(taskTemplate => taskTemplate.id === evt.taskTemplateId);
              // When deleting the first step, we navigate to the next step
              if (index === 0) return taskTemplates[index + 1];
              // When deleting any other step, we navigate to the previous step
              if (index > 0) return taskTemplates[index - 1];
              // The task was not found
              return null;
            })
            .otherwise(() => null);

          if (taskTemplateToNavigate) {
            const template = FormEditorPageMachineHelpers.getTemplate(ctx);

            match(template)
              .with({ templateType: TemplateType.Playbook }, () => {
                ctx.taskTemplateListActorRef.send({
                  type: 'SELECT_TASK_TEMPLATE',
                  taskTemplate: taskTemplateToNavigate,
                  metaKey: false,
                  ctrlKey: false,
                  shiftKey: false,
                });
              })
              .with({ templateType: TemplateType.Form }, () => {
                $state.go('form.step', { groupId: taskTemplateToNavigate.group.id }, { inherit: true });
              })
              .otherwise(() => {});
          }
        },
        unassignDeleteTaskTemplateMachine: assign({
          deleteTaskTemplateActorRefMap: (ctx, evt) => {
            return produce(ctx.deleteTaskTemplateActorRefMap, draft => {
              delete draft[evt.data];
            });
          },
        }),
        updateTaskTemplateMutation: assign({
          updateTaskTemplateMutationActorRefMap: (ctx, evt) => {
            const { taskTemplateId, taskTemplate } = evt;
            const templateRevisionId = FormEditorPageMachineHelpers.getTemplateRevision(ctx)?.id!;

            const mutate = () => {
              return makeMutation(queryClient, {
                variables: { taskTemplateId, taskTemplate },
                mutationFn: UpdateTaskTemplateMutation.mutationFn,
                mutationKey: UpdateTaskTemplateMutation.key,
                onMutate: () => {
                  const previousTaskTemplates =
                    QueryActorSelectors.getQueryData(ctx.taskTemplatesByTemplateRevisionIdQuery)?.all ?? [];

                  queryClient.setQueryData<TaskTemplatesByTemplateRevisionIdQueryResponse>(
                    TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
                    (current = []) => current.map(t => (t.id === taskTemplateId ? { ...t, ...taskTemplate } : t)),
                  );

                  return previousTaskTemplates;
                },
                onSuccess: () => {
                  templateRevisionCacheSetter.updateDraftLastUpdatedDate();
                },
                onError: (_err, _vars, previousTaskTemplates) => {
                  ToastServiceImpl.openToast({
                    status: 'error',
                    title: "We're having problems updating that step",
                  });

                  queryClient.setQueryData<TaskTemplatesByTemplateRevisionIdQueryResponse>(
                    TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
                    previousTaskTemplates ?? [],
                  );
                },
              }).execute();
            };

            return {
              ...ctx.updateTaskTemplateMutationActorRefMap,
              [taskTemplateId]: spawn(fromPromise(mutate), { name: 'updateTaskTemplateMutation' }),
            };
          },
        }),
        stopUpdateTaskTemplateMutationActor: (ctx, evt) => {
          ctx.updateTaskTemplateMutationActorRefMap[evt.data.taskTemplate.id]?.stop?.();
        },
        unassignUpdateTaskTemplateMutationActor: assign({
          updateTaskTemplateMutationActorRefMap: (ctx, evt) =>
            produce(ctx.updateTaskTemplateMutationActorRefMap, draft => {
              delete draft[evt.data.taskTemplate.id];
            }),
        }),

        // @ts-expect-error -- TODO
        createWidgetMutation: assign((context, event) => {
          const taskTemplates =
            QueryActorSelectors.getQueryData(context.taskTemplatesByTemplateRevisionIdQuery)?.all ?? [];
          const taskTemplateGroupId = sharedContext.$state.params.groupId;
          const taskTemplate = taskTemplates.find(tt => tt.group.id === taskTemplateGroupId) ?? taskTemplates[0];
          const allWidgets = QueryActorSelectors.getQueryData(context.widgetsByTemplateRevisionIdQuery)?.all ?? [];
          const defaultGroupId =
            QueryActorSelectors.getQueryData(context.groupsQuery)?.find(
              g => (g.user as User).username === ALL_MEMBERS_GROUP_USERNAME,
            )?.id ?? MuidUtils.randomMuid();
          const templateRevision = FormEditorPageMachineHelpers.getTemplateRevision(context);

          if (!taskTemplate) {
            throw new Error('task template is missing');
          }

          const template = QueryActorSelectors.getQueryData(context.templateQuery);

          if (!template) {
            throw new Error('template is missing');
          }

          const widgetRequest = FormEditorPageMachineHelpers.makeWidgetRequest({
            event,
            taskTemplateId: taskTemplate.id,
            allWidgets,
            defaultGroupId,
            templateType: template.templateType,
          });

          const optimisticWidget = match(event.payload)
            .with({ fieldType: P.not(P.nullish) }, ({ fieldType }) => {
              const key = 'key' in widgetRequest ? widgetRequest.key : '';
              const label = 'label' in widgetRequest ? widgetRequest.label : '';
              const header = generateWidgetHeader(WidgetType.FormField, {
                id: widgetRequest.headerId,
                orderTree: widgetRequest.orderTree,
                taskTemplate,
              });
              const widget = {
                header,
                key,
                label,
                templateRevision,
              };

              return match(fieldType)
                .with(FieldType.Select, fieldType => {
                  return generateFormFieldWidget({
                    ...widget,
                    fieldType,
                    config: widgetRequest.config as SelectFormFieldConfig,
                  });
                })
                .with(FieldType.MultiSelect, fieldType => {
                  return generateFormFieldWidget({
                    ...widget,
                    fieldType,
                    config: widgetRequest.config as SelectFormFieldConfig,
                  });
                })
                .with(FieldType.MultiChoice, fieldType => {
                  return generateFormFieldWidget({
                    ...widget,
                    fieldType,
                    config: widgetRequest.config as SelectFormFieldConfig,
                  });
                })
                .with(FieldType.Url, fieldType => {
                  return generateFormFieldWidget({
                    ...widget,
                    fieldType,
                    config: widgetRequest.config as UrlFormFieldConfig,
                  });
                })
                .with(FieldType.Textarea, fieldType => {
                  return generateFormFieldWidget({
                    ...widget,
                    fieldType,
                    config: widgetRequest.config as TextareaFormFieldConfig,
                  });
                })
                .with(FieldType.SendRichEmail, fieldType => {
                  return generateFormFieldWidget({
                    ...widget,
                    fieldType,
                    config: widgetRequest.config as SendRichEmailFormFieldConfig,
                  });
                })
                .with(FieldType.Table, fieldType => {
                  return generateFormFieldWidget({
                    ...widget,
                    fieldType,
                    config: widgetRequest.config as TableFormFieldConfig.Config,
                  });
                })
                .with(FieldType.Members, fieldType => {
                  return generateFormFieldWidget({
                    ...widget,
                    fieldType,
                    config: widgetRequest.config as MembersFormFieldConfig,
                  });
                })
                .otherwise(fieldType => {
                  return generateFormFieldWidget({
                    ...widget,
                    fieldType,
                  });
                });
            })
            .with({ widgetType: P.not(P.nullish) }, ({ widgetType }) => {
              const header = {
                id: widgetRequest.headerId,
                orderTree: widgetRequest.orderTree,
                taskTemplate,
              };

              return match(widgetType)
                .with(WidgetType.CrossLink, widgetType =>
                  generateCrossLinkWidget({
                    header: generateWidgetHeader(widgetType, header),
                    templateId: undefined,
                  }),
                )
                .with(WidgetType.Email, widgetType =>
                  generateEmailWidget({
                    header: generateWidgetHeader(widgetType, header),
                    recipient: undefined,
                    cc: undefined,
                    subject: undefined,
                    body: undefined,
                  }),
                )
                .with(WidgetType.Embed, widgetType =>
                  generateEmbedWidget({
                    header: generateWidgetHeader(widgetType, header),
                    url: undefined,
                  }),
                )
                .with(WidgetType.File, widgetType =>
                  generateFileWidget({
                    header: generateWidgetHeader(widgetType, header),
                    file: undefined,
                    description: undefined,
                  }),
                )
                .with(WidgetType.Image, widgetType =>
                  generateImageWidget({
                    header: generateWidgetHeader(widgetType, header),
                    caption: undefined,
                    file: undefined,
                  }),
                )
                .with(WidgetType.Table, widgetType =>
                  generateEmptyTableWidget({
                    header: generateWidgetHeader(widgetType, header),
                  }),
                )
                .with(WidgetType.Text, widgetType =>
                  generateTextWidget({
                    header: generateWidgetHeader(widgetType, header),
                    content: undefined,
                  }),
                )
                .with(WidgetType.Video, widgetType =>
                  generateVideoWidget({
                    header: generateWidgetHeader(widgetType, header),
                    description: undefined,
                    file: undefined,
                    service: undefined,
                    serviceCode: undefined,
                  }),
                )
                .otherwise(() => undefined);
            })
            .otherwise(() => undefined);

          const widgetActor = optimisticWidget
            ? (spawnWidgetMachine(optimisticWidget, template, sharedContext, context.isReadOnly) as ActorRef<
                WidgetEvent<any>,
                unknown
              >)
            : null;

          const mutate = () => {
            const queryKey = WidgetsByTemplateRevisionIdQuery.getKey(
              FormEditorPageMachineHelpers.getTemplateRevision(context)?.id,
            );

            return makeMutation(queryClient, {
              mutationFn: () => CreateWidgetMutation.mutationFn(widgetRequest),
              mutationKey: CreateWidgetMutation.getKey(),
              onMutate: () => {
                const rollbackData = queryClient.getQueryData(queryKey);

                if (optimisticWidget) {
                  queryClient.setQueryData<Widget[]>(queryKey, current => {
                    if (!current) return [optimisticWidget];

                    if (!optimisticWidget.header.orderTree) return [...current, optimisticWidget];

                    return [...current, optimisticWidget].sort(widgetComparer);
                  });
                }

                return rollbackData;
              },
              onSuccess: newWidget => {
                const props = {
                  [Key.WIDGET_LEGACY_TYPE]: `${newWidget.header.type}Widget`, // Legacy property
                  [Key.WIDGET_ID]: newWidget.id,
                  [Key.WIDGET_TYPE]: newWidget.header.type,
                  // @ts-expect-error -- TODO
                  [Key.WIDGET_FIELD_TYPE]: newWidget.fieldType,
                  [Key.TASK_ID]: taskTemplate.id,
                  [Key.TASK_NAME]: taskTemplate.name,
                };
                AnalyticsService.trackEvent(AnalyticsConstants.Event.WIDGET_CREATED, props);
                queryClient.setQueryData<Widget[]>(queryKey, current => {
                  return (
                    current?.map(w => {
                      if (w.header.id !== widgetRequest.headerId) return w;
                      return newWidget;
                    }) ?? []
                  );
                });

                // Header ID is assigned by the backend during widget creation, so we need to update the header ID in the widget actor
                widgetActor?.send({ type: 'UPDATE_WIDGET_HEADER', header: newWidget.header });

                templateRevisionCacheSetter.updateDraftLastUpdatedDate();

                const shouldAutoFocus = match(event.payload)
                  .with({ widgetType: P.not(P.nullish) }, ({ widgetType }) =>
                    AUTO_FOCUS_CONTENT_WIDGET_TYPES_ON_CREATION.includes(widgetType),
                  )
                  .with({ fieldType: P.not(P.nullish) }, ({ fieldType }) =>
                    AUTO_FOCUS_FORM_FIELD_WIDGET_TYPES_ON_CREATION.includes(fieldType),
                  )
                  .otherwise(() => false);

                if (shouldAutoFocus) {
                  // @ts-expect-error -- TODO
                  widgetActor?.send({ type: 'FOCUS' });
                }
              },
              onError: (_error, _variables, context) => {
                queryClient.setQueryData(queryKey, context);
              },
            }).execute();
          };

          const actor = spawn(fromPromise(mutate), { name: 'createWidgetMutation' });
          if (widgetActor) {
            // Unfortunately I had to cast this type fo any because TS is complaining about a complex union type
            // Using ts-expect-error didn't helped.
            (widgetActor as any).send({ type: 'AUTO_FOCUS' });

            setTimeout(() => {
              widgetActor.send({ type: 'SCROLL_INTO_VIEW' });
            }, 50);
          }

          return {
            ...context,
            createWidgetMutationActorRefMap: {
              ...context.createWidgetMutationActorRefMap,
              [widgetRequest.headerId]: actor,
            },
            widgetActorMap: {
              ...context.widgetActorMap,
              ...(widgetActor ? { [widgetRequest.headerId]: widgetActor } : {}),
            },
          };
        }),

        createWidgetFromClipboard: assign((context, event) =>
          FormEditorPageMachineActions.createWidgetFromClipboard(context, event),
        ),

        focusNextWidget: (ctx, event) => {
          const widgets = QueryActorSelectors.getQueryData(ctx.widgetsByTemplateRevisionIdQuery)?.all ?? [];
          const widgetIndex = widgets.findIndex(widget => widget.header.id === event.widget.header.id);
          const nextWidget = widgets[widgetIndex + 1];

          if (!nextWidget) return;
          const nextWidgetActor = ctx.widgetActorMap[nextWidget.header.id] as ActorRef<WidgetEvent, any>;

          nextWidgetActor?.send({ type: 'AUTO_FOCUS', caretPlacement: 'start', caretOffset: event.caretOffset });
        },

        focusPreviousWidget: (ctx, event) => {
          const widgets = QueryActorSelectors.getQueryData(ctx.widgetsByTemplateRevisionIdQuery)?.all ?? [];
          const widgetIndex = widgets.findIndex(widget => widget.header.id === event.widget.header.id);
          const previousWidget = widgets[widgetIndex - 1];

          if (!previousWidget) return;

          const previousWidgetActor = ctx.widgetActorMap[previousWidget.header.id] as ActorRef<WidgetEvent, any>;

          previousWidgetActor?.send({ type: 'AUTO_FOCUS', caretPlacement: 'end', caretOffset: event.caretOffset });
        },
        focusFirstTextWidget: (ctx, _evt) => {
          const widgets = QueryActorSelectors.getQueryData(ctx.widgetsByTemplateRevisionIdQuery)?.all;
          const firstWidget = widgets?.[0];
          if (firstWidget && firstWidget.header.type === WidgetType.Text) {
            const firstWidgetActor = ctx.widgetActorMap[firstWidget.header.id] as ActorRef<WidgetEvent, any>;
            firstWidgetActor?.send({ type: 'AUTO_FOCUS', caretPlacement: 'end' });
          }
        },
      },
      services: {
        createDraftRevisionMutation: async context => {
          const draftRevision = QueryActorSelectors.getQueryData(context.templateRevisionsQuery)?.find(
            revision => revision.status === TemplateRevisionStatus.Draft,
          );

          if (draftRevision) return draftRevision;
          return makeMutation<TemplateRevision>(queryClient, {
            mutationKey: CreateTemplateRevisionMutation.key,
            mutationFn: () => CreateTemplateRevisionMutation.mutationFn({ templateId }),
            onSuccess: data => {
              const props = {
                [Key.TEMPLATE_ID]: templateId,
              };
              AnalyticsService.trackEvent(Event.TEMPLATE_REVISION_DRAFT_CREATED, props);
              queryClient.setQueryData<GetNewestTemplateRevisionsByTemplateIdQueryResponse>(
                GetNewestTemplateRevisionsByTemplateIdQuery.getKey({ templateId }),
                current => [current![0], data],
              );
            },
          }).execute();
        },

        createFirstWidgetMutation: async context => {
          const taskTemplates =
            QueryActorSelectors.getQueryData(context.taskTemplatesByTemplateRevisionIdQuery)?.all ?? [];
          const taskTemplateGroupId = sharedContext.$state.params.groupId;
          const taskTemplate = taskTemplates.find(tt => tt.group.id === taskTemplateGroupId) ?? taskTemplates[0];
          const allWidgets = QueryActorSelectors.getQueryData(context.widgetsByTemplateRevisionIdQuery)?.all ?? [];
          const defaultGroupId =
            QueryActorSelectors.getQueryData(context.groupsQuery)?.find(
              g => (g.user as User).username === ALL_MEMBERS_GROUP_USERNAME,
            )?.id ?? MuidUtils.randomMuid();
          const safeEvent: FormEditorPageMachineCreateWidgetEvent = {
            type: 'CREATE_WIDGET',
            payload: { fieldType: FieldType.Text, label: 'Your question here' },
          };

          if (!taskTemplate) {
            throw new Error('task template is missing');
          }

          const template = QueryActorSelectors.getQueryData(context.templateQuery);

          if (!template) {
            throw new Error('template is missing');
          }

          const widgetRequest = FormEditorPageMachineHelpers.makeWidgetRequest({
            event: safeEvent,
            taskTemplateId: taskTemplate.id,
            allWidgets,
            defaultGroupId,
            templateType: template.templateType,
          });

          return makeMutation(queryClient, {
            mutationFn: () => CreateWidgetMutation.mutationFn(widgetRequest),
            mutationKey: CreateWidgetMutation.getKey(),
            onSuccess: newWidget => {
              queryClient.setQueryData<Widget[]>(
                WidgetsByTemplateRevisionIdQuery.getKey(FormEditorPageMachineHelpers.getTemplateRevision(context)?.id),
                current => {
                  if (!current) return [newWidget];
                  return [...current, newWidget].sort(widgetComparer);
                },
              );
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
          }).execute();
        },

        bulkCreateTaskFromPasteMutation: async (context, event) => {
          const template = QueryActorSelectors.getQueryData(context.templateQuery);
          const taskList = event.templateNameList;
          const { $state } = sharedContext;

          const templateRevisionId = FormEditorPageMachineHelpers.getTemplateRevision(context)?.id!;
          const taskTemplatesLookup =
            QueryActorSelectors.getQueryData(context.taskTemplatesByTemplateRevisionIdQuery) ??
            FormEditorPageMachineHelpers.emptyTaskTemplatesLookupMap;

          const optimisticTaskTemplates = makeOptimisticTaskTemplates({
            taskType: TaskTemplateTaskType.Standard,
            afterOrderTree: event.currentTaskTemplate.orderTree,
            templateRevisionId,
            taskTemplates: taskTemplatesLookup.all,
            taskNames: taskList,
          });
          const limit = pLimit(2);
          const templateMutations = optimisticTaskTemplates.map((newTaskTemplate, index) => {
            return limit(() =>
              makeMutation(queryClient, {
                mutationFn: () => CreateTaskTemplateMutation.mutationFn(newTaskTemplate),
                mutationKey: CreateTaskTemplateMutation.key,
                onSuccess: () => {
                  void queryClient.invalidateQueries(
                    TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
                  );
                  templateRevisionCacheSetter.updateDraftLastUpdatedDate();
                  if (index === taskList.length - 1) {
                    const targetRoute =
                      template?.templateType === TemplateType.Playbook ? 'templateV2.task' : 'form.step';
                    void $state.go(targetRoute, { groupId: newTaskTemplate.group.id }, { inherit: true });
                  }
                },
                onError: () => {
                  ToastServiceImpl.openToast({
                    status: 'error',
                    title: "We're having problems creating that step",
                  });
                },
              }).execute(),
            );
          });

          return Promise.all(templateMutations);
        },

        updateTemplateMutation: async (context, event) => {
          const templateId = QueryActorSelectors.getQueryData(context.templateQuery)?.id;
          if (!templateId) {
            throw new Error('missing template');
          }

          return makeMutation(queryClient, {
            mutationFn: () => UpdateTemplateMutation.mutationFn(templateId, { name: event.formName }),
            mutationKey: UpdateTemplateMutation.getKey(templateId),
            onSuccess: updatedTemplate => {
              queryClient.setQueryData<Template>(GetTemplateQuery.getKey({ templateId }), () => updatedTemplate);
            },
          }).execute();
        },

        moveWidgetMutation: async (context, { widget, direction }) => {
          const allWidgets = QueryActorSelectors.getQueryData(context.widgetsByTemplateRevisionIdQuery)?.all ?? [];
          const widgetsByTaskTemplate = groupBy(allWidgets, w => w.header.taskTemplate.id);
          const taskTemplateWidgets = widgetsByTaskTemplate[widget.header.taskTemplate.id] ?? [];

          const widgetIndex = taskTemplateWidgets.findIndex(w => w.header.id === widget.header.id);
          const move = widgetOrderTreeUpdater[direction === 'down' ? 'moveDown' : 'moveUp'];

          const sortedWidgets = move(widgetIndex, taskTemplateWidgets);
          const affectedWidgets = [
            sortedWidgets[widgetIndex],
            sortedWidgets[direction === 'down' ? widgetIndex + 1 : widgetIndex - 1],
          ];
          const orderModels = affectedWidgets.map(w => ({
            widgetHeaderId: w.header.id,
            orderTree: w.header.orderTree!,
          }));

          queryClient.setQueryData<Widget[]>(
            WidgetsByTemplateRevisionIdQuery.getKey(FormEditorPageMachineHelpers.getTemplateRevision(context)?.id),
            () => [
              ...allWidgets.filter(w => w.header.taskTemplate.id !== widget.header.taskTemplate.id),
              ...sortedWidgets,
            ],
          );

          return makeMutation(queryClient, {
            mutationFn: () => UpdateWidgetOrderTreesMutation.mutationFn({ orderModels }),
            mutationKey: UpdateWidgetOrderTreesMutation.getKey(),
            onSuccess: () => {
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
              orderModels.forEach(({ widgetHeaderId, orderTree }) => {
                const widgetActor = context.widgetActorMap[widgetHeaderId];
                widgetActor?.send({
                  type: 'UPDATE_WIDGET_HEADER',
                  header: { orderTree },
                });
              });
            },
          }).execute();
        },
        duplicateWidgetMutation: async (context, { widget }) => {
          const widgets = QueryActorSelectors.getQueryData(context.widgetsByTemplateRevisionIdQuery)?.all ?? [];
          const taskWidgets = widgets.filter(w => w.header.taskTemplate.id === widget.header.taskTemplate.id);
          const orderTrees = taskWidgets.filter(w => Boolean(w.header.orderTree)).map(w => w.header.orderTree!);
          const [nextOrderTree] = orderTreeService.after(orderTrees, widget.header.orderTree!);
          const srcHeader = widget.header;

          const body = match<Widget, CopyWidgetOptions>(widget)
            .with({ header: { type: WidgetType.FormField } }, () => {
              const dstHeader = {
                id: MuidUtils.randomMuid(),
                taskTemplate: widget.header.taskTemplate,
                type: widget.header.type,
                orderTree: nextOrderTree,
              } as WidgetHeader;
              const keys = FormFieldHelpers.toKeys(widgets);
              const key = FormFieldHelpers.generateKeyForCopy(keys, (widget as FormFieldWidget).key);
              const labels = widgets.map(w => (w as FormFieldWidget).label ?? '');
              const label = FormFieldHelpers.generateNameLabelForCopy(labels, (widget as FormFieldWidget).label ?? '');
              return {
                srcHeader,
                dstHeader,
                key,
                label,
              };
            })
            .otherwise(() => {
              return {
                srcHeader,
                dstHeader: {
                  id: MuidUtils.randomMuid(),
                  taskTemplate: widget.header.taskTemplate,
                  type: widget.header.type,
                  orderTree: nextOrderTree,
                },
              };
            });

          return makeMutation(queryClient, {
            mutationFn: () => CopyWidgetByHeaderIdMutation.mutationFn(body),
            mutationKey: CopyWidgetByHeaderIdMutation.getKey(),
            onSuccess: () => {
              void queryClient.refetchQueries(
                WidgetsByTemplateRevisionIdQuery.getKey(FormEditorPageMachineHelpers.getTemplateRevision(context)?.id),
              );
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
          }).execute();
        },

        reorderWidgetsMutation: async (context, _evt) => {
          const { widgetsAtDragStart } = context;
          if (!widgetsAtDragStart) return Promise.resolve();

          const reorderedWidgets =
            QueryActorSelectors.getQueryData(context.widgetsByTemplateRevisionIdQuery)?.all ?? [];

          const summary = orderTreeService.summarizeReorderedArray({
            old: widgetsAtDragStart,
            new: reorderedWidgets,
            getId: w => w?.id,
          });

          if (!summary) return Promise.resolve();
          const { originalIndex, newIndex, displacedItem: displacedWidget, movedItem: originalWidget } = summary;

          const orderTrees = widgetsAtDragStart.filter(w => Boolean(w.header.orderTree)).map(w => w.header.orderTree!);
          const newOrderTrees = orderTreeService[newIndex < originalIndex ? 'before' : 'after'](
            orderTrees,
            displacedWidget.header.orderTree!,
          );
          const [newOrderTree] = newOrderTrees;

          return makeMutation(queryClient, {
            mutationFn: () =>
              UpdateWidgetOrderTreesMutation.mutationFn({
                orderModels: [{ orderTree: newOrderTree, widgetHeaderId: originalWidget.header.id }],
              }),
            mutationKey: UpdateWidgetOrderTreesMutation.getKey(),
            onSuccess: () => {
              void queryClient.refetchQueries(
                WidgetsByTemplateRevisionIdQuery.getKey(FormEditorPageMachineHelpers.getTemplateRevision(context)?.id),
              );
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();

              const widgetActor = context.widgetActorMap[originalWidget.header.id];
              widgetActor?.send({
                type: 'UPDATE_WIDGET_HEADER',
                header: { orderTree: newOrderTree },
              });
            },
            onError: () => {
              queryClient.setQueryData(
                WidgetsByTemplateRevisionIdQuery.getKey(FormEditorPageMachineHelpers.getTemplateRevision(context)?.id),
                () => widgetsAtDragStart,
              );
            },
          }).execute();
        },

        reorderTaskTemplatesMutation: async (context, _evt) => {
          const { taskTemplatesAtDragStart } = context;

          if (!taskTemplatesAtDragStart) return Promise.resolve();

          const reorderedTaskTemplates =
            QueryActorSelectors.getQueryData(context.taskTemplatesByTemplateRevisionIdQuery)?.all ?? [];

          const summary = orderTreeService.summarizeReorderedArray({
            old: taskTemplatesAtDragStart,
            new: reorderedTaskTemplates,
            getId: tt => tt?.id,
          });

          if (!summary) return Promise.resolve();

          const {
            originalIndex,
            newIndex,
            displacedItem: displacedTaskTemplate,
            movedItem: originalTaskTemplate,
          } = summary;

          const orderTrees = taskTemplatesAtDragStart
            .filter(taskTemplate => Boolean(taskTemplate.orderTree))
            .map(taskTemplate => taskTemplate.orderTree!);
          const newOrderTree = orderTreeService[newIndex < originalIndex ? 'before' : 'after'](
            orderTrees,
            displacedTaskTemplate.orderTree!,
          );

          return makeMutation(queryClient, {
            variables: {
              orderModels: [
                {
                  taskTemplateId: originalTaskTemplate.id,
                  taskTemplateGroupId: originalTaskTemplate.group.id,
                  orderTree: newOrderTree[0],
                },
              ],
            },
            mutationFn: UpdateTaskTemplateOrderTreesMutation.mutationFn,
            mutationKey: UpdateTaskTemplateOrderTreesMutation.key,
            onSuccess: () => {
              queryClient.invalidateQueries(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({
                  templateRevisionId: originalTaskTemplate.templateRevision.id,
                }),
              );
              queryClient.invalidateQueries(
                GetAllNativeAutomationsQuery.getKey({
                  templateRevisionId: originalTaskTemplate.templateRevision.id,
                }),
              );
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
            onError: () => {
              queryClient.setQueryData(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({
                  templateRevisionId: originalTaskTemplate.templateRevision.id,
                }),
                () => taskTemplatesAtDragStart,
              );
            },
          }).execute();
        },

        moveTaskTemplateMutation: async (context, evt) => {
          const { direction, taskTemplate, toIndex } = evt;

          const taskTemplatesLookup =
            QueryActorSelectors.getQueryData(context.taskTemplatesByTemplateRevisionIdQuery) ??
            FormEditorPageMachineHelpers.emptyTaskTemplatesLookupMap;
          const orderTrees = toOrderTrees(taskTemplatesLookup.all);
          const referenceTree = taskTemplatesLookup.all[toIndex].orderTree;

          const [newOrderTree] = orderTreeService[direction](orderTrees, referenceTree);

          return makeMutation(queryClient, {
            variables: {
              orderModels: [
                {
                  orderTree: newOrderTree,
                  taskTemplateId: taskTemplate.id,
                  taskTemplateGroupId: taskTemplate.group.id,
                },
              ],
            },
            mutationFn: UpdateTaskTemplateOrderTreesMutation.mutationFn,
            mutationKey: UpdateTaskTemplateOrderTreesMutation.key,
            onSuccess: () => {
              queryClient.refetchQueries(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId: taskTemplate.templateRevision.id }),
              );
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
            onError: () => {
              queryClient.setQueryData(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({
                  templateRevisionId: taskTemplate.templateRevision.id,
                }),
                () => taskTemplatesLookup.all,
              );
            },
          }).execute();
        },

        duplicateTaskTemplateMutation: (context, evt) => {
          const { taskTemplateId } = evt;
          const template = QueryActorSelectors.getQueryData(context.templateQuery);
          const taskTemplatesLookup =
            QueryActorSelectors.getQueryData(context.taskTemplatesByTemplateRevisionIdQuery) ??
            FormEditorPageMachineHelpers.emptyTaskTemplatesLookupMap;
          const index = taskTemplatesLookup.all.findIndex(tt => tt.id === taskTemplateId);

          const placeholderTaskTemplate = createDuplicateTaskTemplatePlaceholder({
            sourceTaskTemplate: taskTemplatesLookup.all[index],
            taskTemplates: taskTemplatesLookup.all,
            index,
          });

          const { $state } = sharedContext;
          return makeMutation(queryClient, {
            variables: {
              taskTemplateId,
              groupId: placeholderTaskTemplate.group.id,
              duplicateId: placeholderTaskTemplate.id,
              orderTree: placeholderTaskTemplate.orderTree,
            },
            mutationFn: DuplicateTaskTemplateMutation.mutationFn,
            mutationKey: DuplicateTaskTemplateMutation.key,
            onSuccess: async newTaskTemplate => {
              await queryClient.invalidateQueries(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({
                  templateRevisionId: FormEditorPageMachineHelpers.getTemplateRevision(context)?.id!,
                }),
              );
              await queryClient.invalidateQueries(
                WidgetsByTemplateRevisionIdQuery.getKey(FormEditorPageMachineHelpers.getTemplateRevision(context)?.id),
              );
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
              if (template?.templateType === TemplateType.Form) {
                $state.go('form.step', { groupId: newTaskTemplate.group.id }, { inherit: true });
              } else if (template?.templateType === TemplateType.Playbook) {
                context.taskTemplateListActorRef.send({
                  type: 'SELECT_TASK_TEMPLATE',
                  taskTemplate: newTaskTemplate,
                  metaKey: false,
                  ctrlKey: false,
                  shiftKey: false,
                });
              }
            },
            onError: (_err, _vars) => {
              ToastServiceImpl.openToast({
                status: 'error',
                title: "We're having problems duplicating that step",
              });
            },
          }).execute();
        },

        moveWidgetToStepMutation: (ctx, evt) => {
          const { widget, from, to } = evt;

          const widgetsLookup =
            QueryActorSelectors.getQueryData(ctx.widgetsByTemplateRevisionIdQuery) ??
            FormEditorPageMachineHelpers.emptyWidgetsLookup;
          const targetTaskWidgets = widgetsLookup.all.filter(w => w.header.taskTemplate.id === to.id);

          const newOrderTree = match(targetTaskWidgets.length)
            .with(0, () => '1')
            .otherwise(() => {
              const targetTaskOrderTrees = targetTaskWidgets
                .filter(w => Boolean(w.header.orderTree))
                .map(w => w.header.orderTree!);
              const [newOrderTree] = orderTreeService.before(
                targetTaskOrderTrees,
                targetTaskWidgets[0].header.orderTree!,
              );
              return newOrderTree;
            });

          return makeMutation(queryClient, {
            variables: {
              headerId: widget.header.id,
              templateRevisionId: to.templateRevision.id,
              targetTaskTemplateId: to.id,
              targetWidgetOrderTree: newOrderTree,
            },
            mutationFn: MoveWidgetMutation.mutationFn,
            mutationKey: MoveWidgetMutation.key,
            onSuccess: header => {
              queryClient.invalidateQueries(
                WidgetsByTemplateRevisionIdQuery.getKey(FormEditorPageMachineHelpers.getTemplateRevision(ctx)?.id),
              );
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();

              // Update widget header in widget actor context to reflect new task template
              const widgetActor = ctx.widgetActorMap?.[widget.header.id];
              const headerLike = { ...(header as WidgetHeaderLike) };
              // taskTemplate returned from widget move mutation is an idRef, enrich it
              const taskTemplatesLookup =
                QueryActorSelectors.getQueryData(ctx.taskTemplatesByTemplateRevisionIdQuery) ??
                FormEditorPageMachineHelpers.emptyTaskTemplatesLookupMap;
              const enrichedHeader = {
                ...headerLike,
                taskTemplate: {
                  ...headerLike.taskTemplate,
                  ...taskTemplatesLookup.byId[headerLike.taskTemplate.id],
                },
              };
              widgetActor?.send({ type: 'UPDATE_WIDGET_HEADER', header: enrichedHeader });
              widgetActor?.send({ type: 'MOVED_FROM_STEP', from });

              const template = QueryActorSelectors.getQueryData(ctx.templateQuery);

              const href =
                template?.templateType === TemplateType.Playbook
                  ? LocationService.editTaskTemplateHref(template?.id ?? '', to.group.id)
                  : LocationService.editStepHref(template?.id ?? '', to.group.id, template?.name ?? '');

              ToastServiceImpl.openToast({
                status: 'success',
                title: `Widget successfully moved to <a href='${href}' >${
                  to.name ?? TaskConstants.DefaultStepName
                }</a>`,
              });
            },
          }).execute();
        },

        updateMergeTagsMutation: async (ctx, evt) => {
          const templateRevision = FormEditorPageMachineHelpers.getTemplateRevision(ctx);

          const { widget, oldKey } = match(evt)
            .with({ type: 'UPDATE_MERGE_TAGS_REFERENCES' }, ({ widget, oldKey }) => ({ widget, oldKey }))
            .otherwise(() => ({ widget: undefined, oldKey: undefined }));

          if (
            !templateRevision ||
            !widget ||
            !oldKey ||
            !isFormFieldWidget(widget) ||
            (widget && isFormFieldWidget(widget) && widget.key === oldKey)
          ) {
            return Promise.resolve();
          } else {
            const widgets =
              queryClient.getQueryData<WidgetsByTemplateRevisionIdQueryResponse>(
                WidgetsByTemplateRevisionIdQuery.getKey(templateRevision.id),
              ) ?? [];

            await MergeTagReferenceUpdateService.updateReferences({
              widgets,
              oldKey,
              newKey: widget.key,
              templateRevision,
              queryClient,
              onWidgetUpdated: w => {
                const widgetActor = ctx.widgetActorMap?.[w.header.id];
                widgetActor?.send({ type: 'UPDATE_WIDGET', widget: w });
              },
            });
          }
        },
      },
      guards: {
        draftDoesNotExist: (ctx, evt) => {
          if (ctx.isReadOnly) return false;

          return (
            QueryActorSelectors.isUpdateEventSuccess(evt, 'template-revisions-query') &&
            evt.state.context.data?.length === 1
          );
        },

        draftTemplateRevisionIdReady: (ctx, evt) => {
          if (ctx.isReadOnly) {
            return (
              QueryActorSelectors.isUpdateEventSuccess(evt, 'template-revisions-query') &&
              Boolean(evt.state.context.data?.[0]?.id)
            );
          }

          return (
            QueryActorSelectors.isUpdateEventSuccess(evt, 'template-revisions-query') &&
            Boolean(evt.state.context.data?.[1]?.id)
          );
        },

        // Ideally we would have 2 guards allDataReady and isGenerating and combine them, but that's in another
        // version of xstate. https://stately.ai/docs/guards#higher-level-guards. Same for isWidgetSuccess and isGenerating.
        allDataReadyAndNotGenerating: ctx => {
          const isDataReady = [
            ctx.templateQuery,
            ctx.templateRevisionsQuery,
            ctx.taskTemplatesByTemplateRevisionIdQuery,
            ctx.widgetsByTemplateRevisionIdQuery,
            ctx.groupsQuery,
          ].every(query => QueryActorSelectors.getQueryData(query) !== undefined);

          const isGenerating =
            ctx.aiWorkflowGenerationActorRef &&
            AiWorkflowGenerationActorSelector.isGenerating(ctx.aiWorkflowGenerationActorRef.state);

          return isDataReady && !isGenerating;
        },

        isWidgetSuccessAndNotGenerating: (ctx, evt) => {
          const isWidgetSuccess = QueryActorSelectors.isUpdateEventSuccess(evt, 'widgets-query');
          const isGenerating =
            ctx.aiWorkflowGenerationActorRef &&
            AiWorkflowGenerationActorSelector.isGenerating(ctx.aiWorkflowGenerationActorRef.state);
          return isWidgetSuccess && !isGenerating;
        },

        isBrandNewForm: (ctx, _evt) => {
          const template = QueryActorSelectors.getQueryData(ctx.templateQuery);
          const createdTime = template?.audit.createdDate ?? 0;
          const now = Date.now();
          const templateRevision = FormEditorPageMachineHelpers.getTemplateRevision(ctx);
          const isFirstDraft = (templateRevision?.revision ?? 0) === 1;
          const wasCreatedRecently = now - createdTime < TemplateConstants.RECENTLY_CREATED_THRESHHOLD;
          const isBrandNewForm = wasCreatedRecently && isFirstDraft;
          const isDefaultName = template?.name === TemplateConstants.BLANK_FORM_NAME;
          return isDefaultName && isBrandNewForm;
        },
        isTaskTemplatesUpdate: (_ctx, evt) => {
          return QueryActorSelectors.isUpdateEventSuccess(evt, 'task-templates-query');
        },
        isTemplateUpdate: (_ctx, evt) => {
          return QueryActorSelectors.isUpdateEventSuccess(evt, 'template-query');
        },
      },
    },
  );
};

const widgetComparer = getOrderableComparer(<W extends { header: { orderTree?: string } }>(widget: W) => widget.header);

export type FormEditorPageMachine = ReturnType<typeof makeFormEditorPageMachine>;

import * as React from 'react';
import { Icon, Flex, Tooltip, Text } from 'components/design/next';

export type TaskListItemWidgetErrorCounterIndicatorProps = {
  invalidWidgetCount: number;
  hasErrors: boolean;
  isSelected: boolean;
};

export const TaskListItemWidgetErrorCounter = ({
  invalidWidgetCount,
  hasErrors,
  isSelected,
}: TaskListItemWidgetErrorCounterIndicatorProps) => {
  if (!hasErrors || invalidWidgetCount === 0) return null;
  const label = `${invalidWidgetCount} form field${invalidWidgetCount > 1 ? 's' : ''} need to be completed`;

  return (
    <Tooltip label={label}>
      <Flex h="8" gap="1" alignItems="center">
        <Icon
          aria-label={label}
          icon="exclamation-circle"
          color="red.500"
          size="4"
          variant="far"
          backgroundColor="white"
          borderRadius="full"
        />
        <Text as="span" fontSize="sm" color={isSelected ? 'white' : 'red.500'} minW={3.5} textAlign="center">
          {invalidWidgetCount}
        </Text>
      </Flex>
    </Tooltip>
  );
};

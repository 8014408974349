import { AuditMetadata, Muid, MuidUtils, OrganizationMembershipWithUser } from '../core';
import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import { TemplateType } from '@process-street/subgrade/process/template-model';

export enum DataSetColumnType {
  Loading = 'Loading', // Synthetic type used to indicate a column is being created.
  Text = 'Text',
  Number = 'Number',
  DateTime = 'DateTime',
}

export interface DataSetColumnDef {
  id: Muid;
  name: string;
  columnType: DataSetColumnType;
}

export enum DataSetTextFilterCondition {
  Equals = 'Equals',
  NotEqual = 'NotEqual',
  Contains = 'Contains',
  NotContains = 'NotContains',
  IsEmpty = 'IsEmpty',
  IsNotEmpty = 'IsNotEmpty',
}

export type DataSetTextFilterParams = {
  condition: DataSetTextFilterCondition;
  value?: string;
};

export type DataSetFilterParams = DataSetTextFilterParams;

export enum DataSetFilterType {
  Text = 'Text',
}

export interface DataSetColumnFilter {
  filterType: DataSetFilterType;
  filterParams: DataSetFilterParams;
}

export interface DataSetColumnFilterForm extends Partial<Omit<DataSetColumnFilter, 'filterParams'>> {
  /** Unique ID for listing filter */
  id: Muid;
  columnId?: Muid;
  filterParams?: Partial<DataSetFilterParams>;
}

export interface DataSetColumnState {
  id: Muid;
  hide?: boolean;
  width?: number;
  filters: DataSetColumnFilter[];
  /** null is used to reset the state  */
  sort?: 'asc' | 'desc' | null;
  /** null is used to reset the state  */
  sortIndex?: number | null;
  pinned?: boolean;
}

export interface DataSetCellValue {
  value: string | number | null;
}

export interface DataSetRow {
  id: Muid;
  cells: Record<string, DataSetCellValue>;
  dataSetId: Muid;
  organizationId: Muid;
  audit: AuditMetadata;
}

export enum SavedViewType {
  Standard = 'Standard',
  AllData = 'AllData',
}

export enum SavedViewFilterOperator {
  And = 'And',
  Or = 'Or',
}

export interface SavedViewConfig {
  filterOperator?: SavedViewFilterOperator;
}

export interface SavedView {
  id: Muid;
  name: string;
  canRead: boolean;
  canUpdate: boolean;
  columns: DataSetColumnState[];
  savedViewType: SavedViewType;
  config?: SavedViewConfig;
}

export interface DataSet {
  id: Muid;
  name: string;
  audit: AuditMetadata;
  dataSetRead: boolean;
  dataSetUpdate: boolean;
  canAccessDataSet: boolean;
  savedViews: SavedView[];
  columnDefs: DataSetColumnDef[];
}

export interface DataSetLinkedWidgetData {
  linkId: Muid;
  dataSetId: Muid;
  dataSetName: string;
  savedViewId: Muid;
  savedViewName: string;
  columnId: Muid;
  columnName: Muid;
  columnData: string[];
}

export interface DataSetMergeTag {
  key: string;
  label: string;
}

export interface DataSetMergeTagWithValue extends DataSetMergeTag {
  value: string;
}

export interface DataSetLinkWithNames {
  id: Muid;
  audit: AuditMetadata;
  organizationId: Muid;
  templateId: Muid;
  templateName: string;
  templateType: TemplateType;
  formFieldWidgetId: Muid;
  formFieldWidgetLabel: string;
  dataSetId: Muid;
  dataSetName: string;
  savedViewId: Muid;
  savedViewName: string;
  columnId: Muid;
  columnName: string;
}

export const DataSetColumnFilterModel = {
  isValid: (filterForm: DataSetColumnFilterForm | DataSetColumnFilter): filterForm is DataSetColumnFilter => {
    const needsOperand = conditionRequiresOperand(filterForm.filterParams?.condition);
    const hasValue = filterForm.filterParams?.value !== undefined;
    return Boolean((!needsOperand || hasValue) && filterForm.filterParams?.condition && filterForm.filterType);
  },
};

export const DataSetColumnStateModel = {
  fromColumnDef: (columnDef: DataSetColumnDef): DataSetColumnState => ({
    id: columnDef.id,
    filters: [],
    hide: false, // explicitly specifying, because we're falling back to `true` in data-set-table.tsx
  }),

  getFilters: (columns: DataSetColumnState[]): DataSetColumnFilterForm[] =>
    flatMap(
      columns.map(column =>
        column.filters.map(filter => ({
          ...filter,
          id: MuidUtils.randomMuid(),
          columnId: column.id,
        })),
      ),
    ),

  combineColumnsWithFilters: (
    columns: DataSetColumnState[],
    filters: DataSetColumnFilterForm[],
  ): DataSetColumnState[] => {
    const filtersGroupedByColumn = groupBy(filters, 'columnId');

    return columns.map(column => {
      const filtersForColumn = filtersGroupedByColumn[column.id] ?? [];

      return {
        ...column,
        filters: filtersForColumn
          .map(f => {
            // Make sure that only valid filters will be applied
            if (DataSetColumnFilterModel.isValid(f))
              return {
                filterType: f.filterType,
                filterParams: {
                  condition: f.filterParams.condition,
                  value: f.filterParams.value,
                },
              };

            return null;
          })
          // Remove the invalid values
          .filter(Boolean) as DataSetColumnFilter[],
      };
    });
  },
};

export enum AddColumnDirection {
  Left = 'left',
  Right = 'right',
}

export interface DataSetPermit {
  id: Muid;
  audit: AuditMetadata;
  organizationMembershipId: Muid;
  dataSetId: Muid;
  dataSetRead: boolean;
  dataSetUpdate: boolean;
}

export interface DataSetPermitWithOm {
  id: Muid;
  audit: AuditMetadata;
  organizationMembership: OrganizationMembershipWithUser;
  dataSetId: Muid;
  dataSetRead: boolean;
  dataSetUpdate: boolean;
}

export function isDataSetPermitWithOm(obj: any): obj is DataSetPermitWithOm {
  return (
    'id' in obj &&
    'audit' in obj &&
    'organizationMembership' in obj &&
    'dataSetId' in obj &&
    'dataSetRead' in obj &&
    'dataSetUpdate' in obj
  );
}

export interface SavedViewPermit {
  id: Muid;
  audit: AuditMetadata;
  organizationMembershipId: Muid;
  savedViewId: Muid;
  savedViewRead: boolean;
  savedViewUpdate: boolean;
}

export interface SavedViewPermitWithOm {
  id: Muid;
  audit: AuditMetadata;
  organizationMembership: OrganizationMembershipWithUser;
  savedViewId: Muid;
  savedViewRead: boolean;
  savedViewUpdate: boolean;
}

export function isSavedViewPermitWithOm(obj: any): obj is SavedViewPermitWithOm {
  return (
    'id' in obj &&
    'audit' in obj &&
    'organizationMembership' in obj &&
    'savedViewId' in obj &&
    'savedViewRead' in obj &&
    'savedViewUpdate' in obj
  );
}

export function getAllDataSavedView(dataSet: DataSet): SavedView | undefined {
  return (dataSet?.savedViews ?? []).find(savedView => savedView.savedViewType === SavedViewType.AllData);
}

export function conditionRequiresOperand(condition?: DataSetTextFilterCondition) {
  return !(condition === DataSetTextFilterCondition.IsEmpty || condition === DataSetTextFilterCondition.IsNotEmpty);
}

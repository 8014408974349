import { queryString } from '@process-street/subgrade/util';
import * as React from 'react';

import { useInjector } from 'app/components/injection-provider';
import { useNavigate } from '@process-street/adapters/navigation';

export type PageEditorWrapperProps = {
  editable: boolean;
};

export const PageEditorWrapper: React.FC<React.PropsWithChildren<PageEditorWrapperProps>> = ({ editable }) => {
  const { $state } = useInjector('$state');
  const navigate = useNavigate();

  const {
    params: { id: pageId },
  } = $state;

  navigate({
    pathname: editable ? 'pageV2' : 'pageViewV2',
    search: queryString.stringify({ id: pageId }),
  });

  return <></>;
};

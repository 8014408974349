import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { Approval, ApprovalStatus } from '@process-street/subgrade/approval-rule';
import { DueDateState } from '@process-street/subgrade/process';

export namespace CreateAllApprovalsMutation {
  export type ApprovalDto = {
    readonly id: Muid;
    organizationId: Muid;
    approvalTaskId: Muid;
    subjectTaskId: Muid;
    status: ApprovalStatus;
    comment?: string;
  };

  export type Params = {
    checklistRevisionId: Muid;
    approvals: ApprovalDto[];
  };

  export type Response = {
    approvals: Approval[];
    dueDateStates: DueDateState[];
  };

  export const key = ['put', 'approvals'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/checklist-revision/${params.checklistRevisionId}/approvals_v2`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}

import * as React from 'react';

interface UseCalculateDropzoneHeightProps {
  widgetDropRef: React.RefObject<HTMLElement>;
  isDragging: boolean;
  isOver: boolean;
}

const INITIAL_DROPZONE_HEIGHT = 80;
const MIN_DROPZONE_HEIGHT = INITIAL_DROPZONE_HEIGHT;

export const useCalculateDropzoneHeight = ({ widgetDropRef, isDragging, isOver }: UseCalculateDropzoneHeightProps) => {
  const [dropzoneHeight, setDropzoneHeight] = React.useState(INITIAL_DROPZONE_HEIGHT);

  React.useLayoutEffect(
    function calculateDropzoneHeight() {
      // This is a hack to get the dropzone to expand to the bottom of the page
      // when dragging a widget over it. This is necessary because the dropzone
      // is absolutely positioned and the height of the dropzone is not known
      // until the widget is dropped.
      setTimeout(() => {
        const boundingClientRect = widgetDropRef.current?.getBoundingClientRect();

        if (!boundingClientRect) return;

        const containerBottom = boundingClientRect.bottom;
        const pageBottom = window.innerHeight;

        const newHeight = boundingClientRect.height + pageBottom - containerBottom;

        setDropzoneHeight(newHeight);
      }, 0);
    },
    [widgetDropRef, isDragging, isOver],
  );

  return Math.max(MIN_DROPZONE_HEIGHT, dropzoneHeight);
};

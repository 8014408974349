import { TemplateType } from '@process-street/subgrade/process';
import { Flex, HStack, Icon, IconButton, LinkBox, LinkBoxProps, LinkOverlay, Text } from 'components/design/next';
import * as React from 'react';
import { Highlight } from './highlight';
import { useListboxContext } from 'components/listbox';
import { useKey } from 'react-use';
import { useInjector } from 'components/injection-provider';
import { useDoClickIfSelected } from './use-do-click-if-selected';
import { SearchCoverIcon } from 'features/cover-icon/components/search';
import { NgLink, NgLinkProps } from 'features/app/components/ng-link';
import { match } from 'ts-pattern';
import { useStateParam } from 'hooks/use-state-param';
import { AppModalName, AppModalQueryParam, GlobalSearchVariant } from 'app/app.constants';
import { abbreviateForTitle } from '@process-street/subgrade/util';
import { GlobalSearchHits } from 'features/global-search/components/model';

export const runPressed = (e: KeyboardEvent | React.KeyboardEvent): boolean => {
  return e.shiftKey && e.key.toLowerCase() === 'r';
};

export type TemplateHitProps = { hit: GlobalSearchHits.TemplateHit } & LinkBoxProps;

export const TemplateHit: React.FC<React.PropsWithChildren<TemplateHitProps>> = ({ hit, ...props }) => {
  const modalVariant = useStateParam<GlobalSearchVariant>({
    key: AppModalQueryParam.ModalVariant,
    defaultValue: GlobalSearchVariant.Search,
  });
  const { $state } = useInjector('$state');
  const { selectedId } = useListboxContext();
  const selected = selectedId === hit.objectID;

  const ref = React.useRef<HTMLAnchorElement>(null);
  useDoClickIfSelected({ id: hit.objectID, node: ref.current });

  const matchingLevels: (string | undefined)[] = ['full', 'partial'];
  const showTasksSnippet = matchingLevels.includes(hit?._snippetResult?.tasks?.matchLevel);
  const showContentSnippet = matchingLevels.includes(hit?._snippetResult?.content?.matchLevel);

  const iconLabel = match(hit.templateType)
    .with(TemplateType.Playbook, () => 'workflow')
    .with(TemplateType.Form, () => 'form')
    .with(TemplateType.Page, () => 'page');

  const urlOptions = match<{ templateType: TemplateType; modalVariant: GlobalSearchVariant | undefined }, NgLinkProps>({
    templateType: hit.templateType as TemplateType,
    modalVariant,
  })
    .with({ modalVariant: GlobalSearchVariant.Run }, () => ({
      to: $state.current,
      params: {
        ...$state.params,
        [AppModalQueryParam.Modal]: AppModalName.RunChecklist,
        [AppModalQueryParam.ModalTemplateId]: hit.objectID,
      },
      options: { inherit: false },
    }))
    .with({ templateType: TemplateType.Playbook }, () => ({
      to: 'templateDashboard',
      params: { id: hit.objectID, title: `${abbreviateForTitle(hit.name)}-` },
      options: { inherit: false },
    }))
    .with({ templateType: TemplateType.Form }, () => ({
      to: 'form',
      params: { id: hit.objectID, title: `${abbreviateForTitle(hit.name)}-` },
      options: { inherit: false },
    }))
    .otherwise(() => {
      return {
        to: 'pageViewV2',
        params: { id: hit.objectID, title: `${abbreviateForTitle(hit.name)}-` },
        options: { inherit: false },
      };
    });

  const runUrlOptions = {
    to: $state.current,
    params: {
      ...$state.params,
      [AppModalQueryParam.Modal]: AppModalName.RunChecklist,
      [AppModalQueryParam.ModalTemplateId]: hit.objectID,
    },
    options: { inherit: false },
  };

  const respondUrlOptions = {
    to: 'addFormResponse',
    params: { id: hit.objectID },
  };

  useKey(
    runPressed,
    event => {
      if (hit.templateType === TemplateType.Playbook && selectedId === hit.objectID) {
        event.preventDefault();
        $state.go(runUrlOptions.to, runUrlOptions.params, runUrlOptions.options);
      }
    },
    {},
    [hit, selectedId],
  );

  const workflowVerb = modalVariant === GlobalSearchVariant.Run ? 'run' : 'open';

  return (
    <LinkBox
      minHeight="14"
      maxHeight="23"
      display="flex"
      flexDir="column"
      justifyContent="center"
      py="2"
      px="4"
      backgroundColor={selected ? 'gray.100' : 'inherit'}
      // this is a somewhat redundant style because if rendered in a listboxoption, hover will result in `selected` any way
      // this is mostly to allow for an isolated story that doesn't depend on listbox option
      _hover={{ textDecoration: 'none', color: 'inherit', backgroundColor: 'gray.100' }}
      {...props}
    >
      <Flex alignItems="center">
        <SearchCoverIcon
          emoji={hit.coverIconEmoji}
          templateType={hit.templateType as TemplateType}
          aria-label={iconLabel}
        />

        <LinkOverlay as={NgLink} {...urlOptions} overflowX="hidden" ref={ref}>
          <Flex direction="column">
            <Text noOfLines={1} fontSize="sm" color="gray.600" title="title">
              <Highlight hit={hit} attribute="name" />
            </Text>

            {hit.description ? (
              <Text noOfLines={1} fontSize="xs" color="gray.500" title="description">
                <Highlight hit={hit} attribute="description" />
              </Text>
            ) : null}

            {showTasksSnippet ? (
              <Text noOfLines={1} fontSize="xs" color="gray.500" fontStyle="italic">
                <>
                  <Text as="span" variant="inherit" color="gray.600">
                    Tasks:
                  </Text>{' '}
                  <Highlight hit={hit} attribute="tasks" highlightProperty="_snippetResult" />
                </>
              </Text>
            ) : null}

            {showContentSnippet && (
              <Text noOfLines={1} fontSize="xs" color="gray.500" fontStyle="italic">
                <Highlight hit={hit} attribute="content" highlightProperty="_snippetResult" />
              </Text>
            )}
          </Flex>
        </LinkOverlay>

        {selected ? (
          <HStack ml="auto" spacing="4">
            <LinkOverlay as={NgLink} {...urlOptions}>
              <Icon
                icon="level-down"
                variant="far"
                transform="rotate(90deg)"
                color="gray.400"
                aria-label={`${workflowVerb} by pressing the enter key`}
                title={`Press the enter key to ${workflowVerb}.`}
                size="4"
              />
            </LinkOverlay>

            {hit.templateType === TemplateType.Playbook && (
              <HStack>
                <IconButton
                  as={NgLink}
                  {...runUrlOptions}
                  variant="tertiary"
                  size="sm"
                  aria-label="run by pressing shift + r"
                  title="Shift + R to Run"
                  padding="0"
                  icon={<Icon icon="play" variant="far" color="gray.600" size="3" />}
                />

                <IconButton
                  as={NgLink}
                  to="templateView"
                  params={{ id: hit.objectID, title: `${abbreviateForTitle(hit.name)}-` }}
                  options={{ inherit: false }}
                  variant="tertiary"
                  size="sm"
                  aria-label="preview workflow"
                  padding="0"
                  icon={<Icon icon="eye" variant="far" color="gray.600" size="3" />}
                />
              </HStack>
            )}

            {hit.templateType === TemplateType.Form && (
              <IconButton
                as={NgLink}
                {...respondUrlOptions}
                variant="tertiary"
                size="sm"
                aria-label={`respond form ${hit.name}`}
                isExternal
                padding="0"
                icon={<Icon icon="play" variant="far" color="gray.600" size="3" />}
              />
            )}
          </HStack>
        ) : null}
      </Flex>
    </LinkBox>
  );
};

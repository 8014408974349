import { ApprovalStatus } from '@process-street/subgrade/approval-rule';
import { TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { Text, VStack } from 'components/design/next';
import * as React from 'react';
import { ApprovedTaskItem } from './approved-task-item';
import { NonEmptyArray } from 'app/utils/array-utils';

export type ApprovedTasksSectionProps = {
  tasks: NonEmptyArray<TaskWithTaskTemplate>;
  handleUpsertApproval: (task: TaskWithTaskTemplate, status: ApprovalStatus, comment?: string) => void;
};

export const ApprovedTasksSection = ({ tasks, handleUpsertApproval }: ApprovedTasksSectionProps) => {
  return (
    <VStack alignItems="stretch" gap={4} width="full">
      <Text
        alignSelf="flex-start"
        padding={1}
        variant="1u"
        color="green.500"
        backgroundColor="green.50"
        fontWeight="bold"
      >
        Approved
      </Text>

      {tasks.map(task => (
        <ApprovedTaskItem key={task.id} task={task} handleUpsertApproval={handleUpsertApproval} />
      ))}
    </VStack>
  );
};

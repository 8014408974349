import { useLeakyLocation } from '@process-street/adapters/navigation';
import * as React from 'react';
import { LibraryList } from '../common-ui';
import { Rows } from '../rows';
import { TemplateLibrarySelectionBarActionsWrapper } from '../selection-bar-actions-wrapper';
import { useFolderViewRows, useTagViewRows } from '../use-rows';

export * from './schedule';

export const FolderView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { rows, isLoading, isEmpty, isPrivateLibrary } = useFolderViewRows();
  const location = useLeakyLocation({ shouldDisableRerender: true });

  return (
    <LibraryList
      key={`${location.pathname}${location.search}`}
      isLoading={isLoading}
      isEmpty={isEmpty}
      isPrivateLibrary={isPrivateLibrary}
    >
      <Rows rows={rows} />
      <TemplateLibrarySelectionBarActionsWrapper />
    </LibraryList>
  );
};

export const TagView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { rows, isLoading, isEmpty } = useTagViewRows();

  return (
    <LibraryList isLoading={isLoading} isEmpty={isEmpty}>
      <Rows rows={rows} />
    </LibraryList>
  );
};

import * as React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { SearchBoxProvided } from 'react-instantsearch-core';
import debounce from 'lodash/debounce';
import { Icon, Input, InputGroup, InputLeftElement } from 'components/design/next';
import { useSearchStatus } from './search-context';
import { useGlobalSearchModalContext } from './modal';
import { downPressed, upPressed } from 'components/listbox';
import { useStateParam } from 'hooks/use-state-param';
import { AppModalQueryParam, GlobalSearchVariant } from 'app/app.constants';
import { match } from 'ts-pattern';
import { useMount } from 'react-use';

const ignoreUpDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
  if (upPressed(e) || downPressed(e)) {
    e.preventDefault();
  }
};

export const SearchDebounce = 100;
export const SearchBoxUI: React.FC<React.PropsWithChildren<SearchBoxProvided>> = ({ refine }) => {
  const { select, query, setQuery } = useSearchStatus();
  const { isOpen } = useGlobalSearchModalContext();

  // refine the results right away
  useMount(() => {
    if (query !== '') {
      processChange(query);
    }
  });

  const onChangeDebounced = React.useMemo(
    () =>
      debounce((filter: string) => {
        refine(filter);
      }, SearchDebounce),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore refine prop
    [],
  );

  const processChange = (value: string) => {
    const minCharsMet = value.length >= 3;
    const queryIsNewlyEmpty = value === '' && query !== '';

    setQuery(value);
    // we want to limit the calls to algolia
    if (minCharsMet || queryIsNewlyEmpty) {
      onChangeDebounced(value);
      return;
    }
  };

  const handleChange: React.FormEventHandler<HTMLInputElement> = e => {
    const { value } = e.currentTarget;
    processChange(value);
  };

  const ref = React.useRef<HTMLInputElement>(null);
  // auto select the query when the modal is reopened
  React.useEffect(() => {
    if (isOpen && query !== '' && ref.current) {
      ref.current.select();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- modal open
  }, [isOpen]);

  const modalVariant = useStateParam<GlobalSearchVariant>({
    key: AppModalQueryParam.ModalVariant,
    defaultValue: GlobalSearchVariant.Search,
  });
  const placeholder = match(modalVariant)
    .with(GlobalSearchVariant.Run, () => 'Search workflows')
    .otherwise(() => 'Search workflows, pages, forms and data sets');

  return (
    <form
      name="global-search"
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        select();
      }}
    >
      <InputGroup>
        <InputLeftElement>
          <Icon size="3.5" variant="far" icon="search" color="gray.500" />
        </InputLeftElement>
        <Input
          bg="gray.50"
          ref={ref}
          onKeyPress={ignoreUpDown}
          onKeyDown={ignoreUpDown}
          name="search"
          type="search"
          placeholder={placeholder}
          value={query}
          onChange={handleChange}
          autoFocus={true}
          variant="outline"
        />
      </InputGroup>
    </form>
  );
};

export const SearchBox = connectSearchBox(SearchBoxUI);

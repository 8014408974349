import { ApprovalStatus } from '@process-street/subgrade/approval-rule';
import { TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { Button, Flex, Icon, IconButton, Textarea, VStack } from 'components/design/next';
import * as React from 'react';

export type RejectTaskButtonProps = {
  task: TaskWithTaskTemplate;
  handleUpsertApproval: (task: TaskWithTaskTemplate, status: ApprovalStatus, comment?: string) => void;
  onRejecting?: () => void;
  onCancel?: () => void;
};

export const RejectTaskButton = ({ task, handleUpsertApproval, onRejecting, onCancel }: RejectTaskButtonProps) => {
  const [isRejectingWithComment, setIsRejectingWithComment] = React.useState(false);
  const [comment, setComment] = React.useState<string | undefined>(undefined);

  const handleRejectApproval = () => {
    handleUpsertApproval(task, ApprovalStatus.Rejected, comment);
  };

  return (
    <>
      {isRejectingWithComment ? (
        <VStack w="full" pt="2">
          <Textarea
            value={comment}
            onChange={e => setComment(e.target.value)}
            bgColor="white"
            placeholder="Leave a comment (optional)"
          />
          <Flex
            w="full"
            direction={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'center', md: 'inherit' }}
            justifyContent="flex-end"
            pt="2"
          >
            <Button
              variant="ghost"
              color="gray.500"
              fontWeight="normal"
              onClick={() => {
                onCancel?.();
                setIsRejectingWithComment(false);
              }}
            >
              Cancel
            </Button>
            <Button
              aria-label="Reject with comment"
              variant="danger"
              colorScheme="red"
              borderTopRightRadius="0"
              borderBottomRightRadius="0"
              onClick={handleRejectApproval}
            >
              Reject and send comment
            </Button>
          </Flex>
        </VStack>
      ) : (
        <Flex gap="0" w={{ base: 'full', md: 'auto' }}>
          <Button
            aria-label="Reject"
            variant="outline"
            colorScheme="red"
            borderTopRightRadius="0"
            borderBottomRightRadius="0"
            w={{ base: 'full', md: '100px' }}
            onClick={handleRejectApproval}
          >
            Reject
          </Button>
          <IconButton
            aria-label="Reject with comments"
            colorScheme="red"
            variant="outline"
            borderLeft="none"
            borderTopLeftRadius="0"
            borderBottomLeftRadius="0"
            onClick={() => {
              onRejecting?.();
              setIsRejectingWithComment(true);
            }}
            icon={<Icon icon="comment" size="4" />}
          />
        </Flex>
      )}
    </>
  );
};

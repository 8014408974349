import * as React from 'react';
import {
  Button,
  Checkbox,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
  Icon,
  Portal,
} from 'components/design/next';
import {
  DueDateRuleDefinition,
  DueDateRuleOffsetDirection,
  DueDateRuleType,
  FormFieldWidget,
  TaskTemplate,
  TemplateRevision,
} from '@process-street/subgrade/process';
import { Option, Period } from '@process-street/subgrade/core';
import { useSelector } from '@xstate/react';
import { useInjector } from 'components/injection-provider';
import { DueDateHelper } from 'features/dynamic-due-dates/services/due-date-helper';
import { DynamicDueDateLabelService } from 'components/dynamic-due-dates/services/dynamic-due-date-label.service';
import { match } from 'ts-pattern';
import { QueryClient } from 'react-query';
import { GetDueDateRulesByTemplateRevisionIdQuery } from 'app/features/dynamic-due-dates/query-builder';
import { getInitialPartialRule, PartialDueDateRule } from 'app/features/dynamic-due-dates/models/models';
import { ChecklistTriggerButton } from 'app/features/dynamic-due-dates/components/due-date-picker/checklist-trigger-button';
import { TaskTriggerButton } from 'app/features/dynamic-due-dates/components/due-date-picker/task-trigger-button';
import { DueDateSourceSelector } from 'app/features/dynamic-due-dates/components/due-date-source-selector';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import _isEqual from 'lodash/isEqual';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';

export interface TaskActionsDueDatePickerProps {
  selectedTaskTemplate: Option<TaskTemplate>;
  selectedTaskTemplates: TaskTemplate[];
  templateRevision: TemplateRevision;
  disabled: boolean;
  editable: boolean;
  ruleType?: DueDateRuleType;
  queryClient?: QueryClient;
  isBulk?: boolean;
}

export const TaskActionsDueDatePicker: React.FC<React.PropsWithChildren<TaskActionsDueDatePickerProps>> = ({
  selectedTaskTemplate,
  selectedTaskTemplates,
  templateRevision,
  disabled,
  editable,
  queryClient,
  isBulk = false,
  ruleType = 'Task',
}) => {
  const [selectedRule, setSelectedRule] = React.useState<PartialDueDateRule>(getInitialPartialRule(ruleType));
  const { onOpen, onClose, isOpen } = useDisclosure();
  const bulk = selectedTaskTemplates?.length > 1 || isBulk;
  const { DynamicDueDateService } = useInjector('DynamicDueDateService');
  const actor = useFormEditorPageActorRef();
  const dateWidgets: FormFieldWidget[] = useSelector(
    actor,
    FormEditorPageActorSelectors.getDateWidgetsByTemplateRevisionId,
    _isEqual,
  );

  const taskTemplates = useSelector(actor, FormEditorPageActorSelectors.getTaskTemplates, _isEqual);

  const rules = useSelector(actor, FormEditorPageActorSelectors.getDueDateRules, _isEqual);

  const rule: Option<DueDateRuleDefinition> = match(ruleType)
    .with('Task', () => selectedTaskTemplate && DueDateHelper.findRuleForTask(rules, selectedTaskTemplate))
    .with('Checklist', () => DueDateHelper.findRuleForChecklist(rules))
    .otherwise(() => undefined);

  const isChecklistRule = ruleType === 'Checklist';

  React.useEffect(() => {
    if (rule) {
      const partialRule: PartialDueDateRule = {
        ...rule,
        dueOffset: DueDateHelper.normalizePeriodYears(rule.dueOffset),
      };

      setSelectedRule(partialRule);
    } else {
      setSelectedRule(getInitialPartialRule(ruleType));
    }
  }, [rule, isOpen, ruleType]);

  const handleOffsetChange = (value: number, property: keyof Period) => {
    setSelectedRule({
      ...selectedRule,
      dueOffset: {
        ...selectedRule.dueOffset,
        [property]: value,
      },
    });
  };

  const handleMonthChange = (_: string, valueAsNumber: number) => handleOffsetChange(valueAsNumber, 'months');
  const handleDayChange = (_: string, valueAsNumber: number) => handleOffsetChange(valueAsNumber, 'days');
  const handleHourChange = (_: string, valueAsNumber: number) => handleOffsetChange(valueAsNumber, 'hours');
  const handleMinuteChange = (_: string, valueAsNumber: number) => handleOffsetChange(valueAsNumber, 'minutes');

  const handleWorkdaysOnlyChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { checked } = e.target;
    setSelectedRule({
      ...selectedRule,
      workdaysOnly: checked,
    });
  };

  const handleOffsetDirectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const offsetDirection = event.target.value as DueDateRuleOffsetDirection;
    setSelectedRule({
      ...selectedRule,
      sourceType: undefined,
      offsetDirection,
    });
  };

  const handleSave = async () => {
    const data = DueDateHelper.convertRuleToServiceFormat(selectedRule);
    if (bulk) {
      await DynamicDueDateService.saveDueRules(
        data,
        selectedTaskTemplates,
        templateRevision,
        rules,
        taskTemplates,
        dateWidgets,
      );
    } else {
      await DynamicDueDateService.saveDueRule(
        data,
        templateRevision,
        rule,
        selectedTaskTemplate,
        taskTemplates,
        dateWidgets,
      );
    }

    queryClient?.invalidateQueries(
      GetDueDateRulesByTemplateRevisionIdQuery.getKey({ templateRevisionId: templateRevision.id }),
    );
    onClose();
  };

  const handleRemove = async () => {
    if (bulk) {
      await DynamicDueDateService.deleteDueRules(templateRevision.id, selectedTaskTemplates, rules);
    } else {
      if (!rule) {
        return;
      }
      await DynamicDueDateService.deleteRules(templateRevision.id, [rule.id]);
      setSelectedRule(getInitialPartialRule(ruleType));
    }
    queryClient?.invalidateQueries(
      GetDueDateRulesByTemplateRevisionIdQuery.getKey({ templateRevisionId: templateRevision.id }),
    );
    onClose();
  };

  const canSave =
    selectedRule.sourceType !== undefined &&
    (selectedRule.dueOffset.months > 0 ||
      selectedRule.dueOffset.days > 0 ||
      selectedRule.dueOffset.hours > 0 ||
      selectedRule.dueOffset.minutes > 0);

  const buttonTitle = match({ hasRule: Boolean(rule), ruleType })
    .with({ hasRule: true }, () => DynamicDueDateLabelService.getFullLabelByRule(rule, taskTemplates, dateWidgets))
    .with({ ruleType: 'Checklist' }, () => 'Select Due Date')
    .otherwise(() => null);

  if (!rule && !editable) {
    return null;
  }

  return (
    <Popover placement="bottom-start" isOpen={isOpen} onOpen={onOpen} onClose={onClose} closeOnBlur={true} isLazy>
      <Tooltip
        isDisabled={bulk}
        label={
          <Text variant="-2" textAlign="center">
            {buttonTitle ?? 'Due date'}
          </Text>
        }
        hasArrow
        shouldWrapChildren
      >
        {match({ isChecklistRule, bulk })
          .with({ bulk: true }, () => <BulkTriggerButton />)
          .with({ isChecklistRule: true }, () => (
            <ChecklistTriggerButton buttonTitle={buttonTitle} disabled={disabled} rule={rule} />
          ))
          .with({ isChecklistRule: false }, () => (
            <TaskTriggerButton buttonTitle={buttonTitle} disabled={disabled} rule={rule} />
          ))
          .exhaustive()}
      </Tooltip>

      <Portal>
        <PopoverContent py={3} w="sm" zIndex="popover" textAlign="left">
          <PopoverHeader p={0} mb={3} mx={3}>
            <Text variant="-1" color="gray.600" fontWeight="medium" fontSize="lg">
              Dynamic due date
            </Text>
            <PopoverCloseButton
              padding="0"
              textTransform="none"
              borderStyle="none"
              outline="initial"
              margin="0"
              marginTop={1}
              cursor="pointer"
              backgroundColor="transparent"
              fontSize="xs"
              fontWeight="md"
              color="gray.500"
              w="24px!important"
            />
          </PopoverHeader>

          <PopoverBody maxH="400px" mt={0} p={3}>
            <VStack alignItems="flex-start">
              <Text variant="-1" fontSize="md">
                This task will be due:
              </Text>

              <HStack>
                <VStack alignItems="flex-start">
                  <NumberInput min={0} value={selectedRule.dueOffset.months} onChange={handleMonthChange}>
                    <NumberInputField color={selectedRule.dueOffset.months > 0 ? 'default' : 'gray.300'} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text variant="-1">months</Text>
                </VStack>

                <VStack alignItems="flex-start">
                  <NumberInput min={0} value={selectedRule.dueOffset.days} onChange={handleDayChange}>
                    <NumberInputField color={selectedRule.dueOffset.days > 0 ? 'default' : 'gray.300'} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text variant="-1">days</Text>
                </VStack>

                <VStack alignItems="flex-start">
                  <NumberInput min={0} value={selectedRule.dueOffset.hours} onChange={handleHourChange}>
                    <NumberInputField color={selectedRule.dueOffset.hours > 0 ? 'default' : 'gray.300'} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text variant="-1">hours</Text>
                </VStack>

                <VStack alignItems="flex-start">
                  <NumberInput min={0} value={selectedRule.dueOffset.minutes} onChange={handleMinuteChange}>
                    <NumberInputField color={selectedRule.dueOffset.minutes > 0 ? 'default' : 'gray.300'} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text variant="-1">minutes</Text>
                </VStack>
              </HStack>

              <Checkbox defaultChecked pt={2} isChecked={selectedRule.workdaysOnly} onChange={handleWorkdaysOnlyChange}>
                <Text variant="-1" fontSize="md">
                  Weekdays only
                </Text>
              </Checkbox>
            </VStack>

            <HStack mt={4}>
              <Select
                width="112px"
                minWidth="112px"
                onChange={handleOffsetDirectionChange}
                value={selectedRule?.offsetDirection}
              >
                <option value={DueDateRuleOffsetDirection.After}>after</option>
                <option value={DueDateRuleOffsetDirection.Before}>before</option>
              </Select>

              <VStack bg="gray.50" position="relative" spacing="0" w="full" alignItems="stretch">
                <DueDateSourceSelector
                  selectedTaskTemplate={selectedTaskTemplate}
                  selectedTaskTemplates={selectedTaskTemplates}
                  taskTemplates={taskTemplates}
                  dateWidgets={dateWidgets}
                  rule={selectedRule}
                  templateRevision={templateRevision}
                  onSelect={setSelectedRule}
                />
              </VStack>
            </HStack>
            <HStack mt={4}>
              <Button variant="primary" flex="0 50%" onClick={handleSave} isDisabled={!canSave}>
                Save
              </Button>
              <Button
                variant="outline"
                flex="0 50%"
                colorScheme="red"
                isDisabled={!rule && selectedTaskTemplate !== undefined}
                onClick={handleRemove}
              >
                Remove
              </Button>
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

const BulkTriggerButton = () => (
  <PopoverTrigger>
    <Button
      minW="163px"
      display="flex"
      color="gray.500"
      justifyContent="flex-start"
      variant="tertiary"
      iconSpacing="3"
      leftIcon={<Icon icon="calendar-days" variant="far" size="4" color="gray.500" />}
    >
      Due date
    </Button>
  </PopoverTrigger>
);

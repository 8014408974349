import * as React from 'react';
import { Button, Icon } from 'app/components/design/next';
import { FormResponsePageProviders } from '../../../providers';
import { FormResponseMachineHooks } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-hooks';

export type TaskListToggleStoppedTasksProps = {
  hiddenTasksCount: number;
};

export const TaskListToggleStoppedTasks = ({ hiddenTasksCount }: TaskListToggleStoppedTasksProps) => {
  const formResponseActor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
  const api = FormResponseMachineHooks.useApi(formResponseActor);
  const shouldHideStoppedTasks = FormResponseMachineHooks.useShouldHideStoppedTasks();

  const handleToggleStoppedTasks = React.useCallback(() => {
    api.onToggleStoppedTasks();
  }, [api]);

  // There is no hidden tasks, so we don't show the button to show them.
  if (shouldHideStoppedTasks && !hiddenTasksCount) return null;

  return (
    <Button
      color="gray.600"
      display="flex"
      fontSize="sm"
      leftIcon={<Icon icon="ellipsis-v" size="3" color="gray.500" />}
      ml={12}
      mt={2}
      py={3}
      variant="unstyled"
      w="max-content"
      onClick={handleToggleStoppedTasks}
    >
      {shouldHideStoppedTasks ? `Show more (${hiddenTasksCount})` : 'Show less'}
    </Button>
  );
};

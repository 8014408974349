import * as React from 'react';
import { SelectFormFieldWidget } from '@process-street/subgrade/process';
import { Box, Flex, HStack, Input, Text } from 'components/design/next';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';

export type LinkedDataDisplayProps = {
  widget: SelectFormFieldWidget;
};

const parseValue = (value: number | string) => {
  if (typeof value === 'number' && !isNaN(value)) {
    const date = new Date(value);
    return date.getTime() > 0 ? DateUtils.formatDateTime(date, DateFormat.DefaultDateOnly) : value;
  }
  return value;
};

export const LinkedDataDisplay: React.FC<LinkedDataDisplayProps> = ({ widget }) => {
  const linkedColumnData = widget.config.linkedColumnData || [];
  const rowCount = widget.config.linkedDataSetRowCount ?? widget.config.linkedColumnData?.length ?? 0;
  const processedData = [];
  for (const item of linkedColumnData) {
    if (processedData.length >= 3) break;
    const parsedValue = parseValue(item);
    if (parsedValue !== null) {
      processedData.push(parsedValue);
    }
  }
  return (
    <>
      {processedData.map((item, index) => (
        <Box key={`${item}-${index}`}>
          <HStack spacing="3" position="relative">
            <Box w="4">
              <Text w="full" fontSize="sm" color="gray.400" textAlign="right" display="inline-block">
                {index + 1}
              </Text>
            </Box>
            <Input
              bg="white"
              key={`option-input:${item}`}
              placeholder={`Option ${index + 1}`}
              value={item}
              _disabled={{ background: 'white', color: 'gray.800', cursor: 'text' }}
              disabled
            />
          </HStack>
        </Box>
      ))}
      {rowCount > 3 && (
        <Flex justifyContent="center">
          <Text color="gray.400">+&nbsp;{rowCount - 3}&nbsp;more</Text>
        </Flex>
      )}
    </>
  );
};

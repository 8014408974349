import { useInjector } from 'components/injection-provider';
import * as React from 'react';
import { useIsEditable } from 'features/rich-text';
import { usePageQuery } from 'pages/pages/_id/edit/page/query';
import { DEFAULT_PAGE_NAME } from '../../../../edit-v2/constants';

export const usePageNameEditor = ({ autoFocus }: { autoFocus?: boolean } = {}) => {
  const editable = useIsEditable();

  const { $state } = useInjector('$state');
  const {
    params: { id: pageId },
  } = $state;

  const { template, updateTemplate } = usePageQuery(pageId, editable);

  const templateName = template?.name ?? '';
  const templateNameInvalid = templateName.trim() === '';

  const handlePageNameChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    e => {
      updateTemplate({ name: e.target.value });
    },
    [updateTemplate],
  );

  return React.useMemo(() => {
    const isDefaultValue = templateName && templateName === DEFAULT_PAGE_NAME;

    return {
      autoFocus: Boolean(autoFocus) && Boolean(templateNameInvalid || isDefaultValue),
      onChange: handlePageNameChange,
      isInvalid: templateNameInvalid,
      isDefaultValue,
      value: templateName,
      editable,
      templateId: template?.id,
    };
  }, [autoFocus, editable, templateName, handlePageNameChange, templateNameInvalid, template?.id]);
};

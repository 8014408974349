import { Template } from '@process-street/subgrade/process';
import { Button, HStack, Icon, VStack } from 'components/design/next';
import { RunWorkflowButton } from 'components/focus-bar/workflow/run-workflow-button';
import { EditButton } from 'app/pages/pages/_id/edit/page/components/edit-button';
import { NgLink } from 'features/app/components/ng-link';
import * as React from 'react';
import { WorkflowSetupButton } from './workflow-setup-button';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { FreeMember } from 'components/paywalls/edit-workflow/ui';
import { usePaywall } from 'components/paywalls';
import { DelayedSpinner } from 'components/design/next/delayed-spinner';
import { TemplateSettingsModalProvider } from 'pages/templates/_id/components/template-settings-modal/template-settings-modal-context';

interface BasicWorkflowActionsProps {
  template?: Template;
}

export function BasicWorkflowActions({ template }: BasicWorkflowActionsProps) {
  const runAriaLabel = `run workflow ${template ? template.name : ''}`;
  const { data, isLoading } = useGetConsolidatedTemplatePermissionsQuery(template?.id, { enabled: Boolean(template) });
  const { setPaywall } = usePaywall();

  const canEdit = data?.permissionMap.templateUpdate ?? false;
  const canRun = data?.permissionMap.checklistCreate ?? false;

  const editButton = (
    <Button
      aria-label="edit workflow"
      variant="secondary"
      fontSize="sm"
      onClick={canEdit ? undefined : () => setPaywall(<FreeMember />)}
      backgroundColor="gray.200"
      _hover={{ backgroundColor: 'gray.300' }}
    >
      Edit
    </Button>
  );

  const runButton = (
    <Button
      aria-label={runAriaLabel}
      leftIcon={<Icon size="4" icon="play" variant="far" />}
      variant="primary"
      fontSize="sm"
      onClick={canRun ? undefined : () => setPaywall(<FreeMember />)}
    >
      Run Workflow
    </Button>
  );

  if (isLoading || !template) {
    return <DelayedSpinner isLoaded={false} isCentered={true} />;
  }

  return (
    <VStack spacing={3} alignItems="flex-start">
      {canRun ? <RunWorkflowButton>{runButton}</RunWorkflowButton> : runButton}

      <HStack spacing="3">
        {canEdit ? (
          <EditButton textProps={{ fontSize: 'sm' }} templateId={template.id}>
            {editButton}
          </EditButton>
        ) : (
          editButton
        )}

        <Button
          aria-label="preview workflow"
          to="templateView"
          params={{ id: template.id }}
          as={NgLink}
          leftIcon={<Icon icon="eye" size="4" />}
          variant="secondary"
          _hover={{ textDecoration: 'unset', color: 'gray.700', bgColor: 'gray.300' }}
          fontSize="sm"
          backgroundColor="gray.200"
        >
          View
        </Button>

        <TemplateSettingsModalProvider templateId={template.id} variant="dashboardSetupScreen">
          <WorkflowSetupButton hasUpdatePermission={canEdit} />
        </TemplateSettingsModalProvider>
      </HStack>
    </VStack>
  );
}

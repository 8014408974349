import * as React from 'react';
import { useNavigate } from '@process-street/adapters/navigation';
import { RightSidebarButton } from '../right-sidebar-button';
import { TaskWithTaskTemplate, Template } from '@process-street/subgrade/process';
import { queryString } from '@process-street/subgrade/util';
import { useGetConsolidatedTemplatePermissionsQuery } from 'app/features/permissions/query-builder';

export type EditWorkflowButtonProps = {
  template: Template;
  currentTask?: TaskWithTaskTemplate;
};

export const EditWorkflowButton = ({ template, currentTask }: EditWorkflowButtonProps) => {
  const templatePermissionMapQuery = useGetConsolidatedTemplatePermissionsQuery(template.id);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate({
      pathname: currentTask ? 'templateV2.task' : 'templateV2',
      search: queryString.stringify({
        id: template.id,
        groupId: currentTask?.taskTemplate.group.id,
      }),
    });
  };

  /* The button is only visible in case the user has permission to update the template */
  if (!templatePermissionMapQuery.data?.permissionMap.templateUpdate) return null;

  return (
    <RightSidebarButton iconName="pencil" onClick={handleClick}>
      Edit Workflow
    </RightSidebarButton>
  );
};

import { Option, OrganizationMembershipRole, OrganizationMembershipWithUser, User, UserType } from '../core';
import { htmlEscaped } from '@process-street/subgrade/util/html-escaped';
import { StringUtils } from '@process-street/subgrade/util/string-utils';
import { match } from 'ts-pattern';
import { isAnonymousUser, isStandardGroupUser } from '@process-street/subgrade/util/user-type-utils';
import {
  ALL_ANONYMOUS_GROUP_USERNAME,
  ALL_FREE_MEMBERS_GROUP_USERNAME,
  ALL_GUESTS_GROUP_USERNAME,
  ALL_MEMBERS_GROUP_USERNAME,
} from './membership-utils';

export namespace UserUtils {
  export type UserWithLabel = User & {
    label?: string;
  };

  export const filterUsersByEmailOrUsername = (users: UserWithLabel[], filter: string): User[] => {
    const pattern = filter.trim().toLowerCase();
    if (!filter) {
      return users;
    }
    return users.filter(
      user =>
        user.email.toLowerCase().includes(pattern) ||
        user.username.toLowerCase().includes(pattern) ||
        user.label?.toLowerCase().includes(pattern),
    );
  };

  export const filterOmUsersByEmailOrUsername = (
    users: OrganizationMembershipWithUser[],
    filter: string,
  ): OrganizationMembershipWithUser[] => {
    const normalizedFilter = filter.trim().toLowerCase();
    if (!filter) {
      return users;
    }
    return users.filter(
      omUser =>
        omUser.user.email.toLowerCase().includes(normalizedFilter) ||
        omUser.user.username.toLowerCase().includes(normalizedFilter),
    );
  };

  export const extractInitials = (user: User): string => {
    const words = (user.username !== '' ? user.username : '?').split(/[\s._-]+/);
    if (words.length > 1) {
      return words
        .map(word => (word.length ? word.charAt(0).toUpperCase() : '?'))
        .slice(0, 2)
        .join('');
    }

    return words[0].slice(0, 2);
  };

  /**
   * The default URL for the Anonymous user.
   * @type {string}
   */
  const ANONYMOUS_URL = 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&f=y&s=';

  export const extractAvatarUrl = (user: User, size: number): Option<string> => {
    if (user.avatarUrl) {
      return user.avatarUrl;
    }

    if (isAnonymousUser(user)) {
      return ANONYMOUS_URL + size;
    }

    if (user.avatarFile) {
      const fileId = user.avatarFile.id;
      return `https://aang.s3.amazonaws.com/${fileId}-${size}.jpg`;
    }

    return undefined;
  };

  export const getLabel = (value: User | string, showEmail = true): string => {
    let label = '???';
    if (StringUtils.isString(value)) {
      // It's an email address
      label = value.toString();
    } else {
      const user = value as User;
      if (user.username) {
        if (user.userType === UserType.Group) {
          label = match(user.username)
            .with(ALL_MEMBERS_GROUP_USERNAME, () => 'All Organization')
            .with(ALL_FREE_MEMBERS_GROUP_USERNAME, () => 'All Guests (Internal)')
            .with(ALL_GUESTS_GROUP_USERNAME, () => 'All Guests (External)')
            .with(ALL_ANONYMOUS_GROUP_USERNAME, () => 'All Guests (Anonymous)')
            .otherwise(() =>
              isStandardGroupUser(user) || !showEmail ? user.username : `${user.username} (${user.email})`,
            );
        } else {
          label = isStandardGroupUser(user) || !showEmail ? user.username : `${user.username} (${user.email})`;
        }
      } else if (user.email) {
        label = user.email;
      } else {
        label = '???';
      }
    }

    return htmlEscaped`${label}`;
  };

  export const getMembershipType = (organizationMembershipWithUser: OrganizationMembershipWithUser) => {
    const user = organizationMembershipWithUser.user as User;

    if (isStandardGroupUser(user)) return 'Group';
    if (isAnonymousUser(user)) return 'Guest (Anonymous)';
    return match(organizationMembershipWithUser.role)
      .with(OrganizationMembershipRole.Admin, () => 'Admin')
      .with(OrganizationMembershipRole.Guest, () => 'Guest (External)')
      .with(OrganizationMembershipRole.FreeMember, () => 'Guest (Internal)')
      .with(OrganizationMembershipRole.FullMember, () => 'Member')
      .otherwise(() => organizationMembershipWithUser.role.toString());
  };

  export const getUsername = (user: Option<User>): string => {
    if (!user) {
      return 'Someone';
    }

    if (user.userType === UserType.Anonymous) {
      return 'Guest (Anonymous)';
    }

    return user.username;
  };
}

import * as React from 'react';
import { TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { AssignmentPicker } from 'app/features/one-off-tasks/components/shared/assignment-picker';
import { OrganizationMembership, OrganizationMembershipWithUser, User, UserType } from '@process-street/subgrade/core';
import { useGetAllOrganizationMembershipsQuery } from 'app/features/organization-memberships/query-builder';
import { useSelectedOrganization } from 'app/hooks/use-selected-organization';
import { isAssignableUserOrGroupOm, sortByVisibility } from '@process-street/subgrade/util/membership-utils';
import { DeleteTaskAssignmentMutation, UpdateTaskAssignmentMutation } from 'app/features/task/query-builder';
import { FormResponsePageMachineSelectors } from 'app/pages/responses/_id/form-response-page-selectors';
import { FormResponsePageProviders } from '../../../providers';
import { useSelector } from '@xstate/react';
import { useToast } from 'components/design/next';
import { useQueryClient } from 'react-query';
import { GetTasksAssignmentsByChecklistRevisionQuery } from 'app/features/checklist-revisions/query-builder/get-tasks-assignments-by-checklist-revision-query';
import { FormResponsePageMachineHooks } from 'app/pages/responses/_id/form-response-page-hooks';
import isEqual from 'lodash/isEqual';
import { DefaultErrorMessages } from 'app/components/utils/error-messages';

export interface ChecklistTaskAssignButtonProps {
  task: TaskWithTaskTemplate;
}

export const ChecklistTaskAssignButton: React.FC<React.PropsWithChildren<ChecklistTaskAssignButtonProps>> = ({
  task,
}) => {
  const toast = useToast();
  const organization = useSelectedOrganization();
  const checklistRevision = FormResponsePageMachineHooks.useChecklistRevision();
  const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();
  const taskAssignmentsSelector = FormResponsePageMachineSelectors.getTaskAssignments(task.id);
  const taskAssignments = useSelector(actor, taskAssignmentsSelector, isEqual);
  const queryClient = useQueryClient();

  const { data: allMemberships } = useGetAllOrganizationMembershipsQuery(
    {
      organizationId: organization?.id,
    },
    {
      enabled: Boolean(organization),
      select: oms => oms.filter(isAssignableUserOrGroupOm),
    },
  );

  const createAssignmentMutation = UpdateTaskAssignmentMutation.useMutation({
    onSuccess: result => {
      const {
        user: { username, email, userType },
      } = result.organizationMembership as OrganizationMembershipWithUser;
      const assignedToLabel = userType === UserType.Standard ? username + ' [' + email + ']' : username;
      queryClient.setQueryData(
        GetTasksAssignmentsByChecklistRevisionQuery.getKey({ checklistRevisionId: checklistRevision?.id ?? '' }),
        [...taskAssignments, result],
      );

      toast({
        title: `Task assigned to ${assignedToLabel}`,
        status: 'success',
        position: 'top',
      });
    },
    onError: () =>
      void toast({
        title: "We're having problems creating the assignment",
        description: DefaultErrorMessages.unexpectedErrorDescription,
        status: 'error',
        position: 'top',
      }),
  });

  const deleteAssignmentMutation = DeleteTaskAssignmentMutation.useMutation({
    onSuccess: result => {
      const membership = taskAssignments.find(ta => ta.id === result.id)
        ?.organizationMembership as OrganizationMembership;
      queryClient.setQueryData(
        GetTasksAssignmentsByChecklistRevisionQuery.getKey({ checklistRevisionId: checklistRevision?.id ?? '' }),
        taskAssignments.filter(ta => ta.id !== result.id),
      );
      toast({
        title: `Task unassigned from ${(membership.user as User).username}`,
        status: 'success',
        position: 'top',
      });
    },
    onError: () =>
      void toast({
        title: "We're having problems deleting the assignment",
        description: DefaultErrorMessages.unexpectedErrorDescription,
        status: 'error',
        position: 'top',
      }),
  });

  const handleOnSelectAssignee = (om: OrganizationMembershipWithUser) => {
    createAssignmentMutation.mutate({
      taskId: task.id,
      email: om.user.email,
    });
  };

  const handleOnRemoveAssignment = (om: OrganizationMembershipWithUser) => {
    deleteAssignmentMutation.mutate({
      assignmentId: om.user.email,
      taskId: task.id,
    });
  };

  if (!taskAssignments || !allMemberships) return null;

  const assignedMemberships =
    taskAssignments.map(om => om.organizationMembership as OrganizationMembershipWithUser).sort(sortByVisibility) ?? [];

  const availableMemberships = allMemberships.filter(
    om => !assignedMemberships.some(aom => aom.user.email === om.user.email),
  );
  return (
    <AssignmentPicker
      onSelect={handleOnSelectAssignee}
      onRemove={handleOnRemoveAssignment}
      availableMemberships={availableMemberships}
      assignedMemberships={assignedMemberships}
      userToPreview={assignedMemberships[0]}
    />
  );
};

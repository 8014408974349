import React from 'react';
import { DueDateRuleDefinition } from '@process-street/subgrade/process';
import { DynamicDueDate } from 'app/features/inbox/components/inbox-item-due-date-picker/dynamic-due-date';
import { FormResponsePageMachineHooks } from 'app/pages/responses/_id/form-response-page-hooks';

interface DynamicDueDateOverrideProps {
  rule: DueDateRuleDefinition;
  /** Rendered after user overrides dynamic due date */
  children: React.ReactNode;
}

export const DynamicDueDateOverride = ({ rule, children }: DynamicDueDateOverrideProps) => {
  const [shouldOverrideDynamicDueDate, setShouldOverrideDynamicDueDate] = React.useState(false);

  const shouldShowOverride = rule && !shouldOverrideDynamicDueDate;
  const taskTemplates = FormResponsePageMachineHooks.useTaskTemplates();
  const dateWidgets = FormResponsePageMachineHooks.useDateWidgets();

  return shouldShowOverride ? (
    <DynamicDueDate
      rule={rule}
      dateWidgets={dateWidgets}
      taskTemplates={taskTemplates}
      onOverride={() => {
        setShouldOverrideDynamicDueDate(true);
      }}
    />
  ) : (
    <>{children}</>
  );
};

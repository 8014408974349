import * as React from 'react';
import { DateFormat } from '@process-street/subgrade/util';
import { Button, Icon, IconButton, Text } from 'components/design/next';
import { FormResponsePageMachineHooks } from 'app/pages/responses/_id/form-response-page-hooks';
import dayjs from 'dayjs';
import { DatePicker } from 'features/conditional-logic/components/date-picker';
import { useCurrentUser } from 'app/hooks/use-current-user';
import { DynamicDueDateOverride } from './due-date-override';
import { FormResponseMachineHooks } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-hooks';
import { match, P } from 'ts-pattern';
import { TaskMachineHooks } from 'app/pages/responses/_id/components/task/task-machine-hooks';

const pickerStyles = {
  cell: {
    fontSize: 'var(--ps-fontSizes-sm)',
  },
};

const disabledDates = [
  {
    before: dayjs().toDate(),
  },
];

export const ChecklistTaskDueDateButton = () => {
  const currentUser = useCurrentUser();
  const currentTaskActor = FormResponseMachineHooks.useCurrentTaskActorRef();
  const api = TaskMachineHooks.useApi(currentTaskActor);
  const currentTaskId = FormResponseMachineHooks.useCurrentTaskId();
  const task = FormResponsePageMachineHooks.useChecklistTaskById(currentTaskId ?? '');
  const rule = FormResponsePageMachineHooks.useDueDateRuleForTaskTemplate(task!.taskTemplate);

  const handleDueDateUpdate = React.useCallback(
    (date: number | null) => {
      if (!date || !task) {
        console.warn('[ChecklistTaskDueDateButton] handleDueDateUpdate: No due date or task provided');
        return;
      }
      api.onUpdateTaskDueDate({ task, date, dateOverridden: Boolean(rule) });
    },
    [api, rule, task],
  );

  const renderTrigger = (value: number | undefined | null, timeZone: string) =>
    match(value)
      .with(P.nullish, () => (
        <IconButton
          justifyContent="center"
          aria-label="Due date picker"
          size="sm"
          variant="outline"
          bgColor="white"
          borderWidth="px"
          borderColor="gray.300"
          color="gray.500"
          icon={<Icon icon="clock" size="4" variant="far" />}
        />
      ))
      .otherwise(() => {
        const formattedDate = dayjs(value).tz(timeZone).format(DateFormat.DateTimeMergeTagLong);
        return (
          <Button
            aria-label="Due date picker"
            size="sm"
            variant="outline"
            bgColor="white"
            borderWidth="px"
            borderColor="gray.300"
            color="gray.500"
            fontSize="md"
          >
            <Icon icon="clock" size="4" variant="far" color="purple.500" mr="2" /> Due {formattedDate}
          </Button>
        );
      });

  const renderContent = React.useCallback(
    (content: React.ReactElement) =>
      rule && task && !task.dueDateOverridden ? (
        <DynamicDueDateOverride rule={rule}>{content}</DynamicDueDateOverride>
      ) : (
        content
      ),
    [rule, task],
  );
  if (!task) return null;

  return (
    <DatePicker
      title={
        <Text fontWeight="bold" px={2} alignSelf="flex-start">
          Due date
        </Text>
      }
      size="md"
      onChange={handleDueDateUpdate}
      value={task.dueDate}
      timeZone={currentUser?.timeZone}
      renderTrigger={renderTrigger}
      renderContent={renderContent}
      pickerProps={{
        disabled: disabledDates,
        styles: pickerStyles,
      }}
      showWeekdayToggle
    />
  );
};

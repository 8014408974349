import * as React from 'react';
import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Portal, Text, Tooltip } from 'components/design/next';
import { match } from 'ts-pattern';
import { TinyMCEEditor } from 'app/features/rich-text';
import { getFormatButtonStyles } from './format-button';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';

export type HeadersProps = {
  editor?: TinyMCEEditor;
  activeCommands: Record<string, boolean>;
};

const formats = [
  { label: 'Header 1', name: 'h1' },
  { label: 'Header 2', name: 'h2' },
  { label: 'Header 3', name: 'h3' },
];

export const Headers = ({ editor, activeCommands }: HeadersProps) => {
  const isSomeFormatActive = formats.some(format => activeCommands[format.name]);

  return (
    <Menu isLazy placement="bottom">
      {({ isOpen }) => (
        <>
          <Tooltip label="Headers" shouldWrapChildren>
            <MenuButton
              {...getFormatButtonStyles({ isActive: isOpen || isSomeFormatActive })}
              as={IconButton}
              aria-label="Headers"
              icon={<Icon icon="heading" size="3.5" />}
            />
          </Tooltip>

          <Portal>
            <ThemeProvider2024>
              <MenuList minW="max-content">
                {formats.map(format => {
                  return (
                    <MenuItem key={format.name} onClick={() => editor?.execCommand('FormatBlock', false, format.name)}>
                      {match(format.name)
                        .with('h1', () => (
                          <Text color="" m={0} fontSize="2xl" fontWeight="semibold">
                            {format.label}
                          </Text>
                        ))
                        .with('h2', () => (
                          <Text m={0} fontSize="xl" fontWeight="semibold">
                            {format.label}
                          </Text>
                        ))
                        .with('h3', () => (
                          <Text m={0} fontSize="lg" fontWeight="semibold">
                            {format.label}
                          </Text>
                        ))
                        .otherwise(() => (
                          <Text>{format.label}</Text>
                        ))}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </ThemeProvider2024>
          </Portal>
        </>
      )}
    </Menu>
  );
};

import * as React from 'react';
import { FormatButton } from './format-button';
import { TextFormats } from './text-formats';
import { TextStyle } from './text-style';
import { TextAlignment } from './text-alignment';
import { Headers } from './headers';

export enum ToolbarMode {
  TextContentWidget = 'TextContentWidget',
  SendEmailWidget = 'SendEmailWidget',
}

export const toolbarOptionsByMode: Record<ToolbarMode, { name: string; hasDivider?: boolean }[]> = {
  [ToolbarMode.TextContentWidget]: [
    { name: 'Undo' },
    { name: 'Redo', hasDivider: true },
    { name: 'TextFormats' },
    { name: 'Bold' },
    { name: 'Italic' },
    { name: 'Underline' },
    { name: 'Strikethrough', hasDivider: true },
    { name: 'TextStyle' },
    { name: 'Bulleted List' },
    { name: 'Numbered List', hasDivider: true },
    { name: 'TextAlignment' },
    { name: 'Horizontal Line' },
    { name: 'Link', hasDivider: true },
  ],
  [ToolbarMode.SendEmailWidget]: [
    { name: 'Undo' },
    { name: 'Redo', hasDivider: true },
    { name: 'Headers' },
    { name: 'Bold' },
    { name: 'Italic' },
    { name: 'Underline' },
    { name: 'Strikethrough', hasDivider: true },
    { name: 'TextStyle' },
    { name: 'Link' },
  ],
};

export const renderOption = (option: string, props: any) => {
  switch (option) {
    case 'Undo':
      return <FormatButton {...props} label="Undo" icon="rotate-left" command="Undo" />;
    case 'Redo':
      return <FormatButton {...props} label="Redo" icon="rotate-right" command="Redo" />;
    case 'TextFormats':
      return <TextFormats editor={props.editor} activeCommands={props.activeCommands} />;
    case 'Headers':
      return <Headers editor={props.editor} activeCommands={props.activeCommands} />;
    case 'Bold':
      return <FormatButton {...props} label="Bold" icon="bold" command="Bold" />;
    case 'Italic':
      return <FormatButton {...props} label="Italic" icon="italic" command="Italic" />;
    case 'Underline':
      return <FormatButton {...props} label="Underline" icon="underline" command="Underline" />;
    case 'Strikethrough':
      return <FormatButton {...props} label="Strikethrough" icon="strikethrough" command="Strikethrough" />;
    case 'TextStyle':
      return <TextStyle editor={props.editor} />;
    case 'Bulleted List':
      return <FormatButton {...props} label="Bulleted List" icon="list" command="insertUnorderedList" />;
    case 'Numbered List':
      return <FormatButton {...props} label="Numbered List" icon="list-ol" command="insertOrderedList" />;
    case 'TextAlignment':
      return <TextAlignment editor={props.editor} activeCommands={props.activeCommands} />;
    case 'Horizontal Line':
      return <FormatButton {...props} label="Horizontal Line" icon="horizontal-rule" command="insertHorizontalRule" />;
    case 'Link':
      return <FormatButton {...props} label="Link" icon="link" command="mceLink" />;
    default:
      return null;
  }
};

import { TaskVisibility } from '@process-street/subgrade/conditional-logic';
import { Option } from '@process-street/subgrade/core';
import {
  CrossLinkWidget,
  EmailWidget,
  EmbedWidget,
  FieldType,
  FileWidget,
  FormFieldWidget,
  ImageWidget,
  isHeading,
  TaskTemplate,
  TextWidget,
  VideoWidget,
  Widget,
  WidgetType,
  getFormFieldWidgetLabel,
  isFormFieldWidget,
} from '@process-street/subgrade/process';
import { StringUtils } from '@process-street/subgrade/util';

export enum NodeType {
  Heading = 'Heading',
  Task = 'Task',
  Widget = 'Widget',
  TimeSource = 'TimeSource',
}

export enum TimeSource {
  ChecklistStartDate = 'ChecklistStartDate',
}

export const TimeSourceLabels = {
  [TimeSource.ChecklistStartDate]: 'Workflow run start date',
};

export const getLevel = (node: Node) => {
  return [NodeType.Heading, NodeType.Task, NodeType.Widget].indexOf(node.type);
};

export interface Node {
  id: string;
  parentId?: string;
  label: string;
  name?: string;
  icon?: string;
  widgets?: Node[];
  type: NodeType;
  ref: TaskTemplate | Widget;
  widgetType?: WidgetType;
  position?: number;
}

export interface NodeInfoMap {
  [key: string]: {
    selected: boolean;
    foldable: boolean;
    folded?: boolean;
  };
}

export const taskTemplateToNode = (taskTemplate: TaskTemplate, index: number): Node => ({
  id: taskTemplate.id,
  label: taskTemplate.name || '&lt;unnamed task&gt;',
  name: taskTemplate.name,
  ref: taskTemplate,
  type: isHeading(taskTemplate) ? NodeType.Heading : NodeType.Task,
  position: index + 1,
});

const widgetToNode = (widget: Widget): Node => {
  const icon = `<i class="far ${getIconForWidget(widget)} fa-fw"></i>`;
  const label = getLabelForWidget(widget);

  return {
    id: widget.header.id,
    label,
    icon,
    name: label,
    ref: widget,
    type: NodeType.Widget,
    widgetType: widget.header.type,
  };
};

const createNodes = (taskTemplates: TaskTemplate[], widgets: Widget[]): Node[] => {
  const taskNodes: Node[] = taskTemplates.map((tt, index) => taskTemplateToNode(tt, index));
  const widgetNodes: Node[] = widgets
    .filter(w => (isFormFieldWidget(w) ? w.fieldType !== FieldType.Snippet : true))
    .map(w => widgetToNode(w));

  const list: Node[] = [];

  // assuming that the list of task templates is ordered
  let lastHeading: Option<Node>;
  taskNodes.forEach(taskNode => {
    list.push(taskNode);

    const heading = taskNode.type === NodeType.Heading;
    if (lastHeading && !heading) {
      taskNode.parentId = lastHeading.id;
    }

    if (heading) {
      lastHeading = taskNode;
    }

    // push widgets
    const taskWidgets = widgetNodes.filter(node => (node.ref as Widget).header.taskTemplate.id === taskNode.id);
    taskWidgets.forEach(node => (node.parentId = taskNode.id));
    list.push(...taskWidgets);

    taskNode.widgets = taskWidgets;
  });

  return list;
};

const changeSelection = (
  nodeInfoMap: NodeInfoMap,
  nodes: Node[],
  selectedNode: Node,
  selected: boolean,
  mode: TaskVisibility,
) => {
  const node = findNodeById(nodes, selectedNode.id);
  if (node) {
    const nodeInfo = nodeInfoMap[node.id];
    if (nodeInfo) {
      nodeInfoMap[node.id] = { ...nodeInfo, selected };
    }
  }

  const selectWidgetParent = !selected && mode === TaskVisibility.HIDE;
  if (selectWidgetParent && selectedNode.type === NodeType.Widget) {
    const parentNode = findNodeById(nodes, selectedNode.parentId!);
    if (parentNode) {
      const nodeInfo = nodeInfoMap[parentNode.id];
      if (nodeInfo) {
        nodeInfoMap[parentNode.id] = { ...nodeInfo, selected };
      }
    }
  }

  if (selectedNode.type !== NodeType.Widget) {
    selectChildren(nodeInfoMap, nodes, selectedNode.id, selected);
  }
};

const findChildren = (nodes: Node[], parentId: string) => nodes.filter(node => node.parentId === parentId);
const findNodeById = (nodes: Node[], id: string) => nodes.find(node => node.id === id);

const selectChildren = (nodeInfoMap: NodeInfoMap, nodes: Node[], parentId: string, selected: boolean) => {
  findChildren(nodes, parentId).forEach(node => {
    const nodeInfo = nodeInfoMap[node.id];
    if (nodeInfo) {
      nodeInfoMap[node.id] = { ...nodeInfo, selected };
    }

    if (node.type !== NodeType.Widget) {
      selectChildren(nodeInfoMap, nodes, node.id, selected);
    }
  });
};

const selectAll = (nodeInfoMap: NodeInfoMap, nodesToSelect: Node[], selected: boolean) => {
  const selectedIds = nodesToSelect.map(node => node.id);
  selectNodesByIds(nodeInfoMap, selectedIds, selected);
};

const selectNodesByIds = (nodeInfoMap: NodeInfoMap, selectedIds: string[], selected: boolean) => {
  selectedIds.forEach(id => {
    if (nodeInfoMap[id]) {
      nodeInfoMap[id].selected = selected;
    }
  });
};

export interface NodesSelection {
  selectedTaskTemplates: TaskTemplate[];
  selectedWidgets: Widget[];
}

const getSelection = (nodeInfoMap: NodeInfoMap, nodeList: Node[]): NodesSelection => {
  const selectedWidgets = nodeList
    .filter(node => node.type === NodeType.Widget && nodeInfoMap[node.id]?.selected)
    .map(node => node.ref as Widget);

  const selectedTaskTemplates = nodeList
    .filter(node => [NodeType.Heading, NodeType.Task].includes(node.type) && nodeInfoMap[node.id]?.selected)
    .map(node => node.ref as TaskTemplate);

  return {
    selectedTaskTemplates,
    selectedWidgets,
  };
};

const areAllChildrenSelected = (nodeInfoMap: NodeInfoMap, nodes: Node[], parentId: string) => {
  const children = findChildren(nodes, parentId);

  return areAllNodesSelected(nodeInfoMap, children);
};

const areAllNodesSelected = (nodeInfoMap: NodeInfoMap, nodes: Node[]) =>
  nodes.length > 0 && nodes.every(node => nodeInfoMap[node.id].selected);

type IconByWigetTypeMap = { [index in WidgetType]: string };

type IconByFieldTypeMap = { [index in FieldType]: string };

const iconByWigetTypeMap: IconByWigetTypeMap = {
  [WidgetType.CrossLink]: 'fa-file-alt',
  [WidgetType.Email]: 'fa-envelope',
  [WidgetType.Embed]: 'fa-code',
  [WidgetType.File]: 'fa-file',
  [WidgetType.FormField]: 'fa-edit',
  [WidgetType.Image]: 'fa-image',
  [WidgetType.Table]: 'fa-table-alt',
  [WidgetType.Text]: 'fa-text-width',
  [WidgetType.Video]: 'fa-video',
};

const iconByFieldTypeMap: IconByFieldTypeMap = {
  [FieldType.Date]: 'fa-calendar-alt',
  [FieldType.Email]: 'fa-envelope',
  [FieldType.File]: 'fa-file',
  [FieldType.Hidden]: 'fa-eye-slash',
  [FieldType.Members]: 'fa-users',
  [FieldType.MultiChoice]: 'fa-shapes',
  [FieldType.MultiSelect]: 'fa-list',
  [FieldType.Number]: 'fa-tally',
  [FieldType.Select]: 'fa-chevron-square-down',
  [FieldType.SendRichEmail]: 'fa-envelope',
  [FieldType.Snippet]: 'fa-brackets-curly',
  [FieldType.Table]: 'fa-table',
  [FieldType.Text]: 'fa-edit',
  [FieldType.Textarea]: 'fa-paragraph',
  [FieldType.Url]: 'fa-globe',
};

const getIconForWidget = (widget: Widget) => {
  const fieldType = widget.header.type === WidgetType.FormField ? (widget as FormFieldWidget).fieldType : undefined;
  const widgetType = widget.header.type;

  return fieldType ? iconByFieldTypeMap[fieldType] : iconByWigetTypeMap[widgetType];
};

const stripHtmlRegexp = /<[^>]*>?/gm;
const stripHtml = (content: string) => content.replace(stripHtmlRegexp, '');

const EMPTY_LABEL = '(Empty)';

export const getLabelForWidget = (widget: Widget): string => {
  switch (widget.header.type) {
    case WidgetType.Text:
    case WidgetType.Table:
      const { content } = widget as TextWidget;
      if (content) {
        return stripHtml(content).substr(0, 100);
      } else {
        return EMPTY_LABEL;
      }
    case WidgetType.File:
      return (widget as FileWidget).description ?? (widget as FileWidget).file?.name ?? '(No file uploaded)';
    case WidgetType.Video:
      const videoWidget = widget as VideoWidget;
      return videoWidget.description ?? (videoWidget.service && `${videoWidget.service} video`) ?? '(No file uploaded)';
    case WidgetType.Image:
      const imageWidget = widget as ImageWidget;
      return imageWidget.caption ?? imageWidget.file?.originalName ?? imageWidget.file?.name ?? '(No file uploaded)';
    case WidgetType.Email:
      return `Subject: ${(widget as EmailWidget).subject || EMPTY_LABEL}`;
    case WidgetType.Embed:
      return (widget as EmbedWidget).url || '(No hyperlink)';
    case WidgetType.CrossLink:
      return (widget as CrossLinkWidget).templateId || EMPTY_LABEL;

    case WidgetType.FormField:
      const ffw = widget as FormFieldWidget;
      return getFormFieldWidgetLabel(ffw);
  }
};

const findNodesAndUpdateNodeInfoMap = (
  nodeInfoMap: NodeInfoMap,
  nodesList: Node[],
  pattern: string,
  start: number,
  end: number,
) => {
  const results: Node[] = [];

  for (const node of nodesList) {
    if (node.type === NodeType.Widget) {
      continue;
    }

    const widgets = node.widgets ?? [];

    if (pattern === '') {
      const selectedWidgets = widgets.filter(widget => nodeInfoMap[widget.id]?.selected);

      results.push(node);

      if (results.length >= start) {
        const nodeInfo = nodeInfoMap[node.id];

        if (nodeInfo) {
          nodeInfo.foldable = widgets.length > 0;
          nodeInfo.folded = selectedWidgets.length === 0;
        }
      }
    } else {
      const filteredWidgets = widgets.filter(widget => StringUtils.containsIgnoreCase(widget.name, pattern));
      const hasMatchingWidgets = filteredWidgets.length > 0;

      if (StringUtils.containsIgnoreCase(node.name, pattern) || hasMatchingWidgets) {
        results.push(node);

        if (results.length >= start) {
          const nodeInfo = nodeInfoMap[node.id];
          nodeInfo.foldable = widgets.length > 0;
          nodeInfo.folded = !hasMatchingWidgets;
        }
      }
    }

    if (results.length >= end) {
      break;
    }
  }

  return results.slice(start, end);
};

const nodeMatchesSearch = (node: Node, searchTerm: string) => {
  if (searchTerm === '') return true;

  const widgets = node.widgets ?? [];

  const hasMatchingWidgets = widgets.some((widget: any) => StringUtils.containsIgnoreCase(widget.name, searchTerm));

  return StringUtils.containsIgnoreCase(node.name, searchTerm) || hasMatchingWidgets;
};

const findNodes = (nodeInfoMap: NodeInfoMap, nodesList: Node[], pattern: string) => {
  const nodesMap = nodesList.reduce<Record<string, Node>>((acc, node) => {
    acc[node.id] = node;
    return acc;
  }, {});

  const allowedHeadings = new Set<string>();

  return nodesList
    .filter(node => {
      if (node.type === NodeType.Widget) {
        const parentId = node.parentId ?? '';

        const parentNode = nodesMap[parentId];
        const parentNodeInfo = nodeInfoMap[parentId];
        const headingNode = nodesMap[parentNode.parentId ?? ''];

        if (!parentNode || !parentNodeInfo) return false;

        const matchesParentTaskName = StringUtils.containsIgnoreCase(parentNode.name, pattern);
        const matchesParentHeadingName = StringUtils.containsIgnoreCase(headingNode?.name, pattern);

        return matchesParentTaskName || matchesParentHeadingName || nodeMatchesSearch(node, pattern);
      }

      const allow = nodeMatchesSearch(node, pattern);

      if (node.type === NodeType.Task && allow && node.parentId) allowedHeadings.add(node.parentId);
      if (node.type === NodeType.Heading) return true;

      return nodeMatchesSearch(node, pattern);
    })
    .filter(node => {
      if (node.type !== NodeType.Heading) return true;

      return nodeMatchesSearch(node, pattern) || allowedHeadings.has(node.id);
    });
};

const updateNodeInfoMap = (nodeInfoMap: NodeInfoMap, nodesList: Node[], pattern: string) => {
  for (const node of nodesList) {
    if (node.type === NodeType.Widget) {
      continue;
    }

    const childWidgets = node.widgets ?? [];

    if (pattern === '') {
      const selectedWidgets = childWidgets.filter(widget => nodeInfoMap[widget.id]?.selected);

      const nodeInfo = nodeInfoMap[node.id];

      if (nodeInfo) {
        if (node.type === NodeType.Heading) {
          if (!nodeInfo.folded && nodeInfo.foldable) {
            nodeInfo.folded = false;
            nodeInfo.foldable = childWidgets.length > 0;
          }
        } else {
          nodeInfo.foldable = childWidgets.length > 0;
          nodeInfo.folded = selectedWidgets.length === 0;
        }
      }
    } else {
      const filteredWidgets = childWidgets.filter(widget => StringUtils.containsIgnoreCase(widget.name, pattern));
      const hasMatchingWidgets = filteredWidgets.length > 0;

      if (StringUtils.containsIgnoreCase(node.name, pattern) || hasMatchingWidgets) {
        const nodeInfo = nodeInfoMap[node.id];
        nodeInfo.foldable = childWidgets.length > 0;
        nodeInfo.folded = !hasMatchingWidgets;
      }
    }
  }

  return nodeInfoMap;
};

const createNodeInfoMap = (nodesList: Node[]): NodeInfoMap => {
  const result: NodeInfoMap = {};

  nodesList.forEach(node => {
    result[node.id] = {
      selected: false,
      foldable: false,
      folded: false,
    };
  });
  return result;
};

export const SelectorHelper = {
  EMPTY_LABEL,
  areAllChildrenSelected,
  areAllNodesSelected,
  changeSelection,
  createNodeInfoMap,
  createNodes,
  findChildren,
  findNodes,
  findNodesAndUpdateNodeInfoMap,
  getIconForWidget,
  getLabelForWidget,
  getSelection,
  selectAll,
  selectNodesByIds,
  stripHtml,
  taskTemplateToNode,
  widgetToNode,
  updateNodeInfoMap,
  getLevel,
};

import * as React from 'react';
import { Box, VStack, Text, BoxProps, useBreakpointValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Button, Icon } from 'app/components/design/next';
import { RightSidebarButton } from './right-sidebar-button';
import { FormResponseMachineHooks } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-hooks';
import { FormResponsePageProviders } from '../../providers';
import { useNavigate } from '@process-street/adapters/navigation';
import { queryString } from '@process-street/subgrade/util';
import { TaskMachineHooks } from 'app/pages/responses/_id/components/task/task-machine-hooks';
import { RightSidebarMenuItems } from './menu-items';

const MotionBox = motion<BoxProps>(Box);

const DRAWER_WIDTH = 232;

export const RightSidebarContent = () => {
  const actorRef = FormResponsePageProviders.FormResponseActorRef.useActorRef();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' });
  const machineApi = FormResponseMachineHooks.useApi(actorRef);
  const isChecklistComplete = FormResponseMachineHooks.useIsComplete();
  const isCompletingRun = FormResponseMachineHooks.useIsCompletingRun();
  const isReactivatingRun = FormResponseMachineHooks.useIsReactivatingRun();
  const template = FormResponseMachineHooks.useTemplate();
  const templateRevision = FormResponseMachineHooks.useTemplateRevision();
  const navigate = useNavigate();
  const currentTaskActorRef = FormResponseMachineHooks.useCurrentTaskActorRef();
  const currentTask = TaskMachineHooks.useTask(currentTaskActorRef);
  const checklist = FormResponseMachineHooks.useChecklist();

  const handleCompleteRun = () => {
    machineApi.onCompleteRun();
  };

  const handleUncompleteRun = () => {
    machineApi.onUncompleteRun();
  };

  const handleViewWorkflowRuns = () => {
    navigate({
      pathname: 'reports',
      search: queryString.stringify({
        templateId: template.id,
      }),
    });
  };

  const handleActivityClick = () => {
    navigate({
      pathname: 'checklistManage.tab',
      search: queryString.stringify({
        id: checklist.id,
        tab: 'activity',
      }),
    });
  };

  const handlePrintClick = () => {
    navigate({
      pathname: 'checklistPrintNew',
      search: queryString.stringify({
        id: checklist.id,
      }),
    });
  };

  return (
    <MotionBox
      position="fixed"
      bg="white"
      h="full"
      borderLeft="1px"
      borderColor="gray.200"
      w={`${DRAWER_WIDTH}px`}
      initial={{ x: isMobile ? 0 : DRAWER_WIDTH }}
      animate={{ x: isMobile ? -DRAWER_WIDTH : 0 }}
      exit={{ x: isMobile ? 0 : DRAWER_WIDTH }}
    >
      <VStack height="full" w="full" p="4" spacing={4}>
        {isChecklistComplete ? (
          <Button
            justifyContent="flex-start"
            iconSpacing={2}
            leftIcon={<Icon size="4" icon="undo" />}
            w="full"
            onClick={handleUncompleteRun}
            isLoading={isReactivatingRun}
            isDisabled={isReactivatingRun}
            loadingText="Reactivating"
          >
            Reactivate
          </Button>
        ) : (
          <Button
            justifyContent="flex-start"
            iconSpacing={2}
            leftIcon={<Icon size="4" icon="check" />}
            w="full"
            onClick={handleCompleteRun}
            isLoading={isCompletingRun}
            isDisabled={isCompletingRun}
            loadingText="Completing run"
          >
            Complete Run
          </Button>
        )}

        {!isChecklistComplete && (
          <VStack w="full" spacing="2" alignItems="stretch">
            <Text color="gray.400" variant="-1u">
              Options
            </Text>

            <VStack w="full" spacing={0}>
              <RightSidebarButton iconName="refresh">Update to Latest</RightSidebarButton>

              <RightSidebarMenuItems.EditWorkflowButton template={template} currentTask={currentTask} />

              <RightSidebarButton iconName="th-list" onClick={handleViewWorkflowRuns}>
                View Workflow Runs
              </RightSidebarButton>
              <RightSidebarButton iconName="history" onClick={handleActivityClick}>
                Activity
              </RightSidebarButton>
              <RightSidebarButton iconName="comment">Comments</RightSidebarButton>
              <RightSidebarButton iconName="envelope">Subscribe</RightSidebarButton>
              <RightSidebarButton iconName="print" onClick={handlePrintClick}>
                Print
              </RightSidebarButton>
              <RightSidebarMenuItems.PrintConditionalLogicButton templateRevision={templateRevision} />
              <RightSidebarButton iconName="archive">Archive</RightSidebarButton>
              <RightSidebarButton iconName="trash">Delete</RightSidebarButton>
            </VStack>
          </VStack>
        )}
      </VStack>
    </MotionBox>
  );
};

import * as React from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from 'components/design/next';
import { DatasetModalOption } from 'pages/data-sets/components/create-data-set-modal/dataset-modal-option';
import { GetAllDataSetsQuery, useCreateDataSetMutation } from 'pages/reports/data-sets/query-builder';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { queryClient } from 'components/react-root';
import { DatasetCreateFromCsvModal } from 'pages/reports/data-sets/components/data-set-creation-modal';
import { useNavigateToDataSet } from '../../hooks';

export type CreateDataSetModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onGenerateWithAi: () => void;
};

export const CreateDataSetModal: React.FC<CreateDataSetModalProps> = ({ isOpen, onClose, onGenerateWithAi }) => {
  const toast = useToast();
  const importFromCsvDisclosure = useDisclosure();
  const navigateToDataSet = useNavigateToDataSet();

  const createDataSetMutation = useCreateDataSetMutation({
    onSuccess: async dataSet => {
      await queryClient.invalidateQueries(GetAllDataSetsQuery.key);

      toast({
        status: 'success',
        title: 'Data set created',
      });

      navigateToDataSet(dataSet, onClose);
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems creating the data set",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  return (
    <>
      <DatasetCreateFromCsvModal
        {...importFromCsvDisclosure}
        onCreateSuccess={dataSet => navigateToDataSet(dataSet, onClose)}
      />

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="18px" color="gray.700" py="6" px="8">
            Create Data Set
          </ModalHeader>

          <ModalCloseButton right="7" top="6" />
          <ModalBody mx={8} pt="6" px={{ base: '9', xs: '4', md: '9' }} pb="10">
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              flexWrap={{ base: 'wrap', md: 'nowrap' }}
              alignItems={{ base: 'center', sm: 'stretch' }}
              justifyContent="center"
              gap={{ base: '6', sm: '2', md: '3' }}
              spacing="0"
            >
              <DatasetModalOption
                title="Blank Data Set"
                description="Create a Data Set from scratch."
                imageSrc={require('app/images/data-sets/new-item.png')}
                onClick={() => createDataSetMutation.mutate({})}
                isDisabled={createDataSetMutation.isLoading}
                stackProps={{
                  maxW: 'unset',
                }}
              />
              <DatasetModalOption
                title="Import Data Set"
                description="Create a Data Set from a CSV file."
                imageSrc={require('app/images/data-sets/import-item.png')}
                onClick={() => {
                  importFromCsvDisclosure.onOpen();
                }}
                stackProps={{
                  maxW: 'unset',
                }}
              />
              <DatasetModalOption
                title="Generate with AI"
                description="Create common Data Sets with countries, industries..."
                imageSrc={require('app/images/data-sets/sparkles.png')}
                onClick={onGenerateWithAi}
                isDisabled={createDataSetMutation.isLoading}
                stackProps={{
                  maxW: 'unset',
                }}
              />
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

import * as React from 'react';
import {
  Box,
  Button,
  Text,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverCloseButton,
  PopoverBody,
  VStack,
  Portal,
  PopoverProps,
} from 'components/design/next';
import { UploadTemplateCoverImage } from 'features/cover-image/components/common/upload';
import { useStateParam } from 'hooks/use-state-param';
import { useQueryClient } from 'react-query';
import { CoverImageByTemplateIdQuery } from 'features/cover-image/query-builder';
import { MAX_FILE_SIZE } from 'features/cover-image/use-upload-cover-image';
import prettyBytes from 'pretty-bytes';

type AddBackgroundImagePopoverProps = PopoverProps & { containerRef: React.RefObject<HTMLDivElement> };

export const AddBackgroundImagePopover: React.FC<React.PropsWithChildren<AddBackgroundImagePopoverProps>> = ({
  containerRef,
  ...props
}) => {
  const templateId = useStateParam({ key: 'id' })!;
  const queryClient = useQueryClient();
  const onUploadSuccess = async () => {
    await queryClient.invalidateQueries(CoverImageByTemplateIdQuery.getKey({ templateId }));
    props.onClose?.();
  };
  return (
    <Popover closeOnBlur={false} {...props}>
      <PopoverTrigger>
        <Button variant="tertiary" leftIcon={<Icon icon="arrow-up-from-line" size="4" variant="far" />}>
          Add
        </Button>
      </PopoverTrigger>
      <Portal appendToParentPortal>
        <Box
          // hack because drawer displays _over_ the popover
          sx={{
            '& .chakra-popover__popper': {
              zIndex: 'popover',
            },
          }}
        >
          <PopoverContent width="md">
            <PopoverCloseButton />
            <PopoverBody>
              <UploadTemplateCoverImage
                templateId={templateId}
                uploadingMessage="Uploading image..."
                acceptMimeTypes={{ 'image/*': [] }}
                onFinish={onUploadSuccess}
              >
                <VStack spacing={3}>
                  <Button>Choose image</Button>
                  <VStack>
                    <Text align="center" variant="-1" color="gray.500" fontWeight="normal">
                      Images wider than 1920px work best
                    </Text>
                    <Text variant="-1" color="gray.500" fontWeight="normal">
                      Maximum file size {prettyBytes(MAX_FILE_SIZE)}
                    </Text>
                  </VStack>
                </VStack>
              </UploadTemplateCoverImage>
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  );
};

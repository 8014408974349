import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useActor } from '@xstate/react';
import { TableFormFieldActor } from './table-form-field-machine';
import { Box, BoxProps, ListItem, UnorderedList, VStack } from 'components/design/next';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { FormFieldLabel } from '../common/form-field-label';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { FormFieldRecentlyMovedIndicator } from '../common/form-field-recently-moved-indicator';
import { ColDef, GetMainMenuItemsParams, MenuItemDef } from '@ag-grid-community/core';
import '@ag-grid-community/styles/ag-grid-no-native-widgets.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import 'app/styles/ag-grid/ag-theme-standard.scss';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { WidgetListItemDragIcon } from 'pages/forms/_id/edit/components/widgets-list/widget-list-item-drag-icon';
import { FormsWidgetMenu, FormsWidgetMenuItems } from 'pages/forms/_id/edit/components';
import { useAgGridColDefs } from 'pages/forms/_id/shared/hooks/use-ag-grid-col-defs';
import { TableFieldValue } from '@process-street/subgrade/process/field-values/table-field-value';
import { MuidUtils } from '@process-street/subgrade/core';
import { TemplateType } from '@process-street/subgrade/process';
import { WorkflowFormFieldLabel } from 'pages/workflows/_id/edit/components/common/workflow-form-field-label';
import debounce from 'lodash/debounce';
import { AddColumnHeader } from 'pages/forms/_id/edit/components/form-fields/table-form-field/add-column-header';
import {
  ColumnHeader,
  ColumnHeaderProps,
} from 'pages/forms/_id/edit/components/form-fields/table-form-field/column-header';
import { ViewModeInteractionWrapper } from '../../view-mode-interaction-wrapper/view-mode-interaction-wrapper';
import { tableFormSettingsSchema } from 'pages/forms/_id/edit/components/form-fields/table-form-field/table-form-field-schema';
import { SettingsModalHeader } from 'pages/forms/_id/edit/components/form-fields/common/settings/settings-modal-content';
import { StringUtils } from '@process-street/subgrade/util';
import { SettingsModalFields } from 'pages/forms/_id/edit/components/form-fields/common/settings/fields';
import Papa from 'papaparse';
import { useColumnHeaderStore } from 'app/pages/reports/data-sets/components/data-set-page/data-set-table/column-header-store';
import { match } from 'ts-pattern';
import { MergeTagTarget } from '@process-street/subgrade/form';

const EXAMPLE_WF_TEXT_CONTENT = 'Data will be typed here…';

export type TableFormFieldWidgetProps = {
  actor: TableFormFieldActor;
  /** True in WF view mode, false otherwise. */
  isDisabled?: boolean;
  isFirst: boolean;
  isLast: boolean;
  templateType?: TemplateType;
};

const LAST_COLUMN_ID = 'lastColumnId';

export const TableFormFieldWidget: React.FC<React.PropsWithChildren<TableFormFieldWidgetProps>> = ({
  actor,
  isDisabled,
  isFirst,
  isLast,
  templateType,
}) => {
  const [state, send] = useActor(actor);
  const { widget, template, labelActor, recentlyMovedFrom, isReadOnly } = state.context;
  const { columnDefs } = widget.config;
  const gridRef = React.useRef<AgGridReact>(null);

  const isEditing = !isDisabled && !isReadOnly;
  const setEditingColumnId = useColumnHeaderStore(store => store.setEditingColumnId);

  React.useEffect(
    function startEditingNewColumn() {
      return actor.subscribe(state => {
        const updatedColumnDefs = state.context.widget.config.columnDefs;
        const newColumnIndex = match(state.event)
          .with({ type: 'INSERT_COLUMN_RIGHT' }, event => {
            const eventColumnIndex = updatedColumnDefs.findIndex(def => def.id === event.id);
            if (eventColumnIndex === -1) return null;
            return eventColumnIndex + 1;
          })
          .with({ type: 'INSERT_COLUMN_LEFT' }, event => {
            const eventColumnIndex = updatedColumnDefs.findIndex(def => def.id === event.id);
            if (eventColumnIndex === -1) return null;
            return eventColumnIndex - 1;
          })
          .otherwise(() => null);
        if (!newColumnIndex) return;

        const newColumnId = updatedColumnDefs[newColumnIndex]?.id;
        if (!newColumnId) return;

        setTimeout(() => gridRef.current?.api.ensureColumnVisible(newColumnId, 'end'), 0);
        setEditingColumnId(newColumnId);
      }).unsubscribe;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- on columns change
    [actor, setEditingColumnId],
  );

  const addColumnDef: ColDef = {
    colId: LAST_COLUMN_ID,
    field: LAST_COLUMN_ID,
    headerName: '',
    headerComponent: AddColumnHeader,
    headerComponentParams: {
      onAddColumn: () => send({ type: 'INSERT_COLUMN_RIGHT', id: columnDefs[columnDefs.length - 1].id }),
    },
    minWidth: 60,
    width: 60,
    suppressMovable: true,
    suppressHeaderMenuButton: true,
    resizable: false,
    sortable: false,
    pinned: 'right',
  };

  const colDefs = useAgGridColDefs<TableFieldValue.Row>(columnDefs, {
    editable: false,
    suppressHeaderMenuButton: isDisabled,
    sortable: false,
    flex: 1,
    minWidth: 200,
    ...(isEditing
      ? {
          headerComponent: ColumnHeader,
          headerComponentParams: {
            onColumnRename: React.useCallback(
              (id: string, columnName: string) => {
                send({ type: 'UPDATE_COLUMN_NAME', id, columnName });
              },
              [send],
            ),
          } as ColumnHeaderProps,
        }
      : {}),
  }).concat(isEditing ? [addColumnDef] : []);

  const [sampleRowData, setSampleRowData] = React.useState<TableFieldValue.Row[]>([]);
  React.useEffect(
    function getSampleData() {
      const sampleData: TableFieldValue.Row[] = [];
      if (StringUtils.isBlank(widget.config.defaultValue)) {
        const [sampleColDef] = columnDefs;
        const sampleColData: TableFieldValue.Cell = { value: EXAMPLE_WF_TEXT_CONTENT };
        const cells = { [sampleColDef.id]: sampleColData };
        sampleData.push({ id: MuidUtils.randomMuid(), cells });
      } else {
        const defaultValue = widget.config.defaultValue!;
        Papa.parse<string[]>(defaultValue, { header: false }).data.forEach(row => {
          const cells: Record<string, TableFieldValue.Cell> = columnDefs.reduce<Record<string, TableFieldValue.Cell>>(
            (acc, colDef, index) => {
              acc[colDef.id] = { value: row[index] };
              return acc;
            },
            {},
          );

          sampleData.push({ id: MuidUtils.randomMuid(), cells });
        });
      }
      setSampleRowData(sampleData);
    },
    [columnDefs, widget.config.defaultValue],
  );

  const getMainMenuItems = useCallback(
    (params: GetMainMenuItemsParams): (string | MenuItemDef)[] => {
      const columnId = params.column.getColId();
      return [
        {
          icon: '←',
          name: 'Insert Left',
          action: () => {
            send({ type: 'INSERT_COLUMN_LEFT', id: columnId });
          },
        },
        {
          icon: '→',
          name: 'Insert Right',
          action: () => {
            send({ type: 'INSERT_COLUMN_RIGHT', id: columnId });
          },
        },
        'separator',
        {
          name: 'Delete',
          disabled: params.api.getColumnDefs()?.length === 2, // count the pinned right column (+)
          action: () => {
            send({ type: 'DELETE_COLUMN', id: columnId });
          },
        },
      ];
    },
    [send],
  );

  const reorderColumns = useMemo(
    // cut "add column" button
    () => debounce((colDefs: ColDef[]) => send({ type: 'REORDER_COLUMNS', colDefs: colDefs.slice(0, -1) }), 500),
    [send],
  );

  const readOnlyGridProps: Partial<AgGridReactProps> = useMemo((): Partial<AgGridReactProps> => {
    if (isEditing) return {};
    return {
      readOnlyEdit: true,
      getMainMenuItems: () => [],
      onColumnMoved: () => {},
    };
  }, [isEditing]);

  const readOnlyContainerProps = useMemo((): Partial<BoxProps> => {
    if (isEditing) return {};

    return {
      pointerEvents: 'none',
      sx: {
        '.ag-cell-value': { color: 'gray.400' },
        '.ag-header-icon.ag-header-cell-menu-button, .ag-header-cell-menu-button': {
          display: 'none',
        },
      },
    };
  }, [isEditing]);

  const grid = (
    <Box
      width="full"
      sx={{
        '.ag-theme-quartz': {
          '.ag-cell-value': { color: 'gray.400' },
          // fixes pinned hover button column borders
          '--ag-borders-critical': 'none',
        },
      }}
    >
      <Box
        height="300"
        className="ag-theme-quartz"
        ref={node => {
          if (node && !state.context.inputNode) {
            send({ type: 'SET_NODE', node });
          }
        }}
        scrollMarginBottom={17}
        {...readOnlyContainerProps}
      >
        <AgGridReact<TableFieldValue.Row>
          ref={gridRef}
          modules={[ClientSideRowModelModule, MenuModule]}
          overlayNoRowsTemplate={'No rows to display.'}
          rowData={sampleRowData}
          columnDefs={colDefs}
          getMainMenuItems={getMainMenuItems}
          onColumnMoved={e => {
            const colDefs = e.api.getColumnDefs();
            if (colDefs) {
              reorderColumns(colDefs);
            }
          }}
          enableCellTextSelection={true}
          suppressMovableColumns={isDisabled}
          suppressDragLeaveHidesColumns={true}
          suppressMenuHide={true}
          icons={{ menu: '⋯' }}
          {...readOnlyGridProps}
        />
      </Box>
    </Box>
  );

  switch (templateType) {
    case TemplateType.Playbook:
      return (
        <WidgetActorProvider widgetActorRef={actor}>
          <VStack w="full" alignItems="flex-start" flex="1" opacity={isDisabled ? 0.8 : undefined}>
            {labelActor && isDisabled ? (
              <WorkflowFormFieldLabel isRequired={widget.required}>
                {widget.label || 'Untitled Table'}
              </WorkflowFormFieldLabel>
            ) : (
              <FormFieldLabel isReadOnly={!isEditing} actor={labelActor} />
            )}
            <ViewModeInteractionWrapper templateId={template.id}>{grid}</ViewModeInteractionWrapper>
          </VStack>
        </WidgetActorProvider>
      );
    case TemplateType.Form:
    default:
      return (
        <WidgetActorProvider widgetActorRef={actor}>
          <FormsWidgetMenuContainer>
            <VStack alignItems="flex-start" flex="1">
              {recentlyMovedFrom && <FormFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
              {labelActor && <FormFieldLabel isReadOnly={!isEditing} actor={labelActor} />}

              {isEditing && <WidgetListItemDragIcon />}

              <ViewModeInteractionWrapper templateId={template.id}>{grid}</ViewModeInteractionWrapper>

              {isEditing && (
                <FormsWidgetMenu>
                  <FormsWidgetMenuItems.Required widget={widget} />
                  <FormsWidgetMenuItems.Settings widget={widget} schema={tableFormSettingsSchema}>
                    <SettingsModalHeader>
                      {StringUtils.getNonEmpty(widget?.label, 'Untitled table field')}
                    </SettingsModalHeader>
                    <SettingsModalFields.DefaultValue
                      templateRevisionId={widget.templateRevision.id}
                      as="textarea"
                      minHeight={20}
                      mergeTagTarget={MergeTagTarget.RICH_CONTENT}
                    />
                    <UnorderedList fontSize="sm" fontWeight={500} color="gray.400" alignSelf="baseline">
                      <ListItem>Type each row of values as a separate line.</ListItem>
                      <ListItem>Separate each value with a comma.</ListItem>
                      <ListItem>No spaces before or after the commas.</ListItem>
                      <ListItem>Make sure each line has the same number of values.</ListItem>
                    </UnorderedList>
                  </FormsWidgetMenuItems.Settings>
                  <FormsWidgetMenuItems.ConditionalLogic
                    widget={widget}
                    templateRevisionId={widget.templateRevision.id}
                    templateType={template.templateType}
                  />
                  <FormsWidgetMenuItems.Divider />
                  <FormsWidgetMenuItems.Duplicate />
                  <FormsWidgetMenuItems.MoveToStep widget={widget} />
                  <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
                  <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
                  <FormsWidgetMenuItems.Delete />
                </FormsWidgetMenu>
              )}
            </VStack>
          </FormsWidgetMenuContainer>
        </WidgetActorProvider>
      );
  }
};

import * as React from 'react';
import { Box, useDisclosure, useBreakpointValue } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { RightSidebarTrigger } from './right-sidebar-trigger';
import { RightSidebarContent } from './right-sidebar-content';

type RightSidebarProps = {};

export const RightSidebar: React.FC<RightSidebarProps> = () => {
  const disclosure = useDisclosure({
    defaultIsOpen: false,
  });

  const isMobile = useBreakpointValue({ base: true, md: false });

  React.useEffect(
    () => {
      if (isMobile) {
        disclosure.onClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMobile],
  );

  return (
    <Box position="relative" h="full">
      <RightSidebarTrigger isOpen={disclosure.isOpen} onClick={disclosure.onToggle} />

      <AnimatePresence initial={false}>{disclosure.isOpen && <RightSidebarContent />}</AnimatePresence>
    </Box>
  );
};

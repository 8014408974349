import * as React from 'react';
import { HStack } from 'components/design/next';
import {
  isHeading,
  TaskTemplate,
  Template,
  TemplateRevision,
  TemplateRevisionStatus,
  TemplateStatus,
} from '@process-street/subgrade/process';

import { useWorkflowState } from 'app/components/focus-bar/workflow/use-workflow-state';
import { AssignTaskButton } from 'app/pages/templates/_id/components/assign-task-button';
import { useGetConsolidatedTemplatePermissionsQuery } from 'app/features/permissions/query-builder';
import { useCurrentUser } from 'app/hooks/use-current-user';
import { useSelectedOrganization } from 'app/hooks/use-selected-organization';
import { canAccess, Feature } from 'app/services/features/features';
import { TaskActionsDueDatePicker } from './task-actions-due-date-picker-button/';
import { useSharedContext } from 'app/pages/forms/_id/shared';
import { TaskPermissionsPicker } from 'app/components/task-permission/components/task-permissions-picker';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useSelector } from '@xstate/react';
import { StopTaskButton } from 'app/pages/workflows/_id/edit-v2/components/task-actions-bar/stop-task-button';
import { TaskActionsAutomationsButton } from './task-actions-automations-button';
import { useSolutionTypeTagInstances } from 'app/pages/templates/_id/automation/components/selector/use-solution-type-tag-instances';
import { TaskActionsConditionalLogicButton } from './task-actions-conditional-logic-button';
import { ConditionalLogicCommonUtils } from '@process-street/subgrade/conditional-logic';
import { TaskActionsAutomationIndicator } from './task-actions-automations-indicator';
import _isEqual from 'lodash/isEqual';
import { AutomationInstanceUtils } from '@process-street/subgrade/automation';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';

export type TaskActionsBarProps = {
  taskTemplate: TaskTemplate;
  template: Template;
  templateRevision: TemplateRevision;
};

export const TASK_ACTION_BAR_HEIGHT_OFFSET = '-42px';

export const TaskActionsBar = ({ taskTemplate, template, templateRevision }: TaskActionsBarProps) => {
  const { queryClient } = useSharedContext();
  const isEditable = useWorkflowState() === 'edit';
  const currentUser = useCurrentUser();

  const actor = useFormEditorPageActorRef();
  const checklistRules = useSelector(actor, FormEditorPageActorSelectors.getRules, _isEqual);

  const widgetsSelector = React.useMemo(() => {
    return FormEditorPageActorSelectors.getWidgetsForTaskTemplateId(taskTemplate.id);
  }, [taskTemplate.id]);

  const widgets = useSelector(actor, widgetsSelector, _isEqual);

  const permissionsQuery = useGetConsolidatedTemplatePermissionsQuery(template?.id);
  const selectedOrganization = useSelectedOrganization();

  const canAccessDynamicDueDates = React.useMemo(() => {
    const planId = selectedOrganization?.subscription.plan.id;
    return currentUser && planId && canAccess(Feature.DYNAMIC_DUE_DATES, planId);
  }, [currentUser, selectedOrganization?.subscription.plan.id]);

  const isTaskHeading = taskTemplate ? isHeading(taskTemplate) : false;

  const {
    instances: { ps_when_task_checked_then },
  } = useSolutionTypeTagInstances({ templateId: template.id });

  const activeTaskAutomations = ps_when_task_checked_then.filter(i =>
    AutomationInstanceUtils.isEnabledTaskInstance(i, taskTemplate),
  );

  const hasAutomations = activeTaskAutomations.length > 0;

  const isTaskTemplateAssociatedWithRules = React.useMemo(
    () => ConditionalLogicCommonUtils.makeTaskTemplateHasAssociatedRule(checklistRules),
    [checklistRules],
  );
  const canManageTasks =
    templateRevision?.status === TemplateRevisionStatus.Draft &&
    template?.status === TemplateStatus.Active &&
    permissionsQuery.data?.permissionMap.templateUpdate;

  return (
    <HStack
      spacing="3"
      w="full"
      position="sticky"
      top={TASK_ACTION_BAR_HEIGHT_OFFSET}
      bg="white"
      zIndex="modal"
      wrap="wrap"
      _empty={{ display: 'none' }}
    >
      {!isTaskHeading && (
        <AssignTaskButton
          taskTemplate={taskTemplate}
          templateRevision={templateRevision}
          isDisabled={!canManageTasks}
          isEditable={isEditable}
        />
      )}
      {canAccessDynamicDueDates && !isTaskHeading && (
        <TaskActionsDueDatePicker
          selectedTaskTemplate={taskTemplate}
          selectedTaskTemplates={[]}
          editable={isEditable}
          templateRevision={templateRevision}
          disabled={!canManageTasks}
          queryClient={queryClient}
        />
      )}
      {isEditable && (
        <TaskPermissionsPicker
          taskTemplates={[taskTemplate]}
          templateRevisionId={templateRevision.id}
          isDisabled={!canManageTasks}
        />
      )}
      <TaskActionsConditionalLogicButton
        taskTemplate={taskTemplate}
        templateRevision={templateRevision}
        hasConditionalLogic={isTaskTemplateAssociatedWithRules({
          widgets,
          taskTemplate,
        })}
        isEditable={isEditable}
      />
      {!isTaskHeading && hasAutomations && (
        <TaskActionsAutomationIndicator
          templateRevision={templateRevision}
          taskTemplate={taskTemplate}
          isEditable={isEditable}
          templateId={template.id}
        />
      )}
      {!isTaskHeading && !hasAutomations && isEditable && (
        <TaskActionsAutomationsButton
          isEditable={isEditable}
          iconButtonProps={{
            borderWidth: 'px',
            borderColor: 'gray.300',
            px: 1,
          }}
        />
      )}
      {!isTaskHeading && <StopTaskButton isEditable={isEditable} taskTemplateGroupId={taskTemplate.group.id} />}
    </HStack>
  );
};

import * as React from 'react';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from 'components/design/next';
import { useActor, useSelector } from '@xstate/react';
import { FileFormFieldActor } from './file-form-field-machine';
import { match, P } from 'ts-pattern';
import { useDropzone } from 'react-dropzone';
import { DeleteConfirmationModal } from './delete-confirmation-modal';
import { ValidationSelectors } from '../validation-machine';
import {
  FileUpload,
  FileUploadLeftElement,
  FileUploadRightElement,
  FileUploadRightElementInfo,
  FileUploadSizeLabel,
  DownloadFileIconButton,
} from 'features/files';
import { FormResponseLabel } from '../common';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';

export interface FileFormFieldProps {
  actor: FileFormFieldActor;
}

export const FileFormField: React.FC<React.PropsWithChildren<FileFormFieldProps>> = ({ actor }) => {
  const [state, send] = useActor(actor);
  const { widget } = state.context;
  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);

  const onFocus = () => {
    send({ type: 'FOCUS' });
  };

  const onBlur = () => {
    send({ type: 'BLUR' });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [file] = e.target.files ?? [];

    if (file) {
      send({ type: 'CHANGE', value: file });
    } else {
      onBlur();
    }
  };

  const ref = React.useRef<HTMLDivElement | null>(null);

  const dropzoneState = useDropzone({
    onDragEnter: onFocus,
    onDragLeave: onBlur,
    onDrop: files => send({ type: 'CHANGE', value: files[0] }),
    disabled: state.matches('input.disabled'),
  });

  const isInvalid = ValidationSelectors.isActorInvalidVisible(state.context.validationActor);
  const dropzoneRootProps = dropzoneState.getRootProps();

  return isHiddenByRule ? null : (
    <>
      <DeleteConfirmationModal
        isOpen={state.matches('input.enabled.deleteConfirmation')}
        onConfirm={() => send('CONFIRM_DELETE')}
        onCancel={() => send('CANCEL_DELETE')}
      />

      <FormControl
        ref={node => {
          ref.current = node;
          if (node && !state.context.inputNode) {
            actor.send({ type: 'SET_NODE', node });
          }
        }}
        w="full"
        as={VStack}
        alignItems="stretch"
        isInvalid={isInvalid}
        isRequired={widget.required}
      >
        <FormResponseLabel onClick={onFocus}>{widget.label || 'Untitled File'}</FormResponseLabel>

        <Box {...dropzoneRootProps}>
          <FileUpload
            {...(isInvalid ? { boxShadow: '0 0 0 1px var(--ps-colors-red-500)' } : {})}
            cursor={!state.context.formFieldValue ? 'pointer' : undefined}
            onClick={_e => {
              onFocus();
            }}
            tabIndex={-1}
          >
            <FileUploadLeftElement
              {...(isInvalid ? { border: '1px solid', borderColor: 'red.500', borderRight: 'none' } : {})}
            />

            <FileUploadRightElement
              {...(isInvalid
                ? { border: '1px solid', borderColor: 'red.500' }
                : {
                    borderWidth: 'px',
                    borderStyle: dropzoneState.isDragActive ? 'dashed' : 'solid',
                    borderColor: dropzoneState.isDragActive ? 'brand.400' : 'gray.200',
                  })}
              backgroundColor={dropzoneState.isDragActive ? 'brand.50' : undefined}
            >
              <FileUploadRightElementInfo>
                {match({
                  name: state.context.file?.name ?? state.context.formFieldValue?.fieldValue?.name,
                  size: state.context.file?.size ?? state.context.formFieldValue?.fieldValue?.size,
                  isDragActive: dropzoneState.isDragActive,
                })
                  .with({ isDragActive: true }, () => (
                    <Text fontWeight="500" color="gray.600">
                      Drop the file here
                    </Text>
                  ))
                  .with({ name: P.not(undefined) }, ({ name, size }) => {
                    return (
                      <>
                        <Tooltip label={name}>
                          <Text color="gray.700" fontSize="sm" maxW="50" w="full" noOfLines={1}>
                            {name}
                          </Text>
                        </Tooltip>

                        {size && <FileUploadSizeLabel>{size}</FileUploadSizeLabel>}
                      </>
                    );
                  })
                  .otherwise(() => (
                    <>
                      <Text fontWeight="500" color="gray.600">
                        Upload File
                      </Text>

                      <Text fontWeight="500" fontSize="sm" color="gray.400">
                        Select or drop a file.
                      </Text>
                    </>
                  ))}
              </FileUploadRightElementInfo>

              <HStack>
                {match({
                  isLoading: !state.matches('mutation.idle'),
                  formFieldValue: state.context.formFieldValue,
                })
                  .with({ isLoading: true }, () => <Spinner />)
                  .with({ formFieldValue: { fieldValue: { url: P.not(undefined) } } }, ({ formFieldValue }) => (
                    <HStack spacing="1">
                      <Tooltip label="Download file" shouldWrapChildren>
                        <DownloadFileIconButton href={formFieldValue.fieldValue.url} />
                      </Tooltip>

                      <Tooltip label="Remove file">
                        <IconButton
                          aria-label="Remove file"
                          size="sm"
                          variant="ghost"
                          borderRadius="full"
                          colorScheme="gray"
                          onClick={() => send('PROMPT_DELETE')}
                          isDisabled={state.matches('input.disabled')}
                        >
                          <Icon icon="trash-alt" size="4" color="gray.600" />
                        </IconButton>
                      </Tooltip>
                    </HStack>
                  ))
                  .otherwise(() => (
                    <Tooltip label="Upload file">
                      <IconButton
                        size="sm"
                        aria-label="Upload file"
                        variant="ghost"
                        borderRadius="full"
                        colorScheme="gray"
                        isDisabled={state.matches('input.disabled')}
                      >
                        <Icon icon="arrow-up-to-line" color="gray.600" size="4" />
                      </IconButton>
                    </Tooltip>
                  ))}
              </HStack>
            </FileUploadRightElement>
          </FileUpload>
        </Box>

        {!state.context.formFieldValue ? (
          <Box
            sx={{
              '> input': {
                w: 0,
                h: 0,
                visibility: 'hidden',
              },
            }}
          >
            <input type="file" onChange={onChange} {...dropzoneState.getInputProps()} />
          </Box>
        ) : (
          <Spacer />
        )}
        <FormErrorMessage>{ValidationSelectors.errorMessage(state.context.validationActor)}</FormErrorMessage>
        {widget.config.placeholder ? <FormHelperText>{widget.config.placeholder}</FormHelperText> : null}
      </FormControl>
    </>
  );
};

import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { useToken } from '@chakra-ui/react';

export const useCanViewDataSets = () => {
  const currentUser = useGetCurrentUserInfoQuery();
  // useBreakpointValue, useMediaQuery is unreliable, we use direct media query instead
  const [breakpointMd] = useToken('breakpoints', ['md']);
  const isDesktop = window.matchMedia(`(min-width: ${breakpointMd})`).matches ?? true;

  return (
    isDesktop &&
    currentUser.data?.organizationMembership &&
    (currentUser.data.organizationMembership.role === OrganizationMembershipRole.Admin ||
      currentUser.data.organizationMembership.role === OrganizationMembershipRole.FullMember)
  );
};

import * as React from 'react';
import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from 'components/design/next';
import { UserTypeAheadSelect } from './user-type-ahead-select';
import { PermitRow } from './permit-row';
import { StringUtils } from '@process-street/subgrade/util';
import { PermissionsDescription } from 'components/permissions/permissions-description/component';
import {
  DataSetPermitWithOm,
  isDataSetPermitWithOm,
  isSavedViewPermitWithOm,
  SavedViewPermitWithOm,
} from '@process-street/subgrade/process';
import uniqBy from 'lodash/uniqBy';
import { useSavedViewPermits } from 'pages/reports/data-sets/components/share/use-saved-view-permits';
import { useDataSetPermits } from 'pages/reports/data-sets/components/share/use-data-set-permits';
import { OrganizationMembershipRole, User } from '@process-street/subgrade/core';
import { useGetCurrentUserInfoQuery } from 'app/features/user/query-builder';

export type ShareSavedViewModalProps = {
  dataSetId: string;
  savedViewId: string;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
};

export const ShareSavedViewModal: React.FC<React.PropsWithChildren<ShareSavedViewModalProps>> = ({
  dataSetId,
  isOpen,
  savedViewId,
  onClose,
}) => {
  const [searchQuery, setSearch] = React.useState('');

  const currentUserInfo = useGetCurrentUserInfoQuery();
  const isAdmin = currentUserInfo.data?.organizationMembership.role === OrganizationMembershipRole.Admin;

  const { handleAddSavedViewPermit, handleRemoveSavedViewPermit, savedViewPermitsWithOms } = useSavedViewPermits({
    dataSetId,
    savedViewId,
  });

  const { dataSetPermitsWithOms, handleRemoveDataSetPermit, handleAddDataSetPermit } = useDataSetPermits({
    dataSetId,
  });

  const savedViewPermitOms =
    savedViewPermitsWithOms.map(savedViewPermitWithOm => savedViewPermitWithOm.organizationMembership) ?? [];

  const dataSetPermitOms =
    dataSetPermitsWithOms.map(dataSetPermitWithOm => dataSetPermitWithOm.organizationMembership) ?? [];

  const dataSetPermitOmIds = dataSetPermitOms.map(om => om.id);

  const allPermitOms = uniqBy(savedViewPermitOms.concat(dataSetPermitOms), 'id');
  const allPermitOmIds = allPermitOms.map(om => om.id);

  const visiblePermits = [...dataSetPermitsWithOms, ...savedViewPermitsWithOms]
    .filter(permitWithOm => {
      const { user } = permitWithOm.organizationMembership;
      // Don't display "view" permission if user already has "edit" permission
      if (isSavedViewPermitWithOm(permitWithOm) && dataSetPermitOmIds.includes(permitWithOm.organizationMembership.id))
        return false;
      if (searchQuery === '' && user.id !== currentUserInfo.data?.user.id) return true;
      const inUsername = StringUtils.containsIgnoreCase(user.username, searchQuery);
      const inEmail = StringUtils.containsIgnoreCase(user.email, searchQuery);
      return inUsername || inEmail;
    })
    .sort((a, b) => a.organizationMembership.user.username.localeCompare(b.organizationMembership.user.username))
    .slice(0, 50);

  // Removes data set permit and saved view permits for the user that may not be visible in the list
  const handleRemoveAllPermits = async (permit: DataSetPermitWithOm) => {
    await handleRemoveDataSetPermit(permit);
    const savedViewPermit = savedViewPermitsWithOms.find(
      savedViewPermit => savedViewPermit.organizationMembership.id === permit.organizationMembership.id,
    );
    return savedViewPermit && handleRemoveSavedViewPermit(savedViewPermit);
  };

  // Creates "data set edit" permit for a saved view permit user
  const handleUpgrade = async (permit: SavedViewPermitWithOm) => {
    return handleAddDataSetPermit(permit.organizationMembership);
  };

  // Removes "Data set edit" permit and creates "Saved view view" permit
  const handleDowngrade = async (permit: DataSetPermitWithOm) => {
    await handleAddSavedViewPermit(permit.organizationMembership);
    await handleRemoveDataSetPermit(permit);
  };

  // Disable permit selection for current user if not admin
  const isRowDisabled = (permitUserId: User['id']) => {
    return currentUserInfo.data?.user.id === permitUserId && !isAdmin;
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="2xl">
        <ModalHeader border="0" borderBottomWidth="1px" borderStyle="solid" borderColor="gray.200">
          <HStack spacing="2">
            <Icon icon="share-alt" size="4" color="gray.400" />

            <Text size="xl" fontWeight="700" color="gray.700">
              Share Saved View
            </Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody pt="6" pb="4">
          <Box m={2}>
            <UserTypeAheadSelect
              organizationMembershipIdsToExclude={allPermitOmIds}
              onChange={handleAddSavedViewPermit}
            />

            <Divider />
          </Box>

          <Box m={2}>
            <HStack justifyContent="space-between" my="4">
              {allPermitOms.length > 0 ? <PermissionsDescription flex="1" memberships={allPermitOms} /> : null}

              {allPermitOmIds.length > 0 ? (
                <Input
                  variant="outline"
                  w="50%"
                  value={searchQuery}
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Filter current users"
                />
              ) : null}
            </HStack>
            <VStack divider={<Divider my="0!important" />} alignItems="stretch" role="list">
              {visiblePermits.map(permit => {
                const isDisabled = isRowDisabled(permit.organizationMembership.user.id);
                return isDataSetPermitWithOm(permit) ? (
                  <PermitRow
                    key={permit.id}
                    isDisabled={isDisabled}
                    permit={permit}
                    handleRemove={() => handleRemoveAllPermits(permit)}
                    handleDowngrade={() => handleDowngrade(permit)}
                  />
                ) : (
                  <PermitRow
                    key={permit.id}
                    isDisabled={isDisabled}
                    permit={permit}
                    handleRemove={() => handleRemoveSavedViewPermit(permit)}
                    handleUpgrade={() => handleUpgrade(permit)}
                  />
                );
              })}
            </VStack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" size="md" onClick={onClose}>
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

import { FieldType, Widget, WidgetType } from '@process-street/subgrade/process';
import sum from 'lodash/sum';
import { match } from 'ts-pattern';

type SizeInPx = number;
// placeholder sizes
const fieldTypeSizeMap: Partial<Record<FieldType, SizeInPx>> = {
  [FieldType.Date]: 70,
  [FieldType.Email]: 70,
  [FieldType.File]: 80,
  [FieldType.Hidden]: 135,
  [FieldType.Members]: 70,
  [FieldType.MultiChoice]: 290,
  [FieldType.MultiSelect]: 190,
  [FieldType.Number]: 70,
  [FieldType.Select]: 290,
  [FieldType.SendRichEmail]: 600,
  [FieldType.Snippet]: 135,
  [FieldType.Table]: 330,
  [FieldType.Text]: 70,
  [FieldType.Textarea]: 109,
  [FieldType.Url]: 70,
};
const widgetSizeMap: Partial<Record<WidgetType, SizeInPx>> = {
  [WidgetType.CrossLink]: 0,
  [WidgetType.Email]: 570,
  [WidgetType.Embed]: 430,
  [WidgetType.File]: 50,
  // images lazy load starting with a height of 0 and a placeholder of height 0 helps prevent flickering
  [WidgetType.Image]: 0,
  [WidgetType.Text]: 40,
  [WidgetType.Video]: 450,
};

/** e.g. a 14px font is on average 7px wide. */
const AVG_CHAR_WIDTH_RATIO = 0.5;
const AVG_LINE_HEIGHT = 1.5;
// Height can only be bigger in practice, due to headings, paragraph spacings etc.
const COMPENSATION_FACTOR = 1.2;
const DEFAULT_WIDTH: SizeInPx = 700;
const FALLBACK_FORM_FIELD_HEIGHT: SizeInPx = 70;
const FALLBACK_WIDGET_HEIGHT: SizeInPx = 120;
const SUBTASK_HEIGHT: SizeInPx = 39;
const SUBTASKS_AFFORDANCE_HEIGHT: SizeInPx = 70;
const TEXT_WIDGET_FONT_SIZE: SizeInPx = 14;

/**
 * Estimate content height without rendering to the DOM,
 * based on font size, character count in each line and widget width.
 * This is only a ballpark estimate,
 * doesn't take HTML tags, headers etc. into account
 */
function estimateTextContentHeight(content: string, width: SizeInPx) {
  // Average character width as a fraction of the font size
  const avgCharWidth = TEXT_WIDGET_FONT_SIZE * AVG_CHAR_WIDTH_RATIO;

  const lineHeight = TEXT_WIDGET_FONT_SIZE * AVG_LINE_HEIGHT;
  const maxLineCharCount = Math.ceil(width / avgCharWidth);

  const wrappedLineCounts = content
    // initial \n-s are only cosmetic in HTML code
    .replace(/\n|<\/p>/, '')
    .replace(/<br>|<p>/, '\n')
    .split('\n')
    // at least 1, but can be more if we estimate it's wrapping
    .map(line => Math.ceil(line.length / maxLineCharCount));

  return Math.max(sum(wrappedLineCounts) * lineHeight * COMPENSATION_FACTOR, widgetSizeMap[WidgetType.Text]!);
}

export function estimateWidgetHeight(widget: Widget, width = DEFAULT_WIDTH) {
  return match(widget)
    .with({ header: { type: WidgetType.Text } }, textWidget =>
      estimateTextContentHeight(textWidget.content ?? '', width),
    )
    .with(
      { fieldType: FieldType.MultiSelect },
      subtasksWidget => subtasksWidget.config.items.length * SUBTASK_HEIGHT + SUBTASKS_AFFORDANCE_HEIGHT,
    )
    .with(
      { header: { type: WidgetType.FormField } },
      formFieldWidget => fieldTypeSizeMap[formFieldWidget.fieldType] ?? FALLBACK_FORM_FIELD_HEIGHT,
    )
    .otherwise(() => widgetSizeMap[widget.header.type] ?? FALLBACK_WIDGET_HEIGHT);
}

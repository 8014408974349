import * as React from 'react';
import { useActor } from '@xstate/react';
import { FileFormFieldActor } from './file-form-field-machine';
import { Text, VStack, HStack } from '@chakra-ui/react';
import { Icon, Box } from 'components/design/next';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { FormFieldLabel } from '../common/form-field-label';
import { WidgetActorProvider } from '../../../../shared/widget-context';
import { SettingsModalHeader } from '../common/settings/settings-modal-content';
import { fileSettingsSchema } from './file-form-field-settings-schema';
import { SettingsModalFields } from '../common/settings/fields';
import { StringUtils } from '@process-street/subgrade/util';
import { TaskTemplate, TemplateType } from '@process-street/subgrade/process';
import { FileUpload, FileUploadLeftElement, FileUploadRightElement, FileUploadRightElementInfo } from 'features/files';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { FormFieldRecentlyMovedIndicator } from '../common/form-field-recently-moved-indicator';
import { ViewModeInteractionWrapper } from '../../view-mode-interaction-wrapper/view-mode-interaction-wrapper';
import { AiTaskTemplateWidgetIndicatorV2 } from 'app/pages/templates/_id/components/ai-task-template-widget-indicator';
import { PreventClickOverlay } from '../common/prevent-click-overlay';

export type FileFormFieldWidgetProps = {
  isFirst: boolean;
  isLast: boolean;
  actor: FileFormFieldActor;
};

export const FileFormFieldWidget: React.FC<React.PropsWithChildren<FileFormFieldWidgetProps>> = ({
  actor,
  isFirst,
  isLast,
}) => {
  const [state, send] = useActor(actor);
  const { widget, template, labelActor, recentlyMovedFrom, isReadOnly } = state.context;
  const ref = React.useRef<HTMLDivElement | null>(null);

  const isWorkflow = template.templateType === TemplateType.Playbook;

  const handleSelectTaskTemplate = (taskTemplate: TaskTemplate) => send({ type: 'SELECT_TASK_TEMPLATE', taskTemplate });

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer>
        <VStack alignItems="flex-start" flex="1" maxW="88">
          {recentlyMovedFrom && <FormFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
          <HStack justifyContent="space-between" w="full">
            {labelActor && <FormFieldLabel isReadOnly={isReadOnly} actor={labelActor} />}
            {isWorkflow && (
              <HStack justifyContent="flex-end" w="auto">
                <AiTaskTemplateWidgetIndicatorV2
                  widget={widget}
                  handleOnSelectTaskTemplate={handleSelectTaskTemplate}
                />
              </HStack>
            )}
          </HStack>
          <Box
            position="relative"
            ref={node => {
              ref.current = node;
              if (node && !state.context.inputNode) {
                send({ type: 'SET_NODE', node });
              }
            }}
            scrollMarginBottom={17}
          >
            <ViewModeInteractionWrapper templateId={template.id}>
              <FileUpload>
                <FileUploadLeftElement bgColor="brand.300" />
                <FileUploadRightElement>
                  <FileUploadRightElementInfo>
                    <Text fontWeight="500" color="gray.400">
                      File will be uploaded here
                    </Text>
                  </FileUploadRightElementInfo>

                  <Icon icon="arrow-up-to-line" color="gray.400" size="4" />
                </FileUploadRightElement>
              </FileUpload>
              {isReadOnly && <PreventClickOverlay />}
            </ViewModeInteractionWrapper>

            {!isReadOnly && (
              <>
                <WidgetListItemDragIcon />
                <FormsWidgetMenu>
                  <FormsWidgetMenuItems.Required widget={widget} />
                  <FormsWidgetMenuItems.Settings widget={widget} schema={fileSettingsSchema}>
                    <SettingsModalHeader>
                      {StringUtils.getNonEmpty(widget.label, 'Untitled file upload field')}
                    </SettingsModalHeader>
                    <SettingsModalFields.Placeholder label="File upload placeholder" />
                  </FormsWidgetMenuItems.Settings>

                  <FormsWidgetMenuItems.Divider />

                  <FormsWidgetMenuItems.Duplicate />
                  <FormsWidgetMenuItems.MoveToStep widget={widget} />
                  <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
                  <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
                  <FormsWidgetMenuItems.Delete />
                </FormsWidgetMenu>
              </>
            )}
          </Box>
        </VStack>
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};

import { Box, Center, Text, VStack } from '@chakra-ui/react';
import { Icon } from 'app/components/design/next';
import { FormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-types';
import pLimit from 'p-limit';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { useFeatureFlag } from 'features/feature-flags';
import { ReorderAutoScrollContext } from 'pages/workflows/_id/edit-v2/providers/reorder-scroll-context';

export type WidgetListDropzoneProps = React.PropsWithChildren<{ actor: FormEditorPageActorRef; isReadonly: boolean }>;

export const WidgetListDropzone = ({ children, actor, isReadonly }: WidgetListDropzoneProps) => {
  const isS3VideoEnabled = useFeatureFlag('s3Video');
  const handleDrop = React.useCallback(
    (files: File[]) => {
      const limit = pLimit(1);

      Array.from(files).forEach(file => {
        void limit(
          () =>
            new Promise(resolve => {
              actor.send({ type: 'PASTE_FILE', file, isS3VideoEnabled });

              setTimeout(() => {
                resolve({});
              }, 500);
            }),
        );
      });
    },
    [actor, isS3VideoEnabled],
  );

  const dropzoneState = useDropzone({
    onDrop: handleDrop,
  });

  if (isReadonly) {
    return <>{children}</>;
  }
  const scrollContainerProps = React.useContext(ReorderAutoScrollContext)?.widgetAutoScroll.scrollContainerProps;

  const [offset, setOffset] = React.useState(0);

  const scrollListener = React.useCallback((evt: Event) => {
    if (evt.target instanceof HTMLElement) {
      setOffset(evt.target.scrollTop);
    }
  }, []);

  React.useEffect(() => {
    // scrollContainerProps.onScroll only allows adding the main scroll listener (for widget drop autoscroll)
    scrollContainerProps?.scrollContainerRef.current?.addEventListener('scroll', scrollListener);
    return () => {
      scrollContainerProps?.scrollContainerRef.current?.removeEventListener('scroll', scrollListener);
    };
  }, [scrollContainerProps, scrollListener]);

  return (
    <Box {...dropzoneState.getRootProps()} onClick={() => {}} w="full">
      <input {...dropzoneState.getInputProps()} />

      {children}

      {dropzoneState.isDragActive && (
        <Center
          position="absolute"
          left="0"
          right="0"
          top={offset}
          bottom={-offset}
          bgColor="rgba(0, 0, 0, 0.3)"
          borderRadius={{ base: '0', lg: 'lg' }}
          zIndex="tooltip"
        >
          <VStack>
            <Icon icon="cloud-arrow-up" size="20" color="white" />
            <Text fontSize="xl" color="white">
              Drop here to upload
            </Text>
            {isS3VideoEnabled && (
              <Text fontSize="md" color="white">
                For creating video embeds, MP4 and WEBM files are supported.
              </Text>
            )}
          </VStack>
        </Center>
      )}
    </Box>
  );
};

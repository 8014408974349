import * as React from 'react';
import { match, P } from 'ts-pattern';
import { useDrop } from 'react-dnd';
import { DraggableType, NewWidgetItem } from '../../types';
import { Widget } from '@process-street/subgrade/process';
import { getCursorLocation, HoverLocation } from 'hooks/get-cursor-location';
import { useFeatureFlags } from 'app/features/feature-flags';
import { FormEditorPageActorRef } from '../../form-editor-page-machine/form-editor-page-machine-types';
import { FormEditorPageActorSelectors } from '../../form-editor-page-machine/form-editor-page-machine-selectors';
import { Muid } from '@process-street/subgrade/core';
import { useInsertWidgetDragStatusStore } from '../insert-widget/insert-widget-drag-status-store';

export function useDropOnWidgetListItem({
  actor,
  ...options
}:
  | {
      actor: FormEditorPageActorRef;
      widget: Widget;
    }
  | {
      actor: FormEditorPageActorRef;
      area: 'top' | 'bottom';
      taskTemplateId?: Muid;
    }) {
  const { send } = actor;
  const insertWidgetDragStatusStore = useInsertWidgetDragStatusStore();

  const ref = React.useRef<HTMLDivElement | null>(null);

  const [hoverLocation, setHoverLocation] = React.useState<HoverLocation>(null);

  const featureFlags = useFeatureFlags('longTextFieldMarkdown');

  const [dropResult, drop] = useDrop(
    () => ({
      accept: [DraggableType.FormField, DraggableType.Content],
      drop: (item: NewWidgetItem, monitor) => {
        if (!ref.current) return;

        const cursorLocation = match(options)
          .with({ area: P.string }, ({ area }) => area)
          .otherwise(() => {
            if (!ref.current) return;
            return getCursorLocation<NewWidgetItem>({ monitor, element: ref.current });
          });
        const taskTemplateId = match(options)
          .with({ widget: P.not(P.nullish) }, ({ widget }) => widget.header.taskTemplate.id)
          .with({ taskTemplateId: P.string }, ({ taskTemplateId }) => taskTemplateId)
          .otherwise(() => undefined);

        const actorState = actor.getSnapshot();

        if (!actorState) {
          console.error(`'actorState' is missing`);
          return;
        }

        const allWidgets = taskTemplateId
          ? (FormEditorPageActorSelectors.getWidgetsByTaskTemplateId(actorState)[taskTemplateId] ?? [])
              .map(widgetId => FormEditorPageActorSelectors.getWidgetById(widgetId)(actorState))
              .filter(Boolean)
          : FormEditorPageActorSelectors.getWidgets(actorState);

        const targetWidget = match(options)
          .with({ widget: P.not(P.nullish) }, ({ widget }) => widget)
          .with({ area: 'top' }, () => allWidgets[0])
          .with({ area: 'bottom' }, () => {
            return allWidgets[allWidgets.length - 1];
          })
          .otherwise(() => undefined);

        if (!cursorLocation) {
          console.error(`'cursorLocation' is missing`);
          return;
        }

        const locationRelativeToWidget = match(cursorLocation)
          .with('top', () => ({ before: targetWidget }))
          .with('bottom', () => ({ after: targetWidget }))
          .otherwise(() => ({}));

        send({ type: 'CREATE_WIDGET', payload: { featureFlags, ...item }, ...locationRelativeToWidget });
      },
      hover(_item, monitor) {
        if (!ref.current) return;
        setHoverLocation(getCursorLocation<NewWidgetItem>({ monitor, element: ref.current }));
      },
      collect: monitor => ({ isOver: monitor.isOver() }),
    }),
    [options],
  );

  React.useEffect(() => {
    drop(ref);
  }, [drop]);

  React.useEffect(
    function syncHoveringDropzone() {
      if (!dropResult.isOver) {
        return insertWidgetDragStatusStore.setHoveringDropzone(null);
      }
      const hoveringDropzone = match(options)
        .with({ area: P.string }, ({ area }) => area)
        .otherwise(() => null);

      insertWidgetDragStatusStore.setHoveringDropzone(hoveringDropzone);
    },
    // We only want to run this effect when `isOver` changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dropResult.isOver],
  );

  return React.useMemo(
    () => ({
      dropResult,
      ref,
      hoverLocation,
    }),
    [dropResult, hoverLocation],
  );
}

import React from 'react';
import { Flex } from 'components/design/next';
import { TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { ApprovalStatus } from '@process-street/subgrade/approval-rule';
import { RejectTaskButton } from '../reject-task-button/reject-task-button';
import { ApproveTaskButton } from '../approve-task-button';

export type ApprovalButtonsProps = {
  subjectTask: TaskWithTaskTemplate;
  handleUpsertApproval: (task: TaskWithTaskTemplate, status: ApprovalStatus, comment?: string) => void;
};

export const ApprovalButtons = ({ subjectTask, handleUpsertApproval }: ApprovalButtonsProps) => {
  const [state, setState] = React.useState<'idle' | 'rejecting' | 'approving'>('idle');
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      alignItems={{ base: 'center', md: 'inherit' }}
      w="full"
      pt="2"
      justifyContent="flex-end"
      gap="2"
    >
      {(state === 'idle' || state === 'rejecting') && (
        <RejectTaskButton
          task={subjectTask}
          handleUpsertApproval={handleUpsertApproval}
          onRejecting={() => setState('rejecting')}
          onCancel={() => setState('idle')}
        />
      )}
      {(state === 'idle' || state === 'approving') && (
        <ApproveTaskButton
          task={subjectTask}
          handleUpsertApproval={handleUpsertApproval}
          onApproving={() => setState('approving')}
          onCancel={() => setState('idle')}
        />
      )}
    </Flex>
  );
};

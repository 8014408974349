import * as React from 'react';
import {
  Text,
  useDisclosure,
  VStack,
  Button,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  BoxProps,
  useToast,
} from 'components/design/next';
import { DesignItemButton } from './design-item-button';
import { UploadTemplateCoverIcon } from 'app/features/cover-icon/components/common/upload';
import { Template } from '@process-street/subgrade/process';
import { useQueryClient } from 'react-query';
import { GetCoverIconByTemplateId, DeleteCoverIconMutation } from 'app/features/cover-icon/query-builder';
import { EmojiPicker } from 'features/cover-icon/components/common/emoji-picker';
import { TemplateCoverIcon } from 'app/features/cover-icon/components/template';
import { DefaultErrorMessages } from 'app/components/utils/error-messages';
import { trace } from 'components/trace';
import { AddFileModal } from './add-file-modal';

const baseCoverIconProps: BoxProps = {
  height: {
    base: 9,
    md: '46px',
  },
  width: {
    base: 9,
    md: '46px',
  },
};

export const AddIconButton = ({ templateId }: { templateId: Template['id'] }) => {
  const addIconDisclosure = useDisclosure();
  const queryClient = useQueryClient();
  const toast = useToast();
  const logger = trace({ name: 'RemoveIcon' });

  const coverIconQuery = GetCoverIconByTemplateId.useQuery({ templateId });

  const removeCoverIconMutation = DeleteCoverIconMutation.useMutation({
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(GetCoverIconByTemplateId.getKey({ templateId: variables.templateId }));
      toast({
        status: 'success',
        title: 'Icon deleted',
      });
    },
    onError: e => {
      logger.error(e.message);
      toast({
        status: 'error',
        title: "We're having problems deleting the icon",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });
  const handleRemoveIcon = () => {
    removeCoverIconMutation.mutate({ templateId });
  };

  const onIconUploadSuccess = (templateId: Template['id']) => {
    queryClient.invalidateQueries(GetCoverIconByTemplateId.getKey({ templateId }));
    toast({
      status: 'success',
      title: 'Icon updated',
    });
  };

  return (
    <>
      <DesignItemButton
        title="Icon"
        onClick={addIconDisclosure.onOpen}
        handleRemove={handleRemoveIcon}
        isLoading={removeCoverIconMutation.isLoading}
        isSmallImage
      >
        {coverIconQuery.data && (
          <TemplateCoverIcon
            templateId={templateId}
            icon={coverIconQuery.data}
            borderWidth="2px"
            imageHeight={36}
            imageWidth={36}
            emojiFontSize="26px"
            {...baseCoverIconProps}
          />
        )}
      </DesignItemButton>
      <AddFileModal isOpen={addIconDisclosure.isOpen} title="Add icon" onClose={addIconDisclosure.onClose}>
        <Tabs variant="default" isLazy>
          <TabList>
            <Tab aria-label="upload image">Upload Image</Tab>
            <Tab aria-label="add emoji">Emoji</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <UploadTemplateCoverIcon
                templateId={templateId}
                uploadingMessage="Uploading icon..."
                acceptMimeTypes={{ 'image/*': [] }}
                onFinish={onIconUploadSuccess}
              >
                <VStack align="center" spacing={3}>
                  <Button size="sm">Choose image</Button>
                  <Text align="center" variant="-2" color="gray.500" fontWeight="normal">
                    Recommended size 75x75px
                  </Text>
                </VStack>
              </UploadTemplateCoverIcon>
            </TabPanel>
            <TabPanel p="0">
              <EmojiPicker templateId={templateId} onFinish={onIconUploadSuccess} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </AddFileModal>
    </>
  );
};

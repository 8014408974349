import * as React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { Muid } from '@process-street/subgrade/core';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useInsertWidgetDragStatusStore } from 'app/pages/forms/_id/edit/components/insert-widget/insert-widget-drag-status-store';
import { useDropOnWidgetListItem } from 'app/pages/forms/_id/edit/components/widgets-list/use-drop-on-widget-list-item';
import { useCalculateDropzoneHeight } from 'app/pages/forms/_id/edit/components/widgets-list/hooks/use-calculate-dropzone-height';

export const TopDropzone = ({ taskTemplateId, ...props }: BoxProps & { taskTemplateId: Muid }) => {
  const actor = useFormEditorPageActorRef();
  const dragStatuses = useInsertWidgetDragStatusStore().statuses;
  const isDragging = React.useMemo(() => {
    return Object.values(dragStatuses).some(({ isDragging }) => isDragging);
  }, [dragStatuses]);

  const { ref: widgetDropRef } = useDropOnWidgetListItem({ actor, area: 'top', taskTemplateId });

  return (
    <Box
      ref={widgetDropRef}
      zIndex={isDragging ? 'tooltip' : 'tooltip'}
      pointerEvents={isDragging ? 'all' : 'none'}
      position="absolute"
      w="full"
      h="175px"
      left="0"
      top="0"
      display="flex"
      alignItems="flex-end"
      {...props}
    />
  );
};

export const BottomDropzone = ({ taskTemplateId, ...props }: BoxProps & { taskTemplateId: Muid }) => {
  const actor = useFormEditorPageActorRef();
  const dragStatuses = useInsertWidgetDragStatusStore().statuses;
  const isDragging = React.useMemo(() => {
    return Object.values(dragStatuses).some(({ isDragging }) => isDragging);
  }, [dragStatuses]);

  const {
    dropResult: { isOver },
    ref: widgetDropRef,
  } = useDropOnWidgetListItem({ actor, area: 'bottom', taskTemplateId });

  const dropzoneHeight = useCalculateDropzoneHeight({ widgetDropRef, isDragging, isOver });

  return (
    <Box
      ref={widgetDropRef}
      zIndex={isDragging ? 'tooltip' : -1}
      pointerEvents={isDragging ? 'all' : 'none'}
      position="absolute"
      w="full"
      h={`${dropzoneHeight}px`}
      left="0"
      mt="-36px"
      display="flex"
      alignItems="flex-start"
      {...props}
    />
  );
};

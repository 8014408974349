import { FormFieldWidgetWithValue, SimpleFieldValue } from '@process-street/subgrade/process';
import { HtmlServiceUtils } from 'app/services/html-service.utils';
import { Box } from 'components/design/next';
import * as React from 'react';

export type ApprovalWidgetUrlRendererProps = {
  widget: FormFieldWidgetWithValue;
};

export const ApprovalWidgetUrlRenderer = ({ widget }: ApprovalWidgetUrlRendererProps) => {
  const rendererValue = React.useMemo(
    () =>
      HtmlServiceUtils.autolinkUrls(
        (widget.formFieldValue?.fieldValue && (widget.formFieldValue?.fieldValue as SimpleFieldValue).value) || '',
      ),
    [widget.formFieldValue],
  );

  return (
    <Box
      color="brand.500"
      fontWeight="normal"
      dangerouslySetInnerHTML={{ __html: rendererValue ?? '' }}
      _hover={{ textDecoration: 'underline' }}
    />
  );
};

import { FormControl, FormErrorMessage, Textarea as ChakraTextarea, VStack } from 'components/design/next';
import Textarea from 'react-autosize-textarea';
import React from 'react';
import { useSelector } from '@xstate/react';
import { TextareaFormFieldActor, TextareaHooks } from './textarea-form-field-machine';
import { StringUtils } from '@process-street/subgrade/util';
import { FormResponseLabel } from '../common';
import { TextAreaRichEditor } from 'pages/runs/_id/components/checklist-textarea-form-field-widget/textarea-rich-editor';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';
import { FormFieldMachineHooks } from '../form-field-machine-hooks';

export interface TextareaFormFieldProps {
  actor: TextareaFormFieldActor;
}

export const TextareaFormField: React.FC<React.PropsWithChildren<TextareaFormFieldProps>> = ({ actor }) => {
  const api = FormFieldMachineHooks.useApi(actor);

  const widget = FormFieldMachineHooks.useWidget(actor);
  const inputNode = FormFieldMachineHooks.useInputNode(actor);
  const isInputDisabled = FormFieldMachineHooks.useIsInputDisabled(actor);
  const isAutofocused = FormFieldMachineHooks.useIsAutofocused(actor);
  const errorMessage = FormFieldMachineHooks.useValidationErrorMessage(actor);
  const isInvalidVisible = FormFieldMachineHooks.useIsInvalidVisible(actor);

  const machineValue = TextareaHooks.useValue(actor);

  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);

  const onFocus = () => api.onFocus();

  const onBlur = () => api.onBlur();

  const setValue = (value: string) => api.onChange(value);

  const handleSetValue: React.ChangeEventHandler<HTMLTextAreaElement> = e => {
    setValue(e.target.value);
  };

  const ref = React.useRef<HTMLDivElement | null>(null);

  const isInvalid = FormFieldMachineHooks.useIsInvalid(actor);

  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !inputNode) {
          api.onSetNode(node);
        }
      }}
      as={VStack}
      alignItems="stretch"
      isRequired={widget.required}
      isInvalid={isInvalid}
      sx={{
        '&[data-invalid]': {
          '.rich-editor-wrapper': { borderColor: 'red.500', boxShadow: '0 0 0 1px var(--ps-colors-red-500)' },
        },
      }}
    >
      <FormResponseLabel>{widget.label || 'Untitled Long Text'}</FormResponseLabel>
      {widget.config.format === 'RichText' ? (
        <TextAreaRichEditor
          setValue={setValue}
          readOnly={isInputDisabled}
          editable={!isInputDisabled}
          placeholder={StringUtils.getNonEmpty(widget.config.placeholder, 'Type answer here...')}
          onBlur={onBlur}
          onFocus={onFocus}
          markdownValue={machineValue}
        />
      ) : (
        <ChakraTextarea
          as={Textarea}
          w="full"
          maxW="175"
          maxH="80"
          borderColor="gray.300"
          value={machineValue}
          placeholder={StringUtils.getNonEmpty(widget.config.placeholder, 'Type answer here...')}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={handleSetValue}
          isDisabled={isInputDisabled}
          autoFocus={isAutofocused}
        />
      )}

      {isInvalidVisible && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

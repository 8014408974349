import { StateFrom } from 'xstate';
import { TaskMachine } from './task-machine';
import { isApproval } from '@process-street/subgrade/process';

type State = StateFrom<TaskMachine>;

export namespace TaskMachineSelectors {
  export const getIsCompleted = (state: State) => state.matches('task.complete');
  export const getWidgetActors = (state: State) => Object.values(state.context.widgetActorsMap);
  export const getTask = (state: State) => state.context.task;
  export const getRulesEngineTargetActor = (state: State) => state.context.rulesEngineTargetActor;
  export const getApprovals = (state: State) => state.context.approvals;
  export const getInvalidWidgetCount = (state: State) => Object.values(state.context.invalidWidgetMap).length;
  export const getIsApproval = (state: State) => isApproval(state.context.task.taskTemplate);
  export const shouldShowErrors = (state: State) => state.matches('validation.invalid.visible');
}

import * as React from 'react';
import { useGetConsolidatedTemplatePermissionsQuery } from 'app/features/permissions/query-builder';
import { RightSidebarButton } from '../right-sidebar-button';
import { GetAllRulesByTemplateRevisionIdQuery } from 'app/features/conditional-logic/query-builder';
import { queryString } from '@process-street/subgrade/util';
import { useNavigate } from '@process-street/adapters/navigation';
import { match } from 'ts-pattern';
import { Tooltip } from '@chakra-ui/react';
import { TemplateRevision } from '@process-street/subgrade/process';

export type PrintConditionalLogicButtonProps = {
  templateRevision: TemplateRevision;
};

export const PrintConditionalLogicButton = ({ templateRevision }: PrintConditionalLogicButtonProps) => {
  const templatePermissionMapQuery = useGetConsolidatedTemplatePermissionsQuery(templateRevision.template.id);
  const rulesQuery = GetAllRulesByTemplateRevisionIdQuery.useQuery({
    templateRevisionId: templateRevision?.id,
  });
  const navigate = useNavigate();

  const canEditTemplate = templatePermissionMapQuery.data?.permissionMap.templateUpdate ?? false;
  const hasRules = (rulesQuery.data?.definitions.length ?? 0) > 0;
  const isDisabled = !canEditTemplate || !hasRules;

  const disabledReason = match({ canEditTemplate, hasRules })
    .with({ canEditTemplate: false }, () => 'You do not have permissions to access this functionality.')
    .with({ hasRules: false }, () => 'No rules to export.')
    .otherwise(() => undefined);

  const handleClick = () => {
    navigate({
      pathname: 'templatePrintRules',
      search: queryString.stringify({
        id: templateRevision.template.id,
      }),
    });
  };

  const btn = (
    <RightSidebarButton isDisabled={isDisabled} iconName="print" onClick={handleClick}>
      Print Conditional Logic
    </RightSidebarButton>
  );

  return isDisabled ? (
    <Tooltip maxW="200px" textAlign="center" label={disabledReason} aria-label="Print Conditional Logic button">
      {btn}
    </Tooltip>
  ) : (
    btn
  );
};

import { isDefined } from '@process-street/subgrade/core';
import { AssertingTestOptions } from 'yup';
import {
  EmailFormFieldWidget,
  isValidEmail,
  isValidUrl,
  isWithinDecimalPlaces,
} from '@process-street/subgrade/process';
import pluralize from 'pluralize';
import { DateUtils, StringUtils } from '@process-street/subgrade/util';

const required = (required: boolean) => ({
  name: 'required',
  message: 'This field is required',
  test: (value: unknown) => {
    if (required) {
      return Boolean(value);
    } else {
      return true;
    }
  },
});

const requiredArray = (required: boolean) => ({
  name: 'required array',
  message: 'This field is required',
  exclusive: true as const,
  test: (value: unknown) => {
    if (required && !value) return false;

    if (required && Array.isArray(value)) return value.some(Boolean);

    return true;
  },
});

const email = () => ({
  name: 'email',
  message: 'Invalid email address',
  test: (value: any) => {
    if (value) {
      return isValidEmail(value);
    } else {
      return true;
    }
  },
});

const emailAllowDomains = (restriction?: EmailFormFieldWidget['constraints']['restriction'], domains?: string[]) => ({
  name: 'email-allow-domain',
  message: 'This domain is not allowed',
  test: (value: any) => {
    if (restriction === 'Allow' && domains && !StringUtils.isBlank(value)) {
      const [, domain] = value.split('@');
      return domains.includes(domain);
    } else {
      return true;
    }
  },
});

const emailBlockDomains = (restriction?: EmailFormFieldWidget['constraints']['restriction'], domains?: string[]) => ({
  name: 'email-block-domain',
  message: 'This domain is not allowed',
  test: (value?: any) => {
    if (restriction === 'Block' && domains && !StringUtils.isBlank(value)) {
      const [, domain] = value.split('@');
      return !domains.includes(domain);
    } else {
      return true;
    }
  },
});

const url = () => ({
  name: 'url',
  message: 'Invalid URL',
  test: (value: any) => {
    if (value) {
      return isValidUrl(value);
    } else {
      return true;
    }
  },
});

const characterRange = (min?: number, max?: number) => ({
  name: 'range',
  params: { min, max },
  message: `Must be between ${min} and ${max} characters`,
  test: (value: any) => {
    if (isDefined(min) && isDefined(max)) {
      return value.length >= min && value.length <= max;
    } else {
      return true;
    }
  },
});

const lengthMin = (min?: number, options: Partial<{ unit: string }> = {}) => ({
  name: `min-${options.unit ?? 'characters'}}`,
  params: { min },
  message: `Must be at least ${min} ${options.unit ?? 'characters'}`,
  test: (value: any) => {
    if (value === undefined || value === '') return true;

    if (isDefined(min)) {
      return String(value).length >= min;
    } else {
      return true;
    }
  },
});

const lengthMax = (max?: number, options: Partial<{ unit: string }> = {}) => ({
  name: `max-${options.unit ?? 'characters'}}`,
  params: { max },
  message: `Must be at most ${max} ${options.unit ?? 'characters'}`,
  test: (value: any) => {
    if (value === undefined || value === '') return true;

    if (isDefined(max)) {
      return String(value).length <= max;
    } else {
      return true;
    }
  },
});

const valueMax = (max?: number): AssertingTestOptions<number, { max?: number }> => ({
  name: 'value-max',
  params: { max },
  message: `Maximum ${max}`,
  test: (value: number): value is number => {
    if (isDefined(max) && isDefined(value)) {
      return value <= max;
    } else {
      return true;
    }
  },
});

const valueMin = (min?: number): AssertingTestOptions<number, { min?: number }> => ({
  name: 'value-min',
  params: { min },
  message: `Minimum ${min}`,
  test: (value: number): value is number => {
    if (isDefined(min) && isDefined(value)) {
      return value >= min;
    } else {
      return true;
    }
  },
});

const beforeDate = (beforeDate?: number): AssertingTestOptions<number, { beforeDate?: number }> => ({
  name: 'before-date',
  params: { beforeDate },
  message: `Select a date before ${
    beforeDate ? DateUtils.formatDateToMonthDay(beforeDate, { showYearIfPast: true }) : ''
  }`,
  test: (value: number): value is number => {
    if (isDefined(beforeDate) && isDefined(value)) {
      return value <= beforeDate;
    } else {
      return true;
    }
  },
});

const afterDate = (afterDate?: number): AssertingTestOptions<number, { afterDate?: number }> => ({
  name: 'after-date',
  params: { afterDate },
  message: `Select a date after ${
    afterDate ? DateUtils.formatDateToMonthDay(afterDate, { showYearIfPast: true }) : ''
  }`,
  test: (value: number): value is number => {
    if (isDefined(afterDate) && isDefined(value)) {
      return value >= afterDate;
    } else {
      return true;
    }
  },
});

const decimalPlaces = (decimalPlaces?: number): AssertingTestOptions<string, { decimalPlaces?: number }> => ({
  name: 'decimal-places',
  params: { decimalPlaces },
  message: `Must have ${pluralize('decimal place', decimalPlaces ?? 0, true)}`,
  test: (value: string): value is string => {
    if (!isDefined(value)) return true;
    return isWithinDecimalPlaces(value, decimalPlaces ?? 0);
  },
});

const allowNegative = (allowNegative?: boolean): AssertingTestOptions<number, { allowNegative?: boolean }> => ({
  name: 'allow-negative',
  params: { allowNegative },
  message: `Cannot be negative`,
  test: (value: number): value is number => {
    if (isDefined(value)) {
      return allowNegative ? true : value >= 0;
    } else {
      return true;
    }
  },
});

export const FormFieldValueSchemaTests = {
  required,
  requiredArray,
  email,
  emailAllowDomains,
  emailBlockDomains,
  url,
  characterRange,
  lengthMin,
  lengthMax,
  valueMax,
  valueMin,
  beforeDate,
  afterDate,
  decimalPlaces,
  allowNegative,
};

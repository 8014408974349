import * as React from 'react';
import { Box } from 'components/design/next';
import { TableContentActor } from './table-content-widget-machine';
import { useActor } from '@xstate/react';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { ContentFieldRecentlyMovedIndicator } from '../common/content-field-recently-moved-indicator';
import { TableEditor } from './components/table-editor';
import { isPage } from '@process-street/subgrade/process';

export const DEFAULT_TABLE_CONTENT =
  '<table><thead><tr><th><p><strong></strong></p></th><th><p><strong></strong></p></th></tr></thead><tbody><tr><td><p></p></td><td><p></p></td></tr><tr><td><p></p></td><td><p></p></td></tr></tbody></table>';
export interface TableContentWidgetProps {
  actor: TableContentActor;
  isFirst?: boolean;
  isLast?: boolean;
}

const tableStyles = {
  'table': { width: 'full', maxWidth: { base: '316px', md: '774px' }, display: 'block', overflowX: 'auto' },
  'th': {
    backgroundColor: 'brand.50',
    minWidth: 30,
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: 'md',
    color: 'gray.700',
    letterSpacing: 0,
  },
  'th, td': { border: '1px solid var(--ps-colors-gray-200)', padding: '2!important', width: '1%' },
  'p': { margin: '0' },
};

export const TableContentWidget: React.FC<React.PropsWithChildren<TableContentWidgetProps>> = ({
  actor,
  isFirst = false,
  isLast = false,
}) => {
  const [state, send] = useActor(actor);
  const { widget, template, recentlyMovedFrom, isReadOnly } = state.context;
  const ref = React.useRef<HTMLDivElement | null>(null);
  if (!widget) return null;
  const initialValue = state.context.widget.content;
  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer alignItems="flex-start" fontSize="md">
        {recentlyMovedFrom && <ContentFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
        {isReadOnly ? (
          <Box dangerouslySetInnerHTML={{ __html: initialValue ?? '' }} sx={tableStyles} />
        ) : (
          <>
            <Box
              w="full"
              sx={tableStyles}
              ref={(node: HTMLDivElement) => {
                ref.current = node;
                if (node && !state.context.inputNode) {
                  send({ type: 'SET_NODE', node });
                }
              }}
            >
              <TableEditor
                widgetId={widget.id}
                value={initialValue}
                onChange={content => send({ type: 'CHANGE', content })}
                onFocus={() => send('FOCUS')}
                onBlur={() => send('BLUR')}
              />
            </Box>
            <WidgetListItemDragIcon />
            <FormsWidgetMenu>
              <FormsWidgetMenuItems.Duplicate />
              {!isPage(template) && <FormsWidgetMenuItems.MoveToStep widget={widget} />}
              <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
              <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
              <FormsWidgetMenuItems.Delete />
            </FormsWidgetMenu>
          </>
        )}
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};

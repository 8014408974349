import * as React from 'react';
import {
  Text,
  VStack,
  Icon,
  HStack,
  Box,
  IconButton,
  Textarea as ChakraTextarea,
  InputGroup,
  InputRightElement,
  useDisclosure,
} from 'components/design/next';
import { MultiSelectFieldValue, TemplateRevision } from '@process-street/subgrade/process';
import { useDropOnSubtaskListItem, UseDropOnSubtaskListItemValue } from './use-drop-on-subtask-list-item';
import { ReorderUtils } from 'pages/forms/_id/edit/components/widgets-list/reorder-utils';
import { SubtaskEditor } from './subtasks-form-field';
import { MergeTagsMenu, MergeTagsMenuButton } from 'app/features/merge-tags/components/merge-tags-menu';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { useMergeTaggableInput } from 'app/hooks/use-merge-taggable-input';
import { Muid } from '@process-street/subgrade/core';
import { useInView } from 'framer-motion';
import { ReorderAutoScrollContext } from 'app/pages/workflows/_id/edit-v2/providers/reorder-scroll-context';
import { ThemeName, useThemeName } from 'app/components/design/next/theme-name-provider';
import Textarea from 'react-autosize-textarea';

type Value = NonNullable<MultiSelectFieldValue>;

export type SubtaskProps = {
  index: number;
  subtask: Value;
  onChange: (index: number, updatedItem: MultiSelectFieldValue) => void;
  onBlur: (index: number) => void;
  onKeyDown: (index: number, e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onDelete: (index: number) => void;
  onDrop: (v: UseDropOnSubtaskListItemValue) => void;
  isTouched?: boolean;
  hasError?: boolean;
  editorType?: SubtaskEditor;
  templateRevisionId?: TemplateRevision['id'];
  headerId?: Muid;
  isReadOnly?: boolean;
};

export const SubtaskImpl = React.memo(
  ({
    index,
    subtask,
    templateRevisionId,
    headerId,
    editorType = SubtaskEditor.OneOffTask,
    hasError = false,
    isTouched = false,
    isReadOnly = false,
    onChange,
    onBlur,
    onKeyDown,
    onDelete,
    onDrop,
  }: SubtaskProps) => {
    const [name, setName] = React.useState(subtask.name);
    const is2024Theme = useThemeName() === ThemeName.TwentyFour;

    const {
      dropResult: { isOver },
      hoverLocation,
      ref: dndRef,
      isDragging,
    } = useDropOnSubtaskListItem({ itemValue: subtask, onDrop });

    const handleMergeTagChange = React.useCallback(
      (value: React.ChangeEvent<HTMLTextAreaElement> | string) => {
        const name = value as string;
        setName(name);
        onChange(index, { ...subtask, name });
      },
      [index, onChange, subtask],
    );

    const { ref: tagRef, insertMergeTag: insertVariable } = useMergeTaggableInput<HTMLTextAreaElement>({
      get: () => subtask.name ?? '',
      set: handleMergeTagChange,
    });

    const [isHovering, setIsHovering] = React.useState(false);
    const [isFocused, setIsFocused] = React.useState(false);
    const mergeTagsMenuDisclosure = useDisclosure();
    const isMergeTagsVisible = isHovering || isFocused || mergeTagsMenuDisclosure.isOpen;

    const handleNameChange = React.useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setName(e.target.value);
        onChange(index, { ...subtask, name: e.target.value });
      },
      [index, onChange, subtask],
    );

    const handleFocus = React.useCallback(() => {
      setIsFocused(true);
    }, []);

    const handleBlur = React.useCallback(() => {
      onBlur(index);
      setIsFocused(false);
    }, [index, onBlur]);

    const handleKeyDown = React.useCallback(
      (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        onKeyDown(index, e);
      },
      [onKeyDown, index],
    );

    const handleMergeTagsMenuSelect = React.useCallback(
      (key: string, _fieldId?: string, fallback?: string) => {
        insertVariable(key, fallback);
      },
      [insertVariable],
    );

    const mergeTagsMenuButton = React.useMemo(() => <MergeTagsMenuButton size="xs" bg="white" />, []);

    React.useEffect(
      function syncName() {
        setName(current => {
          if (current !== subtask.name) return subtask.name;

          return current;
        });
      },
      [subtask.name],
    );

    return (
      <Box
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        position="relative"
        ref={dndRef}
        w="full"
        onPointerDownCapture={e => {
          if (isReadOnly) {
            e.stopPropagation();
            return;
          }
          const target = e.target as HTMLElement;
          const isDragHandle = ReorderUtils.isTargetDragHandle(target);
          // allow reordering from verified handles (e.g., select dropdown choices)

          if (isDragHandle) return;
          e.stopPropagation();
        }}
        _hover={{
          '[data-is-drag-handle]': {
            display: 'block',
          },
          '.taskNumber': {
            visibility: isReadOnly ? undefined : 'hidden',
          },
        }}
      >
        <HStack
          alignItems="flex-start"
          w="full"
          key={`subtask-${index}`}
          spacing={2}
          _hover={{
            '.chakra-button': {
              opacity: 1,
            },
          }}
          opacity={isDragging ? 0 : 1}
        >
          <HStack spacing={2} pt={1.5}>
            <Box minW={5} minH={4.5} position="relative" textAlign="center">
              {!isReadOnly && (
                <Icon
                  icon="grip-vertical"
                  {...ReorderUtils.HANDLE_PROPS}
                  variant="fas"
                  w="full"
                  size="3"
                  color="gray.400"
                  display="none"
                  cursor="grab"
                  position="absolute"
                  top={0.5}
                  right={1}
                />
              )}
              <Text className="taskNumber" color="gray.400" fontSize="xs" transform="scale(1.3)">
                {index + 1}
              </Text>
            </Box>
            <Box minW={5} minH={5} borderRadius="4px" borderStyle="solid" borderWidth="thin" borderColor="gray.300" />
          </HStack>

          <HStack w="full">
            <VStack w="full" alignItems="flex-start">
              <InputGroup>
                <VStack spacing={0} w="full" alignItems="flex-start">
                  <ChakraTextarea
                    as={Textarea}
                    name={`subtasks.${subtask.id}${headerId ? '-' + headerId : ''}`}
                    value={name}
                    border="none"
                    fontSize={is2024Theme ? 'md' : 'sm'}
                    mt="-px"
                    placeholder={
                      tagRef.current === document.activeElement
                        ? '' // Hide placeholder if focused
                        : 'Type here or press enter to add another subtask'
                    }
                    pl={1}
                    // Avoid content going behind the variables button
                    pr={!isReadOnly && editorType === SubtaskEditor.WFEditor ? 10 : 1}
                    isInvalid={hasError && isTouched && editorType === SubtaskEditor.OneOffTask}
                    isDisabled={isReadOnly}
                    size="sm"
                    rows={1}
                    resize="none"
                    wrap="off"
                    overflowX="hidden"
                    overflowWrap="break-word"
                    whiteSpace="pre-wrap"
                    ref={tagRef}
                    onBlur={handleBlur}
                    onChange={handleNameChange}
                    onFocus={handleFocus}
                    onKeyDown={handleKeyDown}
                  />

                  {hasError && isTouched && !subtask.name && editorType === SubtaskEditor.OneOffTask && (
                    <Text size="xs" color="red.500" mb={1}>
                      Subtasks can&apos;t be empty
                    </Text>
                  )}
                </VStack>
                {!isReadOnly && editorType === SubtaskEditor.WFEditor && isMergeTagsVisible && (
                  <InputRightElement
                    className="subtask__input-right-element"
                    _focusWithin={{ zIndex: 3 }}
                    height="full"
                  >
                    <MergeTagsMenu
                      disclosure={mergeTagsMenuDisclosure}
                      templateRevisionId={templateRevisionId}
                      onSelect={handleMergeTagsMenuSelect}
                      mergeTagTarget={MergeTagsConstants.Target.GENERAL}
                      menuButton={mergeTagsMenuButton}
                    />
                  </InputRightElement>
                )}
              </InputGroup>
            </VStack>
            {!isReadOnly && (
              <IconButton
                onClick={() => onDelete(index)}
                aria-label={`Delete "${subtask.name}" subtask`}
                icon={<Icon icon="trash-can" size="4" color="gray.500" />}
                size="sm"
                variant="ghost"
                colorScheme="gray"
                opacity={{ base: 1, lg: 0 }}
              />
            )}
          </HStack>
        </HStack>

        {isOver && hoverLocation ? (
          <Box
            h={1}
            w="full"
            position="absolute"
            bg="brand.200"
            left="0"
            {...(hoverLocation === 'top'
              ? { top: 0, transform: 'translateY(-50%)' }
              : { bottom: 0, transform: 'translateY(50%)' })}
          />
        ) : null}
      </Box>
    );
  },
);

/**
 * If the subtask gets this close to the viewport, render the widget.
 * (See docs for useInView and IntersectionObserver options)
 */
const SUBTASK_VIEWPORT_MARGIN_PX = 500;

export const Subtask = React.memo((props: SubtaskProps) => {
  const taskItemRef = React.useRef(null);
  const scrollContainerRef =
    React.useContext(ReorderAutoScrollContext)?.widgetAutoScroll.scrollContainerProps.scrollContainerRef;
  const isVisible = useInView(taskItemRef, {
    margin: SUBTASK_VIEWPORT_MARGIN_PX + 'px',
    amount: 'some',
    root: scrollContainerRef,
  });
  return (
    <Box minH={8} w="full" ref={taskItemRef}>
      {isVisible && <SubtaskImpl {...props} />}
    </Box>
  );
});

Subtask.displayName = 'Subtask';

import { ResourceFactory } from 'app/resources/factory/resource-factory';

export const Approvals = (resourceFactory: ResourceFactory) => {
  'ngInject';

  return resourceFactory.create({
    getAllByChecklistRevisionId: 'GET[] /1/checklist-revision/:checklistRevisionId/approvals',
    upsertAll: 'PUT[] /1/checklist-revision/:checklistRevisionId/approvals_v2',
    upsertAllByTask: 'POST[] /1/tasks/:taskId/approvals',
  });
};

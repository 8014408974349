import {
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
} from 'components/design/next';
import React from 'react';
import { useActor } from '@xstate/react';
import { UrlFormFieldActor } from './url-form-field-machine';
import { ValidationSelectors } from '../validation-machine';
import { useSelector } from '@xstate/react';
import { FormResponseLabel } from '../common';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';

export interface UrlFormFieldProps {
  actor: UrlFormFieldActor;
}

export const UrlFormField: React.FC<React.PropsWithChildren<UrlFormFieldProps>> = ({ actor }) => {
  const [state, send] = useActor(actor);
  const { widget } = state.context;
  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);

  const onFocus = () => {
    send({ type: 'FOCUS' });
  };

  const onBlur = () => {
    send({ type: 'BLUR' });
  };

  const setValue: React.ChangeEventHandler<HTMLInputElement> = e => {
    send({ type: 'CHANGE', value: e.target.value });
  };

  const ref = React.useRef<HTMLDivElement | null>(null);

  const isInvalid = ValidationSelectors.isActorInvalidVisible(state.context.validationActor);

  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !state.context.inputNode) {
          actor.send({ type: 'SET_NODE', node });
        }
      }}
      as={VStack}
      alignItems="stretch"
      isInvalid={isInvalid}
      isRequired={widget.required}
    >
      <FormResponseLabel>{widget.label || 'Untitled Website'}</FormResponseLabel>
      <InputGroup>
        <InputLeftElement px="3">
          {/* mt because it looks slightly off center to input */}
          <Icon icon="globe" size="4" color="gray.400" />
        </InputLeftElement>
        <Input
          w={{ base: '100%', lg: '78' }}
          borderColor="gray.300"
          value={state.context.value}
          placeholder="https://www.process.st"
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={setValue}
          isDisabled={state.matches('input.disabled')}
          autoFocus={state.matches('autoFocus.enabled')}
        />
      </InputGroup>
      <FormErrorMessage>{ValidationSelectors.errorMessage(state.context.validationActor)}</FormErrorMessage>
    </FormControl>
  );
};

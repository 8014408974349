import * as React from 'react';
import { HStack, Divider, Text, VStack } from '@chakra-ui/react';
import { TaskActor } from 'app/pages/responses/_id/components/task/task-machine';
import { useSelector } from '@xstate/react';
import { isHeading } from '@process-street/subgrade/process';
import { TaskMachineSelectors } from 'app/pages/responses/_id/components/task/task-machine-selectors';
import { ChecklistTaskHeaderButtons } from './components';

export type TaskHeaderProps = {
  currentTaskActor: TaskActor;
};

export const TaskHeader = ({ currentTaskActor }: TaskHeaderProps) => {
  const task = useSelector(currentTaskActor, TaskMachineSelectors.getTask);
  const isHeadingTask = isHeading(task.taskTemplate);

  return (
    <VStack w="full" alignItems="flex-start" spacing={2}>
      {!isHeadingTask && (
        <HStack spacing={2}>
          <ChecklistTaskHeaderButtons.Assign task={task} />
          <ChecklistTaskHeaderButtons.DueDate />
        </HStack>
      )}
      <Text as="h2" fontWeight="medium" fontSize="xl" color="gray.700" minH="6" noOfLines={1}>
        {task.taskTemplate.name}
      </Text>

      <Divider />
    </VStack>
  );
};

import angular from 'angular';
import { trace } from 'components/trace';
import { ChecklistEditorSettingsHelper } from 'pages/checklists/_id/components/checklist-editor-settings-helper';

angular.module('frontStreetApp.services').service('ChecklistEditorService', function (SessionService) {
  const logger = trace({ name: 'ChecklistEditorService' });

  const self = this;

  self.setComment = function (checklistId, taskTemplateGroupId, content) {
    if (checklistId && taskTemplateGroupId && content) {
      const commentKey = self._generateCommentKey(checklistId, taskTemplateGroupId);
      SessionService.setChecklistEditorProperty(commentKey, {
        date: Date.now(), // We set the date so we can drop old comments
        content,
      });
    }
  };

  self.getComment = function (checklistId, taskTemplateGroupId) {
    if (checklistId && taskTemplateGroupId) {
      const commentKey = self._generateCommentKey(checklistId, taskTemplateGroupId);
      const value = SessionService.getChecklistEditorProperty(commentKey);
      return value && value.content;
    } else {
      return undefined;
    }
  };

  self.deleteComment = function (checklistId, taskTemplateGroupId) {
    if (checklistId && taskTemplateGroupId) {
      const commentKey = self._generateCommentKey(checklistId, taskTemplateGroupId);
      SessionService.deleteChecklistEditorProperty(commentKey);
    }
  };

  /**
   * The maximum number of comments to keep cached.
   * @type {number}
   */
  const MAX_COMMENTS = 20;
  const MAX_CHECKLIST_ACTIVE_STEPS = 50;

  /**
   * Get all checklist editor properties.
   */
  self.getProperties = function () {
    self._migrateCommentProperties();
    self._pruneCommentProperties(MAX_COMMENTS);
    self._pruneChecklistActiveSteps(MAX_CHECKLIST_ACTIVE_STEPS);

    return SessionService.getChecklistEditorProperties();
  };

  /**
   * Updates all comment properties so they have a date.
   *
   * @private
   */
  self._migrateCommentProperties = function () {
    angular.forEach(SessionService.getChecklistEditorProperties(), (value, key) => {
      if (self._isCommentKey(key)) {
        if (value) {
          if (!value.date) {
            // If there's a value and it has no date,
            // then we need to convert it to an object with a date
            const newValue = {
              date: Date.now(),
              content: value,
            };
            SessionService.setChecklistEditorProperty(key, newValue);
          }
        } else {
          // Otherwise, we need to delete the comment
          logger.info(`deleting cached comment [${key}]: no value`);
          SessionService.deleteChecklistEditorProperty(key);
        }
      }
    });
  };

  /**
   * Keep `max` comment properties, discarding the rest.
   *
   * @param max The number of comment properties to keep
   * @private
   */
  self._pruneCommentProperties = function (max) {
    const commentProperties = [];
    angular.forEach(SessionService.getChecklistEditorProperties(), (value, key) => {
      if (self._isCommentKey(key)) {
        commentProperties.push({ key, value });
      }
    });

    // Let's sort the properties in descending order and then only keep the top ones
    commentProperties
      .sort((a, b) => b.value.date - a.value.date)
      .slice(max)
      .forEach(prop => {
        logger.info(`deleting cached comment [${prop.key}]: expired`);
        SessionService.deleteChecklistEditorProperty(prop.key);
      });
  };

  self._pruneChecklistActiveSteps = max => {
    const activeSteps = Object.keys(SessionService.getChecklistEditorProperties())
      .filter(self._isActiveStepKey)
      .map(key => {
        const checklistId = ChecklistEditorSettingsHelper.extractChecklistIdFromActiveStepKey(key);
        const lastVisited = SessionService.getChecklistEditorLastVisited(checklistId);
        return { key, lastVisited };
      });

    // Let's sort the properties in descending order and then only keep the top ones
    activeSteps
      .sort((a, b) => b.lastVisited - a.lastVisited)
      .slice(max)
      .forEach(prop => {
        logger.info(`deleting active step [${prop.key}]: expired ${prop.lastVisited}`);
        SessionService.deleteChecklistEditorProperty(prop.key);
      });
  };

  self._generateCommentKey = function (checklistId, taskTemplateGroupId) {
    return `checklist:${checklistId}:task:${taskTemplateGroupId}:comment`;
  };

  self._isCommentKey = key => key.endsWith(':comment');
  self._isActiveStepKey = key => key.endsWith(':activeStep');
});

import * as React from 'react';
import { Box, IconButton, IconButtonProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Icon } from 'app/components/design/next';

export type RightSidebarTriggerProps = Omit<IconButtonProps, 'aria-label'> & {
  isOpen: boolean;
  onClick: () => void;
};

const MotionBox = motion(Box);

export const RightSidebarTrigger = ({ isOpen, onClick, ...props }: RightSidebarTriggerProps) => {
  return (
    <MotionBox
      position="absolute"
      top="4"
      zIndex="2"
      animate={{
        right: isOpen ? 232 : 0,
      }}
    >
      <IconButton
        aria-label={isOpen ? 'Close sidebar' : 'Open sidebar'}
        icon={<Icon icon={isOpen ? 'chevron-right' : 'chevron-left'} size="3" />}
        onClick={onClick}
        size="sm"
        variant="ghost"
        bgColor="white"
        color="gray.500"
        colorScheme="gray"
        borderLeftRadius="md"
        minW="7"
        borderRightRadius="0"
        {...props}
      />
    </MotionBox>
  );
};

import { FormFieldWidgetWithValue, mailto, SimpleFieldValue } from '@process-street/subgrade/process';
import { Link } from 'components/design/next';
import * as React from 'react';

export type ApprovalWidgetEmailRendererProps = {
  widget: FormFieldWidgetWithValue;
};

export const ApprovalWidgetEmailRenderer = ({ widget }: ApprovalWidgetEmailRendererProps) => {
  const rendererValue = React.useMemo(
    () => (widget.formFieldValue?.fieldValue && (widget.formFieldValue?.fieldValue as SimpleFieldValue).value) || '',
    [widget.formFieldValue],
  );

  const href = mailto({ to: rendererValue });

  return (
    <Link color="brand.500" fontWeight="normal" href={href}>
      {rendererValue}
    </Link>
  );
};

import * as React from 'react';
import { StackProps, VStack } from 'components/design/next';
import { useSelector } from '@xstate/react';
import { TaskTemplate, Widget, WidgetType } from '@process-street/subgrade/process';
import { MotionConfig, Reorder } from 'framer-motion';
import { useEvent } from 'react-use';
import _isEqual from 'lodash/isEqual';
import { FormEditorPageMachine, useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { makeGetActor } from 'app/pages/forms/_id/edit/components/widgets-list/make-get-actor';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';
import { useUIActorRef } from 'app/pages/forms/_id/shared';
import { WidgetListItemWrapper } from 'app/pages/forms/_id/edit/components/widgets-list/widget-list-item-wrapper';
import { PageEmptyState } from 'app/pages/forms/_id/edit/components';
import { useStateParam } from 'app/hooks/use-state-param';
import { StateFrom } from 'xstate';
import { Option } from 'space-monad';
import _pick from 'lodash/pick';
import { BottomDropzone, TopDropzone } from './widget-list-dropzone';

type WidgetsListProps = StackProps;

const isMeaningfulTaskTemplatePropertiesEqual = (a: TaskTemplate | undefined, b: TaskTemplate | undefined) => {
  const meaningfulProperties = ['id'];
  if (!a || !b) return a === b;

  return _isEqual(_pick(a, meaningfulProperties), _pick(b, meaningfulProperties));
};

export const WidgetsList = React.memo((props: WidgetsListProps) => {
  const actor = useFormEditorPageActorRef();
  const { uiActorRef } = useUIActorRef();
  const { send } = actor;
  const isMobile = uiActorRef.getSnapshot()?.context.isMobile;
  const isReadOnly = useSelector(actor, FormEditorPageActorSelectors.isReadOnly);

  const widgetsByTemplateRevisionId = useSelector(
    actor,
    FormEditorPageActorSelectors.getWidgetsByTemplateRevisionId,
    _isEqual,
  );

  const template = useSelector(actor, FormEditorPageActorSelectors.getTemplate);
  const taskTemplateGroupId = useStateParam({ key: 'groupId' });
  const taskTemplateSelector = React.useCallback(
    (state: StateFrom<FormEditorPageMachine>) => {
      return Option(taskTemplateGroupId)
        .map(groupId => FormEditorPageActorSelectors.getTaskTemplateByGroupId(groupId)(state))
        .get();
    },
    [taskTemplateGroupId],
  );
  const taskTemplate = useSelector(actor, taskTemplateSelector, isMeaningfulTaskTemplatePropertiesEqual);

  const widgetActorMap = useSelector(actor, state => state.context.widgetActorMap);

  const getActor = React.useCallback(
    <W extends Widget>(widget: W) => {
      return makeGetActor(widgetActorMap)(widget);
    },
    [widgetActorMap],
  );

  const visibleWidgets = React.useMemo(() => {
    const ws = widgetsByTemplateRevisionId?.all ?? [];
    // extra step here to make sure the actor exists,
    // we look it up again within the `match` branches for better type checking
    return ws.filter(w => Boolean(getActor(w)));
  }, [getActor, widgetsByTemplateRevisionId]);

  const lastWidgetIndex = visibleWidgets.length - 1;

  const shouldShowEmptyState = visibleWidgets.length === 0;

  const isPublishing = useSelector(actor, FormEditorPageActorSelectors.isPublishing);

  const handleReorder = React.useCallback(
    (widgets: Widget[]) => {
      send({ type: 'REORDER_WIDGETS', widgets });
    },
    [send],
  );

  // we send MOUSE_UP on these two window events because you can move the mouse off the drag icon while the mouse is clicked
  // and still be dragging. The machine will only respond to this event while in the dragging state.
  useEvent('mouseup', () => {
    const actorSnapshot = actor.getSnapshot();

    if (!actorSnapshot) return;

    const isReordering = FormEditorPageActorSelectors.isReordering(actorSnapshot);
    if (isReordering) {
      send('MOUSE_UP');
    }
  });

  useEvent('touchend', () => {
    const actorSnapshot = actor.getSnapshot();

    if (!actorSnapshot) return;

    const isReordering = FormEditorPageActorSelectors.isReordering(actorSnapshot);
    if (isReordering) {
      send('MOUSE_UP');
    }
  });

  if (!template) return null;

  const canReorder = !isMobile && !isReadOnly;

  return (
    <MotionConfig reducedMotion={'always'}>
      <TopDropzone h="280px" taskTemplateId={taskTemplate?.id ?? ''} />
      <VStack
        as={Reorder.Group}
        axis="y"
        values={visibleWidgets}
        // we are modifying the stack spacing to use padding instead of margin.
        // this allows a better hover experience when for drag'n'drop to insert a widget
        // because margin is not included in element.getBoundingClientRect()
        spacing="0"
        sx={{
          'listStyle': 'none',
          '[data-component="FormFieldLabel"]': { width: 'auto' },
          '& > *:first-of-type': {
            pb: 4,
          },
          '& > *:last-of-type': {
            pb: 0,
          },
          '& > *:not(style)~*:not(style)': {
            py: '4',
          },
        }}
        onReorder={handleReorder}
        alignItems="stretch"
        w="full"
        py={{ base: 0, md: 4 }}
        px="0"
        {...(isPublishing ? { pointerEvents: 'none' } : {})}
        {...props}
      >
        {shouldShowEmptyState ? (
          <PageEmptyState isReadOnly={isReadOnly} />
        ) : (
          visibleWidgets.map((widget, index) => (
            <WidgetListItemWrapper
              key={widget.header.id}
              widget={widget}
              isFirst={index === 0}
              isLast={index === lastWidgetIndex}
              canReorder={canReorder}
              getActor={getActor}
              boxProps={{ py: widget.header.type === WidgetType.Text ? '0px !important' : undefined }}
            />
          ))
        )}
      </VStack>
      <BottomDropzone taskTemplateId={taskTemplate?.id ?? ''} />
    </MotionConfig>
  );
});

WidgetsList.displayName = 'WidgetsList';

import { Checklist } from '@process-street/subgrade/process';
import { SessionState } from 'reducers/types';

export namespace ChecklistEditorSettingsHelper {
  export const getActiveStepKey = (checklistId: Checklist['id']) => `checklist:${checklistId}:activeStep`;

  export const getLastVisitedKey = (checklistId: Checklist['id']) => `checklist:${checklistId}:lastVisited`;

  export const getChecklistEditorProperty = (
    session: Pick<SessionState, 'checklistEditor'>,
    key: string,
  ): string | undefined => session.checklistEditor[key];

  export const getChecklistEditorActiveStep = (
    session: Pick<SessionState, 'checklistEditor'>,
    checklistId: Checklist['id'],
  ) => getChecklistEditorProperty(session, getActiveStepKey(checklistId));

  export const getChecklistEditorLastVisited = (
    session: Pick<SessionState, 'checklistEditor'>,
    checklistId: Checklist['id'],
  ): number => {
    const value = getChecklistEditorProperty(session, getLastVisitedKey(checklistId));
    return value ? Number(value) : 0;
  };

  export const extractChecklistIdFromActiveStepKey = (key: string) => key.split(':')[1];
}

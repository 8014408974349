import * as React from 'react';
import {
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Icon,
  Box,
  Text,
  UseDisclosureProps,
  Portal,
  Button,
  TextProps,
  IconProps,
} from 'components/design/next';
import { Template } from '@process-street/subgrade/process';
import { useGetAllConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { EditButton } from 'app/pages/pages/_id/edit/page/components/edit-button';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { mailto as buildMailTo } from '@process-street/subgrade/process';
import { useInjector } from 'components/injection-provider';
import { abbreviateForTitle } from '@process-street/subgrade/util';
import { useAdminEmails } from 'components/paywalls/use-admin-emails';

type EditWorkflowProps = UseDisclosureProps & {
  template: Template;
  button?: React.ReactElement;
  textProps?: TextProps;
  iconProps?: Partial<IconProps>;
};

export const EditWorkflow: React.FC<React.PropsWithChildren<EditWorkflowProps>> = ({
  template,
  button,
  textProps,
  iconProps,
  ...popoverProps
}) => {
  const user = useSelector(SessionSelector.getCurrentUser);
  const { data: { permissionMap } = {} } = useGetAllConsolidatedTemplatePermissionsQuery({
    select: allTemplatePermits => allTemplatePermits.find(fp => fp.templateId === template.id),
  });
  const { $state } = useInjector('$state');

  const [adminEmail, ...bcc] = useAdminEmails();

  const canUpdateTemplate = permissionMap?.templateUpdate;

  const editButton = button ?? (
    <Button
      size="sm"
      variant="ghost"
      leftIcon={<Icon icon="edit" size="3" color="gray.600" />}
      aria-label={`Edit ${template.name}`}
      colorScheme="gray"
      color="gray.600"
      backgroundColor="white"
      borderWidth="thin"
      borderColor="gray.300"
      borderStyle="solid"
      fontWeight="normal"
      fontSize="xs"
    >
      Edit
    </Button>
  );

  const mailTo = buildMailTo({
    to: adminEmail,
    subject: `Someone on your team wants to edit a workflow`,
    body: `${user?.username} would like to edit a workflow.

Update permissions here: ${window.location.origin}${$state.href('templateView', {
      title: `${abbreviateForTitle(template.name)}-`,
      id: template.id,
    })}`,
    bcc,
  });

  if (canUpdateTemplate)
    return (
      <EditButton
        textProps={textProps ?? { fontSize: 'xs', color: 'gray.600' }}
        iconProps={iconProps ?? { size: '3', color: 'gray.600' }}
        templateId={template.id}
      >
        {editButton}
      </EditButton>
    );

  return (
    <Popover trigger="hover" {...popoverProps}>
      <PopoverTrigger>
        <Box>{React.cloneElement(editButton, { isDisabled: true })}</Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent bg="gray.700" color="white" maxW="50" textAlign="center">
          <PopoverArrow bgColor="gray.700" />

          <PopoverBody py="2" px="3">
            <Text fontSize="sm">
              You don't have permission to edit this Workflow.{' '}
              <Link textDecoration="underline" href={mailTo}>
                Contact your Admin
              </Link>
              .
            </Text>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

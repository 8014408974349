import * as React from 'react';
import { Flex, Icon, Tooltip } from 'components/design/next';
import { TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { DynamicDueDateLabelService } from 'app/components/dynamic-due-dates/services/dynamic-due-date-label.service';
import { FormResponsePageMachineHooks } from 'app/pages/responses/_id/form-response-page-hooks';

export type TaskListItemDynamicDueDateIndicatorProps = {
  task: TaskWithTaskTemplate;
};

export const TaskListItemDynamicDueDateIndicator = ({ task }: TaskListItemDynamicDueDateIndicatorProps) => {
  const rule = FormResponsePageMachineHooks.useDueDateRuleForTaskTemplate(task.taskTemplate);
  const taskTemplates = FormResponsePageMachineHooks.useTaskTemplates();
  const dateWidgets = FormResponsePageMachineHooks.useDateWidgets();

  if (!rule || task.dueDate) return null;

  const label = DynamicDueDateLabelService.getFullLabelByRule(rule, taskTemplates, dateWidgets);

  return (
    <Tooltip label={label}>
      <Flex h="8" alignItems="center" justifyContent="center">
        <Icon aria-label={label} icon="clock" color="purple.500" size="4" />
      </Flex>
    </Tooltip>
  );
};

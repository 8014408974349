import { Accept, DropzoneState, useDropzone } from 'react-dropzone';
import { useCallback, useState } from 'react';
import { uploadToS3, uploadToWistia } from 'pages/pages/_id/edit/page/utils/upload.api';
import { VideoWidget, VideoWidgetService, WidgetConstants } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { isAxiosError } from '@process-street/subgrade/api';
import { noop } from 'lodash';
import { UploadableWidgets } from 'pages/pages/_id/edit/page/components/upload/index';

export const SIZE_TOO_LARGE = 'size-too-large';

export function useUploadQuery<W extends UploadableWidgets>(
  widget: W,
  onFinish: (widget: W) => void,
  accept: Accept | undefined,
): {
  dropzoneState: DropzoneState;
  progress: number | undefined;
  uploadError: AxiosError | undefined;
  clearUploadError: () => void;
} {
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const [uploadError, setUploadError] = useState<AxiosError | undefined>(undefined);

  const clearUploadError = () => {
    setUploadError(undefined);
    setProgress(undefined);
  };

  const onDrop = useCallback(async (acceptedFiles: Array<File>) => {
    if (acceptedFiles.length === 0) return;

    const [file] = acceptedFiles;
    const headerId = widget.header.id;

    uploadToS3<W>({
      file,
      headerId,
      onProgress: setProgress,
    })
      .then(result => onFinish(result.data))
      .catch(setUploadError);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- memoization
  }, []);

  const dropzoneState = useDropzone({
    onDrop,
    accept,
    validator: file => {
      if (file.size > WidgetConstants.MAX_FILE_SIZE) {
        return {
          code: SIZE_TOO_LARGE,
          message: `Uploaded files must be under ${WidgetConstants.MAX_FILE_SIZE / 1024 / 1024} MB.`,
        };
      }
      return null;
    },
  });

  return {
    dropzoneState,
    progress,
    uploadError,
    clearUploadError,
  };
}

export const useWistiaUploadQuery = ({
  widget,
  onFinish,
  onUploadStarted,
  accept,
  onDropFile = noop,
  isReadOnly = false,
}: {
  widget: VideoWidget;
  onFinish: (widget: VideoWidget) => void;
  onUploadStarted: () => void;
  accept: Accept | undefined;
  onDropFile?: () => void;
  isReadOnly?: boolean;
}): {
  dropzoneState: DropzoneState;
  progress: number | undefined;
  uploadError: AxiosError | undefined;
  clearUploadError: () => void;
} => {
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const [uploadError, setUploadError] = useState<AxiosError | undefined>(undefined);

  const clearUploadError = () => {
    setUploadError(undefined);
    setProgress(undefined);
  };

  const onDrop = useCallback(async (acceptedFiles: Array<File>) => {
    if (acceptedFiles.length === 0) return;
    onDropFile();
    const formData = new FormData();

    formData.append('file', acceptedFiles[0]);
    setProgress(1);
    onUploadStarted();
    try {
      const { data, status } = await uploadToWistia(formData, progress => setProgress(progress));

      if (status === 200) {
        onFinish({ ...widget, service: VideoWidgetService.Wistia, serviceCode: data.hashed_id });
      }
    } catch (e) {
      if (isAxiosError(e)) {
        setUploadError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- memoization
  }, []);

  const dropzoneState = useDropzone({
    onDrop,
    accept,
    validator: file => {
      if (file.size > WidgetConstants.MAX_FILE_SIZE) {
        return {
          code: SIZE_TOO_LARGE,
          message: `Uploaded files must be under ${WidgetConstants.MAX_FILE_SIZE / 1024 / 1024} MB.`,
        };
      }
      return null;
    },
    disabled: isReadOnly,
  });

  return {
    dropzoneState,
    progress,
    uploadError,
    clearUploadError,
  };
};

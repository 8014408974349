import { ApprovalStatus } from '@process-street/subgrade/approval-rule';
import { TaskWithTaskTemplate } from '@process-street/subgrade/process';
import * as React from 'react';
import { Button, Flex, Icon, IconButton, Textarea, VStack } from 'components/design/next';

export type ApproveTaskButtonProps = {
  task: TaskWithTaskTemplate;
  handleUpsertApproval: (task: TaskWithTaskTemplate, status: ApprovalStatus, comment?: string) => void;
  onApproving?: () => void;
  onCancel?: () => void;
};

export const ApproveTaskButton = ({ task, handleUpsertApproval, onApproving, onCancel }: ApproveTaskButtonProps) => {
  const [isApprovingWithComment, setIsApprovingWithComment] = React.useState(false);
  const [comment, setComment] = React.useState<string | undefined>(undefined);

  const handleApproveApproval = () => {
    handleUpsertApproval(task, ApprovalStatus.Approved, comment);
  };

  return (
    <>
      {isApprovingWithComment ? (
        <VStack w="full" pt="2">
          <Textarea
            value={comment}
            onChange={e => setComment(e.target.value)}
            bgColor="white"
            placeholder="Leave a comment (optional)"
          />
          <Flex
            w="full"
            direction={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'center', md: 'inherit' }}
            justifyContent="flex-end"
            pt="2"
          >
            <Button
              variant="ghost"
              color="gray.500"
              fontWeight="normal"
              onClick={() => {
                onCancel?.();
                setIsApprovingWithComment(false);
              }}
            >
              Cancel
            </Button>
            <Button
              aria-label="Approve with comment"
              colorScheme="blue"
              borderTopRightRadius="0"
              borderBottomRightRadius="0"
              onClick={handleApproveApproval}
            >
              Approve and send comment
            </Button>
          </Flex>
        </VStack>
      ) : (
        <Flex gap="0" w={{ base: 'full', md: 'auto' }}>
          <Button
            aria-label="Approve"
            colorScheme="blue"
            borderTopRightRadius={0}
            borderBottomRightRadius={0}
            w={{ base: 'full', md: '100px' }}
            onClick={handleApproveApproval}
          >
            Approve
          </Button>
          <IconButton
            aria-label="Approve with comments"
            colorScheme="blue"
            borderLeft="2px solid"
            borderColor="white"
            borderTopLeftRadius="0"
            borderBottomLeftRadius="0"
            onClick={() => {
              onApproving?.();
              setIsApprovingWithComment(true);
            }}
            icon={<Icon icon="comment" size="4" />}
          />
        </Flex>
      )}
    </>
  );
};

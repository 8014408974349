import {
  Alert,
  AlertIcon,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  useToast,
} from 'components/design/next';
import { DataSet, SavedView } from '@process-street/subgrade/process';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { GetAllDataSetsQuery, useUpdateSavedViewMutation } from '../../query-builder';
import { useDeleteSavedViewMutation } from 'pages/reports/data-sets/query-builder';
import { DeleteConfirmationModal } from '../delete-confirmation-modal';
import { OnSubmitCreateSavedViewParams, SavedViewFormModal } from '../saved-view-form-modal';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { useGetSavedViewLinksQuery } from 'pages/reports/data-sets/query-builder/get-saved-view-links';

export type UpdateSavedViewButtonProps = {
  savedView: SavedView;
  dataSet: DataSet;
};

export const UpdateSavedViewButton: React.FC<React.PropsWithChildren<UpdateSavedViewButtonProps>> = ({
  savedView,
  dataSet,
}) => {
  const updateDisclosure = useDisclosure();
  const deleteDisclosure = useDisclosure();
  const queryClient = useQueryClient();
  const toast = useToast();

  const savedViewLinks = useGetSavedViewLinksQuery(
    {
      savedViewId: savedView.id,
      dataSetId: dataSet.id,
    },
    {
      enabled: deleteDisclosure.isOpen,
    },
  );

  const hasLinkedTemplates = (savedViewLinks.data?.length ?? 0) > 0;

  const updateSavedViewMutation = useUpdateSavedViewMutation({
    onSuccess: (_, __) => {
      toast({
        status: 'success',
        title: `Saved view updated`,
      });
      queryClient.invalidateQueries(GetAllDataSetsQuery.key);
      updateDisclosure.onClose();
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems updating the saved view",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const deleteSavedViewMutation = useDeleteSavedViewMutation({
    onSuccess: () => {
      toast({
        status: 'success',
        title: `Saved view deleted`,
      });
      queryClient.invalidateQueries(GetAllDataSetsQuery.key);
      deleteDisclosure.onClose();
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems deleting the saved view",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const handleSubmit = (updatedSavedView: OnSubmitCreateSavedViewParams) => {
    updateSavedViewMutation.mutate({
      dataSetId: dataSet.id,
      savedViewId: savedView.id,
      name: updatedSavedView.name,
      columns: updatedSavedView.columns,
      config: savedView.config,
    });
  };

  const handleDelete = () => {
    deleteSavedViewMutation.mutate({
      dataSetId: dataSet.id,
      savedViewId: savedView.id,
    });
  };

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          isDisabled={!savedView.canUpdate}
          variant="ghost"
          colorScheme="gray"
          aria-label="Edit Saved View"
          ml={1}
          size="xs"
          icon={<Icon color="gray.400" icon="ellipsis" size="3" />}
        />
        <MenuList>
          <MenuItem
            color="gray.600"
            onClick={updateDisclosure.onOpen}
            icon={<Icon variant="far" icon="edit" size="4" />}
          >
            Rename Saved View
          </MenuItem>
          <MenuItem
            onClick={deleteDisclosure.onOpen}
            color="red.500"
            icon={<Icon variant="far" icon="trash-alt" size="4" />}
          >
            Delete Saved View
          </MenuItem>
        </MenuList>
      </Menu>

      <SavedViewFormModal
        initialValues={savedView}
        onSubmit={handleSubmit}
        onDelete={undefined}
        submitButtonText="Save"
        submitButtonLoadingText="Saving..."
        title="Edit Saved View"
        status={updateSavedViewMutation.status}
        deleteStatus={deleteSavedViewMutation.status}
        {...updateDisclosure}
      />

      <DeleteConfirmationModal
        {...deleteDisclosure}
        title="Delete Saved View"
        content={
          <Text>
            {hasLinkedTemplates && (
              <Alert fontWeight="700" color="gray.700" status="error" mb={4}>
                <AlertIcon />
                This Saved View is being used in a workflow.
              </Alert>
            )}
            Your Saved View will be deleted and <strong>can't be recovered</strong>.
          </Text>
        }
        isLoading={deleteSavedViewMutation.isLoading}
        onDelete={handleDelete}
      />
    </>
  );
};

import * as React from 'react';
import { Box, BoxProps } from 'components/design/next';
import { FormatBlockType } from 'directives/text-widget-toolbar/toolbar-blocks';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/models/dom';
import 'tinymce/themes/silver';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import tinyMCE from 'tinymce/tinymce';
import { Editor, IAllProps } from '@tinymce/tinymce-react';
import { useEffectOnce, useToggle } from 'react-use';
import { TemplateType } from '@process-street/subgrade/process';
import { match } from 'ts-pattern';

export type RichTextEditorSource =
  | 'TextContentWidgetPageEditor'
  | 'TextContentWidgetWorkflowEditor'
  | 'LongTextWidgetChecklist';

export interface RichTextEditorProps {
  editorProps?: Omit<IAllProps, 'init'>;
  init?: IAllProps['init'];
  editorWrapperProps?: Partial<BoxProps>;
  templateType?: TemplateType;
  source?: RichTextEditorSource;
}

export type TinyMCEEditor = NonNullable<InstanceType<typeof Editor>['editor']>;

export const PASTE_FROM_WORD_PLUGIN_URL =
  'https://unpkg.com/@pangaeatech/tinymce-paste-from-word-plugin@latest/index.js';

export const RichTextEditor: React.FC<React.PropsWithChildren<RichTextEditorProps>> = ({
  editorProps,
  init,
  editorWrapperProps,
  templateType,
  source,
}) => {
  const [loaded, toggle] = useToggle(false);
  useEffectOnce(() => {
    try {
      void tinyMCE.PluginManager.load('paste_from_word', PASTE_FROM_WORD_PLUGIN_URL);
      tinyMCE.addI18n('en', {
        'Advanced.LinkTitle': 'Tooltip',
        'LinkTitle': 'Tooltip',
        'Title': 'Tooltip',
        'link.title': 'Tooltip',
        'link.form.title.label': 'Tooltip',
      });
      toggle(true);
    } catch {
      console.error(`Url for plugin not found: ${PASTE_FROM_WORD_PLUGIN_URL}`);
    }
  });

  const placeholderStyles = match(source)
    .with('TextContentWidgetPageEditor', () => ({ pt: '15px', pl: '4px' }))
    .with('TextContentWidgetWorkflowEditor', () => ({ pt: '8px', pl: '4px' }))
    .with('LongTextWidgetChecklist', () => ({ pt: '8px' }))
    .otherwise(() => ({}));

  if (!loaded) return null; // needed since we're calling the plugin manager in the useEffectOnce before using the plugin

  return (
    <Box
      {...editorWrapperProps}
      aria-placeholder="Write something here..."
      sx={{
        '.mce-content-body': {
          border: 'none',
          outline: 'none',
          // a little trick to make sure the cursor is visible at the beginning of the line
          mx: -1,
          py: 2,
        },
        '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before': {
          ...placeholderStyles,
          lineHeight: templateType === TemplateType.Playbook ? '1.5' : '.5',
          cursor: 'text',
          color: 'gray.400',
        },
        '.mce-content-body[data-mce-placeholder]:focus:not(.mce-visualblocks)::before': {
          color: 'gray.500',
        },
        ...editorWrapperProps?.sx,
      }}
    >
      <Editor inline={true} init={{ ...INIT, ...init }} {...editorProps} />
    </Box>
  );
};

const INIT: IAllProps['init'] = {
  menubar: false,
  contextmenu: false,
  toolbar: [
    ['bold', 'underline', 'italic'],
    ['blocks'],
    ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
    ['bullist', 'numlist'],
  ]
    .map(section => section.join(' '))
    .join(' '),
  // removed table properties & delete table items
  table_toolbar:
    'tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
  browser_spellcheck: true,
  entity_encoding: 'raw',
  plugins: 'autolink link lists table paste_from_word',
  link_default_target: '_blank',
  link_assume_external_targets: false,

  link_target_list: [
    { title: 'Current window', value: '_self' },
    { title: 'New window', value: '_blank' },
  ],
  valid_elements:
    'a[href|rel|target|title],strong/b,em/i,u,p[class|style],ul,ol,li[style],s,' +
    'br,hr,h1[style],h2[style],h3[style],h4[style],h5[style],h6[style],pre,code,span[style],' +
    'table[border|cellpadding|cellspacing|style],thead,tbody,tr,td[colspan|rowspan|style],th[colspan|rowspan|style]',
  valid_classes: Object.values(FormatBlockType).join(' '),
  valid_styles: {
    li: 'text-align',
    p: 'padding-left,text-align',
    span: 'background-color,color,text-decoration',
    h1: 'text-align',
    h2: 'text-align',
    h3: 'text-align',
    h4: 'text-align',
    h5: 'text-align',
    h6: 'text-align',
    table: 'width,text-align,text-decoration',
  },
  paste_auto_cleanup_on_paste: true,
  fix_list_elements: true,
  convert_urls: false,
  placeholder: 'Write something here...',
  toolbar_mode: 'scrolling', // https://www.tiny.cloud/docs/tinymce/6/toolbar-configuration-options/#toolbar
  paste_webkit_styles: 'all',
  paste_remove_styles_if_webkit: false,
  skin: false,
  content_css: false,
  lists_indent_on_tab: false, // This prevents double indents/outdents on lists
  setup: editor => {
    editor.on('keydown', e => {
      // Ident / Outdent when pressing tab or shift + tab
      if (e.key === 'Tab') {
        e.preventDefault();
        editor.execCommand(e.shiftKey ? 'Outdent' : 'Indent');
      }
    });
  },
};

import * as React from 'react';
import { VStack, Textarea, Button, FormLabel, Text, FormControl } from 'components/design/next';
import {
  GetAllNativeAutomationsQuery,
  UpdateNativeAutomationActionsMutation,
} from 'features/native-automations/query-builder';
import { TaskTemplate } from '@process-street/subgrade/process';
import { queryClient } from 'components/react-root';

export type CodeTaskTemplateEditorProps = {
  taskTemplate: TaskTemplate;
};

export const CodeTaskTemplateEditor: React.FC<CodeTaskTemplateEditorProps> = ({ taskTemplate }) => {
  const taskAutomationQuery = GetAllNativeAutomationsQuery.useGetTaskTemplateAutomationQuery({
    templateRevisionId: taskTemplate?.templateRevision.id,
    taskTemplateId: taskTemplate?.id,
  });

  const [code, setCode] = React.useState<string>('');
  const [inputs, setInputs] = React.useState<string>('{}');

  React.useEffect(
    function readAutomation() {
      if (!taskAutomationQuery.data) return;

      const { actions } = taskAutomationQuery.data;
      const codeAction = actions.find(action => action.actionType === 'ExecuteCode');
      if (codeAction) {
        setCode(codeAction.config.code ?? '');
        setInputs(JSON.stringify(codeAction.config.inputData ?? '{}'));
      }
    },
    [taskAutomationQuery.data],
  );

  const updateNativeAutomationActions = UpdateNativeAutomationActionsMutation.useMutation({
    onSuccess: data => {
      queryClient.setQueryData<GetAllNativeAutomationsQuery.Response>(
        GetAllNativeAutomationsQuery.getKey({ templateRevisionId: taskTemplate!.templateRevision.id }),
        GetAllNativeAutomationsQuery.taskTemplateAutomationUpdater(taskTemplate!.id, data),
      );
    },
  });

  const updateAutomation = () => {
    const nativeAutomation = taskAutomationQuery.data;
    if (!nativeAutomation) return;
    const { actions } = nativeAutomation;
    const codeAction = actions.find(action => action.actionType === 'ExecuteCode');
    if (!codeAction) return;

    const newActions = actions.map(action =>
      action.actionType === 'ExecuteCode'
        ? {
            ...action,
            config: {
              ...action.config,
              code,
              inputData: JSON.parse(inputs),
            },
          }
        : action,
    );

    updateNativeAutomationActions.mutate({
      nativeAutomationId: nativeAutomation.id,
      actions: newActions,
    });
  };

  return (
    <VStack my={4}>
      <FormControl flex="1">
        <FormLabel>
          <Text variant="-2u" fontWeight="semibold">
            Function input
          </Text>
        </FormLabel>
        <Textarea
          placeholder="Provide input data for your code"
          value={inputs}
          onChange={e => setInputs(e.target.value)}
        />
      </FormControl>
      <FormControl flex="1">
        <FormLabel>
          <Text variant="-2u" fontWeight="semibold">
            Function code
          </Text>
        </FormLabel>
        <Textarea placeholder="Enter JS code of your function" value={code} onChange={e => setCode(e.target.value)} />
      </FormControl>
      <Button onClick={updateAutomation}>Save</Button>
    </VStack>
  );
};

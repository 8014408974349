import { VideoWidget, VideoWidgetService } from '@process-street/subgrade/process';
import React from 'react';
import { EmbeddedVideo } from '../EmbeddedVideo';

export interface ChecklistVideoWidgetComponentProps {
  widget: VideoWidget;
}

const getServiceUrl = (widget: VideoWidget) => {
  switch (widget.service) {
    case VideoWidgetService.S3:
      return widget.file?.url;
    case VideoWidgetService.Vimeo:
      return `https://vimeo.com/${widget.serviceCode}`;
    case VideoWidgetService.Wistia:
      return `https://process.wistia.com/medias/${widget.serviceCode}`;
    case VideoWidgetService.YouTube:
      return `https://youtube.com/watch?v=${widget.serviceCode}`;
    case VideoWidgetService.Loom:
      return `https://www.loom.com/embed/${widget.serviceCode}`;
  }
};

export const ChecklistVideoWidget: React.FunctionComponent<
  React.PropsWithChildren<ChecklistVideoWidgetComponentProps>
> = ({ widget }) => {
  const description = widget.description && widget.description.trim().length > 0;

  return widget.service ? (
    <div className="widget-video">
      <figure>
        <EmbeddedVideo service={widget.service} code={widget.serviceCode} fileUrl={widget.file?.url} />
        <div className="visible-print">({getServiceUrl(widget)})</div>
        {description && <figcaption>{widget.description}</figcaption>}
      </figure>
    </div>
  ) : null;
};

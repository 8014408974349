import * as React from 'react';
import { components, OnChangeValue, SelectInstance } from 'react-select';
import { Box, HStack, Icon, Text, useOutsideClick } from 'components/design/next';
import { DataSetColumnDef } from '@process-street/subgrade/process';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { BLVD_SELECT_MAGIC_GRID_AREA, BlvdSelect } from 'components/design/BlvdSelect';
import { match } from 'ts-pattern';
import { useSelectedSavedView } from 'pages/reports/data-sets/components/data-set-page/selected-saved-view-id-store';

export type ColumnOptionType = {
  label: string;
  value: string;
  column: DataSetColumnDef;
};

export type DataSetColumnsVisibilityFilterProps = {
  selected: ColumnOptionType[];
  columns: DataSetColumnDef[];
  onChange: (columns: OnChangeValue<ColumnOptionType, true>) => void;
  isDisabled: boolean;
};

export const columnDefToOptionType = (def: DataSetColumnDef): ColumnOptionType => ({
  label: def.name,
  value: def.id,
  column: def,
});

export const DataSetColumnsVisibilityFilter: React.FC<React.PropsWithChildren<DataSetColumnsVisibilityFilterProps>> = ({
  columns,
  selected,
  onChange,
  isDisabled,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { selectedDataSet } = useSelectedSavedView();
  const selectRef = React.useRef<SelectInstance<ColumnOptionType, true>>(null);
  const menuRef = React.useRef<HTMLDivElement | null>(null);

  const options: ColumnOptionType[] = columns.map(column => ({
    label: column.name,
    value: column.id,
    column,
  }));

  useOutsideClick({
    ref: menuRef,
    handler: () => {
      if (isOpen && menuRef.current) {
        setIsOpen(false);
      }
    },
    enabled: isOpen,
  });

  const handleChange = (selectedValue: OnChangeValue<ColumnOptionType, true>) => {
    onChange(selectedValue);
  };

  const handleOpen = () => {
    setTimeout(
      () => {
        menuRef.current = selectRef.current?.menuListRef?.closest?.('.blvd-select__menu') ?? null;
      },
      // This timeout is needed to wait for the menu to be visible and also prevents the menu from closing right away when the button is clicked.
      500,
    );

    setIsOpen(true);
  };

  const handleClose = () => {
    menuRef.current = null;
    setIsOpen(false);
  };

  const hasHiddenColumns = selected.length < columns.length;
  const shouldHighlightButton = isOpen || hasHiddenColumns;
  const filterColor = isDisabled ? 'gray.300' : shouldHighlightButton ? 'brand.500' : 'gray.600';

  if (!selectedDataSet) return null;
  return (
    <Box
      sx={{
        '.blvd-select': {
          '.blvd-select__control': {
            'boxShadow': 'none',
            'borderColor': isOpen || hasHiddenColumns ? 'brand.500' : 'transparent',
            'bgColor': isOpen || hasHiddenColumns ? 'brand.100' : 'transparent',
            'borderRadius': 'full',
            'fontWeight': isOpen || hasHiddenColumns ? '500' : 'normal',
            'minH': 10,

            '&:hover': {
              cursor: 'pointer',
              borderColor: 'brand.500',
              bgColor: 'brand.100',
            },
          },
        },
      }}
    >
      <BlvdSelect
        selectRef={selectRef}
        isDisabled={isDisabled}
        value={selected}
        onChange={handleChange}
        options={options}
        menuIsOpen={isOpen}
        onMenuOpen={handleOpen}
        onMenuClose={handleClose}
        components={{
          MultiValue: Empty,
          IndicatorsContainer: Empty,
          Placeholder: Empty,
          ValueContainer: props => {
            const selectedColumnsCount = BlvdSelectHelpers.isOptionsType(selected) ? selected.length : columns.length;

            return (
              <components.ValueContainer {...props}>
                <HStack display="inline-flex" gridArea={BLVD_SELECT_MAGIC_GRID_AREA}>
                  <Icon icon="eye-slash" size="4" color={filterColor} />
                  <Text color={filterColor}>
                    {match(selectedColumnsCount)
                      .with(0, () => 'Fields')
                      .with(1, () => '1 Field')
                      .otherwise(l => `${l} Fields`)}
                  </Text>
                  <Icon icon="chevron-down" size="3" color={filterColor} />
                </HStack>

                {props.children}
              </components.ValueContainer>
            );
          },
        }}
        styles={{
          menu: s => ({
            ...s,
            minWidth: '250px',
          }),
        }}
        menuControls
        fixedSize
        isClearable
        isMulti
        isSearchable
      />
    </Box>
  );
};

const Empty: React.FC<React.PropsWithChildren<unknown>> = () => null;

import * as React from 'react';
import {
  Button,
  Center,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  VStack,
} from 'components/design/next';
import { UserUtils } from '@process-street/subgrade/util';
import { UpsertDataSetPermitMutation, UpsertSavedViewPermitMutation } from '../../query-builder';
import { DataSetPermitWithOm, isDataSetPermitWithOm, SavedViewPermitWithOm } from '@process-street/subgrade/process';
import { useIsMutating } from 'react-query';
import { ChakraAvatar } from 'components/design/next/chakra-avatar';
import { HStack } from '@chakra-ui/react';

type PermitWithOm = DataSetPermitWithOm | SavedViewPermitWithOm;

export type PermitRowProps<T extends PermitWithOm> = {
  isDisabled: boolean;
  permit: T;
  handleRemove: () => void;
  handleUpgrade?: () => void;
  handleDowngrade?: () => void;
};

export const PermitRow = <T extends PermitWithOm>(props: PermitRowProps<T>) => {
  const { permit, handleRemove } = props;
  const rowWidth = 32;
  const omUser = permit.organizationMembership;

  const rowHeight = 16;

  const isDataSetPermitsMutating = useIsMutating({ mutationKey: UpsertDataSetPermitMutation.getKey() });
  const isSavedViewPermitsMutating = useIsMutating({ mutationKey: UpsertSavedViewPermitMutation.getKey() });

  const isDataSetPermit = isDataSetPermitWithOm(permit);

  const isLoading = isDataSetPermit ? isDataSetPermitsMutating > 0 : isSavedViewPermitsMutating > 0;

  return (
    <Flex minH={rowHeight} px={3} role="listitem">
      <Center w={8} h={rowHeight}>
        <ChakraAvatar size="md" user={omUser.user} />
      </Center>
      <Center minH={rowHeight} color="gray.600" display={['none', 'flex']} pt={3} pb={3}>
        <VStack spacing="0.5" alignItems="left" pl="4">
          <Text as="span" dangerouslySetInnerHTML={{ __html: UserUtils.getLabel(omUser.user, false) }} />
        </VStack>
      </Center>
      <Spacer />
      <HStack>
        <Menu>
          <MenuButton
            isDisabled={props.isDisabled}
            as={Button}
            variant="ghost"
            rightIcon={<Icon icon="chevron-down" size="4" variant="far" />}
          >
            {isDataSetPermit ? 'Edit all views' : 'View'}
          </MenuButton>
          <MenuList>
            <MenuItem icon={<Icon variant="far" size="4" icon="edit" />} onClick={props.handleUpgrade}>
              Edit all views
            </MenuItem>
            <MenuItem icon={<Icon variant="far" size="4" icon="eye" />} onClick={props.handleDowngrade}>
              View
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>

      <Center w={rowWidth} h={rowHeight} ml={4} color="gray.500" display={['none', 'flex']} justifyContent="flex-end">
        <Text variant={'span'}>{UserUtils.getMembershipType(omUser)}</Text>
      </Center>
      <Center w={6} h={rowHeight} ml={4}>
        <IconButton
          marginTop="5px"
          size="md"
          color="gray.500"
          _hover={{ color: 'gray.700' }}
          variant="unstyled"
          aria-label="Remove"
          icon={<Icon variant="far" size="5" icon="user-times" />}
          _focus={{ boxShadow: 'none' }}
          onClick={handleRemove}
          isLoading={isLoading}
        />
      </Center>
    </Flex>
  );
};

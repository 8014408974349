import { StringUtils } from '../../util/string-utils';
import { AuditMetadata, GetPathTuples, Muid, Period } from '../../core';
import { ExecuteAiPrompt } from './execute-ai-prompt';
import { match } from 'ts-pattern';
import { Checklist, ChecklistRevision, Task } from '../checklist-model';
import jsonpath from 'jsonpath';

export type NativeAutomationType =
  | 'CustomTemplateNotification'
  | 'AiTask'
  | 'AutoSendRichEmailWidget'
  | 'DataSetWorkflowAutomation'
  | 'WorkflowPageAutomation';

export interface NativeAutomation {
  id: Muid;
  stableId: Muid;
  audit: AuditMetadata;
  deletedDate?: number;
  deletedById?: Muid;
  organizationId: Muid;
  status: 'Active' | 'Disabled' | 'Deleted';
  trigger: NativeAutomation.Trigger;
  actions: NativeAutomation.Action[];
  automationType: NativeAutomationType;
}

export interface NativeAutomationLink {
  id: Muid;
  organizationId: Muid;
  nativeAutomationId: Muid;
  formFieldWidgetId?: Muid;
  taskTemplateId?: Muid;
  templateRevisionId?: Muid;
  templateId?: Muid;
  dataSetId?: Muid;
  savedViewId?: Muid;
}

export interface NativeAutomationWithLink {
  automation: NativeAutomation;
  link: NativeAutomationLink;
}

export namespace NativeAutomation {
  export type Prefix = 'native';
  export const APP_NAME = 'native:ProcessStreet';

  // NOTE: We depart from the BE here by modeling configs directly with triggerType, but the runtime values are the same
  export type Trigger =
    | {
        triggerType: 'TaskCompleted';
        config: {
          taskTemplateId: Muid;
        };
      }
    | {
        triggerType: 'TaskUncompleted';
        config: {
          taskTemplateId: Muid;
        };
      }
    | {
        triggerType: 'TaskDueDateReached';
        config: {
          taskTemplateId: Muid;
        };
      }
    | {
        triggerType: 'TaskDueDateCreated';
        config: {
          taskTemplateId: Muid;
        };
      }
    | {
        triggerType: 'TaskDueDateUpdated';
        config: {
          taskTemplateId: Muid;
        };
      }
    | {
        triggerType: 'TaskDueDateDeleted';
        config: {
          taskTemplateId: Muid;
        };
      }
    | {
        triggerType: 'WorkflowRunCreated';
        config: {
          templateRevisionId: Muid;
        };
      }
    | {
        triggerType: 'WorkflowRunCompleted';
        config: {
          templateRevisionId: Muid;
        };
      }
    | {
        triggerType: 'WorkflowRunUncompleted';
        config: {
          templateRevisionId: Muid;
        };
      }
    | {
        triggerType: 'Scheduled';
        config: {
          offset: Partial<Period>;
          offsetDirection: 'On' | 'Before' | 'After';
          createTrigger: CreateOrUpdateTrigger;
          updateTriggers: CreateOrUpdateTrigger[];
          deleteTriggers: DeleteTrigger[];
        };
      }
    | {
        triggerType: 'DataSetRowCreated' | 'DataSetRowUpdated' | 'DataSetRowChanged';
        config: {
          dataSetId: Muid;
          savedViewId: Muid;
        };
      };

  export type GetTriggerByType<T extends Trigger['triggerType']> = Extract<Trigger, { triggerType: T }>;

  type CreateOrUpdateTrigger = Trigger & {
    scheduledJobKeyPath: string;
    scheduledJobDatePath: string;
  };

  type DeleteTrigger = Trigger & {
    scheduledJobKeyPath: string;
  };

  export const getJsonPath = <Obj extends object = Record<string, any>>(...keys: GetPathTuples<Obj>) => {
    return jsonpath.stringify(keys as string[]);
  };

  export enum ConditionType {
    Contains = 'Contains',
    DoesNotContain = 'DoesNotContain',
    Equals = 'Equals',
    NotEquals = 'NotEquals',
    IsEmpty = 'IsEmpty',
    IsNotEmpty = 'IsNotEmpty',
  }

  export type MappingKey = `$.actions.${Uncapitalize<ActionType>}${number}.${string}${`.${string}` | ''}`;

  export const makeMappingKey = ({
    actionType = 'ExecuteAiPrompt',
    index = 0,
    path = 'output',
  }: {
    actionType?: ActionType;
    index?: number;
    path?: string;
  } = {}): MappingKey => `$.actions.${StringUtils.uncapitalize(actionType)}${index}.${path}`;

  export const DEFAULT_MAPPING_KEY: MappingKey = makeMappingKey();

  export const MATH_MAPPING_KEY = makeMappingKey({
    actionType: 'ExecuteMathFormula',
    path: 'output',
  });

  export const MATH_LEFT_OPERAND_MAPPING_KEY: MappingKey = makeMappingKey({
    actionType: 'ExecuteMathFormula',
    path: 'leftOperand',
  });

  export const MATH_RIGHT_OPERAND_MAPPING_KEY: MappingKey = makeMappingKey({
    actionType: 'ExecuteMathFormula',
    path: 'rightOperand',
  });

  export type Operator = 'Add' | 'Subtract' | 'Multiply' | 'Divide';

  // NOTE: We depart from the BE here by modeling configs directly with actionType, but the runtime values are the same
  export type Action =
    | {
        actionType: 'CompleteTask';
        key: `completeTask${number}`;
        config: {
          checklistRevisionIdPath: string;
          taskTemplateId: Muid;
        };
      }
    | {
        actionType: 'ExecuteAiPrompt';
        key: `executeAiPrompt${number}`;
        config: ExecuteAiPrompt.PromptConfig;
      }
    | {
        actionType: 'ExecuteMathFormula';
        key: `executeMathFormula${number}`;
        config: {
          mapping: Record<MappingKey, Muid>;
          checklistRevisionIdPath: string;
          operator?: Operator;
        };
      }
    | {
        actionType: 'SendRichEmailWidget';
        key: `sendRichEmailWidget${number}`;
        config: {
          checklistRevisionIdPath: string;
          richEmailFormFieldWidgetId: Muid;
        };
      }
    | {
        actionType: 'UpdateFormFields';
        key: `updateFormFields${number}`;
        config: {
          checklistRevisionIdPath: string;
          mapping: Record<MappingKey, Muid>;
        };
      }
    | {
        actionType: 'SendEmail';
        key: `sendEmail${number}`;
        config: {
          emailTemplate: 'CustomTemplateNotification';
          emailTemplateConfig: {
            customNotificationType: CustomNotificationType;
            checklistRevisionIdPath: string;
            taskIdPath?: string;
            customMessage?: string;
          };
          recipients: SendEmailActionRecipient[];
        };
      }
    | {
        actionType: 'CreateWorkflowRun';
        key: `action${number}`;
        config: {
          templateId: Muid;
        };
      }
    | {
        actionType: 'ConditionalStop';
        key: `action${number}`;
        config: {
          clause: {
            conditionType: ConditionType;
            operandA: `$.trigger.event.${'updatedRow' | 'originalRow'}.cells.${Muid}.value`;
            operandB?: string;
          };
        };
      }
    | {
        actionType: 'PublishTaskToPage';
        key: `action${number}`;
        config: {
          taskTemplateGroupId: Muid;
          widgetGroupId: Muid;
        };
      }
    | {
        actionType: 'ExecuteCode';
        key: `executeCode${number}`;
        config: {
          code: string;
          inputData: string;
        };
      };

  export type SendEmailActionRecipient =
    | { recipientType: 'User'; userId: Muid }
    | { recipientType: 'JsonPath'; path: string }
    | { recipientType: 'MergeTag'; key: string };

  export type EmailTemplate = 'CustomTemplateNotification';

  export type CustomNotificationType =
    | 'OnWorkflowRunCreated'
    | 'AfterWorkflowRunCreated'
    | 'OnTaskCompleted'
    | 'OnTaskDueDateReached'
    | 'BeforeTaskDueDate'
    | 'AfterTaskDueDate'
    | 'AfterTaskCompleted'
    | 'OnWorkflowRunCompleted'
    | 'AfterWorkflowRunCompleted';

  export type ActionType = Action['actionType'];
  export type GetActionByType<T extends Action['actionType']> = Extract<Action, { actionType: T }>;
  export type GetActionConfigByType<T extends Action['actionType']> = Extract<Action, { actionType: T }>['config'];

  export function isExecuteAiPromptAction(
    action: Action,
  ): action is Extract<Action, { actionType: 'ExecuteAiPrompt' }> {
    return action.actionType === 'ExecuteAiPrompt';
  }

  export function isUpdateFormFieldsAction(
    action: Action,
  ): action is Extract<Action, { actionType: 'UpdateFormFields' }> {
    return action.actionType === 'UpdateFormFields';
  }

  export function isMathFormulaAction(action: Action): action is Extract<Action, { actionType: 'ExecuteMathFormula' }> {
    return action.actionType === 'ExecuteMathFormula';
  }

  export type EventStatus = 'AutomationRunning' | 'AutomationComplete' | 'AutomationFailed';

  export type Event = {
    nativeAutomationId: Muid;
    checklistRevisionId: Muid;
    status: EventStatus;
    errorMessage?: string;
  };

  export namespace AutomationContext {
    export type TriggerEvent<Obj = unknown> = {
      trigger: {
        event: Obj;
        additionalData: {
          mergeTagContext: {
            customMap: Record<string, unknown>;
            runCreatedByEmail: string;
          };
        };
      };
    };

    export type WorkflowRunCreated = TriggerEvent<{
      checklist: Checklist;
      checklistRevision: ChecklistRevision;
    }>;

    export type TaskCompleted = TriggerEvent<{
      task: Task;
      checklist: Checklist;
      checklistRevision: ChecklistRevision;
    }>;

    export type TaskUncompleted = TriggerEvent<{
      task: Task;
      checklist: Checklist;
      checklistRevision: ChecklistRevision;
    }>;

    export type TaskDueDateCreated = TriggerEvent<{
      task: Task;
      checklist: Checklist;
      checklistRevision: ChecklistRevision;
    }>;

    export type TaskDueDateUpdated = TriggerEvent<{
      task: Task;
      checklist: Checklist;
      checklistRevision: ChecklistRevision;
    }>;

    export type TaskDueDateDeleted = TriggerEvent<{
      task: Task;
      checklist: Checklist;
      checklistRevision: ChecklistRevision;
    }>;

    export type TaskDueDateReached = TriggerEvent<{
      task: Task;
      checklist: Checklist;
      checklistRevision: ChecklistRevision;
    }>;

    export type WorkflowRunCompleted = TriggerEvent<{
      checklist: Checklist;
      checklistRevision: ChecklistRevision;
    }>;
  }

  export interface Log {
    id: Muid;
    timestamp: number;
    executionId: Muid;
    currentActionIndex: number;
    currentActionType: Action['actionType'];
    currentActionStatus: 'Waiting' | 'Running' | 'Completed' | 'Failed';
    automationStatus: 'Running' | 'Completed' | 'Failed';
    errorMessage?: string;
  }

  export function isUpdateFormFieldsValid(action: GetActionByType<'UpdateFormFields'>) {
    const { mapping } = action.config;
    const keysExist = Object.keys(mapping).length > 0;
    const valuesExist = Object.values(mapping).every(Boolean);
    const isValid = keysExist && valuesExist;
    return isValid;
  }

  export function isMathFormulaValid(action: GetActionByType<'ExecuteMathFormula'>) {
    const { mapping, operator } = action.config;
    const isValid = Boolean(
      mapping[NativeAutomation.MATH_LEFT_OPERAND_MAPPING_KEY] &&
        mapping[NativeAutomation.MATH_RIGHT_OPERAND_MAPPING_KEY] &&
        operator,
    );

    return isValid;
  }

  export function isAutomationConfigured(automation: NativeAutomation) {
    return automation.actions.every(action => {
      return match(action)
        .with({ actionType: 'ExecuteAiPrompt' }, ExecuteAiPrompt.isActionValid)
        .with({ actionType: 'UpdateFormFields' }, isUpdateFormFieldsValid)
        .with({ actionType: 'ExecuteMathFormula' }, isMathFormulaValid)
        .otherwise(() => true);
    });
  }

  export interface CreateNativeAutomationForm {
    automationType: NativeAutomationType;
    linkedEntity:
      | { templateRevisionId: Muid }
      | { taskTemplateId: Muid }
      | { formFieldWidgetId: Muid }
      | { dataSetId: Muid; savedViewId: Muid; workflowId: Muid };
    trigger?: Trigger;
    actions?: Action[];
  }

  export const workflowPageAutomationDescription = 'update and publish a Page';
}

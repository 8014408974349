import * as React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonProps,
  forwardRef,
  HStack,
  Icon,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useBoolean,
  useDisclosure,
  useToast,
  VStack,
} from 'components/design/next';
import {
  ChecklistRevision,
  FormFieldValue,
  RichEmailWidgetAttachmentWithS3File,
  SendRichEmailFieldValue,
  SendRichEmailFormFieldConfig,
  SendRichEmailFormFieldValue,
  SendRichEmailFormFieldWidget,
  Task,
  Template,
  TemplateRevision,
} from '@process-street/subgrade/process';
import { getEmailBody, isSendEmailDisabled, normalizeBodyToHtml } from '../util';
import {
  SendEmailResponseType,
  useGetFormFieldValuesByChecklistRevisionIdQuery,
  useSendEmailMutation,
} from 'features/widgets/query-builder';
import {
  EmailBodyIframe,
  EmailMetaData,
  EmailMetaDataRowKey,
  EmailMetaDataRowValue,
  sanitizeEmailBody,
  sanitizeEmailRecipientsField,
  sanitizeEmailSubject,
} from '../common/common';
import { SendRichEmailTimestamp } from '../../send-rich-email-timestamp';
import { useParseChecklistMergeTagsByTarget } from './use-parse-checklist-merge-tags-by-target';
import { Option } from '@process-street/subgrade/core';
import { MergeTagMode, MergeTagTarget } from '@process-street/subgrade/form';
import { padTrailingBr, preserveEmptyParagraphsWithNbsp, safeEscapeHtml } from '@process-street/subgrade/util';
import { useAreFormFieldsUpdating } from './use-are-form-fields-updating';
import { isValidEmail } from '@process-street/subgrade/process/validation';
import { SendRichEmailChecklistEditor } from './editor';
import { EmailFieldValues } from 'features/widgets/components/send-rich-email/common/email-fields';
import { SendTestMailtoButton } from 'features/widgets/components/send-rich-email/template/send-test-mailto-button';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { EmailAttachmentItem } from 'features/widgets/components/send-rich-email/email_attachment/email-attachment-item';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { useGetIsPaidAccount } from 'utils/plans/get-is-paid-account/use-get-is-paid-account';
import { PaidPlanRequiredPopover } from 'features/ui/popovers/paid-plan-required/paid-plan-required-popover';
import { useAiTaskOutputWidgetStatus } from 'pages/runs/_id/hooks/use-ai-task-output-widget-status';
import { useOutputWidgetAiTasksMap } from 'features/native-automations/hooks/use-output-widget-ai-tasks-map';
import { AiTaskAudit } from 'pages/runs/_id/components/ai-task-audit';
import { WidgetProvider } from 'pages/runs/_id/hooks/use-widget-context';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';
import { useMatch } from '@process-street/adapters/navigation';

export const EMAIL_SENDING_PREVENT_RAPID_FIRE_TIMEOUT = 8000;

export type SendRichEmailChecklistWidgetProps = {
  widget: SendRichEmailFormFieldWidget;
  checklistRevisionId: ChecklistRevision['id'];
  formFieldValueId?: SendRichEmailFormFieldValue['id'];
  onUpdate: (
    widget: SendRichEmailFormFieldWidget,
    fieldValue: SendRichEmailFormFieldValue['fieldValue'],
    shouldSkipDebounce?: boolean,
  ) => Promise<FormFieldValue>;
  taskId: Task['id'];
  templateRevisionId: TemplateRevision['id'];
  templateId: Template['id'];
  readOnly: boolean;
};

export const SendRichEmailChecklistWidget: React.FC<React.PropsWithChildren<SendRichEmailChecklistWidgetProps>> = ({
  checklistRevisionId,
  formFieldValueId,
  onUpdate,
  taskId,
  templateRevisionId,
  widget,
  readOnly,
}) => {
  const formFieldValueQuery = useGetFormFieldValuesByChecklistRevisionIdQuery(
    { checklistRevisionId },
    {
      select: formFieldValues => {
        return formFieldValues.find(ffv => ffv.id === formFieldValueId) as SendRichEmailFormFieldValue;
      },
    },
  );

  const { status: aiAutomationStatus } = useAiTaskOutputWidgetStatus(widget.id);
  const outputWidgetAiTasksMap = useOutputWidgetAiTasksMap({ templateRevisionId });
  const outputFromAiTasks = React.useMemo(() => {
    return [...(outputWidgetAiTasksMap[widget.id] ?? [])].reverse();
  }, [outputWidgetAiTasksMap, widget.id]);

  const formFieldValue = formFieldValueQuery.data;
  const [fieldValue, setFieldValue] = React.useState<Option<SendRichEmailFieldValue>>();
  React.useEffect(() => {
    if (formFieldValue) {
      setFieldValue(formFieldValue.fieldValue);
      setUpdating.off();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form field value change
  }, [formFieldValue]);

  const [isEditMode, setEditMode] = useBoolean();
  const [isUpdating, setUpdating] = useBoolean();

  const handleEditClick = () => {
    setEditMode.on();
  };

  const handleSave =
    (isCreatingFormFieldValue: boolean) =>
    (fieldValues: EmailFieldValues, body: string, attachments: RichEmailWidgetAttachmentWithS3File[]) => {
      !isCreatingFormFieldValue && setEditMode.off();
      const updatedFieldValue = {
        ...fieldValue,
        body,
        to: fieldValues.to,
        cc: fieldValues.cc,
        bcc: fieldValues.bcc,
        subject: fieldValues.subject,
        attachments,
      };

      setFieldValue(updatedFieldValue); // optimistic update
      setUpdating.on();
      return onUpdate(widget, updatedFieldValue, isCreatingFormFieldValue);
    };

  const onSave = handleSave(false);
  const onCreateFormFieldValue = handleSave(true);

  const parseEmail = useParseChecklistMergeTagsByTarget({
    checklistRevisionId,
    taskId,
    mergeTagTarget: MergeTagTarget.EMAIL,
  });
  const parseGeneral = useParseChecklistMergeTagsByTarget({
    checklistRevisionId,
    taskId,
    mergeTagTarget: MergeTagTarget.RICH_CONTENT,
  });

  const parseAndSanitizeEmails = (emails?: SendRichEmailFormFieldConfig['to' | 'cc' | 'bcc']) =>
    parseEmail({
      content: sanitizeEmailRecipientsField(safeEscapeHtml(emails?.join(',') ?? '')),
      mode: MergeTagMode.HTML,
    });

  const parseAndSanitizeRawStringRecipients = (emails?: SendRichEmailFormFieldConfig['to' | 'cc' | 'bcc']) =>
    parseEmail({
      content: sanitizeEmailRecipientsField(safeEscapeHtml(emails?.join(',') ?? '')),
      mode: MergeTagMode.PLAINTEXT,
    });

  const parseAndSanitizeSubject = (subject?: SendRichEmailFormFieldConfig['subject']) =>
    parseGeneral({ content: sanitizeEmailSubject(safeEscapeHtml(subject ?? '')), mode: MergeTagMode.HTML });

  const parseAndSanitizePlainTextSubject = (subject?: SendRichEmailFormFieldConfig['subject']) =>
    parseGeneral({ content: subject ?? '', mode: MergeTagMode.PLAINTEXT });

  const parseAndSanitizeBody = React.useCallback(
    (body?: SendRichEmailFormFieldConfig['richEditorBody' | 'rawHTMLBody' | 'plainTextBody']) =>
      preserveEmptyParagraphsWithNbsp(
        padTrailingBr(
          normalizeBodyToHtml(
            parseGeneral({
              content: sanitizeEmailBody(body ?? ''),
              mode: MergeTagMode.HTML, // This is always HTML because even in plaintext mode it's normalized to HTML
            }),
            widget.config.emailFormat,
          ),
        ),
      ),
    [parseGeneral, widget.config.emailFormat],
  );

  const parseAndSanitizePlainTextBody = (body?: SendRichEmailFormFieldConfig['plainTextBody']) =>
    padTrailingBr(parseGeneral({ content: body ?? '', mode: MergeTagMode.PLAINTEXT }));

  const richEmailAutomationQuery = GetAllNativeAutomationsQuery.useFormFieldWidgetAutomationQuery({
    templateRevisionId,
    formFieldWidgetId: widget?.id,
  });
  const isAutoSendEnabled = richEmailAutomationQuery.isSuccess && richEmailAutomationQuery.data?.status === 'Active';
  const isAutoSendLoading = richEmailAutomationQuery.isLoading;
  const isAutoSendUnknown = richEmailAutomationQuery.isError;

  const emailWasSent = Boolean(fieldValue?.lastSentDate);
  const editButtonVisible = widget.config.emailFormat === 'RichTextOrHtml' && widget.config.editAllowed;

  const toast = useToast();
  const sendEmailMutation = useSendEmailMutation({
    onSuccess: data => {
      toast({
        ...(data.responseType === SendEmailResponseType.AllSent
          ? { status: 'success', title: 'Email sent' }
          : {
              status: 'warning',
              title: 'Not all recipients will receive the email',
              description: data.summaryMessage,
            }),
      });
      onUpdate(widget, { ...fieldValue, lastSentByUserId: data.senderUserId, lastSentDate: data.sentDate });
    },
    onError: () => {
      toast({
        title: "We're having problems sending the email",
        status: 'error',
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
    onSettled: () => {
      setSendEmailWasClicked.off();
      setIsWaitingForSendingCooldown(true);
    },
  });

  const currentUserId = useSelector(SessionSelector.getCurrentUserId);
  const updateSendFields = () => {
    if (currentUserId) {
      const updatedFieldValue = { ...fieldValue, lastSentByUserId: currentUserId, lastSentDate: Date.now() };

      setFieldValue(updatedFieldValue); // optimistic update
      setUpdating.on();
      onUpdate(widget, updatedFieldValue);
    }
  };

  const [isWaitingForSendingCooldown, setIsWaitingForSendingCooldown] = React.useState(false);

  const [sendEmailWasClicked, setSendEmailWasClicked] = useBoolean();
  const areFormFieldValuesUpdating = useAreFormFieldsUpdating();
  const isWaitingToSend = sendEmailWasClicked && areFormFieldValuesUpdating && !sendEmailMutation.isLoading;
  const isReadyToSend =
    sendEmailWasClicked && !areFormFieldValuesUpdating && !sendEmailMutation.isLoading && !isWaitingForSendingCooldown;

  React.useEffect(() => {
    if (isReadyToSend) {
      sendEmailMutation.mutate({ checklistRevisionId, widgetId: widget.id });
    }
  }, [checklistRevisionId, isReadyToSend, sendEmailMutation, widget, isWaitingForSendingCooldown]);

  React.useEffect(() => {
    if (isWaitingForSendingCooldown) {
      const timeout = setTimeout(() => {
        setIsWaitingForSendingCooldown(false);
      }, EMAIL_SENDING_PREVENT_RAPID_FIRE_TIMEOUT);
      return () => clearTimeout(timeout);
    }
  }, [isWaitingForSendingCooldown]);

  const to = fieldValue?.to ?? widget.config.to;
  const cc = fieldValue?.cc ?? widget.config.cc;
  const bcc = fieldValue?.bcc ?? widget.config.bcc;
  const subject = fieldValue?.subject ?? widget.config.subject;
  const body = fieldValue?.body ?? (widget.config ? getEmailBody(widget.config) : '');
  const templateAttachments = widget.config.attachments ?? [];
  const checklistAttachments = fieldValue?.attachments ?? [];
  const isEmailSendable = !isSendEmailDisabled({ to, subject, body }) || readOnly;

  const disclosure = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const getInvalidRecipients = () => {
    const invalidRecipients = [to, cc, bcc]
      .map(parseAndSanitizeRawStringRecipients)
      .flatMap(emails => emails.split(/[,;]/))
      .map(email => email.trim())
      .filter(email => !isValidEmail(email));
    const unique = [...new Set(invalidRecipients)];
    return unique.filter(rec => rec);
  };
  const invalidRecipients = getInvalidRecipients();

  const isRichTextSendEnabled = useGetIsPaidAccount();

  const sendRichButton = (
    <RequiredButton
      {...{
        variant: 'tertiary',
        onClick: () => {
          if (invalidRecipients.length === 0) {
            setSendEmailWasClicked.on();
          } else {
            disclosure.onToggle();
          }
        },
        leftIcon: isEmailSendable ? (
          <Icon icon="paper-plane" size="4" variant="far" />
        ) : (
          <Icon icon="exclamation-circle" size="4" variant="far" color="red.500" />
        ),
        iconSpacing: '2',
        isLoading: isAutoSendLoading || sendEmailMutation.isLoading || isWaitingToSend,
        isDisabled:
          isAutoSendUnknown ||
          !isEmailSendable ||
          isWaitingForSendingCooldown ||
          isUpdating ||
          !isRichTextSendEnabled ||
          readOnly,
        loadingText: isAutoSendLoading ? 'Loading...' : 'Sending...',
        isRequired: widget.required,
      }}
    >
      Send Email
    </RequiredButton>
  );

  const sendRichButtonWithTooltip = isRichTextSendEnabled ? (
    <Tooltip
      label={
        !isEmailSendable
          ? "Emails must have a 'To' recipient and a subject or body"
          : 'You must wait a few seconds before trying to send this email again'
      }
      hasArrow
      shouldWrapChildren
      isDisabled={isEmailSendable && !isWaitingForSendingCooldown && isRichTextSendEnabled}
    >
      {sendRichButton}
    </Tooltip>
  ) : (
    <PaidPlanRequiredPopover needPaidFor={'performAction'}>{sendRichButton}</PaidPlanRequiredPopover>
  );

  const isPrintView =
    Boolean(useMatch('checklistPrintNew')) || Boolean(useMatch('checklistPrint')) || Boolean(useMatch('templatePrint'));

  const sanitizedEmailBody = React.useMemo(() => parseAndSanitizeBody(body), [body, parseAndSanitizeBody]);

  return (
    <WidgetProvider widget={widget}>
      <Stack spacing="4" backgroundColor={isEditMode ? 'inherit' : 'brand.50'} padding={6}>
        {isEditMode && (
          <SendRichEmailChecklistEditor
            templateRevisionId={templateRevisionId}
            taskId={taskId}
            widget={widget}
            formFieldValueId={formFieldValueId}
            onCancel={() => setEditMode.off()}
            onSave={onSave}
            onCreateFormFieldValue={onCreateFormFieldValue}
            fieldValue={fieldValue}
          />
        )}

        {!isEditMode && (
          <>
            <EmailMetaData mb="2" ccVisible={(cc?.length ?? 0) > 0} bccVisible={(bcc?.length ?? 0) > 0}>
              <EmailMetaDataRowKey row="to">To</EmailMetaDataRowKey>
              <EmailMetaDataRowValue row="to" dangerouslySetInnerHTML={{ __html: parseAndSanitizeEmails(to) }} />

              {(cc?.length ?? 0) > 0 && (
                <>
                  <EmailMetaDataRowKey row="cc">CC</EmailMetaDataRowKey>
                  <EmailMetaDataRowValue row="cc" dangerouslySetInnerHTML={{ __html: parseAndSanitizeEmails(cc) }} />
                </>
              )}

              {(bcc?.length ?? 0) > 0 && (
                <>
                  <EmailMetaDataRowKey row="bcc">BCC</EmailMetaDataRowKey>
                  <EmailMetaDataRowValue row="bcc" dangerouslySetInnerHTML={{ __html: parseAndSanitizeEmails(bcc) }} />
                </>
              )}

              <EmailMetaDataRowKey row="subject">Subject</EmailMetaDataRowKey>
              <EmailMetaDataRowValue
                row="subject"
                dangerouslySetInnerHTML={{ __html: parseAndSanitizeSubject(subject) }}
              />

              <EmailMetaDataRowKey row="body">Body</EmailMetaDataRowKey>
              <EmailMetaDataRowValue row="body" borderLeft="solid 1px" borderLeftColor="gray.300">
                {isPrintView ? (
                  <Box
                    px={2}
                    py={4}
                    sx={{
                      h1: {
                        fontSize: '2xl',
                        fontWeight: 'semibold',
                        my: 1,
                        lineHeight: 'tall',
                      },
                      h2: {
                        fontSize: 'xl',
                        fontWeight: 'semibold',
                        my: 1,
                        lineHeight: 'tall',
                      },
                      h3: {
                        fontSize: 'lg',
                        fontWeight: 'semibold',
                        my: 1,
                        lineHeight: 'tall',
                      },
                    }}
                    dangerouslySetInnerHTML={{ __html: sanitizedEmailBody }}
                  />
                ) : (
                  <EmailBodyIframe srcDoc={parseAndSanitizeBody(body)} />
                )}
              </EmailMetaDataRowValue>
            </EmailMetaData>

            <VStack alignItems="start" paddingLeft={22}>
              {templateAttachments.map(attachment => (
                <EmailAttachmentItem
                  key={attachment.attachment.id}
                  attachment={attachment}
                  mode="view"
                  backgroundColor="gray.100"
                />
              ))}
              {checklistAttachments.map(attachment => (
                <EmailAttachmentItem key={attachment.attachment.id} attachment={attachment} mode="view" />
              ))}
            </VStack>
          </>
        )}

        <VStack alignItems="flex-start">
          <AlertDialog
            leastDestructiveRef={cancelRef}
            isOpen={disclosure.isOpen}
            onClose={disclosure.onClose}
            onEsc={disclosure.onClose}
            onOverlayClick={disclosure.onClose}
            size="xl"
            scrollBehavior="inside"
          >
            <AlertDialogOverlay />
            <AlertDialogContent pt="0">
              <AlertDialogHeader py="4" px="8" borderBottom="1px" borderColor="gray.200" fontSize="lg">
                Warning
              </AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody pt="4" px="8" pb="8">
                <Text>It looks like there are some invalid email addresses, would you like send anyway?</Text>
                <Text mt="4">
                  <Text as="span" fontWeight="700">
                    {'Invalid addresses: '}
                  </Text>
                  {invalidRecipients.join(', ')}
                </Text>
              </AlertDialogBody>
              <AlertDialogFooter py="6" px="8" borderTop="1px" borderColor="gray.200">
                <Button ref={cancelRef} variant="ghost" mr="4" onClick={disclosure.onClose}>
                  Cancel
                </Button>
                <Button
                  variant="warning"
                  onClick={() => {
                    disclosure.onClose();
                    setSendEmailWasClicked.on();
                  }}
                >
                  Send anyway
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          {!isEditMode && (
            <HStack className="send-rich-email-checklist-widget__controls" justifyContent="end" width="full">
              {editButtonVisible && (
                <Tooltip
                  hasArrow
                  label="You can’t edit once an email has been sent"
                  shouldWrapChildren
                  mt="2"
                  isDisabled={!emailWasSent}
                >
                  <Button
                    variant="tertiary"
                    leftIcon={<Icon icon="pen-to-square" size="4" variant="far" />}
                    onClick={handleEditClick}
                    isDisabled={emailWasSent || aiAutomationStatus === 'AutomationRunning' || readOnly}
                  >
                    Edit
                  </Button>
                </Tooltip>
              )}
              {!isAutoSendEnabled &&
                (widget.config.emailFormat === 'PlainText' ? (
                  <SendTestMailtoButton
                    isRequired={widget.required}
                    onClick={updateSendFields}
                    caption="Send Email"
                    disabled={readOnly}
                    config={{
                      ...widget.config,
                      to: [parseAndSanitizeRawStringRecipients(to)],
                      cc: [parseAndSanitizeRawStringRecipients(cc)],
                      bcc: [parseAndSanitizeRawStringRecipients(bcc)],
                      subject: parseAndSanitizePlainTextSubject(subject),
                      plainTextBody: parseAndSanitizePlainTextBody(body),
                    }}
                  />
                ) : (
                  sendRichButtonWithTooltip
                ))}
            </HStack>
          )}
          {fieldValue ? <SendRichEmailTimestamp {...fieldValue} showIfNotSent={false} /> : null}

          {outputFromAiTasks.length > 0 && (
            <HStack>
              <AiTaskAudit fontSize="xs" formFieldValue={formFieldValue} aiTaskTemplate={outputFromAiTasks[0]} />
              {aiAutomationStatus === 'AutomationRunning' && (
                <Spinner color="gray.300" data-testid="native-automation-running-spinner" />
              )}
            </HStack>
          )}
        </VStack>
      </Stack>
      {isAutoSendEnabled && (
        <Text fontStyle="italic" fontSize="sm" mt={2}>
          This email will be sent automatically when the task is complete.
        </Text>
      )}
    </WidgetProvider>
  );
};

const RequiredButton = forwardRef<ButtonProps & { isRequired: boolean }, 'button'>(({ isRequired, ...props }, ref) => (
  <Button
    {...{
      ref,
      ...(isRequired
        ? {
            'aria-required': true,
            '_after': {
              content: '"*"',
              color: 'red.500',
              position: 'absolute',
              top: '0',
              right: '-3',
              lineHeight: 'none',
            },
          }
        : {}),
      ...props,
    }}
  />
));

import * as React from 'react';
import { FormResponseActor } from '../../form-response-body/form-response-machine';
import { ActorRefFrom } from 'xstate';
import { TaskMachine } from '../../task/task-machine';
import { useTaskApprovalTaskGroupsMap } from '../hooks/use-approval-task-groups-map';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  Button,
  Flex,
  Icon,
  IconButton,
  useDisclosure,
  AlertDialogHeader,
  AlertDialogBody,
  Textarea,
  AlertDialogFooter,
} from 'components/design/next';
import { ApprovalStatus } from '@process-street/subgrade/approval-rule';
import { TaskMachineHooks } from '../../task/task-machine-hooks';
import { MuidUtils } from '@process-street/subgrade/core';
import { CreateAllApprovalsMutation } from 'app/features/approvals/query-builder';
import { FormResponseMachineHooks } from '../../form-response-body/form-response-machine-hooks';
import { StringUtils } from '@process-street/subgrade/util';

export type ApprovalTaskFooter = {
  taskMachine: ActorRefFrom<TaskMachine>;
  formResponseActor: FormResponseActor;
};

export const ApprovalTaskFooter = ({ taskMachine, formResponseActor }: ApprovalTaskFooter) => {
  const api = FormResponseMachineHooks.useApi(formResponseActor);
  const approvals = FormResponseMachineHooks.useApprovals();
  const taskGroupsMap = useTaskApprovalTaskGroupsMap({ taskMachine });
  const approvalTask = TaskMachineHooks.useTask(taskMachine);
  const disclosure = useDisclosure();
  const [mode, setMode] = React.useState<'reject' | 'approve'>('approve');

  const waitingForApproval = taskGroupsMap.awaitingTasks;

  const handleUpsertAllApprovals = (status: ApprovalStatus, comment?: string) => {
    const updatedApprovals: Array<CreateAllApprovalsMutation.ApprovalDto> = waitingForApproval.map(task => {
      const existingApproval = approvals.find(
        approval => approval.subjectTaskId === task.id && approval.approvalTaskId === approvalTask.id,
      );

      const id = existingApproval?.id ?? MuidUtils.randomMuid();
      return {
        id,
        subjectTaskId: task.id,
        approvalTaskId: approvalTask.id,
        organizationId: approvalTask.organization.id,
        status,
        comment,
      };
    });

    api.onCreateApprovals(updatedApprovals);
  };

  if (waitingForApproval.length < 2) return null;

  return (
    <>
      <Flex gap="2" direction={{ base: 'column', md: 'row' }}>
        <Flex justifyContent={{ base: 'center', md: 'auto' }} gap="0" w={{ base: 'full', md: 'auto' }}>
          <Button
            aria-label="Reject"
            variant="outline"
            colorScheme="red"
            borderTopRightRadius="0"
            borderBottomRightRadius="0"
            onClick={() => handleUpsertAllApprovals(ApprovalStatus.Rejected)}
            w={{ base: 'full', md: 'auto' }}
          >
            Reject {waitingForApproval.length} tasks
          </Button>
          <IconButton
            aria-label="Reject with comments"
            colorScheme="red"
            variant="outline"
            borderLeft="none"
            borderTopLeftRadius="0"
            borderBottomLeftRadius="0"
            onClick={() => {
              setMode('reject');
              disclosure.onOpen();
            }}
            icon={<Icon icon="comment" size="4" />}
          />
        </Flex>
        <Flex gap="0" justifyContent={{ base: 'center', md: 'auto' }} w={{ base: 'full', md: 'auto' }}>
          <Button
            aria-label="Approve"
            colorScheme="blue"
            borderTopRightRadius={0}
            borderBottomRightRadius={0}
            onClick={() => handleUpsertAllApprovals(ApprovalStatus.Approved)}
            w={{ base: 'full', md: 'auto' }}
          >
            Approve {waitingForApproval.length} tasks
          </Button>
          <IconButton
            aria-label="Approve with comments"
            colorScheme="blue"
            borderLeft="2px solid"
            borderColor="white"
            borderTopLeftRadius="0"
            borderBottomLeftRadius="0"
            onClick={() => {
              setMode('approve');
              disclosure.onOpen();
            }}
            icon={<Icon icon="comment" size="4" />}
          />
        </Flex>
      </Flex>
      {disclosure.isOpen && (
        <BulkRejectApproveDialog
          mode={mode}
          itemsCount={waitingForApproval.length}
          disclosure={disclosure}
          handleOnUpsertAllApprovals={handleUpsertAllApprovals}
        />
      )}
    </>
  );
};

type BulkRejectApproveDialogProps = {
  mode: 'approve' | 'reject';
  itemsCount: number;
  disclosure: ReturnType<typeof useDisclosure>;
  handleOnUpsertAllApprovals: (status: ApprovalStatus, comment?: string) => void;
};

const BulkRejectApproveDialog = ({
  itemsCount,
  mode,
  disclosure,
  handleOnUpsertAllApprovals,
}: BulkRejectApproveDialogProps) => {
  const { isOpen, onClose } = disclosure;
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const [comment, setComment] = React.useState<string | undefined>(undefined);

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontSize="md" fontWeight="medium">
          {StringUtils.capitalize(mode)} {itemsCount} Tasks
        </AlertDialogHeader>
        <AlertDialogBody>
          <Textarea
            fontSize="sm"
            value={comment}
            onChange={e => setComment(e.target.value)}
            bgColor="white"
            placeholder="Leave a comment (optional)"
          />
        </AlertDialogBody>
        <AlertDialogFooter gap="2">
          <Button fontSize="sm" variant="secondary" ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button
            aria-label={`${mode} with comment`}
            fontSize="sm"
            variant={mode === 'reject' ? 'danger' : 'primary'}
            colorScheme={mode === 'reject' ? 'red' : 'blue'}
            borderTopRightRadius="0"
            borderBottomRightRadius="0"
            onClick={() =>
              handleOnUpsertAllApprovals(mode === 'reject' ? ApprovalStatus.Rejected : ApprovalStatus.Approved, comment)
            }
          >
            {StringUtils.capitalize(mode)} and send comment
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

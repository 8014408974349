import { useCallback, useMemo } from 'react';
import { useUIActorRef } from './ui-context';
import { ContextDisclosureName } from './ui-machine';

export namespace UIMachineHooks {
  export const useApi = () => {
    const { uiActorRef } = useUIActorRef();

    const closeDisclosure = useCallback(
      (disclosureName: ContextDisclosureName) => {
        uiActorRef?.send({ type: 'CLOSE_DISCLOSURE', name: disclosureName });
      },
      [uiActorRef],
    );

    const openDisclosure = useCallback(
      (disclosureName: ContextDisclosureName) => {
        uiActorRef?.send({ type: 'OPEN_DISCLOSURE', name: disclosureName });
      },
      [uiActorRef],
    );

    return useMemo(
      () => ({
        openDisclosure,
        closeDisclosure,
      }),
      [openDisclosure, closeDisclosure],
    );
  };
}

import * as React from 'react';
import { Box, BoxProps, Center } from 'components/design/next';
import { Widget } from '@process-street/subgrade/process';
import { useFormEditorPageActorRef } from '../../form-editor-page-machine';
import { motion, Reorder } from 'framer-motion';
import { GetActor } from './make-get-actor';
import { useDropOnWidgetListItem } from './use-drop-on-widget-list-item';
import { ReorderUtils } from './reorder-utils';
import { ReorderAutoScrollContext } from 'app/pages/workflows/_id/edit-v2/providers/reorder-scroll-context';
import { WidgetListItem } from './widget-list-item';
import { useInsertWidgetDragStatusStore } from '../insert-widget/insert-widget-drag-status-store';
import { HoverLocation } from 'app/hooks/get-cursor-location';
import { WidgetMachineHelpers } from '../../helpers/machine';
import { match } from 'ts-pattern';

export type WidgetListItemWrapperProps = {
  widget: Widget;
  getActor: GetActor;
  isFirst: boolean;
  isLast: boolean;
  canReorder?: boolean;
  shouldRenderLoading?: boolean;
  estimatedHeight?: number;
  boxProps?: Partial<BoxProps>;
};

const MotionBox = motion(Box);

export const WidgetListItemWrapper = React.forwardRef<HTMLDivElement, WidgetListItemWrapperProps>(
  ({ widget, getActor, isFirst, isLast, canReorder = true, shouldRenderLoading, boxProps, estimatedHeight }, ref) => {
    const actor = useFormEditorPageActorRef();
    const { hoveringDropzone } = useInsertWidgetDragStatusStore();

    const {
      dropResult: { isOver },
      hoverLocation,
      ref: widgetDropRef,
    } = useDropOnWidgetListItem({ actor, widget });

    const isOverFromHoveringDropzone = React.useMemo(() => {
      return match({ hoveringDropzone, isLast, isFirst })
        .with({ hoveringDropzone: 'top', isFirst: true }, () => true)
        .with({ hoveringDropzone: 'bottom', isLast: true }, () => true)
        .otherwise(() => false);
    }, [hoveringDropzone, isLast, isFirst]);

    React.useEffect(() => {
      if (ref && 'current' in ref) {
        // if we receive a ref, set it on the widgetDropRef which always exists
        ref.current = widgetDropRef.current;
      }
    }, [ref, widgetDropRef]);

    const handlePointerDownCapture: React.PointerEventHandler = React.useCallback(e => {
      const target = e.target as HTMLElement;
      const isDragHandle = ReorderUtils.isTargetDragHandle(target);
      // allow reordering from verified handles (e.g., select dropdown choices)
      if (isDragHandle) return;
      e.stopPropagation();
    }, []);

    const reorderItemProps = React.useContext(ReorderAutoScrollContext)?.widgetAutoScroll.reorderItemProps;

    return (
      <Box
        id={WidgetMachineHelpers.getId(widget)}
        as={Reorder.Item}
        value={widget}
        position="relative"
        ref={widgetDropRef}
        drag={canReorder}
        {...reorderItemProps}
        {...boxProps}
      >
        <WidgetListItemDropzone
          isOver={isOverFromHoveringDropzone ? isOverFromHoveringDropzone : isOver}
          hoverLocation={isOverFromHoveringDropzone ? hoveringDropzone : hoverLocation}
        />
        {/* Wrap with a box that prevents unintentional dragging */}
        <Box onPointerDownCapture={handlePointerDownCapture}>
          {shouldRenderLoading ? (
            <Center h={(estimatedHeight ?? 120) + 'px'} />
          ) : (
            <MotionBox initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
              <WidgetListItem widget={widget} getActor={getActor} isFirst={isFirst} isLast={isLast} />
            </MotionBox>
          )}
        </Box>
      </Box>
    );
  },
);

type WidgetListItemDropzone = {
  hoverLocation: HoverLocation;
  isOver: boolean;
};

const WidgetListItemDropzone = ({ isOver, hoverLocation }: WidgetListItemDropzone) => {
  const [leftOffset, setLeftOffset] = React.useState(0);
  const [parentWidth, setParentWidth] = React.useState(0);
  const ref = React.useRef<HTMLDivElement>(null);

  const dragStatuses = useInsertWidgetDragStatusStore().statuses;
  const isDragging = React.useMemo(() => {
    return Object.values(dragStatuses).some(({ isDragging }) => isDragging);
  }, [dragStatuses]);

  React.useEffect(
    // This effect will retrieve the `left` and `width` of the parent element.
    // This will be used to correctly align the dropzone indicator.
    function updateParentElementInfo() {
      if (isDragging) {
        window.requestAnimationFrame(() => {
          if (ref.current?.parentElement) {
            const rect = ref.current.parentElement.getBoundingClientRect();
            setLeftOffset(rect.left);
            setParentWidth(rect.width);
          }
        });
      }
    },
    [isDragging],
  );

  if (!isDragging) return null;

  return (
    <Box
      ref={ref}
      w="100vw"
      ml={{ base: 0, lg: `-${leftOffset}px` }}
      position="absolute"
      inset="0"
      zIndex="tooltip"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {isOver && hoverLocation ? (
        // Show a line to indicate where the widget will be dropped
        <Box
          h="2"
          position="absolute"
          bg="brand.200"
          left={`${leftOffset}px`}
          w="full"
          maxW={`${parentWidth}px`}
          {...(hoverLocation === 'top'
            ? { top: 0, transform: 'translateY(-50%)' }
            : { bottom: 0, transform: 'translateY(50%)' })}
        />
      ) : null}
    </Box>
  );
};

import { FormControl, FormErrorMessage, Input, VStack } from 'components/design/next';
import React from 'react';
import { useActor, useSelector } from '@xstate/react';
import { TextFormFieldActor } from './text-form-field-machine';
import { ValidationSelectors } from '../validation-machine';
import { StringUtils } from '@process-street/subgrade/util';
import { FormResponseLabel } from '../common';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';

export interface TextFormFieldProps {
  actor: TextFormFieldActor;
}

export const TextFormField: React.FC<React.PropsWithChildren<TextFormFieldProps>> = ({ actor }) => {
  const [state, send] = useActor(actor);
  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);
  const { widget } = state.context;

  const onFocus = () => {
    send({ type: 'FOCUS' });
  };

  const onBlur = () => {
    send({ type: 'BLUR' });
  };

  const setValue: React.ChangeEventHandler<HTMLInputElement> = e => {
    send({ type: 'CHANGE', value: e.target.value });
  };

  const ref = React.useRef<HTMLDivElement | null>(null);

  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !state.context.inputNode) {
          actor.send({ type: 'SET_NODE', node });
        }
      }}
      as={VStack}
      alignItems="stretch"
      isRequired={widget.required}
      isInvalid={ValidationSelectors.isActorInvalidVisible(state.context.validationActor)}
    >
      <FormResponseLabel>{widget.label || 'Untitled Short Text'}</FormResponseLabel>
      <Input
        w={{ base: '100%', lg: '88' }}
        borderColor="gray.300"
        value={state.context.value}
        placeholder={StringUtils.getNonEmpty(widget.config.placeholder, 'Type answer here...')}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={setValue}
        isDisabled={state.matches('input.disabled')}
        autoFocus={state.matches('autoFocus.enabled')}
      />
      <FormErrorMessage>{ValidationSelectors.errorMessage(state.context.validationActor)}</FormErrorMessage>
    </FormControl>
  );
};

import angular from 'angular';
import { StateService } from '@uirouter/core';
import * as templateServiceInterface from './template-service.interface';
import * as folderServiceInterface from './folder-service.interface';
import { PremadeTemplateOverview } from '@process-street/subgrade/process/premade-template-model';
import { Muid } from '@process-street/subgrade/core';
import { Template } from '@process-street/subgrade/process';
import { DEFAULT_PAGE_NAME } from 'app/pages/pages/_id/edit-v2/constants';
import { TemplateConstants } from './template-constants';
import { SHOW_WORKFLOW_SETUP_MODAL_FOR } from 'components/focus-bar/workflow/right-button-group';
import { Method, CreateTemplateSource } from './template-service.interface';
import { getEnv } from 'components/common/env';
import { SHOW_AI_WORKFLOW_SETUP_MODAL_FOR } from 'pages/templates/_id/components/ai-generated-workflow-settings-modal/wrapper';
import { abbreviateForTitle } from '@process-street/subgrade/util';

export type CreateTemplateByOverviewParams = {
  folderId: Muid;
  premadeTemplateOverview: PremadeTemplateOverview;
  source: CreateTemplateSource;
};

export type CreateTemplateParams = {
  folderId: Muid;
  source: CreateTemplateSource;
  isAiGenerated?: boolean;
  isReactWorkflowEditorEnabled?: boolean;
};

export type ImportTemplateParams = {
  folderId: Muid;
  premadeTemplateId: Muid;
  name: string;
  source: CreateTemplateSource;
};

export interface PremadeTemplateService {
  /**
   * Creates a template given a premadeTemplateOverview in a folder and redirects to it
   * @param premadeTemplateOverviewprem
   */
  createInFolderAndRedirectByOverview(params: CreateTemplateByOverviewParams): angular.IPromise<Template>;

  /**
   * Creates a form workflow in a folder and redirects to it
   */
  createBlankFormInFolderAndRedirect(params: CreateTemplateParams): angular.IPromise<Template>;

  /**
   * Creates a blank template in a folder and redirects to it
   */
  createBlankWorkflowInFolderAndRedirect(params: CreateTemplateParams): angular.IPromise<Template>;

  /**
   * Creates a blank template in a given folder and redirects to it
   */
  createBlankPageInFolderAndRedirect(params: CreateTemplateParams): angular.IPromise<Template>;

  importTemplateToFolderAndRedirect(params: ImportTemplateParams): angular.IPromise<Template>;

  importPageToFolderAndRedirect(params: ImportTemplateParams): angular.IPromise<Template>;
}

export class PremadeTemplateServiceImpl implements PremadeTemplateService {
  public static $inject = ['$state', 'FolderService', 'TemplateService'];

  constructor(
    private readonly $state: StateService,
    private readonly FolderService: folderServiceInterface.FolderService,
    private readonly TemplateService: templateServiceInterface.TemplateService,
  ) {}

  public createBlankFormInFolderAndRedirect({ folderId, source }: CreateTemplateParams): angular.IPromise<Template> {
    return this.createTemplateInFolderAndRedirect(
      folderId,
      getEnv().APP_BLANK_FORM_ID,
      TemplateConstants.BLANK_FORM_NAME,
      Method.BLANK,
      'form',
      source,
    );
  }

  public createTemplateAndRedirect(
    premadeTemplateId: Muid,
    name: string,
    method: Method,
    mode: string,
    source: CreateTemplateSource,
  ) {
    const currentFolder = this.FolderService.getCurrentFolder(this.$state.params.path, this.$state.params.type);
    return this.createTemplateInFolderAndRedirect(currentFolder.id, premadeTemplateId, name, method, mode, source);
  }

  public createInFolderAndRedirectByOverview({
    folderId,
    premadeTemplateOverview,
    source,
  }: CreateTemplateByOverviewParams) {
    return this.createTemplateInFolderAndRedirect(
      folderId,
      premadeTemplateOverview.templateId,
      premadeTemplateOverview.name,
      Method.PREMADE,
      'templateView',
      source,
    );
  }

  public createBlankWorkflowInFolderAndRedirect({
    folderId,
    source,
    isAiGenerated,
    isReactWorkflowEditorEnabled,
  }: CreateTemplateParams) {
    const mode = isReactWorkflowEditorEnabled ? 'templateV2' : 'template';
    return this.createTemplateInFolderAndRedirect(
      folderId,
      getEnv().APP_BLANK_WORKFLOW_ID,
      TemplateConstants.BLANK_WORKFLOW_NAME,
      isAiGenerated ? Method.AI_AUTOGENERATED : Method.BLANK,
      mode,
      source,
      isAiGenerated,
    );
  }

  public createBlankPageInFolderAndRedirect({ folderId, source }: CreateTemplateParams) {
    return this.createTemplateInFolderAndRedirect(
      folderId,
      getEnv().APP_BLANK_PAGE_ID,
      DEFAULT_PAGE_NAME,
      Method.BLANK,
      'page',
      source,
    );
  }

  public importPageToFolderAndRedirect({ folderId, premadeTemplateId, name, source }: ImportTemplateParams) {
    return this.createTemplateInFolderAndRedirect(folderId, premadeTemplateId, name, Method.IMPORT, 'page', source);
  }

  public importTemplateToFolderAndRedirect({ folderId, premadeTemplateId, name, source }: ImportTemplateParams) {
    return this.createTemplateInFolderAndRedirect(
      folderId,
      premadeTemplateId,
      name,
      Method.IMPORT,
      'templateView',
      source,
    );
  }

  public createTemplateInFolderAndRedirect(
    folderId: Muid,
    premadeTemplateId: Muid,
    name: string,
    method: Method,
    mode: string,
    source: CreateTemplateSource,
    isAiGenerated = false,
  ): angular.IPromise<Template> {
    const promise = this.TemplateService.createByPremadeId(premadeTemplateId, folderId, name, method, source);

    const isWorkflowSetupVisible = isAiGenerated
      ? false
      : [Method.BLANK, Method.COPY, Method.IMPORT, Method.PREMADE].includes(method);

    this.$state.go('loadAndGo', {
      promise,
      onSuccess: (template: Template) => {
        if (isWorkflowSetupVisible) {
          localStorage.setItem(SHOW_WORKFLOW_SETUP_MODAL_FOR, template.id);
        }

        if (isAiGenerated) {
          localStorage.setItem(SHOW_AI_WORKFLOW_SETUP_MODAL_FOR, template.id);
        }

        return { to: mode, params: { id: template.id, title: `${abbreviateForTitle(template?.name)}-` } };
      },
    });

    return promise;
  }
}

angular.module('frontStreetApp.services').service('PremadeTemplateService', PremadeTemplateServiceImpl);

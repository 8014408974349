import * as React from 'react';
import { Box, Progress } from 'components/design/next';
import { ChecklistUtils } from '@process-street/subgrade/process';
import { StatusBar } from './status-bar';
import { TOP_BAR_HEIGHT_TOKEN } from 'app/pages/forms/_id/shared';
import { FormResponseMachineHooks } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-hooks';

export const ChecklistTopBar = () => {
  const checklist = FormResponseMachineHooks.useChecklist();
  const progress = FormResponseMachineHooks.useCompletionPercentage();

  return (
    <Box position="sticky" top={TOP_BAR_HEIGHT_TOKEN} zIndex="banner">
      {ChecklistUtils.isArchived(checklist) || ChecklistUtils.isCompleted(checklist) ? (
        <StatusBar checklist={checklist} />
      ) : (
        <Progress w="full" size="sm" value={progress} isAnimated={true} aria-label="workflow run progress bar" />
      )}
    </Box>
  );
};

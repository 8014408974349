import * as React from 'react';
import { Box, Text, useToast } from 'components/design/next';
import { Template } from '@process-street/subgrade/process';
import { DefaultErrorMessages } from 'app/components/utils/error-messages';
import { useUploadBackgroundImage } from '../../hooks/use-upload-background-image';
import last from 'lodash/last';
import { UploadArea, UploadProgress } from 'app/features/upload/components';
import { getHighlightColor } from 'app/features/upload/components/utils';
import { Accept } from 'react-dropzone';

export const SIZE_TOO_LARGE = 'size-too-large';

export type UploadBackgroundImageProps = {
  templateId: Template['id'];
  uploadingMessage: string;
  onFinish: (templateId: Template['id']) => void;
  acceptMimeTypes?: Accept;
};

export const UploadBackgroundImage: React.FC<React.PropsWithChildren<UploadBackgroundImageProps>> = ({
  templateId,
  uploadingMessage,
  onFinish,
  acceptMimeTypes,
  children,
}) => {
  const toast = useToast();

  const { dropzoneState, progress, uploadError } = useUploadBackgroundImage({
    templateId,
    onFinish,
    accept: acceptMimeTypes,
  });

  React.useEffect(() => {
    if (uploadError) {
      toast({
        status: 'error',
        title: "We're having problems uploading the background image",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    }
  }, [toast, uploadError]);

  const fileTooLarge =
    dropzoneState.fileRejections.length > 0 && last(dropzoneState.fileRejections[0].errors)?.code === SIZE_TOO_LARGE;

  return (
    <Box {...dropzoneState.getRootProps({ className: 'dropzone' })}>
      <Box p={4} borderRadius="md" cursor="pointer" bgColor={getHighlightColor(dropzoneState)}>
        {progress === undefined ? (
          <UploadArea dropzoneInputProps={dropzoneState.getInputProps()}>{children}</UploadArea>
        ) : (
          <UploadProgress progress={progress} message={uploadingMessage} />
        )}
        {fileTooLarge && (
          <Text color="red.500" align="center" fontWeight="medium">
            {last(dropzoneState.fileRejections[0].errors)?.message}
          </Text>
        )}
      </Box>
    </Box>
  );
};

import {
  AiTaskPromptMergeTagLabel,
  StdMergeTagKey,
  StdMergeTagLabel,
  StdMergeTagLabelLegacy,
} from '@process-street/subgrade/core';
import { MergeTagsConstants, MergeTagTarget } from '@process-street/subgrade/form';
import { FieldType } from '@process-street/subgrade/process';
import { omit } from 'lodash';

export const FORM_FIELD_VALUE_KEY_PREFIX = 'form.';

const emailMergeTag = {
  [StdMergeTagKey.CurrentUserEmail]: StdMergeTagLabel[StdMergeTagKey.CurrentUserEmail],
  [StdMergeTagKey.EmailTriggerSenderEmail]: StdMergeTagLabel[StdMergeTagKey.EmailTriggerSenderEmail],
  [StdMergeTagKey.RunCreatedByEmail]: StdMergeTagLabel[StdMergeTagKey.RunCreatedByEmail],
} as const;

const workflowRunMergeTags = omit(StdMergeTagLabel, [
  StdMergeTagKey.RunName,
  StdMergeTagKey.RunDueDate,
  StdMergeTagKey.TaskName,
  StdMergeTagKey.TaskUrl,
  StdMergeTagKey.TaskDueDate,
]);

export const StandardMergeTag = {
  [MergeTagsConstants.Target.GENERAL]: StdMergeTagLabel,
  [MergeTagsConstants.Target.RICH_CONTENT]: StdMergeTagLabel,
  [MergeTagsConstants.Target.SNIPPET]: StdMergeTagLabel,
  [MergeTagsConstants.Target.EMAIL]: emailMergeTag,
  [MergeTagsConstants.Target.CHECKLIST]: workflowRunMergeTags,
  [MergeTagsConstants.Target.APPROVAL_TASK]: workflowRunMergeTags,
  [MergeTagsConstants.Target.AI_TASK_PROMPT]: AiTaskPromptMergeTagLabel,
} as const;

export const StandardMergeTagLegacy = {
  [MergeTagsConstants.Target.GENERAL]: StdMergeTagLabelLegacy,
  [MergeTagsConstants.Target.RICH_CONTENT]: StdMergeTagLabelLegacy,
  [MergeTagsConstants.Target.SNIPPET]: StdMergeTagLabelLegacy,
  [MergeTagsConstants.Target.EMAIL]: {},
  [MergeTagsConstants.Target.CHECKLIST]: {},
  [MergeTagsConstants.Target.APPROVAL_TASK]: {},
  [MergeTagsConstants.Target.AI_TASK_PROMPT]: {},
} as const;

const generalFieldMap = {
  [FieldType.Date]: true,
  [FieldType.Email]: true,
  [FieldType.File]: true,
  [FieldType.Hidden]: true,
  [FieldType.Members]: true,
  [FieldType.MultiChoice]: true,
  [FieldType.MultiSelect]: false,
  [FieldType.Number]: true,
  [FieldType.Select]: true,
  [FieldType.SendRichEmail]: false,
  [FieldType.Snippet]: false, // disable for now because there's no rendering support
  [FieldType.Table]: false,
  [FieldType.Text]: true,
  [FieldType.Textarea]: true,
  [FieldType.Url]: true,
};

const richContentFieldMap = {
  ...generalFieldMap,
  [FieldType.Table]: true,
};

const emailFieldMap = {
  [FieldType.Date]: false,
  [FieldType.Email]: true,
  [FieldType.File]: false,
  [FieldType.Hidden]: false,
  [FieldType.Members]: true,
  [FieldType.MultiChoice]: true,
  [FieldType.MultiSelect]: false,
  [FieldType.Number]: true,
  [FieldType.Select]: true,
  [FieldType.SendRichEmail]: false,
  [FieldType.Snippet]: false,
  [FieldType.Table]: false,
  [FieldType.Text]: true,
  [FieldType.Textarea]: false,
  [FieldType.Url]: false,
};

/** This determines which form fields are selectable/insertable and/or renderable */
export const FieldTypeToMergeTagTargetMap: Record<MergeTagTarget, Record<FieldType, boolean>> = {
  [MergeTagsConstants.Target.GENERAL]: generalFieldMap,
  [MergeTagsConstants.Target.RICH_CONTENT]: richContentFieldMap,
  [MergeTagsConstants.Target.SNIPPET]: generalFieldMap,
  [MergeTagsConstants.Target.CHECKLIST]: generalFieldMap,
  [MergeTagsConstants.Target.EMAIL]: emailFieldMap,
  [MergeTagsConstants.Target.APPROVAL_TASK]: richContentFieldMap,
  [MergeTagsConstants.Target.AI_TASK_PROMPT]: generalFieldMap,
};

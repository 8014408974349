import { Muid } from '@process-street/subgrade/core';
import { axiosService } from 'services/axios-service';
import axios, { AxiosResponse } from 'axios';
import { env } from 'components/common/env';
import { UploadableWidgets } from 'pages/pages/_id/edit/page/components/upload';
import { WidgetHeader } from '@process-street/subgrade/process';

export type GetUploadUrlRequest = {
  headerId: Muid;
  fileName: string;
  mimeType: string;
};

export type GetUploadUrlResponse = {
  url: string;
  key: string;
};

/** Uploads file to S3 then updates the widget with the uploaded file. */
export const uploadToS3 = async <W extends UploadableWidgets>({
  file,
  headerId,
  onProgress,
}: {
  file: File;
  headerId: WidgetHeader['id'];
  onProgress?: (progress: number) => void;
}): Promise<AxiosResponse<W>> => {
  const reader = new FileReader();
  const { url, key } = await getUploadUrl({
    headerId,
    fileName: file.name,
    mimeType: file.type,
  });

  onProgress?.(1);

  return new Promise((resolve, reject) => {
    reader.onload = async () => {
      if (reader.result === null) {
        return;
      }
      try {
        await processS3Upload({ file, url, data: reader.result }, onProgress);
        const uploadResult = await finishUpload<W>({
          headerId,
          contentType: file.type,
          originalFilename: file.name,
          key,
        });
        resolve(uploadResult);
      } catch (e) {
        reject(e);
      }
    };

    reader.readAsArrayBuffer(file);
  });
};

const getUploadUrl = async (req: GetUploadUrlRequest): Promise<GetUploadUrlResponse> => {
  return axiosService
    .getAxios()
    .post<GetUploadUrlResponse>(`/1/widgets/${req.headerId}/upload-url`, req)
    .then(res => res.data);
};

export type FinishUploadRequest = {
  headerId: Muid;
  key: string;
  originalFilename: string;
  contentType: string;
};

const finishUpload = async <W extends UploadableWidgets>(req: FinishUploadRequest): Promise<AxiosResponse<W>> => {
  return axiosService.getAxios().post<W>(`/1/widgets/${req.headerId}/finish-upload`, req);
};

export type UploadRequest = {
  file: File;
  url: string;
  data: string | ArrayBuffer;
};

export type UploadResponse = {
  url: string;
  key: string;
};

export type ProgressEvent = {
  lengthComputable: boolean;
  loaded: number;
  total: number;
};

const processS3Upload = async (
  req: UploadRequest,
  onProgress?: (value: number) => void,
): Promise<AxiosResponse<UploadResponse>> => {
  // A separate instance of axios as S3 complains about our auth token.
  return axios.put(req.url, req.data, {
    headers: { 'Content-Type': req.file.type },
    onUploadProgress: (event: ProgressEvent) => {
      onProgress?.((100 * event.loaded) / event.total);
    },
  });
};

export type WistiaUploadResponse = {
  hashed_id: string;
};

export const uploadToWistia = async (
  formData: FormData,
  onProgress: (value: number) => void,
): Promise<AxiosResponse<WistiaUploadResponse>> => {
  formData.append('api_password', env?.WISTIA_API_PASSWORD);
  formData.append('project_id', env?.WISTIA_PROJECT_ID);
  // A separate instance of axios as Wistia complains about our auth token.
  return axios.post(env?.WISTIA_UPLOAD_URL, formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
    onUploadProgress: (event: ProgressEvent) => {
      onProgress((100 * event.loaded) / event.total);
    },
  });
};

import * as React from 'react';
import angular from 'angular';
import {
  ChecklistRevision,
  FormFieldValueUpdateResult,
  TableFormFieldValue,
  TableFormFieldWidget,
  TemplateType,
} from '@process-street/subgrade/process';
import {
  makeTableFormFieldMachine,
  TableFormField,
  TableFormFieldMachine,
} from 'pages/responses/_id/components/form-fields/table-form-field';
import { useMachine } from '@xstate/react';
import { useFormResponseSharedContext } from 'pages/responses/_id/hooks/use-form-response-shared-context';
import { Box } from '@chakra-ui/react';
import { ActorRefFrom, createMachine } from 'xstate';
import { FormFieldAudit } from 'components/widgets/form-field/common/FormFieldAudit';
import { useEffect } from 'react';
import { useUpdateEffect } from 'react-use';

export interface ChecklistTableFormFieldProps {
  checklistRevision: ChecklistRevision;
  widget: TableFormFieldWidget;
  formFieldValue?: TableFormFieldValue;
  onUpdateValue: (
    widget: TableFormFieldWidget,
    fieldValue: TableFormFieldValue['fieldValue'],
  ) => angular.IPromise<FormFieldValueUpdateResult>;
  isInvalid: boolean;
  editable: boolean;
  readOnly: boolean;
}

function toPromise<T>(q: angular.IPromise<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    q.then(resolve, reject);
  });
}

export const ChecklistTableFormField = (props: ChecklistTableFormFieldProps) => {
  const sharedContext = useFormResponseSharedContext();
  const [state] = useMachine(() => {
    const tableMachine = makeTableFormFieldMachine({
      checklistRevisionId: props.checklistRevision.id,
      formFieldValue: props.formFieldValue,
      formFieldWidget: props.widget,
      inputNode: null,
      autoFocus: false,
      isEditable: props.editable && !props.readOnly,
      sharedContext,
      update: fieldValue => toPromise(props.onUpdateValue(props.widget, fieldValue)),
    });
    // This is a dummy machine to catch events sent to the parent
    return createMachine({
      id: 'parent',
      initial: 'running',
      states: {
        running: { invoke: { id: 'child', src: tableMachine } },
      },
    });
  });

  const actor = state.children.child as ActorRefFrom<TableFormFieldMachine>;

  useUpdateEffect(() => {
    actor.send({ type: 'SYNC', value: props.formFieldValue?.fieldValue?.rows ?? [] });
  }, [actor.send, props.formFieldValue?.fieldValue]);

  useEffect(() => {
    if (props.isInvalid) {
      actor.send('REVEAL_INVALID');
    }
  }, [actor, props.isInvalid]);

  useEffect(() => {
    if (!props.editable || props.readOnly) {
      actor.send('DISABLE');
    } else if (props.editable && !props.readOnly) {
      actor.send('ENABLE');
    }
  }, [actor, props.editable, props.readOnly]);

  return (
    <Box
      sx={{
        '.chakra-form-control label': {
          marginBottom: '0px',
        },
      }}
    >
      <TableFormField actor={actor} templateType={TemplateType.Playbook} />

      {props.formFieldValue?.audit && <FormFieldAudit fontSize="xs" mt={1} audit={props.formFieldValue.audit} />}
    </Box>
  );
};

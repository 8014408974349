import { FormFieldWidgetWithValue, isTableFieldValue, isTableFormFieldWidget } from '@process-street/subgrade/process';
import { TableFormFieldUtils } from '@process-street/subgrade/process/widget-utils/table-form-field-utils';
import { Box } from 'components/design/next';
import * as React from 'react';

export type ApprovalWidgetTableRendererProps = {
  widget: FormFieldWidgetWithValue;
};

export const ApprovalWidgetTableRenderer = ({ widget }: ApprovalWidgetTableRendererProps) => {
  const rendererValue = React.useMemo(() => {
    if (
      isTableFormFieldWidget(widget) &&
      isTableFieldValue(widget.formFieldValue?.fieldValue) &&
      widget.formFieldValue
    ) {
      return TableFormFieldUtils.toHtmlString(widget, widget.formFieldValue.fieldValue);
    }

    return '';
  }, [widget]);

  return (
    <Box
      w="full"
      dangerouslySetInnerHTML={{ __html: rendererValue }}
      sx={{
        border: '1px solid',
        borderColor: 'gray.300',
        borderRadius: '4px',
        marginBottom: '16px',
        overflowX: 'auto',

        table: {
          width: '100%',
        },

        thead: {
          backgroundColor: 'gray.100',
          borderBottom: '1px solid',
          borderBottomColor: 'gray.300',
          th: {
            color: 'gray.800',
            fontSize: 'sm',
            fontWeight: 'bold',
            minWidth: '200px',
            textTransform: 'none',
          },
        },

        tbody: {
          'tr:nth-of-type(even)': {
            backgroundColor: 'gray.50',
          },
          'tr': {
            borderTop: '1px solid',
            borderTopColor: 'gray.200',
          },
        },

        th: {
          height: '39px',
          padding: '4px 17px',
          textAlign: 'left',
        },

        td: {
          height: '39px',
          padding: '4px 17px',
          textAlign: 'left',
        },
      }}
    />
  );
};

import { FormFieldWidgetWithValue, TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { VStack, Text } from 'components/design/next';
import * as React from 'react';
import { ApprovalStatus } from '@process-street/subgrade/approval-rule';
import { ApprovalButtons } from '../common/approval-buttons';
import { Muid } from '@process-street/subgrade/core';
import { ArrayUtils, NonEmptyArray } from 'app/utils/array-utils';
import { ApprovalWidgetsSection } from '../approval-widgets-section';

export type WaitingForApprovalTasksSectionProps = {
  tasks: NonEmptyArray<TaskWithTaskTemplate>;
  widgetsByTaskMap: Record<Muid, FormFieldWidgetWithValue[]>;
  handleUpsertApproval: (task: TaskWithTaskTemplate, status: ApprovalStatus, comment?: string) => void;
};

export const WaitingForApprovalTasksSection = ({
  tasks,
  widgetsByTaskMap,
  handleUpsertApproval,
}: WaitingForApprovalTasksSectionProps) => {
  return (
    <VStack alignItems="stretch" gap={4} width="full">
      <Text variant="1u" color="gray.400" fontWeight="bold">
        Waiting for approval
      </Text>

      {tasks.map(task => {
        const widgets = widgetsByTaskMap[task.taskTemplate.id];
        return (
          <VStack key={task.id} alignItems="flex-start" backgroundColor="gray.50" padding={4}>
            <Text>{task.taskTemplate.name}</Text>
            {ArrayUtils.isNonEmptyArray(widgets) && <ApprovalWidgetsSection widgets={widgets} />}

            <ApprovalButtons subjectTask={task} handleUpsertApproval={handleUpsertApproval} />
          </VStack>
        );
      })}
    </VStack>
  );
};

import { combineReducers } from 'redux';
import { handleActionsOnSuccess } from 'reducers/util';
import { INBOX_GET_ALL_BY_ORGANIZATION_ID } from 'reducers/inbox/inbox.actions';
import {
  TASK_GET_ALL_BY_CHECKLIST_REVISION_ID,
  TASK_UPDATE_INTERNAL,
  TASK_UPDATE_DUE_DATE,
} from 'reducers/task/task.actions';
import { TASK_ASSIGNED_UNASSIGNED_EVENT } from 'reducers/scope-event/scope-event.actions';
import { referencesNormalizer } from 'reducers/entities/entities.utils';
import { TASK_ASSIGNMENT_GET_ALL_BY_CHECKLIST_REVISION } from 'components/checklist-task-assignment/store/checklist-task-assignment.actions';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';
import { FORM_FIELD_VALUE_UPDATE } from 'components/form-field-value/store/form-field-value.actions';
import { TaskTemplateTaskType } from '@process-street/subgrade/process';
import { InboxItemUtils } from '@process-street/subgrade/inbox';
import { APPROVAL_UPSERT_ALL } from 'components/approvals/store/approval.actions';

const normalizeTask = referencesNormalizer(['organization', 'checklistRevision', 'taskTemplate']);

export const taskEntitiesReducer = handleActionsOnSuccess(
  {
    [INBOX_GET_ALL_BY_ORGANIZATION_ID]: (state, { payload: items }) => {
      const taskItems = InboxItemUtils.getTasks(items);
      const tasks = taskItems.map(({ task }) => task);
      return EntitiesReducerUtils.upsertAll(state, tasks, normalizeTask);
    },
    [TASK_UPDATE_DUE_DATE]: (state, { payload: task }) => EntitiesReducerUtils.upsert(state, task, normalizeTask),
    [TASK_GET_ALL_BY_CHECKLIST_REVISION_ID]: (state, { payload: tasks }) =>
      EntitiesReducerUtils.upsertAll(state, tasks, normalizeTask),
    [TASK_UPDATE_INTERNAL]: (state, { payload: { task } }) => EntitiesReducerUtils.upsert(state, task, normalizeTask),
    [FORM_FIELD_VALUE_UPDATE]: (state, { payload: { tasks, dueDateTaskStates } }) => {
      const newState = { ...state };

      // update hidden flag of tasks
      tasks.forEach(({ id, hidden, status, taskTemplate: { taskType } }) => {
        const task = newState[id];
        if (task) {
          const newStatus = taskType === TaskTemplateTaskType.Standard ? task.status : status;
          newState[id] = { ...task, hidden, status: newStatus };
        }
      });

      dueDateTaskStates.forEach(({ id, dueDate }) => {
        const task = newState[id];
        if (task) {
          newState[id] = { ...task, dueDate };
        }
      });
      return newState;
    },
    [APPROVAL_UPSERT_ALL]: (state, { payload: { dueDateStates } }) => {
      const newState = { ...state };
      dueDateStates.forEach(({ id, dueDate }) => {
        const task = newState[id];
        if (task) {
          newState[id] = { ...task, dueDate };
        }
      });
      return newState;
    },
  },
  {},
);

const byChecklistReducer = handleActionsOnSuccess(
  {
    [INBOX_GET_ALL_BY_ORGANIZATION_ID]: (state, { payload: items }) => {
      const tasks = InboxItemUtils.getTasks(items);
      const checklistToTasks = tasks.reduce((agg, { task, checklist }) => {
        if (!agg[checklist.id]) {
          agg[checklist.id] = [];
        }
        agg[checklist.id].push(task.id);
        return agg;
      }, {});
      return LookupsReducerUtils.mergeLookupMap(state, checklistToTasks);
    },
    [TASK_GET_ALL_BY_CHECKLIST_REVISION_ID]: (state, { payload: tasks, meta: { checklistId } }) => ({
      ...state,
      [checklistId]: tasks.map(task => task.id),
    }),
  },
  {},
);

export const toAssigneesReducer = handleActionsOnSuccess(
  {
    [INBOX_GET_ALL_BY_ORGANIZATION_ID]: (state, { payload: items }) => {
      const tasks = InboxItemUtils.getTasks(items);
      const taskToUser = tasks.reduce((agg, taskItem) => {
        const taskId = taskItem.task.id;
        const assignees = taskItem.assignees.map(user => user.id);
        agg[taskId] = assignees;
        return agg;
      }, {});
      return { ...state, ...taskToUser };
    },
    [TASK_ASSIGNED_UNASSIGNED_EVENT]: (state, { payload }) => {
      if (payload.assigned) {
        return LookupsReducerUtils.upsertUsingSelectorFunctions(
          state,
          payload,
          p => p.task.id,
          p => p.user.id,
        );
      } else {
        return LookupsReducerUtils.deleteUsingSelectorFunctions(
          state,
          payload,
          p => p.task.id,
          p => p.user.id,
        );
      }
    },
    [TASK_ASSIGNMENT_GET_ALL_BY_CHECKLIST_REVISION]: (state, { payload: taskAssignments }) =>
      LookupsReducerUtils.replaceAllUsingSelectorFunctions(
        state,
        taskAssignments,
        ta => ta.task.id,
        ta => ta.organizationMembership.user.id,
      ),
  },
  {},
);

export const taskReducer = combineReducers({
  byChecklist: byChecklistReducer,
  toAssignees: toAssigneesReducer,
});

const byChecklistRevisionIdLookupReducer = handleActionsOnSuccess(
  {
    [TASK_GET_ALL_BY_CHECKLIST_REVISION_ID]: (state, { payload: tasks }) =>
      LookupsReducerUtils.replaceAllUsingSelectorFunctions(state, tasks, task => task.checklistRevision.id),
  },
  {},
);

export const taskLookupReducer = combineReducers({
  byChecklistRevisionId: byChecklistRevisionIdLookupReducer,
});

import * as React from 'react';
import { Box, Button, BoxProps, HStack, Stack, Text, Skeleton, Tooltip } from 'components/design/next';
import { Icon } from 'components/design/next';

type PlaceholderProps = BoxProps & { isLoading?: boolean };

const EmptyRuleDefinitionSkeleton: React.FC<React.PropsWithChildren<PlaceholderProps>> = ({ isLoading, ...props }) => {
  return (
    <Skeleton
      isLoaded={!isLoading}
      startColor="gray.200"
      endColor="gray.50"
      bgColor="gray.50"
      borderRadius="12px"
      height="29px"
      w="full"
      {...props}
    />
  );
};

export type EmptyRuleDefinitionProps = {
  onAddRule?: () => void;
  isLoading?: boolean;
  canAddRule?: boolean;
};

export const EmptyRuleDefinition = ({ onAddRule, isLoading, canAddRule = true }: EmptyRuleDefinitionProps) => {
  return (
    <Box w="full" px="8" position="relative">
      <Stack padding="6" spacing="3" borderRadius="12px" borderWidth="1px" borderStyle="dashed" borderColor="gray.200">
        <HStack spacing="4">
          <Box minWidth="10">
            <Text fontWeight="bold" variant="-2u" color="gray.300" letterSpacing="1px" textAlign="right">
              If
            </Text>
          </Box>
          <EmptyRuleDefinitionSkeleton isLoading={isLoading} />
        </HStack>

        <HStack spacing="4">
          <Box minWidth="10"></Box>
          <EmptyRuleDefinitionSkeleton isLoading={isLoading} maxW="170px" />
          <EmptyRuleDefinitionSkeleton isLoading={isLoading} />
        </HStack>

        <HStack spacing="4">
          <Box minWidth="10">
            <Text fontWeight="bold" variant="-2u" color="gray.300" letterSpacing="1px" textAlign="right">
              Then
            </Text>
          </Box>

          <EmptyRuleDefinitionSkeleton isLoading={isLoading} maxW="30" />
          <EmptyRuleDefinitionSkeleton isLoading={isLoading} />
        </HStack>
      </Stack>
      {!isLoading && (
        <Tooltip isDisabled={canAddRule} hasArrow label="Cannot add rule for selected widget">
          <Button
            position="absolute"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)"
            leftIcon={<Icon icon="plus" size="4" />}
            iconSpacing="2"
            onClick={onAddRule}
            isDisabled={!canAddRule}
          >
            Add rule
          </Button>
        </Tooltip>
      )}
    </Box>
  );
};

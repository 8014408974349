import { Box, Skeleton, Text, VStack } from 'components/design/next';
import * as React from 'react';
import { useDrop } from 'react-dnd';
import { useFormEditorPageActorRef } from '../../form-editor-page-machine';
import { DraggableType, NewWidgetItem } from '../../types';
import { useInsertWidgetDragStatusStore } from '../insert-widget/insert-widget-drag-status-store';

export type FormEmptyStateProps = {
  isLoading?: boolean;
};

export const FormEmptyState: React.FC<React.PropsWithChildren<FormEmptyStateProps>> = ({ isLoading }) => {
  const actor = useFormEditorPageActorRef();
  const ref = React.useRef<HTMLDivElement>(null);

  const { hoveringDropzone } = useInsertWidgetDragStatusStore();

  const [dropResult, drop] = useDrop(() => ({
    accept: [DraggableType.FormField, DraggableType.Content],
    drop: (item: NewWidgetItem) => {
      actor.send({ type: 'CREATE_WIDGET', payload: item });
    },
    collect: monitor => ({ isOver: monitor.isOver() }),
  }));

  const isOver = dropResult.isOver || hoveringDropzone;

  React.useEffect(() => {
    drop(ref);
  }, [drop]);

  const skeletonProps = isLoading
    ? {
        startColor: 'gray.100',
        endColor: 'gray.200',
      }
    : {
        startColor: isOver ? 'brand.200' : 'gray.100',
        endColor: isOver ? 'brand.200' : 'gray.100',
      };

  return (
    <VStack w="full" alignItems="strech" spacing="7" ref={ref}>
      {!isLoading && (
        <Text fontStyle="italic" color="gray.400">
          Click on the form fields on the sidebar and they will show up here
        </Text>
      )}

      <VStack w="full" alignItems="stretch" maxW="100" spacing="3">
        <Skeleton maxW="50" w="full" h="3" borderRadius="4px" {...skeletonProps} />

        <Box
          bgColor="white"
          borderWidth="2px"
          borderStyle="solid"
          borderColor={isOver ? 'brand.200' : 'gray.100'}
          h="8"
          borderRadius="8px"
        />
      </VStack>

      <VStack w="full" alignItems="stretch" spacing="3">
        <Skeleton maxW="100" w="full" h="3" borderRadius="4px" {...skeletonProps} />

        <Box
          bgColor="white"
          borderWidth="2px"
          borderStyle="solid"
          borderColor={isOver ? 'brand.200' : 'gray.100'}
          h="25"
          borderRadius="8px"
        />
      </VStack>
    </VStack>
  );
};

import { Widget } from '@process-street/subgrade/process';
import { ActorRefFrom } from 'xstate';
import { makeRulesEngineTargetMachine } from '../components/form-response-body/rules-engine-machine';

export namespace ChecklistWidgetMachineSelectors {
  export type Context = { widget: Widget; rulesEngineTargetActor: ActorRefFrom<typeof makeRulesEngineTargetMachine> };
  export type State = {
    context: Context;
  };

  export const getRulesEngineTargetActor = (state: State) => state.context.rulesEngineTargetActor;

  export const getWidget = (state: State) => state.context.widget;

  export const getIsHiddenByRule = (state: State) => {
    return state.context.rulesEngineTargetActor.getSnapshot()?.matches('hidden');
  };
}

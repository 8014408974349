import { useSelector } from '@xstate/react';
import { FormResponsePageProviders } from 'app/pages/checklists/_id/providers';
import { FormResponseMachineSelectors } from './form-response-machine-selectors';
import { Muid } from '@process-street/subgrade/core';
import { useCallback, useMemo } from 'react';
import { FormResponseActor } from './form-response-machine';
import { CreateAllApprovalsMutation } from 'app/features/approvals/query-builder';
import { Checklist } from '@process-street/subgrade/process';
import { OneOffTask } from '@process-street/subgrade/one-off-task';

export namespace FormResponseMachineHooks {
  export const useCurrentTaskIndex = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getCurrentTaskIndex);
  };

  export const useCurrentTaskId = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getCurrentTaskId);
  };

  export const useCurrentTaskActorRef = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    const taskActor = useSelector(actor, FormResponseMachineSelectors.getCurrentTaskActor);
    if (!taskActor) {
      throw new Error('Current Task actor not found');
    }
    return taskActor;
  };

  export const useTaskActorsMap = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getTaskActorsMap);
  };

  export const useIsComplete = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getIsComplete);
  };

  export const useIsCompletingCurrentTask = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getIsCompletingCurrentTask);
  };

  export const useIsUncompletingCurrentTask = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getIsUncompletingCurrentTask);
  };

  export const useTaskActor = (taskId: Muid) => {
    const selector = useMemo(() => FormResponseMachineSelectors.getTaskActorByTaskId(taskId), [taskId]);
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, selector);
  };

  export const useChecklist = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getChecklist);
  };

  export const useApprovals = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getApprovals);
  };

  export const useApprovalRules = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getApprovalRules);
  };

  export const useTasks = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getTasks);
  };

  export const useIsEveryTaskValid = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getIsEveryTaskValid);
  };

  export const useCompletionPercentage = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getCompletionPercentage);
  };

  export const useShouldHideCompletedTasks = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getShouldHideCompletedTasks);
  };

  export const useShouldHideStoppedTasks = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getShouldHideStoppedTasks);
  };

  export const useFormFieldWidgetsWithValuesByTaskMap = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getFormFieldWidgetsWithValuesByTaskMap);
  };

  export const useIsCompletingRun = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getIsCompletingRun);
  };

  export const useIsReactivatingRun = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getIsReactivatingRun);
  };

  export const useTemplate = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getTemplate);
  };

  export const useTemplateRevision = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getTemplateRevision);
  };

  export const useApi = (actorRef: FormResponseActor) => {
    const onCompleteTask = useCallback(
      (taskId: Muid) => {
        actorRef.send({ type: 'COMPLETE_TASK', taskId });
      },
      [actorRef],
    );

    const onCreateApprovals = useCallback(
      (approvals: Array<CreateAllApprovalsMutation.ApprovalDto>) => {
        actorRef.send({ type: 'CREATE_APPROVALS', approvals });
      },
      [actorRef],
    );

    const onNextTask = useCallback(() => {
      actorRef.send({ type: 'NEXT_TASK' });
    }, [actorRef]);

    const onPreviousTask = useCallback(() => {
      actorRef.send({ type: 'PREVIOUS_TASK' });
    }, [actorRef]);

    const onUncompleteCurrentTask = useCallback(() => {
      actorRef.send({ type: 'UNCOMPLETE_CURRENT_TASK' });
    }, [actorRef]);

    const onUncompleteTask = useCallback(
      (taskId: Muid) => {
        actorRef.send({ type: 'UNCOMPLETE_TASK', taskId });
      },
      [actorRef],
    );

    const onUpdateChecklist = useCallback(
      (checklist?: Checklist) => {
        actorRef.send({ type: 'UPDATE_CHECKLIST', checklist });
      },
      [actorRef],
    );

    const onUpdateOneOffTasks = useCallback(
      (oneOffTasks?: OneOffTask[]) => {
        actorRef.send({ type: 'UPDATE_ONE_OFF_TASKS', oneOffTasks });
      },
      [actorRef],
    );

    const onSelectTask = useCallback(
      (taskId: Muid) => {
        actorRef.send({ type: 'SELECT_TASK', taskId });
      },
      [actorRef],
    );

    const onSkipTask = useCallback(() => {
      actorRef.send({ type: 'SKIP_TASK' });
    }, [actorRef]);

    const onToggleCompletedTasks = useCallback(() => {
      actorRef.send({ type: 'TOGGLE_COMPLETED_TASKS' });
    }, [actorRef]);

    const onToggleStoppedTasks = useCallback(() => {
      actorRef.send({ type: 'TOGGLE_STOPPED_TASKS' });
    }, [actorRef]);

    const onCompleteRun = useCallback(() => {
      actorRef.send({ type: 'COMPLETE_RUN' });
    }, [actorRef]);

    const onUncompleteRun = useCallback(() => {
      actorRef.send({ type: 'UNCOMPLETE_RUN' });
    }, [actorRef]);

    const getIsCurrentTaskActorInvalid = useCallback(() => {
      return actorRef.getSnapshot()?.context.currentTaskActor?.getSnapshot()?.matches('validation.invalid.hidden');
    }, [actorRef]);

    return useMemo(
      () => ({
        onCompleteRun,
        onCompleteTask,
        onCreateApprovals,
        onNextTask,
        onPreviousTask,
        onSelectTask,
        onSkipTask,
        onToggleCompletedTasks,
        onToggleStoppedTasks,
        onUncompleteCurrentTask,
        onUncompleteTask,
        onUpdateChecklist,
        onUpdateOneOffTasks,
        onUncompleteRun,
        getIsCurrentTaskActorInvalid,
      }),
      [
        onCompleteRun,
        onCompleteTask,
        onCreateApprovals,
        onNextTask,
        onPreviousTask,
        onSelectTask,
        onSkipTask,
        onToggleCompletedTasks,
        onToggleStoppedTasks,
        onUncompleteCurrentTask,
        onUncompleteTask,
        onUpdateChecklist,
        onUpdateOneOffTasks,
        onUncompleteRun,
        getIsCurrentTaskActorInvalid,
      ],
    );
  };
}

import { isDateFormFieldWidget, MultiSelectItemValueStatus, WidgetUtils } from '@process-street/subgrade/process';
import { WidgetConstants } from '@process-street/subgrade/process/widget-constants';
import angular from 'angular';
import { ApprovalSelector } from 'components/approvals/store/approval.selectors';
import { ChecklistWidgetSelector } from 'components/widgets/store/checklist-widget.selector';
import { WidgetSelector } from 'components/widgets/store/widget.selector';
import { SessionSelector } from 'reducers/session/session.selectors';
import { createSelector } from 'reselect';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';
import { canAccess, Feature } from 'services/features/features';
import './checklist-widgets-container.component.html';
import './checklist-widgets-container.scss';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import _keyBy from 'lodash/keyBy';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { ProgressService } from 'services/progress-service';
import { trace } from 'components/trace';
import { queryClient } from 'components/react-root';
import { GetFormFieldValuesByChecklistRevisionIdQuery } from './query-builder';
import { GetConsolidatedTaskPermitsQuery } from 'features/checklist-revisions/query-builder';
import { EventName } from 'services/event-name';
import { TaskListEvent } from 'directives/task-list/task-list-event';
import { FormFieldEvent } from 'services/form-field-event';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'ChecklistWidgetsCtrl',
    function (
      $ngRedux,
      $q,
      $rootScope,
      $scope,
      $state,
      ApprovalActions,
      ChecklistRightbarEvent,
      ChecklistService,
      FileUploadService,
      FormFieldValueService,
      OrganizationService,
      SessionService,
      TaskTemplateService,
      ToastService,
      WidgetService,
    ) {
      const ctrl = this;
      const logger = trace({ name: 'ChecklistWidgetsCtrl' });

      $scope.latestReadonlyCheckedTaskId = undefined;

      const widgetsMapSelector = checklistRevision =>
        createSelector(
          [
            WidgetSelector.getAllWithTaskTemplateByChecklistRevisionId(checklistRevision.id),
            ChecklistWidgetSelector.getAllByChecklistRevisionId(checklistRevision.id),
          ],
          (widgets, checklistWidgets) => $scope.convertWidgetsToMap(widgets, checklistWidgets),
        );

      const enrichWidgetConfig = widget => {
        const enrichedConfig = $scope.widgetsById[widget.id]?.config;

        if (!enrichedConfig) return widget;

        return {
          ...widget,
          config: enrichedConfig,
        };
      };

      $scope.convertWidgetsToMap = (widgets, checklistWidgets) => {
        const widgetsMap = {};

        WidgetUtils.filterVisibleWidgets(widgets, checklistWidgets).forEach(widget => {
          if (WidgetService.isEmpty(widget)) {
            widget._hidden = true;
          }

          const stepId = widget.header.taskTemplate.group.id;
          const stepWidgets = widgetsMap[stepId] || [];
          stepWidgets.push(enrichWidgetConfig(widget));

          widgetsMap[stepId] = stepWidgets;
        });

        angular.forEach(widgetsMap, WidgetService.sortWidgets);
        return widgetsMap;
      };

      const mapStateToThis = checklistRevision =>
        createSelector(
          [
            WidgetSelector.getAllByChecklistRevisionId(checklistRevision.id),
            widgetsMapSelector(checklistRevision),
            ApprovalSelector.getAllApprovedTaskIds(checklistRevision.id),
            SessionSelector.getCurrentUser,
            FeatureFlagSelector.getFeatureFlags,
            OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId,
          ],
          (widgets, widgetsMap, approvedTaskIds, user, featureFlags, currentOrganizationMembership) => {
            const enrichedWidgets = $scope.widgetsById ? widgets.map(enrichWidgetConfig) : widgets;

            return {
              widgets: enrichedWidgets,
              widgetsMap,
              approvedTaskIds,
              user,
              featureFlags,
              currentOrganizationMembership,
            };
          },
        );

      const mapDispatchToThis = () => ({
        getAllApprovalsByChecklistRevisionId: ApprovalActions.getAllByChecklistRevisionId,
      });

      $scope.isActiveTaskLocked = () => {
        return (
          $scope.activeChecklistTask &&
          angular.isArray($scope.approvedTaskIds) &&
          $scope.approvedTaskIds.includes($scope.activeChecklistTask.id)
        );
      };

      $scope.currentUserIsFreeMember = function () {
        return (
          $scope.currentOrganizationMembership &&
          $scope.currentOrganizationMembership.role === OrganizationMembershipRole.FreeMember
        );
      };

      $scope.connectToRedux = checklistRevision => {
        if ($scope.unsubscribe) {
          $scope.unsubscribe();
        }
        $scope.unsubscribe = $ngRedux.connect(mapStateToThis(checklistRevision), mapDispatchToThis)($scope);
      };

      ctrl.$onInit = () => {
        $scope.sidebarHidden = true;

        const deregisterWatch = $scope.$watch('revision', checklistRevision => {
          if (!checklistRevision) {
            return;
          }

          $scope.connectToRedux(checklistRevision);

          deregisterWatch();

          OrganizationService.getById(checklistRevision.organization.id).then(organization => {
            const planId = organization && organization.subscription.plan.id;
            $scope.taskAssignmentEnabled = canAccess(Feature.TASK_ASSIGNMENT, planId);

            $scope.organization = organization;
          });

          loadWidgetsAndFormFieldValues(checklistRevision);

          $ngRedux.dispatch($scope.getAllApprovalsByChecklistRevisionId(checklistRevision.id));
        });

        function loadWidgetsAndFormFieldValues(checklistRevision) {
          $q.all({
            widgets: WidgetService.getAllByChecklistRevisionId(checklistRevision.id),
            formFieldValues: FormFieldValueService.getAllByChecklistRevisionId(
              checklistRevision.id,
              true /* flushCache */,
            ),
          }).then(
            ({ widgets, formFieldValues }) => {
              $scope.widgetsLoaded = true; // todo move to redux

              const widgetsGroupedByStep = $scope.convertWidgetsToMap(widgets, []);
              // We will store these widgets map in order to use them to enrich the `formFieldWidget`'s config
              // returned by the `/1/checklist-revisions/:checklistRevisionId/form-field-values` endpoint.
              // The widgets returned by this endpoint don't contain the `linkedColumnId` property while the
              // one returned by the `WidgetService.getAllByChecklistRevisionId` contains.
              $scope.widgetsById = _keyBy(widgets, 'id');

              initializeFormFields(formFieldValues, widgetsGroupedByStep, $scope.widgetsById);

              // This will make the checkboxes show next to the items
              $scope.formFieldValuesLoaded = true;
            },
            () => {
              ToastService.openToast({
                status: 'error',
                title: `We're having problems loading the form field values`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            },
          );
        }

        $scope.$on(FormFieldEvent.SUB_CHECKLIST_ITEM_STATUS_UPDATED, (__event, __item, itemValue, data) => {
          const groupId = data.taskTemplate.group.id;

          switch (itemValue.status) {
            case 'Completed':
              $scope.taskProgressMap[groupId].completed += 1;
              break;
            case 'NotCompleted':
              $scope.taskProgressMap[groupId].completed -= 1;
              break;
            default:
              logger.error('unexpected status: %s', itemValue.status);
          }

          $scope.taskProgressMap[groupId].progress = ProgressService.calculate(
            $scope.taskProgressMap[groupId].completed,
            $scope.taskProgressMap[groupId].total,
          ).value;
        });
      };

      $scope.$watch(function () {
        if (
          $scope.activeChecklistTask &&
          $scope.revision &&
          $scope.activeChecklistTask.id !== $scope.latestReadonlyCheckedTaskId
        ) {
          ctrl.setIsReadOnly($scope.activeChecklistTask.id, $scope.revision.id);
        }
      });

      ctrl.setIsReadOnly = (taskId, checklistRevisionId) => {
        const params = {
          checklistRevisionId,
        };

        return $q((resolve, reject) =>
          queryClient
            .fetchQuery(GetConsolidatedTaskPermitsQuery.getKey(params), () =>
              GetConsolidatedTaskPermitsQuery.queryFn(params),
            )
            .then(tasksPermits => resolve(tasksPermits))
            .catch(e => {
              reject(e);
            }),
        )
          .then(tasksPermits => {
            const taskPermits = tasksPermits.find(t => t.taskId === taskId);
            $scope.readOnly = !taskPermits?.taskPermissionMap.canUpdate;
          })
          .finally(() => {
            $scope.latestReadonlyCheckedTaskId = taskId;
          });
      };

      //Used to get the entire FormFieldValue object
      $scope.formFieldValueMap = $scope.formFieldValueMap || {};

      function initializeFormFields(formFieldValues, widgetsGroupedByStep, widgetsById) {
        FormFieldValueService.initializeFormFieldValueMap(
          $scope.formFieldValueMap,
          formFieldValues,
          widgetsGroupedByStep,
          widgetsById,
        );

        initializeProgressMaps();
      }

      function initializeProgressMaps() {
        angular.forEach($scope.formFieldValueMap, formFieldValue => {
          const widget = formFieldValue.formFieldWidget;

          if (widget.fieldType === 'MultiSelect') {
            initializeProgressMap(formFieldValue);
          }
        });
      }

      function refreshWidgets(checklistRevisionId) {
        return WidgetService.getAllByChecklistRevisionId(checklistRevisionId).then(widgets => {
          const widgetsByGroupId = {};

          widgets.forEach(widget => {
            widgetsByGroupId[widget.header.group.id] = widget;

            const stepId = widget.header.taskTemplate.group.id;
            const widgetIndex = $scope.widgetsMap[stepId].findIndex(w => w.id === widget.id);
            $scope.widgetsMap[stepId][widgetIndex] = widget;
          });
        });
      }

      function hasDateWidgetWithDynamicValidation() {
        return $scope.widgets.some(
          w =>
            isDateFormFieldWidget(w) &&
            (w.constraints.afterDateFormFieldWidgetGroupId || w.constraints.beforeDateFormFieldWidgetGroupId),
        );
      }

      $scope.$on(FormFieldEvent.FORM_FIELD_VALUE_UPDATE_OK, (__event, formFieldValue) => {
        const formFieldWidget = $scope.widgets.find(widget => widget.id === formFieldValue.formFieldWidget.id);
        const checklistRevisionId = $scope.revision.id;

        if (
          !checklistRevisionId ||
          !formFieldWidget ||
          !isDateFormFieldWidget(formFieldWidget) ||
          !hasDateWidgetWithDynamicValidation()
        )
          return;

        refreshWidgets(checklistRevisionId);
      });

      $scope.$on(EventName.TASK_DUE_DATE_UPDATE_OK, () => {
        const checklistRevisionId = $scope.revision.id;

        if (!checklistRevisionId || !hasDateWidgetWithDynamicValidation()) return;

        refreshWidgets(checklistRevisionId);
      });

      $scope.$on(EventName.CHECKLIST_DUE_DATE_UPDATE_OK, () => {
        const checklistRevisionId = $scope.revision.id;

        if (!checklistRevisionId || !hasDateWidgetWithDynamicValidation()) return;

        refreshWidgets(checklistRevisionId);
      });

      $scope.$on(FormFieldEvent.FORM_FIELD_VALUE_LIVE_UPDATED, (__event, formFieldValue) => {
        const formFieldWidget = $scope.widgets.find(widget => widget.id === formFieldValue.formFieldWidget.id);
        if (formFieldWidget) {
          const currentFormFieldValue = $scope.formFieldValueMap[formFieldWidget.header.id];
          const updatedFormFieldValue = {
            ...currentFormFieldValue,
            fieldValue: formFieldValue.fieldValue,
            audit: formFieldValue.audit,
          };

          $scope.formFieldValueMap[formFieldWidget.header.id] = updatedFormFieldValue;

          queryClient.setQueryData(
            GetFormFieldValuesByChecklistRevisionIdQuery.getKey({ checklistRevisionId: $scope.revision.id }),
            current =>
              current?.map(formFieldValue => {
                if (formFieldValue.id === updatedFormFieldValue.id) {
                  return updatedFormFieldValue;
                }
                return formFieldValue;
              }),
          );

          if (formFieldWidget.fieldType === 'MultiSelect') {
            initializeProgressMap(updatedFormFieldValue);
          }
        }
      });

      $scope.$on(FormFieldEvent.FORM_FIELD_VALUE_LIVE_DELETED, (__event, formFieldValue) => {
        const formFieldWidget = $scope.widgets.find(widget => widget.id === formFieldValue.formFieldWidget.id);
        const originalValue = formFieldWidget && $scope.formFieldValueMap[formFieldWidget.header.id];
        if (formFieldWidget && originalValue) {
          // this matches what a deleted form field value from the server looks like: just formFieldWidget and fieldValue: {}
          $scope.formFieldValueMap[formFieldWidget.header.id] = {
            formFieldWidget,
            fieldValue: {},
          };
        }
      });

      function initializeProgressMap(formFieldValue) {
        const widget = formFieldValue.formFieldWidget;
        const groupId = widget.header.taskTemplate.group.id;
        const defaultTaskProgress = {
          completed: 0,
          total: 0,
          progress: 0,
        };

        $scope.taskProgressMap[groupId] = $scope.taskProgressMap[groupId]
          ? $scope.taskProgressMap[groupId]
          : defaultTaskProgress;

        angular.forEach(widget.config.items, item => {
          if (item.name && item.name.trim()) {
            $scope.taskProgressMap[groupId].total += 1;
          }
        });

        angular.forEach(formFieldValue.fieldValue.itemValues, itemValue => {
          const completed = itemValue.status === MultiSelectItemValueStatus.Completed;
          $scope.taskProgressMap[groupId].completed += completed ? 1 : 0;
        });

        $scope.taskProgressMap[groupId].progress = ProgressService.calculate(
          $scope.taskProgressMap[groupId].completed,
          $scope.taskProgressMap[groupId].total,
        ).value;

        $rootScope.$broadcast(TaskListEvent.TASK_PROGRESS_UPDATED, groupId, $scope.taskProgressMap[groupId]);
      }

      $scope.isLastTask = function (step) {
        return $scope.steps.indexOf(step) === $scope.steps.length - 1;
      };

      $scope.updateTaskProgressMap = function (itemValue, groupId) {
        switch (itemValue.status) {
          case MultiSelectItemValueStatus.Completed:
            $scope.taskProgressMap[groupId].completed -= 1;
            break;
          case MultiSelectItemValueStatus.NotCompleted:
            $scope.taskProgressMap[groupId].completed += 1;
            break;
          default:
            logger.error('unexpected status: %s', itemValue.status);
        }

        $scope.taskProgressMap[groupId].progress = ProgressService.calculate(
          $scope.taskProgressMap[groupId].completed,
          $scope.taskProgressMap[groupId].total,
        ).value;

        $rootScope.$broadcast(TaskListEvent.TASK_PROGRESS_UPDATED, groupId, $scope.taskProgressMap[groupId]);
      };

      // Image
      $scope.imageMimeTypes = WidgetConstants.IMAGE_MIME_TYPES;

      // These are the video types supported by Wistia:
      // http://wistia.com/doc/export-settings#formats_wistia_supports
      $scope.videoMimeTypes = WidgetConstants.VIDEO_MIME_TYPES;

      $scope.$on('$destroy', () => {
        $scope.unsubscribe();

        // Cancel all upload form field values
        FileUploadService.abortChecklistUploads();
      });

      $scope.isHeading = TaskTemplateService.isHeading;

      $scope.isApprovalTask = function () {
        return TaskTemplateService.isApproval($scope.activeStepTaskTemplate);
      };

      $scope.widgetsById = {};

      // UI

      $scope.isChecklistActionable = ChecklistService.isChecklistActionable;

      $scope.$on(ChecklistRightbarEvent.TOGGLED, (__event, sidebarHidden) => {
        $scope.sidebarHidden = sidebarHidden;
      });

      $scope.userIsDeveloper = $scope.user.developer;

      $scope.isEmbeddedInMsTeams = () => $state.includes('microsoftTeams');

      $scope.countNonHiddenWidgets = function () {
        const widgets = $scope.widgetsMap[$scope.activeStep.id];
        return widgets ? widgets.filter(widget => !widget._hidden).length : 0;
      };

      // show if approval note is present
      $scope.shouldShowChecklistWidgets = () =>
        !$scope.isApprovalTask($scope.activeStepTaskTemplate) || $scope.countNonHiddenWidgets() > 0;
    },
  );

import * as React from 'react';
import { isPage, TemplateType } from '@process-street/subgrade/process';
import { PageContentActorRef } from './page-content-machine';
import { useActor, useSelector } from '@xstate/react';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { ContentFieldRecentlyMovedIndicator } from '../common/content-field-recently-moved-indicator';
import { CrossLinkCardSearch } from 'app/components/widgets/cross-link/search';
import { TemplateTypeProvider } from 'app/utils/template/template-type-context';
import { Muid } from '@process-street/subgrade/core';
import { CrossLinkCardDisplay } from 'app/components/widgets/cross-link/display';
import { useMatch } from '@process-street/adapters/navigation';
import { VStack } from '@chakra-ui/react';
import { useFormEditorPageActorRef } from '../../../form-editor-page-machine';
import { FormEditorPageActorSelectors } from '../../../form-editor-page-machine/form-editor-page-machine-selectors';

export interface PageContentProps {
  actor: PageContentActorRef;
  isFirst?: boolean;
  isLast?: boolean;
}

export const PageContent: React.FC<React.PropsWithChildren<PageContentProps>> = ({
  actor,
  isFirst = false,
  isLast = false,
}) => {
  const [current, send] = useActor(actor);
  const editorActor = useFormEditorPageActorRef();
  const isLoading = useSelector(editorActor, FormEditorPageActorSelectors.isLoading);

  const ref = React.useRef<HTMLDivElement | null>(null);
  const { widget, template, recentlyMovedFrom, isReadOnly } = current.context;

  const isPageV2 = Boolean(useMatch('pageV2')) || Boolean(useMatch('pageViewV2'));

  const templateTypeLookup = isPageV2 ? TemplateType.Playbook : TemplateType.Page;
  // Don't show empty page widget in view mode
  if (isReadOnly && !widget.templateId) return null;

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer alignItems="flex-start">
        <VStack
          w="full"
          alignItems="normal"
          ref={node => {
            ref.current = node;
            if (node && !current.context.inputNode) {
              send({ type: 'SET_NODE', node });
            }
          }}
          scrollMarginBottom={17}
        >
          {recentlyMovedFrom && <ContentFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
          <TemplateTypeProvider templateType={templateTypeLookup}>
            {widget.templateId ? (
              <CrossLinkCardDisplay templateId={widget.templateId} />
            ) : (
              <CrossLinkCardSearch
                onSelect={(templateId: Muid) => send({ type: 'CHANGE', templateId })}
                onFocus={() => send('FOCUS')}
                isReadOnly={isReadOnly}
                isLoading={isLoading}
              />
            )}
          </TemplateTypeProvider>
          {!isReadOnly && (
            <>
              <WidgetListItemDragIcon />
              <FormsWidgetMenu>
                <FormsWidgetMenuItems.Duplicate />
                {!isPage(template) && <FormsWidgetMenuItems.MoveToStep widget={widget} />}
                <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
                <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
                <FormsWidgetMenuItems.Delete />
              </FormsWidgetMenu>
            </>
          )}
        </VStack>
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};

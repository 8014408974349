import {
  ChecklistRuleDefinition,
  ChecklistRuleDefinitionOperator,
  ConditionalLogicCommonUtils,
  RuleConstants,
} from '@process-street/subgrade/conditional-logic';
import {
  FeatureFlags,
  FieldType,
  FormFieldWidget,
  isFormFieldWidget,
  OrderTreeUtils,
  TaskTemplate,
  TemplateType,
  Widget,
  WidgetUtils,
} from '@process-street/subgrade/process';
import { Trace } from 'components/trace';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _keyBy from 'lodash/keyBy';
import { match, P } from 'ts-pattern';
import {
  Node,
  NodeType,
  SelectorHelper,
  TimeSource,
  TimeSourceLabels,
} from 'directives/rules/template/task-templates-selector/selector-helper';
import { Muid } from '@process-street/subgrade/core';

export type CalendarMode = 'day' | 'month' | 'year';

export type NormalizedData = {
  widgets: {
    byId: Record<Muid, Widget>;
    byHeaderId: Record<Muid, Widget>;
    byGroupId: Record<Muid, Widget>;
    ids: Muid[];
    hiddenByDefaultIds: Set<Muid>;
  };
  tasks: {
    byId: Record<Muid, TaskTemplate>;
    byHeaderId: Record<Muid, TaskTemplate>;
    byGroupId: Record<Muid, TaskTemplate>;
    ids: Muid[];
    hiddenByDefaultIds: Set<Muid>;
  };
  rules: {
    byId: Record<Muid, ChecklistRuleDefinition>;
    byFormFieldWidgetGroupId: Record<Muid, ChecklistRuleDefinition[]>;
    ids: Muid[];
  };
  nodes: {
    byId: Record<Muid, Node>;
    ids: Muid[];
    byParentId: Record<Muid, Node[]>;
    all: Node[];
  };
};

export type NodeStatus = {
  isTriggerAt: Set<string>;
  isHiddenBy: Set<string>;
  isShownBy: Set<string>;
  isHiddenByDefault: boolean;
  hasChildTrigger: boolean;
};

export namespace ConditionalLogicUtils {
  export const getAvailableOperatorListByWidget = (
    widget: Widget,
    logger?: Trace,
  ): ChecklistRuleDefinitionOperator[] => {
    if (!WidgetUtils.isFormFieldWidget(widget)) {
      logger?.log(`widget type ${widget.header.type || 'none'} is not supported.`);

      return [];
    }

    const key = _has(RuleConstants.FieldTypeToOperatorListMap, widget.fieldType) ? widget.fieldType : 'default';

    return _get(RuleConstants.FieldTypeToOperatorListMap, key) ?? [];
  };

  export const resolveOperatorName = (operator: ChecklistRuleDefinitionOperator, widget: Widget, logger?: Trace) => {
    if (!WidgetUtils.isFormFieldWidget(widget)) {
      logger?.error(`widget type ${widget.header.type || 'none'} is not supported`);

      return;
    }

    const key = _has(RuleConstants.OperatorNameMap, widget.fieldType) ? widget.fieldType : 'default';

    return _get(RuleConstants.OperatorNameMap, [key, operator]);
  };

  export const getNodeGroupId = (node: Node) => {
    return match<Node, string>(node)
      .with({ type: NodeType.Widget }, node => (node.ref as Widget).header.group.id)
      .with({ type: NodeType.Task }, node => (node.ref as TaskTemplate).group.id)
      .otherwise(() => (node.ref as TaskTemplate).group.id);
  };

  export const isWidgetNodeTriggeringRule = (
    widgetNode: Node,
    rule: ChecklistRuleDefinition,
    nodesStatus: Record<string, NodeStatus>,
  ) => {
    const nodeStatus = nodesStatus[getNodeGroupId(widgetNode)];

    return nodeStatus?.isTriggerAt.has(rule.id) ?? false;
  };

  export const isWidgetNodeAffectedByRule = (
    widgetNode: Node,
    rule: ChecklistRuleDefinition,
    nodesStatus: Record<string, NodeStatus>,
  ) => {
    const nodeStatus = nodesStatus[getNodeGroupId(widgetNode)];
    const isHiddenByRule = nodeStatus?.isHiddenBy.has(rule.id);
    const isShownByRule = nodeStatus?.isShownBy.has(rule.id);
    const isTrigger = nodeStatus?.isTriggerAt.has(rule.id);

    return Boolean(isHiddenByRule || isShownByRule || isTrigger) ?? false;
  };

  const createEmptyStatus = (): NodeStatus => ({
    isTriggerAt: new Set(),
    isHiddenBy: new Set(),
    isShownBy: new Set(),
    isHiddenByDefault: false,
    hasChildTrigger: false,
  });

  export const getNodesStatus = (
    rules: ChecklistRuleDefinition[],
    normalizedData: NormalizedData,
  ): Record<string, NodeStatus> => {
    const data: Record<string, NodeStatus> = {};

    rules.forEach(rule => {
      const isHidden = rule.hidden;
      const affectedWidgets = rule.widgetGroupIds;
      const affectedTaskTemplates = rule.taskTemplateGroupIds;

      [...affectedWidgets, ...affectedTaskTemplates].forEach(groupId => {
        const widgetStatus = data[groupId] ?? createEmptyStatus();

        if (isHidden) {
          widgetStatus.isHiddenBy.add(rule.id);
        } else {
          widgetStatus.isShownBy.add(rule.id);
        }

        data[groupId] = widgetStatus;
      });

      ConditionalLogicCommonUtils.getConditionFormFieldWidgetGroupIdsFromRule(rule).forEach(triggerWidgetId => {
        const triggerStatuses = data[triggerWidgetId] ?? createEmptyStatus();

        triggerStatuses.isTriggerAt.add(rule.id);
        triggerStatuses.isHiddenByDefault =
          triggerStatuses.isHiddenByDefault ||
          (normalizedData.widgets.byGroupId[triggerWidgetId]?.header.hiddenByDefault ?? false);

        data[triggerWidgetId] = triggerStatuses;
      });

      ConditionalLogicCommonUtils.getConditionTaskTemplateGroupIdsFromRule(rule).forEach(triggerTaskTemplateId => {
        const triggerStatuses = data[triggerTaskTemplateId] ?? createEmptyStatus();

        triggerStatuses.isTriggerAt.add(rule.id);
        triggerStatuses.isHiddenByDefault =
          triggerStatuses.isHiddenByDefault ||
          (normalizedData.tasks.byGroupId[triggerTaskTemplateId]?.hiddenByDefault ?? false);

        data[triggerTaskTemplateId] = triggerStatuses;
      });
    });

    normalizedData.nodes.ids.forEach(nodeId => {
      const node = normalizedData.nodes.byId[nodeId];

      if (!node) return;

      const groupId = getNodeGroupId(node);
      const id = normalizedData.widgets.byGroupId[groupId]?.id || normalizedData.tasks.byGroupId[groupId]?.id;
      const widgetStatus = data[groupId] ?? createEmptyStatus();

      const isHiddenByDefault =
        normalizedData.widgets.hiddenByDefaultIds.has(id) || normalizedData.tasks.hiddenByDefaultIds.has(id);

      widgetStatus.isHiddenByDefault = widgetStatus.isHiddenByDefault || isHiddenByDefault;

      data[groupId] = widgetStatus;
    });

    return data;
  };

  export const getRulesAffectingWidget = (
    widget: Widget | null,
    selectedRule: ChecklistRuleDefinition | null,
    nodesStatus: Record<string, NodeStatus>,
    rules: ChecklistRuleDefinition[],
  ) => {
    if (!widget) return [];

    return rules.filter(rule => {
      if (rule.id === selectedRule?.id) return false;

      const nodeStatus = nodesStatus[widget.header.group.id];

      return nodeStatus?.isHiddenBy.has(rule.id) || nodeStatus?.isShownBy.has(rule.id);
    });
  };

  export const getNormalizedRules = (rules: ChecklistRuleDefinition[]) => {
    const normalizedRules: NormalizedData['rules'] = {
      byId: {},
      byFormFieldWidgetGroupId: {},
      ids: [],
    };

    rules.forEach(rule => {
      normalizedRules.byId[rule.id] = rule;
      normalizedRules.ids.push(rule.id);

      ConditionalLogicCommonUtils.getFormFieldWidgetGroupIdsFromRule(rule).forEach(formFieldWidgetGroupId => {
        if (normalizedRules.byFormFieldWidgetGroupId[formFieldWidgetGroupId]) {
          normalizedRules.byFormFieldWidgetGroupId[formFieldWidgetGroupId].push(rule);
        } else {
          normalizedRules.byFormFieldWidgetGroupId[formFieldWidgetGroupId] = [rule];
        }
      });
    });

    return normalizedRules;
  };

  export const getNormalizedWidgets = (widgets: Widget[]) => {
    const normalizedWidgets: NormalizedData['widgets'] = {
      byGroupId: {},
      byHeaderId: {},
      byId: {},
      ids: [],
      hiddenByDefaultIds: new Set(),
    };

    widgets.forEach(widget => {
      const widgetGroupId = match(widget)
        .with({ header: { group: { id: P.string } } }, () => widget.header.group.id)
        .otherwise(() => null);

      normalizedWidgets.byId[widget.id] = widget;
      normalizedWidgets.byHeaderId[widget.header.id] = widget;
      normalizedWidgets.ids.push(widget.id);

      if (widgetGroupId) {
        normalizedWidgets.byGroupId[widgetGroupId] = widget;
      }

      if (widget.header.hiddenByDefault) normalizedWidgets.hiddenByDefaultIds.add(widget.id);
    });

    return normalizedWidgets;
  };

  export const getNormalizedTasks = (tasks: TaskTemplate[]) => {
    const normalizedTasks: NormalizedData['tasks'] = {
      byId: {},
      byHeaderId: {},
      byGroupId: {},
      ids: [],
      hiddenByDefaultIds: new Set(),
    };

    tasks.forEach(task => {
      normalizedTasks.byId[task.id] = task;
      normalizedTasks.byHeaderId[task.id] = task;
      normalizedTasks.byGroupId[task.group.id] = task;
      normalizedTasks.ids.push(task.id);

      if (task.hiddenByDefault) normalizedTasks.hiddenByDefaultIds.add(task.id);
    });

    return normalizedTasks;
  };

  export const getNormalizedNodes = (tasks: TaskTemplate[], widgets: Widget[]) => {
    const normalizedNodes: NormalizedData['nodes'] = {
      byId: {},
      byParentId: {},
      ids: [],
      all: [],
    };

    const nodes = SelectorHelper.createNodes(tasks, widgets);

    normalizedNodes.all = nodes;

    nodes.forEach(node => {
      normalizedNodes.byId[node.id] = node;
      normalizedNodes.ids.push(node.id);

      if (node.parentId) {
        normalizedNodes.byParentId[node.parentId] = normalizedNodes.byParentId[node.parentId] ?? [];
        normalizedNodes.byParentId[node.parentId].push(node);
      }
    });

    return normalizedNodes;
  };

  export const getNormalizedData = (
    widgets: Widget[],
    tasks: TaskTemplate[],
    rules: ChecklistRuleDefinition[],
  ): NormalizedData => {
    const normalizedData: NormalizedData = {
      widgets: getNormalizedWidgets(widgets),
      tasks: getNormalizedTasks(tasks),
      rules: getNormalizedRules(rules),
      nodes: getNormalizedNodes(tasks, widgets),
    };

    return normalizedData;
  };

  export const getNodeParentId = (node: Node) => {
    if (node.parentId) return node.parentId;

    if (node.type === NodeType.Widget) return (node.ref as Widget).header.taskTemplate.id;

    return undefined;
  };

  const OperatorsWithRequiredValue: Set<ChecklistRuleDefinitionOperator> = new Set([
    ChecklistRuleDefinitionOperator.Is,
    ChecklistRuleDefinitionOperator.IsNot,
    ChecklistRuleDefinitionOperator.IsGreaterThan,
    ChecklistRuleDefinitionOperator.IsLessThan,
    ChecklistRuleDefinitionOperator.StartsWith,
    ChecklistRuleDefinitionOperator.EndsWith,
    ChecklistRuleDefinitionOperator.Contains,
    ChecklistRuleDefinitionOperator.DoesNotContain,
    ChecklistRuleDefinitionOperator.TaskStatusIs,
    ChecklistRuleDefinitionOperator.PeriodAfter,
  ]);
  export const isOperandValueRequired = (operator: ChecklistRuleDefinitionOperator) =>
    OperatorsWithRequiredValue.has(operator);

  export const getInitialNodeInfoMap = (
    nodesList: Node[],
    selectedWidgets: Widget[],
    selectedTaskTemplates: TaskTemplate[],
  ) => {
    const nodesMap = _keyBy(nodesList, 'id');

    const initialNodeInfoMap = SelectorHelper.updateNodeInfoMap(
      SelectorHelper.createNodeInfoMap(nodesList),
      nodesList,
      '',
    );

    const taskTemplateIdsToUnfold = new Set<string>();

    /* Using the good old `for` for performance reasons */
    for (let i = 0; i < Math.max(selectedWidgets.length, selectedTaskTemplates.length); i++) {
      const widget = selectedWidgets[i];
      const widgetHeaderId = widget?.header.id;
      const taskTemplateId = selectedTaskTemplates[i]?.id;

      if (widgetHeaderId && initialNodeInfoMap[widgetHeaderId]) {
        initialNodeInfoMap[widgetHeaderId].selected = true;

        if (widget?.header.taskTemplate.id) {
          taskTemplateIdsToUnfold.add(widget?.header.taskTemplate.id);

          const taskTemplateNode = nodesMap[widget.header.taskTemplate.id];

          // Unfold the headings that contains at least one checked widget
          if (taskTemplateNode?.parentId) taskTemplateIdsToUnfold.add(taskTemplateNode.parentId);
        }
      }

      if (taskTemplateId && initialNodeInfoMap[taskTemplateId]) {
        initialNodeInfoMap[taskTemplateId].selected = true;
      }
    }

    taskTemplateIdsToUnfold.forEach(taskTemplateIdToUnfold => {
      if (initialNodeInfoMap[taskTemplateIdToUnfold]) {
        initialNodeInfoMap[taskTemplateIdToUnfold].folded = false;
      }
    });

    return initialNodeInfoMap;
  };

  export const getLevel = (node: Node, parentNode?: Node) => {
    let level = [NodeType.Heading, NodeType.Task, NodeType.Widget].indexOf(node.type);

    if (!node.parentId) level--;
    if (node.type === NodeType.Widget && parentNode?.type === NodeType.Heading) level--;

    return level;
  };

  export const getAllowedFormFieldTypes = (
    featureFlags: Pick<FeatureFlags, 'conditionalLogicMembersField'>,
  ): Set<FieldType> => {
    const fieldTypesMap = {
      [FieldType.Date]: true,
      [FieldType.Email]: true,
      [FieldType.Hidden]: true,
      [FieldType.Members]: featureFlags.conditionalLogicMembersField,
      [FieldType.MultiChoice]: true,
      [FieldType.Number]: true,
      [FieldType.Select]: true,
      [FieldType.SendRichEmail]: true,
      [FieldType.Text]: true,
      [FieldType.Textarea]: true,
      [FieldType.Url]: true,
    };

    return new Set((Object.keys(fieldTypesMap) as (keyof typeof fieldTypesMap)[]).filter(key => fieldTypesMap[key]));
  };

  export const getFormFieldSelectOptions = ({
    widgetsEligibleForSelection,
    taskTemplates,
    featureFlags,
    templateType,
  }: {
    widgetsEligibleForSelection: Widget[];
    taskTemplates: TaskTemplate[];
    featureFlags: FeatureFlags;
    templateType: TemplateType;
  }) => {
    const allowedFormFieldTypes = ConditionalLogicUtils.getAllowedFormFieldTypes(featureFlags);
    const formFieldWidgets =
      widgetsEligibleForSelection.filter(widget =>
        match(widget)
          .when(WidgetUtils.isFormFieldWidget, widget => allowedFormFieldTypes.has(widget.fieldType))
          .otherwise(() => false),
      ) ?? [];

    if (!featureFlags.taskCondition) {
      return formFieldWidgets.map(getFormFieldWidgetOption);
    }

    const sortedTaskTemplates = [...taskTemplates].sort((a, b) => OrderTreeUtils.compare(a.orderTree, b.orderTree));
    const taskTemplatesIds = sortedTaskTemplates.map(tt => tt.id);

    const nodes = SelectorHelper.createNodes(sortedTaskTemplates, formFieldWidgets);

    const shouldAddTimeBasedOptions = templateType === TemplateType.Playbook && featureFlags.timeBasedCL;
    const timeBasedOptions = shouldAddTimeBasedOptions ? [getTimeBasedOption()] : [];

    const taskAndFormFieldOptions = nodes.map(node =>
      node.type === NodeType.Widget
        ? getFormFieldWidgetOptionFromNode(node)
        : getTaskOptionFromNode(node, taskTemplatesIds),
    );

    return timeBasedOptions.concat(taskAndFormFieldOptions);
  };

  /**
   * If a widget is selected, can create a rule only if it's a form field widget. Content widgets cannot be target of the IF part of a rule.
   */
  export const getCanAddRuleGivenSelectedWidget = (
    featureFlags: FeatureFlags,
    selectedWidget?: Widget | null,
  ): boolean => {
    const allowedFormFieldTypes = ConditionalLogicUtils.getAllowedFormFieldTypes(featureFlags);
    return selectedWidget
      ? isFormFieldWidget(selectedWidget) && allowedFormFieldTypes.has(selectedWidget.fieldType)
      : true;
  };
}

const getFormFieldWidgetOption = (formField: Widget) => ({
  value: formField.id,
  label: SelectorHelper.getLabelForWidget(formField),
  icon: SelectorHelper.getIconForWidget(formField),
  type: NodeType.Widget,
});

const getFormFieldWidgetOptionFromNode = (node: Node) => {
  const formField = node.ref as FormFieldWidget;
  return getFormFieldWidgetOption(formField);
};

const getTaskOptionFromNode = (node: Node, taskTemplatesIds: string[]) => {
  const taskTemplate = node.ref as TaskTemplate;
  const taskIndex = taskTemplatesIds.indexOf(taskTemplate.id);

  return {
    value: taskTemplate.id,
    label: `${taskIndex + 1}. ${taskTemplate.name ?? 'unnamed task'}`,
    icon: '',
    type: node.type,
  };
};

const getTimeBasedOption = () => ({
  value: TimeSource.ChecklistStartDate.toString(),
  label: TimeSourceLabels[TimeSource.ChecklistStartDate],
  icon: 'fa-regular fa-play',
  type: NodeType.TimeSource,
});

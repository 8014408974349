import {} from '@process-street/subgrade/core';
import { Task, TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace UpdateTaskDueDateMutation {
  export type Params = {
    dueDate?: number | null;
    dueDateOverridden?: boolean;
    id: Task['id'];
  };

  export type Response = TaskWithTaskTemplate;

  export const key = ['task', 'due', 'date'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/tasks/${params.id}/due`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}

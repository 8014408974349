import { AngularDependenciesContext, getRealContext } from 'components/common/context/angular-dependencies-context';
import { useInjector } from 'components/injection-provider';
import { SignUpGuard } from 'features/sign-up/components/guard';
import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { AnalyticsService } from 'components/analytics/analytics.service';

const ChecklistDashboardScreen = React.lazy(() =>
  import('./checklist-dashboard-screen').then(({ ChecklistDashboardScreen }) => ({
    default: ChecklistDashboardScreen,
  })),
);

export const ChecklistDashboardScreenWrapper: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { $state, OrganizationMembershipActions, SessionService } = useInjector(
    '$state',
    'OrganizationMembershipActions',
    'SessionService',
  );
  useEffect(() => {
    AnalyticsService.trackEvent('Checklist Dashboard viewed', {});
  }, []);

  const realContext = getRealContext($state, OrganizationMembershipActions, SessionService);

  return (
    <React.Suspense fallback="">
      <CookiesProvider>
        <AngularDependenciesContext.Provider value={realContext}>
          <SignUpGuard>
            <ChecklistDashboardScreen />
          </SignUpGuard>
        </AngularDependenciesContext.Provider>
      </CookiesProvider>
    </React.Suspense>
  );
};

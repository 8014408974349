import * as React from 'react';
import { useInView } from 'framer-motion';
import { ReorderAutoScrollContext } from 'app/pages/workflows/_id/edit-v2/providers/reorder-scroll-context';
import { WidgetListItemWrapper, WidgetListItemWrapperProps } from './widget-list-item-wrapper';
import { estimateWidgetHeight } from 'pages/forms/_id/edit/components/widgets-list/estimate-widget-height';
import { isImageWidget } from '@process-street/subgrade/process';

/**
 * If the widget gets this close to the viewport, render the widget.
 * (See docs for useInView and IntersectionObserver options)
 */
const WIDGET_VIEWPORT_MARGIN_PX = 1000;
/** Delay virtualizing widgets after they leave the viewport. This prevents flickering. */
const VIRTUALIZATION_DEBOUNCE_DELAY_MS = 1000;

export const VirtualizedWidgetListItemWrapper = (props: Omit<WidgetListItemWrapperProps, 'shouldRenderLoading'>) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const scrollContainerRef =
    React.useContext(ReorderAutoScrollContext)?.widgetAutoScroll.scrollContainerProps.scrollContainerRef;

  const isCurrentlyInViewport = useInView(ref, {
    margin: WIDGET_VIEWPORT_MARGIN_PX + 'px',
    root: scrollContainerRef,
  });

  const [isInViewport, setIsInViewport] = React.useState(isCurrentlyInViewport);

  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();

  React.useEffect(
    function debounceVirtualization() {
      // a one-way debounce
      timeoutRef.current && clearTimeout(timeoutRef.current);
      if (isCurrentlyInViewport) {
        setIsInViewport(true);
      } else {
        timeoutRef.current = setTimeout(() => setIsInViewport(false), VIRTUALIZATION_DEBOUNCE_DELAY_MS);
      }
    },
    [isCurrentlyInViewport],
  );

  const { widget } = props;

  const lastKnownHeightRef = React.useRef<number>(0);

  React.useLayoutEffect(function measureWidgetHeight() {
    if (isInViewport && ref.current?.clientHeight !== 0) {
      lastKnownHeightRef.current = ref.current?.clientHeight ?? 0;
    }
  });

  const widgetHeight = React.useMemo(() => {
    if (lastKnownHeightRef.current !== 0 && !isImageWidget(widget)) {
      // image widgets always start with height 0 when loading so discard measurement
      return lastKnownHeightRef.current;
    } else if (isInViewport) {
      // only estimate when out of viewport i.e. virtualized
      return 0;
    } else {
      return estimateWidgetHeight(widget, ref.current?.clientWidth);
    }
  }, [widget, isInViewport]);

  return (
    <WidgetListItemWrapper shouldRenderLoading={!isInViewport} ref={ref} estimatedHeight={widgetHeight} {...props} />
  );
};

import * as React from 'react';
import { useActor } from '@xstate/react';
import { DateFormFieldActor } from './date-form-field-machine';
import {
  FormLabel,
  Input,
  InputLeftElement,
  VStack,
  HStack,
  Text,
  useDisclosure,
  Modal,
  MenuItem,
} from 'components/design/next';
import { Icon, InputGroup } from 'components/design/next';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { FormFieldLabel } from '../common/form-field-label';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { dateSettingsSchema } from './date-form-field-schema';
import { SettingsModalHeader } from '../common/settings/settings-modal-content';
import { StringUtils } from '@process-street/subgrade/util';
import { DateSettingsDatePicker } from './settings';
import { FormFieldRecentlyMovedIndicator } from '../common/form-field-recently-moved-indicator';
import { TaskTemplate, TemplateType } from '@process-street/subgrade/process';
import { DateSettingsWithDynamicParametersModalContent } from 'app/components/widgets/form-field/settings/date-settings/date-settings-with-dynamic-parameters';
import { ViewModeInteractionWrapper } from '../../view-mode-interaction-wrapper/view-mode-interaction-wrapper';
import { AiTaskTemplateWidgetIndicatorV2 } from 'app/pages/templates/_id/components/ai-task-template-widget-indicator';
import { PreventClickOverlay } from '../common/prevent-click-overlay';

export type DateFormFieldWidgetProps = {
  isFirst: boolean;
  isLast: boolean;
  actor: DateFormFieldActor;
};

export const DateFormFieldWidget: React.FC<React.PropsWithChildren<DateFormFieldWidgetProps>> = ({
  actor,
  isFirst,
  isLast,
}) => {
  const [state, send] = useActor(actor);
  const { widget, labelActor, recentlyMovedFrom, template, isReadOnly } = state.context;
  const ref = React.useRef<HTMLDivElement | null>(null);
  const settingsDisclosure = useDisclosure();

  const isWorkflow = template.templateType === TemplateType.Playbook;

  const handleSelectTaskTemplate = (taskTemplate: TaskTemplate) => send({ type: 'SELECT_TASK_TEMPLATE', taskTemplate });

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <>
        <FormsWidgetMenuContainer>
          <VStack alignItems="flex-start" flex="1" maxW="88">
            {recentlyMovedFrom && <FormFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}

            <HStack justifyContent="space-between" w="full">
              {labelActor && <FormFieldLabel isReadOnly={isReadOnly} actor={labelActor} />}
              {isWorkflow && (
                <HStack justifyContent="flex-end" w="auto">
                  <AiTaskTemplateWidgetIndicatorV2
                    widget={widget}
                    handleOnSelectTaskTemplate={handleSelectTaskTemplate}
                  />
                </HStack>
              )}
            </HStack>
            <ViewModeInteractionWrapper templateId={template.id}>
              <InputGroup
                ref={node => {
                  ref.current = node;
                  if (node && !state.context.inputNode) {
                    send({ type: 'SET_NODE', node });
                  }
                }}
                scrollMarginBottom={17}
              >
                <InputLeftElement px="3">
                  <Icon icon="calendar-days" size="4" color="gray.300" />
                </InputLeftElement>
                <Input
                  id={`form-field-widget-${widget.id}`}
                  w="full"
                  placeholder="Date will be selected here"
                  isDisabled
                  borderColor="gray.200"
                  _disabled={{ bgColor: 'gray.50', opacity: 1 }}
                />

                {isReadOnly ? (
                  <PreventClickOverlay />
                ) : (
                  <>
                    <WidgetListItemDragIcon />
                    <FormsWidgetMenu>
                      <FormsWidgetMenuItems.Required widget={widget} />

                      {template.templateType === TemplateType.Form && (
                        <FormsWidgetMenuItems.Settings widget={widget} schema={dateSettingsSchema}>
                          <SettingsModalHeader>
                            {StringUtils.getNonEmpty(widget.label, 'Untitled date field')}
                          </SettingsModalHeader>
                          <>
                            <DateSettingsDatePicker
                              name="constraints.afterDate"
                              label={
                                <FormLabel m="0">
                                  Date must be{' '}
                                  <Text as="span" fontWeight="bold">
                                    after
                                  </Text>{' '}
                                  a specific calendar date
                                </FormLabel>
                              }
                            />
                            <DateSettingsDatePicker
                              name="constraints.beforeDate"
                              label={
                                <FormLabel m="0">
                                  Date must be{' '}
                                  <Text as="span" fontWeight="bold">
                                    before
                                  </Text>{' '}
                                  a specific calendar date
                                </FormLabel>
                              }
                            />
                          </>
                        </FormsWidgetMenuItems.Settings>
                      )}

                      {template.templateType === TemplateType.Playbook && (
                        <MenuItem
                          iconSpacing="2"
                          aria-label="Widget settings"
                          icon={<Icon icon="cog" size="4" />}
                          onClick={() => settingsDisclosure.onOpen()}
                        >
                          Settings
                        </MenuItem>
                      )}

                      <FormsWidgetMenuItems.ConditionalLogic
                        widget={widget}
                        templateRevisionId={widget.templateRevision.id}
                        templateType={template.templateType}
                      />

                      <FormsWidgetMenuItems.Divider />

                      <FormsWidgetMenuItems.Duplicate />
                      <FormsWidgetMenuItems.MoveToStep widget={widget} />
                      <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
                      <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
                      <FormsWidgetMenuItems.Delete />
                    </FormsWidgetMenu>
                  </>
                )}
              </InputGroup>
            </ViewModeInteractionWrapper>
          </VStack>
        </FormsWidgetMenuContainer>
        <Modal size="xl" {...settingsDisclosure}>
          {/* Special scenario since date widget configuration has it's own complex logic and
          components, so it does not uses formik right now. We pass the actor.send() to keep machines up to date */}
          <DateSettingsWithDynamicParametersModalContent
            widget={widget}
            onUpdate={w => send({ type: 'UPDATE_WIDGET', widget: w })}
          />
        </Modal>
      </>
    </WidgetActorProvider>
  );
};

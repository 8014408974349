import { FormControl, FormErrorMessage, VStack, Box, useDisclosure } from 'components/design/next';
import React from 'react';
import { useActor } from '@xstate/react';
import { DateFormFieldActor } from './date-form-field-machine';
import { ValidationSelectors } from '../validation-machine';
import { useSelector } from '@xstate/react';
import { StringUtils } from '@process-street/subgrade/util';
import { useGetCurrentUserQuery } from 'features/user/query-builder';
import { BlvdDatePicker, BlvdDatePickerProps } from 'components/design/BlvdDatePicker';
import { useSelector as useReduxSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { DateContextUtils } from '@process-street/subgrade/core/date-context';
import { FormResponseLabel } from '../common';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';

export interface DateFormFieldProps {
  actor: DateFormFieldActor;
}

export const DateFormField: React.FC<React.PropsWithChildren<DateFormFieldProps>> = ({ actor }) => {
  const [state, send] = useActor(actor);
  const { widget } = state.context;
  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);

  const handleSave: BlvdDatePickerProps['onSave'] = (date, timeStatus) => {
    if (date) {
      send({ type: 'CHANGE', value: date.getTime(), isTimeHidden: timeStatus !== 'visible' });
      return;
    }
    send({ type: 'CHANGE', value: undefined, isTimeHidden: true });
  };

  const isInvalid = ValidationSelectors.isActorInvalidVisible(state.context.validationActor);
  const user = useGetCurrentUserQuery().data;
  const org = useReduxSelector(SessionSelector.getSelectedOrganization)!;
  const dateContext = DateContextUtils.getDateContext(org, user);
  const disclosure = useDisclosure({
    onClose: () => {
      actor.send({ type: 'BLUR' });
    },
  });

  const ref = React.useRef<HTMLDivElement | null>(null);

  const id = `date-form-field-${widget.id}`;
  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !state.context.inputNode) {
          actor.send({ type: 'SET_NODE', node });
        }
      }}
      as={VStack}
      alignItems="stretch"
      isInvalid={isInvalid}
      isRequired={widget.required}
    >
      <FormResponseLabel htmlFor={id}>{StringUtils.getNonEmpty(widget.label, 'Untitled Date')}</FormResponseLabel>
      <Box w={{ base: 'full', lg: '88' }}>
        <BlvdDatePicker
          disclosure={disclosure}
          isDisabled={state.matches('input.disabled')}
          value={state.context.value ? new Date(state.context.value) : undefined}
          onSave={handleSave}
          dateContext={dateContext}
          timeStatus={state.context.isTimeHidden ? 'hidden' : 'visible'}
          isInvalid={isInvalid}
          id={id}
          triggerProps={{ autoFocus: state.matches('autoFocus.enabled') }}
        />
      </Box>
      <FormErrorMessage>{ValidationSelectors.errorMessage(state.context.validationActor)}</FormErrorMessage>
    </FormControl>
  );
};

import * as React from 'react';
import { Box, BoxProps } from 'components/design/next';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid-no-native-widgets.css';
import { withCommonAgGridStyleProps } from 'components/design/ag-grid';
import {
  ChecklistGridDataWithPredefined,
  FormResponsesColDef,
  FormResponsesStoreSelectors,
  useFormResponsesStore,
} from '../../store';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { useFormResponsesDatasource } from './use-form-responses-datasource';
import { useGetTemplateSchemaQuery } from 'features/template/query-builder';
import { useStateParam } from 'hooks/use-state-param';
import { useSortChangedHandler } from './use-sort-changed-handler';
import { GRID_OPTIONS } from './grid-options';
import { ChecklistDashboardSelectionBarActionsWrapper } from 'components/dashboard/components/checklist/ChecklistDashboardSelectionBarActionsWrapper';
import { ColumnMovedEvent, GridReadyEvent, SelectionChangedEvent } from '@ag-grid-community/core';
import { useChecklistScreenSlice } from 'app/components/dashboard/components/checklist/checklist-dashboard-screen/checklist-screen-store';
import { useQueryClient } from 'react-query';
import { GetFormResponsesSearchQuery } from 'features/forms/query-builder';
import { ChecklistGridCountQuery } from 'features/checklists/query-builder';

export interface FormResponsesTableProps extends BoxProps {}

export const FormResponsesTable: React.FC<React.PropsWithChildren<FormResponsesTableProps>> = props => {
  const formId = useStateParam({ key: 'id' });
  const schemaQuery = useGetTemplateSchemaQuery({ templateId: formId }, { staleTime: Infinity });
  const visibleColumns = React.useMemo(
    () => FormResponsesStoreSelectors.getVisibleColumns(schemaQuery.data),
    [schemaQuery.data],
  );
  const colDefs = useFormResponsesStore(visibleColumns);
  const gridRef = React.useRef<AgGridReact>(null);
  const datasource = useFormResponsesDatasource();
  const handleSortChanged = useSortChangedHandler<ChecklistGridDataWithPredefined>();

  const store = useFormResponsesStore();

  const handleColumnMoved = React.useCallback(
    (event: ColumnMovedEvent) => {
      if (!event.finished) return;
      const newColumnDefs = event.api.getAllGridColumns();
      if (!newColumnDefs?.length) {
        return;
      }
      const newVisibleColumns = newColumnDefs.map(c => (c.getColDef() as FormResponsesColDef).field);
      store.setVisibleColumnFields(newVisibleColumns);
    },
    [store],
  );

  const { setSelectedNodes } = useChecklistScreenSlice();
  const handleSelectionChanged = React.useCallback(
    ({ api }: SelectionChangedEvent) => {
      setSelectedNodes(api?.getSelectedNodes() ?? []);
    },
    [setSelectedNodes],
  );
  const queryClient = useQueryClient();

  const conditionalFilter = useFormResponsesStore(FormResponsesStoreSelectors.getConditionalFilter);
  React.useEffect(() => {
    // deselect all rows when filter changes
    setSelectedNodes([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- filter change
  }, [conditionalFilter]);

  const onGridReady = React.useCallback((event: GridReadyEvent) => {
    event.api.applyColumnState({ state: [{ colId: 'checklistCompletedDate', sort: 'desc' }] });
  }, []);

  return (
    <>
      <ChecklistDashboardSelectionBarActionsWrapper
        mode="FormResponse"
        onActionCompleted={async () => {
          await queryClient.invalidateQueries(GetFormResponsesSearchQuery.key);
          await queryClient.invalidateQueries(ChecklistGridCountQuery.key);
          gridRef.current?.api.refreshInfiniteCache();
          gridRef.current?.api.getSelectedNodes().forEach(node => node.setSelected(false));
        }}
      />
      <Box w="full" sx={styles} {...props}>
        <AgGridReact<ChecklistGridDataWithPredefined>
          modules={[InfiniteRowModelModule]}
          {...GRID_OPTIONS}
          datasource={datasource}
          onSortChanged={handleSortChanged}
          ref={gridRef}
          context={{ gridRef }}
          // @ts-expect-error can't find a solution for this type
          columnDefs={colDefs}
          onColumnMoved={handleColumnMoved}
          onGridReady={onGridReady}
          onSelectionChanged={handleSelectionChanged}
        />
      </Box>
    </>
  );
};

const styles = withCommonAgGridStyleProps({
  '.ag-cell[col-id="completedBy"]': {
    py: 0,
  },
  '.ag-row': {
    bgColor: 'white',
  },
  '.ag-header-viewport .ag-header-cell .column-name, .ag-cell': {
    borderColor: 'gray.200',
  },
  '.ag-header-cell-label': {
    justifyContent: 'space-between',
  },
  '.ag-pinned-left-header': {
    bgColor: 'brand.100',
  },

  // fix selected cell border colors
  '--ag-range-selection-border-color': 'var(--ps-colors-gray-200)',
});

import { DateContextUtils } from '@process-street/subgrade/core/date-context';
import { DateFieldValue, FormFieldWidgetWithValue } from '@process-street/subgrade/process';
import { DateFieldUtils } from '@process-street/subgrade/util';
import { useGetOrganizationQuery } from 'app/features/organization/query-builder';
import { useCurrentUser } from 'app/hooks/use-current-user';
import { Text } from 'components/design/next';
import * as React from 'react';

export type ApprovalWidgetDateRendererProps = {
  widget: FormFieldWidgetWithValue;
};

export const ApprovalWidgetDateRenderer = ({ widget }: ApprovalWidgetDateRendererProps) => {
  const user = useCurrentUser();
  const organizationQuery = useGetOrganizationQuery({
    organizationId: widget.header.organization.id,
  });

  const rendererValue = React.useMemo(() => {
    const dateValue = widget.formFieldValue?.fieldValue && (widget.formFieldValue?.fieldValue as DateFieldValue);
    if (!dateValue?.value || !organizationQuery.data) return null;

    const dateContext = DateContextUtils.getDateContext(organizationQuery.data, user);
    return DateFieldUtils.formatDateFieldValueToShortMonth(dateValue, dateContext);
  }, [widget.formFieldValue?.fieldValue, organizationQuery.data, user]);

  return (
    <Text fontWeight="normal" color="gray.600">
      {rendererValue}
    </Text>
  );
};

import { Muid } from '@process-street/subgrade/core';
import {
  FormFieldWidgetWithValue,
  MultiChoiceItemValueStatus,
  MultiOptionFieldValue,
  SelectFormFieldConfig,
} from '@process-street/subgrade/process';
import { Text } from 'components/design/next';
import * as React from 'react';

export type ApprovalWidgetMultiChoiceRendererProps = {
  widget: FormFieldWidgetWithValue;
};

export const ApprovalWidgetMultiChoiceRenderer = ({ widget }: ApprovalWidgetMultiChoiceRendererProps) => {
  const rendererValue = React.useMemo(() => {
    const value = widget.formFieldValue?.fieldValue && (widget.formFieldValue?.fieldValue as MultiOptionFieldValue);
    const selectedIds =
      value?.itemValues.reduce((ids: Muid[], item) => {
        if (item.status === MultiChoiceItemValueStatus.Selected) {
          ids.push(item.id);
        }
        return ids;
      }, []) ?? [];

    return (widget.config as SelectFormFieldConfig).items
      .filter(item => selectedIds.includes(item.id))
      .map(item => item.name)
      .join(', ');
  }, [widget.config, widget.formFieldValue?.fieldValue]);

  return <Text color="gray.600">{rendererValue}</Text>;
};

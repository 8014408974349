import * as React from 'react';
import {
  Box,
  Divider,
  HStack,
  IconButton,
  MenuButton,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Tooltip,
} from 'components/design/next';
import { MergeTagsMenu } from 'app/features/merge-tags/components/merge-tags-menu';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { createMergeTag } from 'app/hooks/use-insert-merge-tag-callback';
import { escapeHtml } from 'markdown-it/lib/common/utils';
import { AiGenerateTextWidgetContentMenu } from 'app/features/widgets/components/ai/ai-generate-text-widget-content-menu';
import {
  isSendRichEmailContentWidget,
  isTextWidget,
  SendRichEmailFormFieldWidget,
  TemplateRevision,
  TextWidget,
  WithTaskTemplate,
} from '@process-street/subgrade/process';
import { TinyMCEEditor } from 'app/features/rich-text';
import { getFormatButtonStyles } from './format-button';
import { Icon } from 'app/components/design/next';
import { useCommandsStatus } from '../hooks/use-commands-status';
import { useToolbarDisclosure } from '../hooks/use-toolbar-disclosure';
import { useToolbarAnchorPosition } from '../hooks/use-toolbar-anchor-position';
import { useMatch } from '@process-street/adapters/navigation';
import { renderOption, ToolbarMode, toolbarOptionsByMode } from './utils';
import { MutationStatus } from 'react-query';
import { noop } from 'lodash';

export type TextWidgetToolbarProps = {
  editor?: TinyMCEEditor;
  widget: WithTaskTemplate<TextWidget> | SendRichEmailFormFieldWidget;
  templateRevisionId?: TemplateRevision['id'];
  popoverContentRef: React.RefObject<HTMLDivElement>;
  onGenerationStatusChange?: (status: MutationStatus) => void;
};

export const TextWidgetToolbar = React.memo(
  ({
    editor,
    widget,
    templateRevisionId,
    popoverContentRef,
    onGenerationStatusChange = noop,
  }: TextWidgetToolbarProps) => {
    const mergeTagsMenuContentRef = React.useRef<HTMLDivElement | null>(null);
    const toolbarDisclosure = useToolbarDisclosure({ editor, popoverContentRef, mergeTagsMenuContentRef });
    const resolvedTemplateRevisionId =
      'templateRevision' in widget.header.taskTemplate
        ? widget.header.taskTemplate.templateRevision?.id
        : templateRevisionId;
    const anchorRect = useToolbarAnchorPosition({ editor });
    const { disabledCommands, activeCommands } = useCommandsStatus({ editor });
    const isPageContent = Boolean(useMatch('pageV2'));
    const isWorkflowRun = Boolean(useMatch('checklist'));
    const isTextContentWidget = isTextWidget(widget);
    const mode = isTextContentWidget ? ToolbarMode.TextContentWidget : ToolbarMode.SendEmailWidget;
    const toolbarOptions = toolbarOptionsByMode[mode];

    return (
      <Popover {...toolbarDisclosure} closeOnBlur={false} autoFocus={false} placement="top">
        {anchorRect && toolbarDisclosure.isOpen && (
          <PopoverAnchor>
            <Box
              visibility="hidden"
              position="fixed"
              style={{
                top: anchorRect.top,
                left: anchorRect.left,
                width: anchorRect.width,
                height: anchorRect.height,
              }}
            />
          </PopoverAnchor>
        )}

        <PopoverContent mt={-10} p={0} w="max-content" ref={popoverContentRef} bgColor="gray.700">
          <PopoverBody p={0}>
            <HStack spacing={0.5}>
              {toolbarOptions.map(({ name, hasDivider }) => (
                <React.Fragment key={name}>
                  {renderOption(name, { editor, activeCommands, disabledCommands })}
                  {hasDivider && <Divider h="8" w="1px" bgColor="gray.600" orientation="vertical" />}
                </React.Fragment>
              ))}
              {!isPageContent && (
                <>
                  <MergeTagsMenu
                    ref={mergeTagsMenuContentRef}
                    menuButton={
                      <Tooltip label="Variables">
                        <MenuButton
                          as={IconButton}
                          {...getFormatButtonStyles()}
                          aria-label="Variables"
                          icon={<Icon icon="wand-magic-sparkles" size="3.5" />}
                        />
                      </Tooltip>
                    }
                    mergeTagTarget={MergeTagsConstants.Target.RICH_CONTENT}
                    templateRevisionId={resolvedTemplateRevisionId}
                    onSelect={(key, _fieldId, fallback) => {
                      editor?.insertContent(createMergeTag(escapeHtml(key), fallback));
                    }}
                  />

                  {!isWorkflowRun && (
                    <AiGenerateTextWidgetContentMenu
                      editor={editor}
                      widget={widget}
                      menuButton={
                        <Tooltip label="Generate content with AI">
                          <MenuButton
                            as={IconButton}
                            {...getFormatButtonStyles()}
                            aria-label="Generate Content with AI"
                            icon={<Icon icon="sparkles" size="3.5" />}
                          />
                        </Tooltip>
                      }
                      onGenerationStatusChange={onGenerationStatusChange}
                      onContentGenerated={w => {
                        if (isTextContentWidget) {
                          editor?.setContent((w as TextWidget).content ?? '', { format: 'html' });
                        }

                        if (isSendRichEmailContentWidget(w as SendRichEmailFormFieldWidget)) {
                          editor?.setContent((w as SendRichEmailFormFieldWidget).config.richEditorBody ?? '', {
                            format: 'html',
                          });
                        }
                      }}
                    />
                  )}
                </>
              )}
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  },
);

import * as React from 'react';
import { Icon, MenuItem, MenuItemProps } from 'components/design/next';
import { WidgetHeader } from '@process-street/subgrade/process';
import { getWidgetModalsMachineService } from 'app/features/widgets/widget-modals-machine';
import { useDataSetButton } from 'app/pages/templates/_id/components/widget-menu/use-data-set-button';
import { match } from 'ts-pattern';
import { useFormEditorPageActorRef } from '../../form-editor-page-machine/form-editor-page-machine-context';
import { useSelector } from '@xstate/react';
import { FormEditorPageActorSelectors } from '../../form-editor-page-machine/form-editor-page-machine-selectors';
import _isEqual from 'lodash/isEqual';

export type ConnectDataSetMenuItemProps = MenuItemProps & {
  widgetHeaderId: WidgetHeader['id'];
};

const widgetModalsActor = getWidgetModalsMachineService();

export const ConnectDataSetMenuItem: React.FC<ConnectDataSetMenuItemProps> = ({ widgetHeaderId }) => {
  const editorActor = useFormEditorPageActorRef();
  // Making sure we're getting the right widget from the BE
  const widget = useSelector(editorActor, FormEditorPageActorSelectors.getWidgetByHeaderId(widgetHeaderId), _isEqual);
  const { dataSetButtonToShow } = useDataSetButton(widget);

  return (
    <>
      {match(dataSetButtonToShow)
        .with('link', () => (
          <MenuItem
            onClick={() => widgetModalsActor.send({ type: 'OPEN_CONNECT_DATA_SET', widget })}
            icon={<Icon icon="database" variant="far" size="4" />}
          >
            Connect Data Set
          </MenuItem>
        ))
        .with('unlink', () => (
          <MenuItem
            icon={
              <Icon
                aria-description="This widget is connected to a data set"
                color="purple.500"
                icon="database"
                size="4"
                variant="far"
              />
            }
            onClick={() => widgetModalsActor.send({ type: 'OPEN_UNLINK_DATA_SET', widget })}
          >
            Disconnect Data Set
          </MenuItem>
        ))
        .otherwise(() => null)}
    </>
  );
};

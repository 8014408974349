import { TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { Button, HStack, Text, VStack } from 'components/design/next';
import * as React from 'react';
import { RejectTaskButton } from '../common/reject-task-button';
import { ApprovalStatus } from '@process-street/subgrade/approval-rule';

export type ApprovedTaskItemProps = {
  task: TaskWithTaskTemplate;
  handleUpsertApproval: (task: TaskWithTaskTemplate, status: ApprovalStatus, comment?: string) => void;
};

export const ApprovedTaskItem = ({ task, handleUpsertApproval }: ApprovedTaskItemProps) => {
  const [isEditingStatus, setIsEditingStatus] = React.useState(false);
  const [isRejectingWithComment, setIsRejectingWithComment] = React.useState(false);

  return (
    <VStack
      w="full"
      key={task.id}
      role="group"
      backgroundColor="gray.50"
      p="4"
      borderLeft="solid 2px"
      borderColor="green.500"
      _hover={{ cursor: 'pointer' }}
    >
      <HStack w="full" justifyContent="space-between">
        <Text color="brand.500">{task.taskTemplate.name}</Text>
        {!isEditingStatus && (
          <Button
            variant="unstyled"
            color="gray.300"
            fontSize="md"
            display="none"
            w="auto"
            onClick={() => setIsEditingStatus(true)}
            _groupHover={{ display: 'block' }}
            _hover={{ color: 'gray.400' }}
          >
            Edit status
          </Button>
        )}
      </HStack>
      {isEditingStatus && (
        <HStack w="full" pt="2" justifyContent="flex-end">
          <Button
            display={isRejectingWithComment ? 'none' : 'block'}
            variant="ghost"
            color="gray.500"
            fontWeight="normal"
            onClick={() => setIsEditingStatus(false)}
          >
            Cancel
          </Button>

          <RejectTaskButton
            task={task}
            handleUpsertApproval={handleUpsertApproval}
            onRejecting={() => setIsRejectingWithComment(true)}
            onCancel={() => setIsRejectingWithComment(false)}
          />
        </HStack>
      )}
    </VStack>
  );
};

import * as React from 'react';
import {
  Button,
  ButtonGroup,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from 'components/design/next';
import { Muid, Option } from '@process-street/subgrade/core';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { DataSet, SavedView, SavedViewType, SelectFormFieldWidget, Widget } from '@process-street/subgrade/process';
import {
  ActionMeta,
  components,
  createFilter,
  GroupBase,
  GroupHeadingProps,
  OnChangeValue,
  OptionProps,
} from 'react-select';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { GetAllDataSetsQuery } from 'pages/reports/data-sets/query-builder/get-all-data-sets';

import { useCreateDataSetLinkMutation } from 'pages/reports/data-sets/query-builder';
import { useQueryClient } from 'react-query';
import { MergeTagsByTemplateRevisionIdQuery } from 'features/merge-tags/query-builder';
import { getWidgetModalsMachineService, Selectors } from 'features/widgets/widget-modals-machine';
import { useSelector } from '@xstate/react';
import { SimpleOption } from 'components/design/BlvdSelect/types';
import { HStack, useToken } from '@chakra-ui/react';

export interface LinkDataSetModalProps {
  onUpdate: (widget: Widget) => Promise<Widget>;
  templateRevisionId: Muid;
  taskTemplateId: Muid;
}

interface SavedViewOptionData {
  savedView: SavedView;
  dataSet: DataSet;
}

interface SavedViewOption extends SavedViewOptionData {
  value: Muid;
  label: string;
}

export const CustomSavedViewOption: React.FC<
  React.PropsWithChildren<OptionProps<SavedViewOption, false, GroupBase<SavedViewOption>>>
> = (props: OptionProps<SavedViewOption, false, GroupBase<SavedViewOption>>) => {
  const savedView = props.data.savedView as SavedView;
  const dataSet = props.data.dataSet as DataSet;

  return (
    <div className="blvd-select__option__wrapper" title={props.label}>
      <components.Option {...props}>
        <VStack alignItems="start">
          <Text>{savedView.name}</Text>
          <Text color="gray.500" variant="-1">
            <Icon icon="database" variant="far" size="3.5" color="gray.600" mr={1} />
            {dataSet.name}
          </Text>
        </VStack>
      </components.Option>
    </div>
  );
};

const CustomGroupHeading = (props: GroupHeadingProps<SavedViewOption, false, GroupBase<SavedViewOption>>) => (
  <components.GroupHeading {...props}>
    <HStack spacing={2}>
      <Icon icon="database" size="3" variant="far" />
      <Text variant="-2u">{props.data.label}</Text>
    </HStack>
  </components.GroupHeading>
);

const actor = getWidgetModalsMachineService();

export const LinkDataSetModal: React.FC<React.PropsWithChildren<LinkDataSetModalProps>> = ({
  onUpdate,
  templateRevisionId,
  taskTemplateId,
}) => {
  const onClose = () => {
    actor.send({ type: 'CLOSE_LINK_DATA_SET' });
  };
  const widget = useSelector(actor, Selectors.getWidget<SelectFormFieldWidget>);
  const isOpen = useSelector(actor, Selectors.getIsOpen('linkDataSet'));

  // passing selected dataset from import
  const initialDataSet = useSelector(actor, Selectors.getDataSet);
  const initialSavedView = initialDataSet?.savedViews.find(sv => sv.savedViewType === SavedViewType.AllData);

  const [selectedColumn, selectColumn] = React.useState<Option<SimpleOption>>();

  const getAllDataSetsQuery = GetAllDataSetsQuery.useQuery();
  const savedViewOptionsData: SavedViewOptionData[] = React.useMemo(
    () =>
      getAllDataSetsQuery.data?.flatMap(dataSet =>
        dataSet.savedViews.map(savedView => ({
          savedView,
          dataSet,
        })),
      ) ?? [],
    [getAllDataSetsQuery],
  );

  const savedViewOptionDataOption = (savedView: SavedViewOptionData) => ({
    value: savedView.savedView.id,
    label: savedView.savedView.name,
    savedView: savedView.savedView,
    dataSet: savedView.dataSet,
  });

  const [selectedSavedView, selectSavedView] = React.useState<Option<SavedViewOptionData>>();
  React.useEffect(
    function selectInitialOption() {
      const initialOption = savedViewOptionsData.find(o => o.savedView.id === initialSavedView?.id);
      if (initialOption) {
        selectSavedView(initialOption);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- including savedViewOptionsData was causing infinite loop
    [initialSavedView?.id, selectSavedView],
  );

  const selectedOption = selectedSavedView ? savedViewOptionDataOption(selectedSavedView) : undefined;
  const createDataSetLinkMutation = useCreateDataSetLinkMutation({});
  const queryClient = useQueryClient();

  const handleConnectDataSet = () => {
    if (selectedSavedView && selectedColumn && widget) {
      createDataSetLinkMutation.mutate(
        {
          templateRevisionId,
          taskTemplateId,
          columnId: selectedColumn.value,
          formFieldWidgetId: widget.id,
          dataSetId: selectedSavedView.dataSet.id,
          savedViewId: selectedSavedView.savedView.id,
        },
        {
          onSuccess: linkedColumnData => {
            void MergeTagsByTemplateRevisionIdQuery.invalidate(queryClient, templateRevisionId);
            void onUpdate({
              ...widget,
              label: linkedColumnData.columnName,
              config: {
                ...widget.config,
                linkId: linkedColumnData.linkId,
                linkedDataSetId: linkedColumnData.dataSetId,
                linkedDataSetName: linkedColumnData.dataSetName,
                linkedSavedViewId: linkedColumnData.savedViewId,
                linkedSavedViewName: linkedColumnData.savedViewName,
                linkedColumnId: linkedColumnData.columnId,
                linkedColumnName: linkedColumnData.columnName,
                linkedColumnData: linkedColumnData.columnData,
              },
            });

            onClose();
          },
        },
      );
    }
  };

  const savedViewGroups: GroupBase<SavedViewOption>[] = (getAllDataSetsQuery.data ?? [])
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(dataSet => ({
      label: dataSet.name,
      options: dataSet.savedViews
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map(savedView => ({
          value: savedView.id,
          label: savedView.name,
          savedView,
          dataSet,
        })),
    }));

  const columnsOptions: SimpleOption[] =
    selectedSavedView?.savedView.columns
      .filter(column => !column.hide)
      .map(column => {
        const columnDef = selectedSavedView.dataSet.columnDefs.find(columnDef => columnDef.id === column.id);

        return {
          value: columnDef?.id ?? '(missing id)',
          label: columnDef?.name ?? '(missing name)',
        };
      }) ?? [];

  const handleSavedViewSelect = (
    value: OnChangeValue<SavedViewOption, false>,
    actionMeta: ActionMeta<SavedViewOption>,
  ) => {
    if (actionMeta.action === 'select-option') {
      if (BlvdSelectHelpers.isOptionType<SavedViewOption>(value)) {
        selectSavedView({ savedView: value.savedView, dataSet: value.dataSet });
        selectColumn(undefined);
      }
    }
  };

  const handleColumnSelect = (value: OnChangeValue<SimpleOption, false>, actionMeta: ActionMeta<SimpleOption>) => {
    if (actionMeta.action === 'select-option') {
      if (BlvdSelectHelpers.isOptionType<SimpleOption>(value)) {
        selectColumn(value);
      }
    }
  };

  const [size1] = useToken('sizes', [1]);

  return (
    <Modal isOpen={isOpen} size="xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader p={8}>
          <Text variant="2">
            <Icon icon="database" variant="far" size="5" color="gray.600" mr={3} />
            Connect Data Set
          </Text>
        </ModalHeader>
        <ModalBody px={9} py={2}>
          <VStack alignItems="stretch">
            <Text variant="1" fontWeight="medium" color="gray.700" paddingTop={4} as={FormLabel} id="saved-views-label">
              Select View
            </Text>
            <BlvdSelect<SavedViewOption, false, GroupBase<SavedViewOption>>
              onChange={handleSavedViewSelect}
              options={savedViewGroups}
              isSearchable={true}
              enableSearchAutoFocus
              components={{ GroupHeading: CustomGroupHeading }}
              placeholder="Select view"
              isDisabled={savedViewGroups.length === 0}
              value={selectedOption}
              aria-labelledby="saved-views-label"
              styles={{
                groupHeading: s => ({
                  ...s,
                  paddingBottom: size1,
                }),
              }}
              filterOption={createFilter({
                stringify: option => `${option.data.dataSet.name} ${option.data.savedView.name}`,
              })}
            />
            <Text variant="1" fontWeight="medium" color="gray.700" paddingTop={4} as={FormLabel} id="columns-label">
              Column
            </Text>
            <BlvdSelect
              onChange={handleColumnSelect}
              value={selectedColumn ?? null}
              options={columnsOptions}
              placeholder="Select column"
              isDisabled={!selectedSavedView}
              aria-labelledby="columns-label"
              isSearchable
            />
          </VStack>
        </ModalBody>
        <ModalFooter p={6}>
          <ButtonGroup>
            <Button aria-label="cancel" variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              aria-label="connect data set"
              isDisabled={!selectedSavedView || !selectedColumn}
              onClick={handleConnectDataSet}
              isLoading={createDataSetLinkMutation.isLoading}
              loadingText="Connecting..."
            >
              Connect
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

import * as React from 'react';
import { DateFormat, DateUtils, dayjs } from '@process-street/subgrade/util';
import { Badge, BadgeProps, Box, Flex, Portal, Tooltip, useDisclosure, useToast } from 'components/design/next';
import { TasksTableUtils } from '../tasks-table/tasks-table-utils';
import { DatePicker } from 'app/features/conditional-logic/components/date-picker';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { UpdateChecklistDueDateMutation } from 'app/features/checklists/query-builder';
import { UpdateTaskDueDateMutation } from 'app/features/one-off-tasks/query-builder';
import { match, P } from 'ts-pattern';
import { InboxItemType } from '@process-street/subgrade/inbox';
import { GetInboxItemsQuery } from 'app/features/microsoft-teams/query-builder';
import { useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { DefaultErrorMessages } from 'app/components/utils/error-messages';
import { useInboxItemsGridApi, useIsCellHovered } from '../../use-inbox-items-grid-context-store';
import { MyWorkGridContext } from './use-grid-context';
import { DynamicDueDateOverride } from 'app/features/inbox/components/inbox-item-due-date-picker/dynamic-due-date-override';

export type DueDateRendererProps = {
  data: TasksTableUtils.TasksTableItem;
  value: number;
  context: MyWorkGridContext;
};

const handleUpdateDueDateError = (e: unknown, toast: ReturnType<typeof useToast>) => {
  const serverErrorMessage = (e as AxiosError<{ message: string }>).response?.data.message;
  const serverErrorStatus = (e as AxiosError<{ status: number }>)?.response?.status;

  match({ serverErrorStatus, serverErrorMessage })
    .with(
      {
        serverErrorStatus: 403,
        serverErrorMessage: P.when(message => !!message && message.includes('does not have the permission needed')),
      },
      () => {
        toast({
          status: 'error',
          title: 'We’re having problems updating the due date',
          description: 'You do not have permission update this due date, please reach out to an admin.',
        });
      },
    )
    .with(
      {
        serverErrorMessage: P.when(message => !!message && message.includes('must be in the future')),
      },
      () => {
        toast({
          status: 'error',
          title: 'We’re having problems updating the due date',
          description: 'The new due date must be in the future.',
        });
      },
    )
    .otherwise(() => {
      toast({
        status: 'error',
        title: 'We’re having problems updating the due date',
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    });
};

const getBadgeProps = (timestamp: number, timeZone: string, isCompleted: boolean): BadgeProps => {
  const dueDate = dayjs.tz(timestamp, timeZone);
  const now = dayjs();

  if (isCompleted) {
    return {
      bgColor: 'gray.100',
      color: 'gray,500',
    };
  }

  if (dueDate.isBefore(now))
    return {
      bgColor: 'red.100',
      color: 'red.600',
    };
  if (dueDate.diff(now, 'h') <= 24)
    return {
      bgColor: 'yellow.100',
      color: 'yellow.600',
    };

  return {
    bgColor: 'gray.50',
    color: 'gray.500',
  };
};

export const DueDateRenderer = ({ value, data, context }: DueDateRendererProps) => {
  const dueDate = value;
  const isHovered = useIsCellHovered(data, GetInboxItemsQuery.SortBy.AssignmentDueDate);
  const gridApi = useInboxItemsGridApi();
  const isCompleted = TasksTableUtils.isInboxItemCompleted(data);

  const datePickerDisclosure = useDisclosure({
    onClose: () => {
      context.focusManagerActions.reset();
      gridApi?.clearFocusedCell();
      context.focusManagerActions.reset();
      gridApi?.clearFocusedCell();
    },
  });

  const queryClient = useQueryClient();
  const toast = useToast();

  const updateChecklistDueDateMutation = UpdateChecklistDueDateMutation.useMutation();
  const updateTaskDueDateMutation = UpdateTaskDueDateMutation.useMutation();

  const dueDateBadgeFormat = DateUtils.formatDateToMonthDay(dueDate ?? 0, { timeZone: context.timeZone });
  const dueDateTooltipFormat = DateUtils.formatDateTime(
    dueDate ?? 0,
    DateFormat.DateMonthDayYearAtTime,
    context.timeZone,
  );

  const handleUpdateDueDate = async (newDueDate: number | null) => {
    try {
      const promise = match(data)
        .with({ itemType: InboxItemType.Checklist }, inboxItem => {
          return updateChecklistDueDateMutation.mutateAsync({ dueDate: newDueDate, id: inboxItem.checklist.id });
        })
        .with(
          { itemType: P.union(InboxItemType.OneOffTask, InboxItemType.ApprovalTask, InboxItemType.StandardTask) },
          inboxItem => {
            return updateTaskDueDateMutation.mutateAsync({ id: inboxItem.task.id, dueDate: newDueDate });
          },
        )
        .otherwise(() => {
          return Promise.resolve();
        });

      await promise;
      datePickerDisclosure.onClose();
      await GetInboxItemsQuery.invalidate(queryClient);
    } catch (e) {
      handleUpdateDueDateError(e, toast);
      datePickerDisclosure.onClose();
    }
  };

  const renderDueDateBadge = React.useCallback(
    () => (
      <Flex h="full" alignItems="center" px={2}>
        <Tooltip hasArrow isDisabled={!dueDate} label={dueDateTooltipFormat}>
          {dueDate ? (
            <Badge
              px={2}
              py={0}
              fontSize="md"
              borderRadius="8px"
              lineHeight="24px"
              fontWeight="medium"
              opacity={datePickerDisclosure.isOpen ? 0.8 : 1}
              _hover={{
                opacity: 0.8,
                cursor: 'pointer',
              }}
              {...getBadgeProps(value, context.timeZone, isCompleted)}
            >
              {dueDateBadgeFormat}
            </Badge>
          ) : (
            <Box px={2} h={6} w={15} cursor="pointer" />
          )}
        </Tooltip>
      </Flex>
    ),
    [
      context.timeZone,
      datePickerDisclosure.isOpen,
      dueDate,
      dueDateBadgeFormat,
      dueDateTooltipFormat,
      isCompleted,
      value,
    ],
  );

  const renderContent = React.useCallback(
    (content: React.ReactElement) => {
      if (!TasksTableUtils.isInboxItemRow(data)) return content;
      return <DynamicDueDateOverride inboxItem={data}>{content}</DynamicDueDateOverride>;
    },
    [data],
  );

  const wrapper = React.useCallback(
    ({ children }: { children?: React.ReactNode }) => (
      <PopoverContentWrapper isMobile={context.isMobile ?? false}>{children}</PopoverContentWrapper>
    ),
    [context.isMobile],
  );

  if (isCompleted || (!isHovered && !context.isMobile && !datePickerDisclosure.isOpen)) {
    return renderDueDateBadge();
  }

  return (
    <DatePicker
      isDisabled
      onChange={handleUpdateDueDate}
      timeZone={context.timeZone}
      value={dueDate}
      pickerProps={{
        disabled: date => dayjs(date).isBefore(dayjs().subtract(1, 'day')),
      }}
      popoverProps={{
        ...datePickerDisclosure,
        placement: 'bottom-start',
      }}
      renderTrigger={renderDueDateBadge}
      renderContent={renderContent}
      size="md"
      PopoverContentWrapper={wrapper}
    />
  );
};

const PopoverContentWrapper = ({ children, isMobile }: { children: React.ReactNode; isMobile: boolean }) => (
  <Portal>
    <Box
      sx={{
        '.rdp-day': {
          fontSize: 'sm',
        },
        '.chakra-popover__popper': isMobile
          ? {
              transform: { base: 'unset !important' },
              position: { base: 'fixed !important' },
              top: { base: '0 !important' },
              left: { base: '0 !important' },
              display: { base: 'flex' },
              alignItems: { base: 'center' },
              justifyContent: { base: 'center' },
              width: { base: '100vw' },
              height: { base: '100vh' },
              backgroundColor: { base: 'blackAlpha.200' },
            }
          : {},
      }}
    >
      <ThemeProvider2024>{children}</ThemeProvider2024>
    </Box>
  </Portal>
);

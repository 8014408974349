import React from 'react';
import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  VStack,
} from 'components/design/next';
import { UploadTemplateCoverIcon } from 'features/cover-icon/components/common/upload';
import { EmojiPicker } from 'features/cover-icon/components/common/emoji-picker';
import { Template } from '@process-street/subgrade/process';
import { useQueryClient } from 'react-query';
import { GetCoverIconByTemplateId } from 'features/cover-icon/query-builder';

export type CoverIconUploadPopoverProps = {
  templateId: Template['id'];
  onFinish?: () => void;
  children: React.ReactElement<ButtonProps>;
};

export const CoverIconUploadPopover: React.FC<React.PropsWithChildren<CoverIconUploadPopoverProps>> = ({
  templateId,
  onFinish,
  children,
  ...props
}) => {
  const queryClient = useQueryClient();

  const onEmojiSelectSuccess = (templateId: Template['id']) => {
    queryClient.invalidateQueries(GetCoverIconByTemplateId.getKey({ templateId }));
    onFinish && onFinish();
  };
  const modalDisclosure = useDisclosure();
  const triggerButton = React.cloneElement(children, {
    onClick: () => {
      modalDisclosure.onOpen();
    },
  });
  return (
    <>
      {triggerButton}
      <Modal {...props} {...modalDisclosure}>
        <ModalOverlay />
        <ModalContent minW={{ sm: 'full', md: 'md', lg: 'sm', xl: 'md' }}>
          <ModalCloseButton zIndex={1} />
          <ModalBody>
            <Tabs variant="default" isLazy>
              <TabList>
                <Tab aria-label="upload image">Upload Image</Tab>
                <Tab aria-label="add emoji">Emoji</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <UploadTemplateCoverIcon
                    templateId={templateId}
                    uploadingMessage="Uploading icon..."
                    acceptMimeTypes={{ 'image/*': [] }}
                    onFinish={onEmojiSelectSuccess}
                  >
                    <VStack align="center" spacing={3}>
                      <Button size="sm">Choose image</Button>
                      <Text align="center" variant="-2" color="gray.500" fontWeight="normal">
                        Recommended size 75x75px
                      </Text>
                    </VStack>
                  </UploadTemplateCoverIcon>
                </TabPanel>
                <TabPanel p="0">
                  <EmojiPicker {...{ templateId, onFinish: onEmojiSelectSuccess }} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

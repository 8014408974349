import * as React from 'react';
import { Text, VStack } from 'components/design/next';
import { ActorRefFrom } from 'xstate';
import { TaskMachine } from 'pages/responses/_id/components/task/task-machine';
import { FormResponseActor } from 'pages/responses/_id/components/form-response-body/form-response-machine';
import { ApprovalStatus } from '@process-street/subgrade/approval-rule';
import { ApprovedTasksSection } from './approved-tasks-section';
import { ArrayUtils } from 'app/utils/array-utils';
import { WaitingForApprovalTasksSection } from './waiting-for-approval-tasks-section';
import { TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { CreateAllApprovalsMutation } from 'app/features/approvals/query-builder';
import { MuidUtils } from '@process-street/subgrade/core';
import { useTaskApprovalTaskGroupsMap } from './hooks/use-approval-task-groups-map';
import { TaskMachineHooks } from '../task/task-machine-hooks';
import { FormResponseMachineHooks } from '../form-response-body/form-response-machine-hooks';

export type ApprovalTaskProps = {
  taskMachine: ActorRefFrom<TaskMachine>;
  formResponseActor: FormResponseActor;
};

export const ApprovalTask = ({ taskMachine, formResponseActor }: ApprovalTaskProps) => {
  const api = FormResponseMachineHooks.useApi(formResponseActor);
  const approvals = FormResponseMachineHooks.useApprovals();
  const approvalTask = TaskMachineHooks.useTask(taskMachine);
  const widgetsByTaskMap = FormResponseMachineHooks.useFormFieldWidgetsWithValuesByTaskMap();

  const taskGroupsMap = useTaskApprovalTaskGroupsMap({ taskMachine });

  const handleOnUpsertApproval = (task: TaskWithTaskTemplate, status: ApprovalStatus, comment?: string) => {
    if (!approvalTask) return;

    const existingApproval = approvals.find(
      approval => approval.subjectTaskId === task.id && approval.approvalTaskId === approvalTask.id,
    );
    const id = existingApproval?.id ?? MuidUtils.randomMuid();

    const approval: CreateAllApprovalsMutation.ApprovalDto = {
      id,
      subjectTaskId: task.id,
      approvalTaskId: approvalTask.id,
      organizationId: approvalTask.organization.id,
      status,
      comment,
    };

    api.onCreateApprovals([approval]);
  };

  if (!approvalTask) return;

  return (
    <VStack width="full" gap={6}>
      {ArrayUtils.isNonEmptyArray(taskGroupsMap.awaitingTasks) && (
        <WaitingForApprovalTasksSection
          tasks={taskGroupsMap.awaitingTasks}
          widgetsByTaskMap={widgetsByTaskMap}
          handleUpsertApproval={handleOnUpsertApproval}
        />
      )}

      {taskGroupsMap.notSubmittedTasks.length > 0 && (
        <VStack alignItems="stretch" gap={4} width="full">
          <Text variant="1u" color="gray.400" fontWeight="bold">
            Waiting for submission
          </Text>

          {taskGroupsMap.notSubmittedTasks.map(task => (
            <VStack key={task.id} alignItems="flex-start" backgroundColor="gray.50" padding={4}>
              <Text>{task.taskTemplate.name}</Text>
              <Text fontStyle="italic" color="gray.500">
                Will be submitted
              </Text>
            </VStack>
          ))}
        </VStack>
      )}
      {taskGroupsMap.rejectedTasks.length > 0 && (
        <VStack alignItems="stretch" gap={4} width="full">
          <Text
            alignSelf="flex-start"
            padding={1}
            variant="1u"
            color="red.500"
            backgroundColor="red.50"
            fontWeight="bold"
          >
            Rejected
          </Text>

          {taskGroupsMap.rejectedTasks.map(task => (
            <VStack
              key={task.id}
              alignItems="flex-start"
              backgroundColor="gray.50"
              padding={4}
              borderLeft="solid 2px"
              borderColor="red.500"
            >
              <Text>{task.taskTemplate.name}</Text>
            </VStack>
          ))}
        </VStack>
      )}

      {ArrayUtils.isNonEmptyArray(taskGroupsMap.approvedTasks) && (
        <ApprovedTasksSection tasks={taskGroupsMap.approvedTasks} handleUpsertApproval={handleOnUpsertApproval} />
      )}
    </VStack>
  );
};
